import { VehicleImpoundmentFeeType } from "./VehicleImpoundmentFeeType";

export interface VehicleImpoundmentFee {
  id: string;
  feeType: VehicleImpoundmentFeeType;
  description?: string;
  total: number;
  baseRate: number;
  daysStored: number;
  mileageRate: number;
  mileageKM: number;
  hourlyRate: number;
  winchUsageHours: number;
}

export const defaultVehicleImpoundmentFee = {
  id: "00000000-0000-0000-0000-000000000000",
  feeType: VehicleImpoundmentFeeType.TowingLight,
  description: "",
  total: 0,
  baseRate: 0,
  daysStored: 0,
  mileageRate: 0,
  mileageKM: 0,
  hourlyRate: 0,
  winchUsageHours: 0,
};

export interface ImpoundmentFeeRule {
  id: string;
  isActive?: boolean;
  feeType: VehicleImpoundmentFeeType;
  feeTypeDescription: string;
  hourlyRate: number;
  baseRate: number;
  mileageRate: number;
  effectiveDate: string;
  expiryDate?: string;
  changeReason?: string;
}

export const defaultImpoundmentFeeRule: ImpoundmentFeeRule = {
  id: "00000000-0000-0000-0000-000000000000",
  feeType: VehicleImpoundmentFeeType.Administration,
  feeTypeDescription: "",
  hourlyRate: 0,
  baseRate: 0,
  mileageRate: 0,
  effectiveDate: "",
};
