import { Col, Form } from "react-bootstrap";
import { VehicleImpoundment } from "../../Types/vehicleImpoundment";
import { IsSoldStatus } from "../../Types/impoundedVehicleStatus";
import Accordion from "../../Components/Accordion/Accordion";
import FormLabel from "../../Components/FormElements/FormLabel";
import FormText from "../../Components/FormElements/FormText";

interface Props {
  onChange(event: any): void;
  vehicleImpoundment: VehicleImpoundment;
  control: boolean;
}

export function SaleProcessForm({
  onChange,
  vehicleImpoundment,
  control,
}: Props) {
  const formCheckBox = (
    label: string,
    field: string,
    value: any,
    required: boolean = false
  ) => {
    return (
      <>
        <FormLabel required={required} text={label} />
        <Form.Check>
          <Form.Check.Input
            data-testid={field}
            name={field}
            onChange={onChange}
            checked={value ?? false}
            required={required}
          />
          <Form.Control.Feedback type="invalid">
            {label} is required
          </Form.Control.Feedback>
        </Form.Check>
      </>
    );
  };

  const isSoldStatus = IsSoldStatus(vehicleImpoundment.status);

  if (!isSoldStatus) return <></>;

  return (
    <>
      <Accordion title="Sales Details" defaultShow control={control}>
        <Form.Row>
          <Form.Group as={Col} md="5" controlId="formSalesDocRecvd">
            {formCheckBox(
              "Sales Document Received",
              "salesDocRecvd",
              vehicleImpoundment.salesDocRecvd,
              isSoldStatus
            )}
          </Form.Group>
          <Form.Group as={Col} md="7" controlId="formStatutoryDeclRecvd">
            {formCheckBox(
              "Completed Statutory Declaration of Sale Received",
              "statutoryDeclRecvd",
              vehicleImpoundment.statutoryDeclRecvd,
              isSoldStatus
            )}
          </Form.Group>
        </Form.Row>
        <br />
        <Form.Row>
          <Form.Group as={Col} md="5" controlId="formInvoiceRecvd">
            {formCheckBox(
              "Invoice Received",
              "invoiceRecvd",
              vehicleImpoundment.invoiceRecvd
            )}
          </Form.Group>
        </Form.Row>
        <br />
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="saleReceivedDate">
            <FormText
              type="date"
              testId="saleReceivedDate"
              onChange={onChange}
              label="Sales Document Received Date"
              field="saleReceivedDate"
              value={vehicleImpoundment.saleReceivedDate}
              required={isSoldStatus}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="saleDate">
            <FormText
              type="date"
              testId="saleDate"
              onChange={onChange}
              label="Sale Date"
              field="saleDate"
              value={vehicleImpoundment.saleDate}
              required={isSoldStatus}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="paymentDate">
            <FormText
              type="date"
              testId="paymentDate"
              onChange={onChange}
              label="Payment Date"
              field="paymentDate"
              value={vehicleImpoundment.paymentDate}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </Form.Row>
      </Accordion>
    </>
  );
}

export default SaleProcessForm;
