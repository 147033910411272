import { Address } from "./address";
import { CommentCount } from "./comment";

export interface Location {
  [index: string]: any;
  id: string;
  status: LocationStatus;
  garageKeeperId: string;
  phoneNumber?: string;
  physicalAddress: Address;
  operationHours: OperationHours[];
  commentCount: CommentCount;
  attachmentCount: number;
  impoundmentCount: number;
}

export enum LocationStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
}

export interface OperationHours {
  [index: string]: any;
  day: string;
  closed: boolean;
  open: string;
  close: string;
}

export const defaultOperationHours: OperationHours = {
  day: "",
  closed: true,
  open: "0:00",
  close: "0:00",
};

const getDefaultDayOfWeek = (day: string) => {
  return { ...defaultOperationHours, day };
};

const defaultWeek = [
  getDefaultDayOfWeek("Sunday"),
  getDefaultDayOfWeek("Monday"),
  getDefaultDayOfWeek("Tuesday"),
  getDefaultDayOfWeek("Wednesday"),
  getDefaultDayOfWeek("Thursday"),
  getDefaultDayOfWeek("Friday"),
  getDefaultDayOfWeek("Saturday"),
];

export const defaultLocation: Location = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperId: "",
  status: LocationStatus.Active,
  physicalAddress: {
    street: "",
    city: "",
    country: "CA",
    postalCode: "",
    province: "SK",
  },
  operationHours: defaultWeek,
  commentCount: {
    internal: 0,
    external: 0,
  },
  attachmentCount: 0,
  impoundmentCount: 0,
};
