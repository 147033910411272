import { useEffect, useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Alert, Row } from "react-bootstrap";
import {
  faWarehouse,
  faCar,
  faRecycle,
  faPaperclip,
  faFileAlt,
  faHandPaper,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router";
import { useIsAuthenticated } from "@azure/msal-react";

import allActions from "Redux/allActions";
import RegularLayout from "Layouts/RegularLayout";
import { GkCounts } from "Types/gkCounts";
import { VICounts } from "Types/VICounts";
import { VIFormCounts } from "Types/VIFormCounts";
import DashboardCount from "./DashboardCount";
import {
  impoundmentRequestTypes,
  garagekeeperRequestTypes,
} from "Types/requestType";
import { GarageKeeperStatus } from "Types/garageKeeperStatus";
import {
  ImpoundedVehicleStatus,
  ImpoundedVehicleStatusLabel,
  IsApprovedOrSoldStatus,
  IsApprovedStatus,
  IsSoldStatus,
} from "Types/impoundedVehicleStatus";
import { RequestStatus } from "Redux/requestStatus";

import RouteSwitcher from "Components/Admin/RouteSwitcher";
import DashboardCard from "./DashboardCard";
import DashboardRequestCountTable from "./DashboardRequestCountTable";
import LawEnforcementAttachmentList from "Components/LawEnforcement/LawEnforcementAttachmentList";
import DashboardInsuranceCount from "./DashboardInsuranceCount";
import DashboardRequireAttentionCommentsCount from "./DashboardRequireAttentionCommentsCount";

export default function DashboardInternal() {
  fontAwesomeLibrary.add(
    faWarehouse,
    faCar,
    faRecycle,
    faPaperclip,
    faFileAlt,
    faHandPaper,
    faCheckCircle
  );

  const VICounts: VICounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.impoundedVehicleCounts
  );
  const gkCounts: GkCounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.garageKeeperStatusCounts
  );
  const VIFormCounts: VIFormCounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.impoundedVehicleFormCounts
  );
  const status: RequestStatus = useSelector(
    (state: RootStateOrAny) => state.dashboard.status
  );
  const gkFYICounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.gkFYICounts
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const getCount = (type: any, filter: string) => {
    return type[filter] ?? type[filter.toLowerCase()];
  };

  const refreshStats = useCallback(() => {
    dispatch(allActions.DashboardActions.loadGarageKeeperCounts());
    dispatch(allActions.DashboardActions.loadImpoundedVehicleCounts());
    dispatch(allActions.DashboardActions.loadImpoundedVehicleFormCounts());
    dispatch(allActions.DashboardActions.loadInsurancePolicyCounts());
    dispatch(allActions.DashboardActions.loadRequestCounts());
    dispatch(allActions.DashboardActions.loadGarageKeeperFYICounts());
  }, [dispatch]);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) refreshStats();
  }, [dispatch, isAuthenticated, refreshStats]);

  return (
    <RegularLayout title="Dashboard">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>
      <RouteSwitcher />
      {status === RequestStatus.ERROR && (
        <Alert variant="danger">Error loading dashboard data!</Alert>
      )}
      <Row>
        <DashboardCard testId="impoundment-request-counts">
          <DashboardRequestCountTable
            types={impoundmentRequestTypes}
            title="Impoundment Requests"
            history={history}
            internal={true}
          />
        </DashboardCard>
        <DashboardCard testId="gk-request-counts">
          <DashboardRequestCountTable
            types={garagekeeperRequestTypes}
            title="Garage Keeper Requests"
            history={history}
            internal={true}
          />
        </DashboardCard>
        <DashboardInsuranceCount />
        <DashboardCard
          title="Garage Keepers"
          icon="warehouse"
          testId="gk-status-counts"
        >
          {Object.keys(GarageKeeperStatus).map((status) => {
            return (
              <DashboardCount
                key={status}
                handleClick={() => {
                  history.push({
                    pathname: "/GarageKeepers",
                  });
                  dispatch(allActions.GKList.filterStatus(status));
                }}
                countTitle={status}
                status={status}
                count={getCount(gkCounts, status)}
              />
            );
          })}
        </DashboardCard>
        <DashboardCard
          title="Impounded Vehicles"
          icon="car"
          testId="impounded-vehicle-counts"
        >
          {Object.keys(ImpoundedVehicleStatus)
            .filter((status) => !IsApprovedOrSoldStatus(status))
            .filter((status) => status !== "Released")
            .map((status) => {
              return (
                <DashboardCount
                  key={status}
                  handleClick={() => {
                    history.push({
                      pathname: "/ImpoundedVehicles",
                    });
                    dispatch(
                      allActions.ImpoundedVehicleListActions.filterTermEnded(
                        false
                      )
                    );
                    dispatch(
                      allActions.ImpoundedVehicleListActions.filterWrongful(
                        false
                      )
                    );
                    dispatch(
                      allActions.ImpoundedVehicleListActions.filterStatus(
                        status
                      )
                    );
                  }}
                  countTitle={
                    ImpoundedVehicleStatusLabel[
                      status as keyof typeof ImpoundedVehicleStatusLabel
                    ]
                  }
                  status={status}
                  count={getCount(VICounts, status)}
                />
              );
            })}
          <DashboardCount
            key="Wrongful"
            handleClick={() => {
              history.push({
                pathname: "/ImpoundedVehicles",
              });
              dispatch(
                allActions.ImpoundedVehicleListActions.filterStatusAll()
              );
              dispatch(
                allActions.ImpoundedVehicleListActions.filterTermEnded(false)
              );
              dispatch(
                allActions.ImpoundedVehicleListActions.filterWrongful(true)
              );
            }}
            countTitle="Wrongful"
            status="Wrongful"
            count={getCount(VICounts, "Wrongful")}
          />
          <DashboardCount
            key="TermEnded"
            handleClick={() => {
              history.push({
                pathname: "/ImpoundedVehicles",
              });
              dispatch(
                allActions.ImpoundedVehicleListActions.filterStatusAll()
              );
              dispatch(
                allActions.ImpoundedVehicleListActions.filterWrongful(false)
              );
              dispatch(
                allActions.ImpoundedVehicleListActions.filterTermEnded(true)
              );
            }}
            countTitle="Term Ended"
            status="TermEnded"
            count={getCount(VICounts, "TermEnded")}
          />
          <DashboardCount
            handleClick={() => {
              history.push({
                pathname: "/ImpoundedVehicles",
              });
              dispatch(
                allActions.ImpoundedVehicleListActions.filterInvFormReceived(
                  true
                )
              );
            }}
            status="Missing"
            countTitle="Inventory Forms "
            count={VIFormCounts}
          />
        </DashboardCard>
        <DashboardCard
          title="Disposals"
          icon="recycle"
          testId="disposal-counts"
        >
          {Object.keys(ImpoundedVehicleStatus)
            .filter(
              (status) => IsApprovedStatus(status) && !IsSoldStatus(status)
            )
            .map((status) => {
              return (
                <DashboardCount
                  key={status}
                  handleClick={() => {
                    history.push({
                      pathname: "/ImpoundedVehicles",
                    });
                    dispatch(
                      allActions.ImpoundedVehicleListActions.filterStatus(
                        status
                      )
                    );
                  }}
                  countTitle={
                    ImpoundedVehicleStatusLabel[
                      status as keyof typeof ImpoundedVehicleStatusLabel
                    ]
                  }
                  status={status}
                  count={getCount(VICounts, status)}
                />
              );
            })}
        </DashboardCard>
        <DashboardCard
          title="FYI - Changes in Last Week"
          icon="check-circle"
          testId="gk-fyi-counts"
        >
          {Object.keys(gkFYICounts).map((counts) => {
            return (
              <DashboardCount
                key={counts}
                // handleClick={() => {
                //   history.push({
                //     pathname: "/GarageKeepers",
                //   });
                //   dispatch(allActions.GKList.filterStatus(status));
                // }}
                countTitle={counts}
                status={counts}
                count={getCount(gkFYICounts, counts)}
              />
            );
          })}
        </DashboardCard>
        <DashboardCard
          title="Law Enforcement Documents"
          testId="le-docs"
        >
          <div
            style={{
              paddingRight: "10px",
            }}
          >
            <LawEnforcementAttachmentList />
          </div>
        </DashboardCard>
        <DashboardRequireAttentionCommentsCount/>
      </Row>
    </RegularLayout>
  );
}
