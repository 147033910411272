import { Col, Form } from "react-bootstrap";

import FormText from "Components/FormElements/FormText";
import { Contact } from "Types/contact";
import { patterns } from "Utils/regex";

interface Props {
  contact: Contact;
  onChange(event: any): void;
}

export default function LawEnforcementContactForm({
  contact,
  onChange,
}: Props) {
  return (
    <div data-testid="law-enforcement-contact-form">
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="contactDescription">
          <FormText
            testId="contact-description"
            required
            label="Contact Name"
            field="description"
            value={contact.description}
            onChange={onchange}
            disabled
          />
          <Form.Control.Feedback />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="contactPhoneNumber">
          <FormText
            required
            testId="contact-phone"
            label="Phone Number"
            field="phoneNumber"
            value={contact.phoneNumber}
            pattern={patterns.phoneNumber}
            onChange={onChange}
            invalidMessage=" is invalid"
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
}
