import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { validateFileType } from "Utils/fileUtils";

interface Props {
  required?: boolean;
  onFileSelect: any;
  multiple?: boolean;
  selectedFiles: any[];
  testId?: string;
  accept?: string;
  onFileRemove(index: number): any;
  useDescription?: boolean;
  descriptionPlaceholder?: string;
  descriptionRequired?: boolean;
  onDescriptionChange?(index: number, value: string): any;
}

function FormAttachment({
  required = false,
  onFileSelect,
  multiple = false,
  selectedFiles,
  onFileRemove,
  accept,
  testId,
  useDescription = false,
  descriptionRequired = false,
  descriptionPlaceholder = "Description",
  onDescriptionChange,
}: Props) {
  library.add(faWindowClose);
  interface DeleteButtonProps {
    i: number;
  }
  const DeleteButton = ({ i }: DeleteButtonProps) => (
    <div
      style={{
        display: "inline-flex",
        borderRadius: "50%",
        overflow: "hidden",
        margin: "0 0.7em",
        flexShrink: 0,
      }}
      onClick={() => onFileRemove(i)}
      data-testid={"file-remove-" + i}
    >
      <FontAwesomeIcon className="delete-button" icon="window-close" />
    </div>
  );

  return (
    <div data-testid="form-attachment">
      <div className="inputWrapper">
          Click here to select a file, or drag and drop
          <input
            required={required}
            accept={accept}
            data-testid={testId}
            type="file"
            name="fileUpload"
            id="fileUpload"
            multiple={multiple}
            className="fileInput"
            onChange={(e: any) => {
              const files: File[] = Array.from(e.target.files ?? []);
              let validatedFiles: File[] = [];
    
              files.forEach((f) => {
                if (validateFileType(f)) validatedFiles.push(f);
              });
    
              multiple
                ? onFileSelect(validatedFiles)
                : onFileSelect(validatedFiles[0]);

              e.target.value = "";
            }}
          />
      </div>
      {selectedFiles.length === 1 && !multiple ? (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <span style={{ whiteSpace: "nowrap", padding: " 0 0.5rem" }}>
            {selectedFiles[0].name}
          </span>
          {useDescription && (
            <Form.Control
              required={descriptionRequired}
              style={{
                marginLeft: "1em",
              }}
              data-testid="file-description-0"
              placeholder={descriptionPlaceholder}
              onChange={(e: any) => onDescriptionChange?.(0, e.target.value)}
            />
          )}
          <DeleteButton i={0} />
        </div>
      ) : (
        <div>
          {selectedFiles.map((file, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DeleteButton i={i} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5em",
                  }}
                >
                  {file.name}
                  {useDescription && (
                    <Form.Control
                      required={descriptionRequired}
                      style={{
                        marginBottom: "1em",
                        width: "20em",
                      }}
                      data-testid={"file-description-" + i}
                      placeholder={descriptionPlaceholder}
                      onChange={(e: any) =>
                        onDescriptionChange?.(i, e.target.value)
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Form.Control.Feedback
        type="invalid"
        data-testid={testId ? testId + "-error" : undefined}
      >
        Attachment is required
      </Form.Control.Feedback>
    </div>
  );
}

export default FormAttachment;
