import { useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Button, Form, Modal, Alert, Badge } from "react-bootstrap";
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import CommentElement from "./CommentElement";
import CommentInput from "./CommentInput";
import { Comment, CommentCount } from "Types/comment";
import Loader from "Components/Loader/Loader";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { UserPermissions, UserType } from "Types/userInfo";
import CommentActions from "Redux/comments/CommentsActions";
import { EntityType } from "Types/EntityType";

interface Props {
  id: string;
  count: CommentCount;
  size?: string;
  entityType: EntityType;
  onOpen?: () => Promise<void>;
  parentEntityExist?: boolean;
  orgId?: string;
  readOnly?: boolean;
}

function CommentContainer(
  { id,
    count,
    size,
    entityType,
    onOpen,
    parentEntityExist = true,
    orgId = undefined,
    readOnly = false
  }: Props) {
  library.add(faCommentDots);

  const isExternal =
    useSelector((state: RootStateOrAny) => state.userInfo.userType) ===
    UserType.GarageKeeper;
  const comments = useSelector((state: RootStateOrAny) => state.comments.list as Comment[]);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const [show, setShow] = useState(false);

  const commentCount: number = useMemo(() => {
    let initialCount = isExternal
      ? count.external
      : count.external + count.internal;

    if (comments.length === 0)
      return initialCount;
    else
      return comments.filter((x) => !isExternal || x.isInternal !== isExternal)
        .length;
  }, [count, comments, isExternal]);

  const buttonSize = size === "lg" ? 6 : 0;

  const dispatch = useDispatch();

  const requestUrl = `${window.REACT_APP_API_BASEURL}comments${isExternal ? "/external" : ""
    }/commentEntityId=${id}?entityType=${entityType}`;

  const commentsContainer = {
    border: "1px solid grey",
    borderRadius: "5px",
  } as React.CSSProperties;

  const commentsList = {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "10px 10px 10px 10px",
    maxHeight: "400px",
    scrollbarWidth: "thin",
  } as React.CSSProperties;

  const commentBox = {
    borderBottom: "1px solid grey",
    padding: "10px",
  } as React.CSSProperties;

  const countStyle = {
    position: "absolute",
    left: `${buttonSize + 10}px`,
    top: `${buttonSize + 20}px`,
    fontSize: "10px",
  } as React.CSSProperties;

  const buttonStyle = {
    cursor: "pointer",
    fontSize: `${buttonSize + 25}px`,
    position: "relative",
  } as React.CSSProperties;

  const handleClose = () => {
    setShow(false);
  };
  const { editId } = useSelector((state: RootStateOrAny) => state.comments);

  const disableMainInput = editId !== "";

  return (
    <>
      <span
        style={buttonStyle}
        className="edit-button"
        title="Comments"
        onClick={async () => {
          if (onOpen !== undefined)
            await onOpen();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={["far", "comment-dots"]} />
        {commentCount > 0 && (
          <Badge
            style={countStyle}
            pill
            data-testid="commentCount"
            variant="danger"
          >
            {commentCount}
          </Badge>
        )}
      </span>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <h2>Comments</h2>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Loader
              url={requestUrl}
              onBeforeLoad={() =>
                dispatch(CommentActions.clearCommentState())}
              onLoadComplete={(result: Comment[]) => {
                dispatch(CommentActions.setList(result));
              }}
              disabled={!parentEntityExist}
            >
              <div style={commentsContainer}>
                {
                  !readOnly &&
                  <div style={commentBox}>
                    <CommentInput
                      id={id}
                      author={account?.name!}
                      disabled={disableMainInput}
                      onSave={(c: Comment) => dispatch(CommentActions.setList([c, ...comments]))}
                      entityType={entityType}
                      orgId={orgId}
                    />
                  </div>
                }

                {comments.length > 0 ? (
                  <div style={commentsList}>
                    {comments.map((x: Comment, i: number) => {
                      if (editId === x.id)
                        return (
                          <CommentInput
                            id={id}
                            key={x.id}
                            author={account?.name!}
                            commentToEdit={x}
                            onSave={(c: Comment) =>
                              dispatch(CommentActions.setList([c, ...comments]))
                            }
                            onUpdate={(c: Comment) => {
                              let temp = comments;
                              temp[i] = c;
                              dispatch(CommentActions.setList([...temp]))
                            }}
                            entityType={entityType}
                            orgId={orgId}
                          />
                        );
                      else
                        return (
                          <CommentElement
                            key={x.id}
                            comment={x}
                            user={(account?.idTokenClaims as any)?.email}
                            onDelete={() => {
                              const temp = [...comments];
                              temp.splice(i, 1);
                              dispatch(CommentActions.setList(temp));
                            }}
                            onUpdate={(c: Comment) => {
                              let temp = comments;
                              temp[i] = c;
                              dispatch(CommentActions.setList([...temp]));
                            }}
                            entityType={entityType}
                            readonly={readOnly}
                          />
                        );
                    })}
                  </div>
                ) : (
                  <div>
                    <Alert variant="dark">
                      <i>No comments found</i>
                    </Alert>
                  </div>
                )}
              </div>
            </Loader>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CommentContainer;
