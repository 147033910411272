import { Button, FormLabel } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import RootActions from "Redux/root/rootActions";
import GKListActions from "Redux/garagekeepers/GKListActions";
import GKProfileActions from "Redux/garagekeepers/GKProfileActions";
import { UserInfoActions } from "Redux/userInfo/userInfoActions";
import { defaultGarageKeeper } from "Types/garageKeeper";
import { GarageKeeperListInfo } from "Types/garageKeeperListInfo";
import { GkPermissions, UserPermissions, UserType } from "Types/userInfo";
import FormSelect from "../FormElements/FormSelect";
import Loader from "Components/Loader/Loader";
import { SetNewGk } from "Routing/GlobalRouter";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";

export default function RouteSwitcher() {
  const dispatch = useDispatch();
  const {
    userType,
    userPermissions,
  }: { userType: UserType; userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );
  const gkList = useSelector((state: RootStateOrAny) => state.GKList.gkList);
  const gkProfile = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const userEmail = (account?.idTokenClaims as any)?.email;
  const currentUserType = userPermissions.isAdmin ? UserType.Admin : UserType.DriverExamEmployee;

  return (
    <div style={{ display: "flex", gap: "1em", margin: "1em 0 " }}>
      {(userPermissions.canViewAsGarageKeeper) && (
        <Button
          variant="outline-success"
          data-testid="switch-view-button"
          onClick={() => {
            dispatch(RootActions.changeUserType());
            dispatch(GKProfileActions.setGK(defaultGarageKeeper));
            if (userType === UserType.Admin || userType === UserType.DriverExamEmployee) {
              dispatch(UserInfoActions.setUserType(UserType.GarageKeeper));
            } else if (userType === UserType.GarageKeeper) {              
              dispatch(UserInfoActions.setUserType(currentUserType));
            }

            dispatch(UserInfoActions.setUserPermissions(userPermissions));
          }}
        >
          Switch to {userType === UserType.Admin || userType === UserType.DriverExamEmployee ? "Garage Keeper" : "Admin"}
        </Button>
      )}

      <div>
        {userType === UserType.GarageKeeper && (userPermissions.isAdmin || userPermissions.isDriverRecordsEmployee) 
        && (
          <Loader
            url={window.REACT_APP_API_BASEURL + "garageKeepers/list"}
            onLoadComplete={(data: GarageKeeperListInfo[]) =>
              dispatch(GKListActions.setGkList(data))
            }
          >
            <FormSelect
              testId="switch-view-gk-dropdown"
              field=""
              value={gkProfile.id}
              data={gkList}
              sorting={(i1, i2) => {
                if (i1.name < i2.name) return -1;
                if (i1.name > i2.name) return 1;
                return 0;
              }}
              defaultOption={{
                text: "Select a GK to view",
                value: defaultGarageKeeper.id,
              }}
              mapping={(g: GarageKeeperListInfo) => {
                return { text: g.name, value: g.id };
              }}
              onChange={(e: any) => {
                var newGk = gkList.find((g: any) => g.id === e.target.value);
                SetNewGk(dispatch, newGk);
              }}
            />
          </Loader>
        )}
        {userPermissions.isGarageKeeper &&
          userPermissions.gkPermissions.length > 1 && (
            <>
              <FormLabel data-testid="switch-gk-message">
                {gkProfile.id === defaultGarageKeeper.id
                  ? "Select a Garage Keeper to Work As: "
                  : "Switch to a different Garage Keeper to Work As: "}
              </FormLabel>
              <FormSelect
                testId="switch-gk-dropdown"
                field=""
                value={gkProfile.securityOrganizationId}
                data={userPermissions.gkPermissions}
                sorting={(i1, i2) => {
                  if (i1.name < i2.name) return -1;
                  if (i1.name > i2.name) return 1;
                  return 0;
                }}
                defaultOption={{
                  text: "Select a Garage Keeper",
                  value: defaultGarageKeeper.id,
                }}
                mapping={(p: GkPermissions) => {
                  return {
                    text: p.name,
                    value: p.securityOrganizationId,
                  };
                }}
                onChange={(e: any) => {
                  var workAsGk = userPermissions.gkPermissions.find(
                    (p: any) => p.securityOrganizationId === e.target.value
                  );
                  SetNewGk(dispatch, workAsGk);
                  localStorage.setItem(
                    "workAsGk",
                    JSON.stringify({
                      userEmail: userEmail,
                      workAsGk: workAsGk,
                    })
                  );
                }}
              />
            </>
          )}
      </div>
    </div>
  );
}
