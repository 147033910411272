import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import RequestActions from "Redux/requests/requestActions";
import { UserType } from "Types/userInfo";

interface Props {
  history: any;
}

export default function RequestListSortButtons({ history }: Props) {
  const { sortField, sortDirection } = useSelector(
    (state: RootStateOrAny) => state.requests
  );

  const dispatch = useDispatch();

  const Columns: { [key: string]: string } = {
    createdOn: "Created",
    garageKeeperName: "Garage Keeper",
    lastUpdatedOn: "Last Updated",
    policeFileNumber: "Police File #",
    status: "Status",
    type: "Type",
  };

  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  return (
    <>
      <span>&nbsp;</span>
      <ButtonGroup>
        <Button
          className="mb-2"
          onClick={() =>
            dispatch(
              RequestActions.sortRequests(
                sortField,
                sortDirection === "ascending" ? "descending" : "ascending"
              )
            )
          }
          data-testid="sort-direction"
        >
          {sortDirection === "ascending" ? "↑" : "↓"}
        </Button>

        <DropdownButton
          as={ButtonGroup}
          title={Columns[sortField]}
          id="bg-nested-dropdown"
          className="mb-2"
          data-testid="sort-field"
        >
          {Object.keys(Columns)
            .filter((k) =>
              userType === UserType.Admin &&
              history.location.pathname !== "/RequestHistory"
                ? true
                : k !== "garageKeeperName"
            )
            .map((columnName: string) => {
              return (
                <Dropdown.Item
                  key={columnName}
                  data-testid={`sort-${columnName}`}
                  onClick={() =>
                    dispatch(
                      RequestActions.sortRequests(columnName, sortDirection)
                    )
                  }
                >
                  {Columns[columnName]}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
      </ButtonGroup>
    </>
  );
}
