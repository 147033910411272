import { EntityType } from "./EntityType";

const AttentionStatus = {
  None: "None",
  Required: "Required",
  Resolved: "Resolved",
  Complete: "Completed"
}

export interface Comment {
  id: string;
  commentEntityId: string;
  commentEntityType: EntityType
  author: string;
  commentText: string;
  createdOn: Date;
  createdBy: string;
  edited: boolean;
  isInternal: boolean;
  garageKeeperId: string | null,
  attentionStatus: string;
}

export const defaultComment: Comment = {
  id: "00000000-0000-0000-0000-000000000000",
  commentEntityId: "00000000-0000-0000-0000-000000000000",
  author: "",
  commentText: "",
  createdOn: new Date(),
  createdBy: "",
  edited: false,
  isInternal: false,
  garageKeeperId: null,
  commentEntityType: EntityType.Request,
  attentionStatus: AttentionStatus.None
};

export interface CommentCount {
  internal: number;
  external: number;
}

export { AttentionStatus }