import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Spinner, Button } from "react-bootstrap";
import CallBffApi from "../../Utils/CallBff";

type Props = {
	url: string;
	onBeforeLoad?(): any;
	onLoadComplete(result: any): any;
	disabled?: boolean;
};

const Loader: FunctionComponent<Props> = (props) => {
	const [status, setStatus] = useState<string>("Initial");

	const { onLoadComplete, onBeforeLoad, url, disabled = false } = props;

	useEffect(() => {
		if (!disabled && status !== "Loaded") {			
			setStatus((status) => "Initial");
		}
		else {
			onLoadComplete([]);
			setStatus("Loaded");
		}
	}, [url]);

	const load = useCallback(async () => {
		if (!url) return;

		if (onBeforeLoad) {
			onBeforeLoad();
		}

		const response = await CallBffApi(url);

		if (!response.ok) {
			setStatus("Failed");
			return console.log("Error: ", JSON.stringify(response));
		}

		const result = await response.json();
		onLoadComplete(result);
		setStatus("Loaded");
	}, [onBeforeLoad, onLoadComplete, url]);

	useEffect(() => {
		if (!disabled && url && status === "Initial") {
			setStatus("Loading");
			load();
		}
	}, [url, status, setStatus, load]);

	return (
		<>
			{status === "Loading" && (
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			)}
			{status === "Failed" && (
				<>
					<h2>Failed to load.</h2>
					<Button onClick={() => setStatus("Initial")}>Retry</Button>
				</>
			)}
			{status === "Loaded" && props.children}
		</>
	);
};

export default Loader;
