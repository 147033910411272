import { formatInsurancePolicy, InsurancePolicy } from "../../Types/insurancePolicy";
import CallBffApi from "../../Utils/CallBff";
import { doToast, ToastType } from "../../Utils/toastUtils";

export const ADD_INSURANCE_POLICY = "INSURANCEPOLICY/ADD_INSURANCE_POLICY";
export const UPDATE_INSURANCE_POLICY =
  "INSURANCEPOLICY/UPDATE_INSURANCE_POLICY";

export const SET_INSURANCEPOLICIES = "INSURANCEPOLICY/SET_INSURANCEPOLICY";

export const SAVE_INSURANCEPOLICY = "INSURANCEPOLICY/SAVE_INSURANCEPOLICY";
export const SAVE_INSURANCEPOLICY_SUCCESS =
  "INSURANCEPOLICY/SAVE_INSURANCEPOLICY_SUCCESS";

export const LOAD_INSURANCEPOLICIES = "INSURANCEPOLICY/LOAD_INSURANCEPOLICIES";
export const LOAD_INSURANCEPOLICIES_SUCCESS =
  "INSURANCEPOLICY/LOAD_INSURANCEPOLICIES_SUCCESS";

export const DELETE_INSURANCEPOLICY = "INSURANCEPOLICY/DELETE_INSURANCEPOLICY";
export const DELETE_INSURANCEPOLICY_SUCCESS =
  "INSURANCEPOLICY/DELETE_INSURANCEPOLICY_SUCCESS";

export const ERROR = "INSURANCEPOLICY/API_ERROR";

export const insurancePolicyActions = {
  addInsurancePolicy: (insurancePolicy: InsurancePolicy) => {
    return {
      type: ADD_INSURANCE_POLICY,
      value: insurancePolicy,
    };
  },

  updateInsurancePolicy: (insurancePolicy: InsurancePolicy) => {
    return {
      type: UPDATE_INSURANCE_POLICY,
      value: insurancePolicy,
    };
  },

  setInsurancePolicies: (insurancePolicies: InsurancePolicy[]) => {
    return {
      type: SET_INSURANCEPOLICIES,
      value: insurancePolicies,
    };
  },

  startLoadingInsurancePolicies: () => {
    return { type: LOAD_INSURANCEPOLICIES };
  },

  loadInsurancePolicies: (gkId: string) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}insurancepolicies/List?garageKeeperId=${gkId}`
      );

      if (response.ok) {
        const result = await response.json();
        let formattedResult = [] as InsurancePolicy[];
			  result.forEach((p: InsurancePolicy) => formattedResult.push(formatInsurancePolicy(p)));
        return dispatch({
          type: LOAD_INSURANCEPOLICIES_SUCCESS,
          value: formattedResult,
        });
      }
      dispatch({
        type: ERROR,
      });
    };
  },

  saveInsurancePolicy: (insurancePolicy: InsurancePolicy) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}insurancepolicies`,
        {
          method: "POST",
          body: JSON.stringify(insurancePolicy),
        }
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: SAVE_INSURANCEPOLICY_SUCCESS,
          value: result,
        });
      }

      console.log("Error saving insurance policy in thunk");
      dispatch({ type: ERROR });
    };
  },

  deleteInsurancePolicy: (
    insurancePolicyId: string,
    deleteReason: string
  ) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}insurancepolicies/${insurancePolicyId}?deleteReason=${deleteReason}`,
        {
          method: "DELETE",
        }
      );
  
      if (response.ok) {
        doToast("Insurance policy deleted successfully", ToastType.Success);
  
        return dispatch({
          type: DELETE_INSURANCEPOLICY_SUCCESS,
          insurancePolicyId: insurancePolicyId,
        });
      }
  
      doToast("Error deleting insurance policy", ToastType.Error);
  
      dispatch({ type: ERROR });
    };
  },
};

export default insurancePolicyActions;
