import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

export enum ControlPosition {
  Bottom,
  BottomFloating,
  Top,
  TopAndBottom,
}

interface Props {
  onSubmit(event: any): Promise<boolean>;
  edit?: boolean;
  readonly?: boolean;
  children?: any;
  controlPosition?: ControlPosition;
  cancel?(): any;
  openInEditMode?: boolean;
  setKeepOpenOverride?: Function;
}

function CustomForm({
  onSubmit,
  cancel,
  controlPosition = ControlPosition.BottomFloating,
  children,
  readonly = false,
  edit = false,
  openInEditMode = false,
  setKeepOpenOverride,
}: Props) {
  const [keepOpen, setKeepOpen] = useState(false);
  const [inSubmission, setInSubmission] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editing, setEditing] = useState(openInEditMode);

  const handleCancel = () => {
    setValidated(false);

    if (edit && editing) setEditing(false);
    cancel?.();
  };

  const handleSubmit = async (event: any) => {
    setInSubmission(true);
    const result = await onSubmit(event);
    setInSubmission(false);
    if (result && !keepOpen) return cancel?.();
    setValidated(!result);
  };

  const controlsAtTop =
    controlPosition === ControlPosition.Top ||
    controlPosition === ControlPosition.TopAndBottom;

  const controlsAtBottom =
    controlPosition === ControlPosition.Bottom ||
    controlPosition === ControlPosition.BottomFloating ||
    controlPosition === ControlPosition.TopAndBottom;

  const multipleControls = controlsAtTop && controlsAtBottom;

  let controlStyle: React.CSSProperties = {};

  if (controlPosition === ControlPosition.BottomFloating) {
    controlStyle = {
      position: "sticky",
      bottom: "15px",
      backgroundColor: "#ffffffc8",
    };
  }

  const formControls = (location: string) => (
    <div style={controlStyle}>
      {edit && !editing ? (
        <Form.Row>
          <Button
            variant="primary"
            disabled={inSubmission || readonly}
            id={"form-edit" + (multipleControls ? "-" + location : "")}
            data-testid={"form-edit" + (multipleControls ? "-" + location : "")}
            onClick={() => setEditing(true)}
            style={{ width: "6rem" }}
          >
            Edit
          </Button>
        </Form.Row>
      ) : (
        <>
          <Form.Row>
            <Button
              variant="primary"
              disabled={inSubmission || readonly}
              type="submit"
              id={"form-submit" + (multipleControls ? "-" + location : "")}
              data-testid={
                "form-submit" + (multipleControls ? "-" + location : "")
              }
              style={{ width: "6rem" }}
            >
              Save
            </Button>
            <Button
              variant="secondary"
              onClick={handleCancel}
              id={"form-cancel" + (multipleControls ? "-" + location : "")}
              data-testid={
                "form-cancel" + (multipleControls ? "-" + location : "")
              }
              className="ml-2"
              style={{ width: "6rem" }}
            >
              Cancel
            </Button>
            {!edit && !readonly && (
              <div className="ml-2">
                <Form.Check
                  name="keepFormOpenChk"
                  checked={keepOpen}
                  label="Keep form open after save?"
                  onChange={(e) => {
                    if (setKeepOpenOverride !== undefined)
                      setKeepOpenOverride(e.target.checked);
                    setKeepOpen(e.target.checked);
                  }}
                  data-testid={
                    "form-keep-open" + (multipleControls ? "-" + location : "")
                  }
                />
              </div>
            )}
          </Form.Row>
        </>
      )}
    </div>
  );

  return (
    <Form
      data-testid="custom-form"
      noValidate
      onSubmit={handleSubmit}
      validated={validated}
    >
      {controlsAtTop && formControls("top")}
      <fieldset
        data-testid="form-fieldset"
        disabled={readonly || (edit && !editing)}
      >
        {children}
      </fieldset>
      {controlsAtBottom && formControls("bottom")}
    </Form>
  );
}

export default CustomForm;
