import { CommentCount } from "./comment";

export interface InsurancePolicy {
  [index: string]: any;
  id: string;
  garageKeeperId: string;
  state: InsuranceState;
  insuranceType: string | InsurancePolicyTypes;
  insurer: string;
  effectiveDate: Date | string;
  expiryDate: Date | string;
  liabilityAmount: number | string;
  commentCount: CommentCount;
  attachmentCount: number;
  status: InsurancePolicyStatus;
}

export enum InsurancePolicyStatus {
  Active = "Active",
  Pending = "Pending",
}

export enum InsuranceState {
  Active = "Active",
  Expired = "Expired",
  Expiring = "Expiring",
  Future = "Future",
}

export const defaultInsurancePolicy: InsurancePolicy = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperId: "00000000-0000-0000-0000-000000000000",
  state: InsuranceState.Active,
  insuranceType: "",
  insurer: "",
  effectiveDate: "",
  expiryDate: "",
  liabilityAmount: "",
  commentCount: {
    internal: 0,
    external: 0,
  },
  attachmentCount: 0,
  status: InsurancePolicyStatus.Active,
};

//This constant value is used to mark policies as "Expiring" if they expire within the next x days based on this threshold
//This constant's value should match the constant defined in the InsurancePolicyService in the BFF
export const expiringSoonDaysThreshold: number = 30;

export enum InsurancePolicyTypes {
  CollisionUpset = "Collision/Upset",
  Comprehensive = "Comprehensive",
}

export function insurancePolicyTypeDescription(
  type: InsurancePolicyTypes | string
): string {
  return InsurancePolicyTypes[type as keyof typeof InsurancePolicyTypes];
}

export const getInsurancePolicyState = (
  insurancePolicy: InsurancePolicy
): InsuranceState => {
  let today = new Date();
  let expDate = new Date(insurancePolicy.expiryDate);
  let effDate = new Date(insurancePolicy.effectiveDate);

  if (expDate <= today) return InsuranceState.Expired;
  if (effDate >= today) return InsuranceState.Future;
  return InsuranceState.Active;
};

export function formatInsurancePolicy(
  policy: InsurancePolicy
): InsurancePolicy {
  return {
    ...policy,
    state: getInsurancePolicyState(policy),
    effectiveDate: policy.effectiveDate.toString().split("T")[0],
    expiryDate: policy.expiryDate.toString().split("T")[0],
  };
}
