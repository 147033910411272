export function getAllErrorsFromBadRequestObject(
    badRequestObject: any
  ): string[] {
    const errorsToReturn: string[] = [];
  
    if (badRequestObject && badRequestObject["errors"]) {
      Object.keys(badRequestObject["errors"]).forEach((key) => {
        const errors: string[] = badRequestObject["errors"][key];
  
        errors.forEach((error) => errorsToReturn.push(error));
      });
    }
  
    return errorsToReturn;
  }
  
  export async function getBadRequestErrorsAsList(
    response: any
  ): Promise<JSX.Element | undefined> {
    let errors: string[] = [];
  
    if (response.status === 400) {
      const result = await response.json();
      errors = getAllErrorsFromBadRequestObject(result);
    }
  
    if (errors.length > 0) {
      const formattedErrrors = (
        <ul>
          {errors.map((val, idx) => {
            return <li key={`${idx}`}>{val}</li>;
          })}
        </ul>
      );
  
      return formattedErrrors;
    } else {
      return undefined;
    }
  }
  