import { Attachment } from "Types/attachment";
import CallBffApi from "./CallBff";
import { previewableExtensions } from "./consts";
import { doToast, ToastType } from "./toastUtils";

export const isFilePreviewable = (fileName: string) => {
  return previewableExtensions.some((ext) =>
    fileName.toLowerCase().endsWith(ext)
  );
};

export const retrieveAttachmentUrl = async (
  attachment: Attachment
): Promise<string> => {
  const attachmentUrl = `${(window as any).REACT_APP_API_BASEURL}attachments/${
    attachment.id
  }/download?entityType=${attachment.entityType}`;

  const response = await CallBffApi(attachmentUrl);

  if (response.ok) {
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } else {
    doToast(`Failed to retrieve file: ${attachment.fileName}`, ToastType.Error);
    return "";
  }
};

export const previewAttachment = (blobUrl: string) => {
  window.open(blobUrl);
};

export const downloadAttchment = (blobUrl: string, fileName: string) => {
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const allowedFileTypes = [
  "application/pdf", // pdf
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/vnd.ms-excel", // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/vnd.ms-powerpoint", // ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
  "application/rtf", // rtf
  "audio/wav", // wav
  "text/plain", // txt
  "image/jpeg", // jpg & jpeg
  "image/png", // png
  "image/bmp", // bmp
  "image/gif", // gif
  "image/tiff", // tiff
  "audio/mid", // rmi
  "audio/midi", // mid
];

const fileExtensions = `Documents
     .pdf  .txt
     .doc  .docx
     .xls   .xlsx
     .ppt  .pptx
     .rtf

Images
     .jpg  .jpeg  .png 
     .gif  .bmp   .tiff

Audio
     .wav  .rmi .mid
`;

export const validateFileType = (file: File): boolean => {
  if (allowedFileTypes.includes(file.type)) return true;

  let ext = file.name.split(".").pop();

  let message = (
    <div title={fileExtensions} style={{ margin: "-15px", padding: "15px" }}>
      File type .{ext} is not allowed.
      <br />
      <span style={{ color: "black", fontWeight: 600, fontStyle: "italic" }}>
        Mouse-over here to see a list of allowed file types.
      </span>
    </div>
  );

  doToast(message, ToastType.Error, 8000);
  return false;
};
