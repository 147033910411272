import Loader from "Components/Loader/Loader";
import ReusableModal from "Components/Modal/ReusableModal";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { RootStateOrAny, useSelector } from "react-redux";
import { Attachment } from "Types/attachment";
import { LawEnforcement } from "Types/lawEnforcement";
import { UserPermissions } from "Types/userInfo";
import {
  downloadAttchment,
  previewAttachment,
  retrieveAttachmentUrl,
} from "Utils/fileUtils";
import { formatDateStripTime } from "Utils/format";

interface Props {
  impoundmentId: string;
}

function ImpoundmentLawEnforcementList({ impoundmentId }: Props) {
  const [show, setShow] = useState(false);
  const [lawEnforcementRecords, setLawEnforcementRecords] = useState<
    LawEnforcement[]
  >([]);

  const { userPermissions }: { userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const getFile = async (
    e: any,
    attachment: Attachment,
    download: boolean = false
  ) => {
    e.preventDefault();

    const url = await retrieveAttachmentUrl(attachment);

    if (url !== "") {
      if (download) downloadAttchment(url, attachment.fileName);
      else previewAttachment(url);
    }
  };

  if (userPermissions.canViewLawEnforcementDocuments) {
    return (
      <div data-testid="ImpoundmentLEList">
        <Loader
          url={
            window.REACT_APP_API_BASEURL +
            "lawEnforcement/impoundmentId=" +
            impoundmentId
          }
          onLoadComplete={(records: LawEnforcement[]) => {
            setLawEnforcementRecords(records);
          }}
        >
          <ReusableModal
            close={() => setShow(false)}
            open={() => {
              setShow(true);
            }}
            show={show}
            buttonText={
              lawEnforcementRecords.length > 0
                ? `View Records (${lawEnforcementRecords.length})`
                : "No Records"
            }
            headerText="Law Enforcement Records"
            variant="outline-primary"
            disabled={lawEnforcementRecords.length === 0}
          >
            <Table data-testid="le-records-table">
              <thead>
                <tr>
                  <th>Date Submitted</th>
                  <th>Author</th>
                  <th colSpan={2}></th>
                </tr>
              </thead>
              <tbody>
                {lawEnforcementRecords.map((le) => {
                  return (
                    <tr key={le.id} data-testid={"le-record-" + le.id}>
                      <td>{formatDateStripTime(le.createdOn.toString())}</td>
                      <td>{le.createdBy}</td>

                      <td>
                        <a
                          href={le.attachment?.id}
                          onClick={(e) => getFile(e, le.attachment!)}
                        >
                          Preview
                        </a>
                      </td>
                      <td>
                        <a
                          href={le.attachment?.id}
                          onClick={(e) => getFile(e, le.attachment!, true)}
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  );
                })}
                {lawEnforcementRecords.length === 0 && (
                  <tr>
                    <td colSpan={4}>No records found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ReusableModal>
        </Loader>
      </div>
    );
  } else {
    return <div data-testid="ImpoundmentLEList-NoPermission"></div>;
  }
}

export default ImpoundmentLawEnforcementList;
