export enum VehicleImpoundmentFeeType {
  TowingLight = "TowingLight",
  TowingHeavy = "TowingHeavy",
  Storage = "Storage",
  DollyUse = "DollyUse",
  FlatDeck = "FlatDeck",
  Winching = "Winching",
  PlateReturn = "PlateReturn",
  Administration = "Administration",
  Other = "Other",
  AuctionLight = "AuctionLight",
  AuctionHeavy = "AuctionHeavy"
}

export const VehicleImpoundmentFeeLabel: {
  [key in VehicleImpoundmentFeeType]: string;
} = {
  [VehicleImpoundmentFeeType.TowingLight]: "Towing - Light Vehicle",
  [VehicleImpoundmentFeeType.TowingHeavy]: "Towing - Heavy Vehicle",
  [VehicleImpoundmentFeeType.Storage]: "Storage",
  [VehicleImpoundmentFeeType.DollyUse]: "Dolly Use",
  [VehicleImpoundmentFeeType.FlatDeck]: "Flat Deck",
  [VehicleImpoundmentFeeType.Winching]: "Winching",
  [VehicleImpoundmentFeeType.PlateReturn]: "Plate Return",
  [VehicleImpoundmentFeeType.Administration]: "Administration",
  [VehicleImpoundmentFeeType.Other]: "Other",
  [VehicleImpoundmentFeeType.AuctionLight]: "Tow to Auction - Light",
  [VehicleImpoundmentFeeType.AuctionHeavy]: "Tow to Auction - Heavy",
};
