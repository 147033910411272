import { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { UserType } from "Types/userInfo";
import CustomForm from "Components/FormElements/CustomForm";
import FormSelect from "Components/FormElements/FormSelect";

import FormText from "Components/FormElements/FormText";
import towingVehicleActions from "Redux/towingVehicles/towingVehicleActions";
import { TowingVehicle, defaultTowingVehicle } from "Types/towingVehicle";
import { TowingVehicleStatus } from "Types/towingVehicleStatus";
import { doToast, ToastType } from "Utils/toastUtils";
import { getBadRequestErrorsAsList } from "Utils/apiErrorUtils";

interface Props {
  data?: any;
  cancel?: any;
  submit: any;
}

export default function TowingVehicleForm(props: Props) {
  const isExternal =
    useSelector((state: RootStateOrAny) => state.userInfo.userType) ===
    UserType.GarageKeeper;

  const [towingVehicle, setTowingVehicle] = useState<TowingVehicle>(
    props.data ?? {
      ...defaultTowingVehicle,
      status: isExternal
        ? TowingVehicleStatus.Pending
        : defaultTowingVehicle.status,
    }
  );

  const [saved, setSaved] = useState(false);

  const dispatch = useDispatch();

  const editMode = props.data ? true : false;

  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const yearList: number[] = [];
  const startYear: number = 1925;
  const currentYear: number = new Date().getFullYear();
  for (let i = currentYear + 1; i >= startYear; i--) {
    yearList.push(i);
  }

  const handleUpdate = (event: any) => {
    let splitName = event.target.name.split(".");

    if (splitName[0] === "licensePlate" || splitName[0] === "vin") {
      setTowingVehicle({
        ...towingVehicle,
        [event.target.name]: event.target.value.toUpperCase(),
      });
    } else if (splitName[0] === "metRequirements") {
      setTowingVehicle({
        ...towingVehicle,
        [event.target.name]: event.target.checked,
      });
    } else {
      setTowingVehicle({
        ...towingVehicle,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event: any): Promise<boolean> => {
    event.preventDefault();

    let isValid = event.currentTarget.checkValidity();

    if (!isValid) return false;

    const towingVehicleToSubmit: TowingVehicle = {
      ...towingVehicle,
      garageKeeperId: garageKeeper.id,
    };

    let requestUrl = `${window.REACT_APP_API_BASEURL}towingvehicles`;

    if (isExternal) requestUrl += "/external";

    if (editMode) requestUrl += `/${towingVehicle.id}`;

    try {
      const response = await props.submit(requestUrl, {
        method: editMode ? "PUT" : "POST",
        body: JSON.stringify(towingVehicleToSubmit),
      });

      if (response.ok) {
        if (!editMode) {
          const result = await response.json();
          dispatch(towingVehicleActions.addTowingVehicle(result));
          setTowingVehicle({
            ...defaultTowingVehicle,
            status: isExternal
              ? TowingVehicleStatus.Pending
              : defaultTowingVehicle.status,
          });
        } else {
          dispatch(
            towingVehicleActions.updateTowingVehicle(towingVehicleToSubmit)
          );
        }
        setSaved(true);
        doToast("Towing Vehicle saved sucessfully", ToastType.Success);
        return true;
      } else {
        const formattedApiErrors = await getBadRequestErrorsAsList(response);

        doToast(formattedApiErrors ?? "An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const handleCancel = () => {
    if (editMode && !saved) {
      setTowingVehicle(props.data);
    }
    props.cancel?.();
  };

  return (
    <CustomForm
      edit={editMode}
      onSubmit={handleSubmit}
      cancel={handleCancel}
      openInEditMode={true}
    >
      {/* Only show Status dropdown when external and editing */}
      {(!isExternal || (isExternal && editMode)) && (
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formStatus">
            <FormSelect
              required
              testId={"towvehicleform-status"}
              label="Status"
              field="status"
              onChange={handleUpdate}
              data={Object.keys(TowingVehicleStatus).filter(
                (status) => status !== TowingVehicleStatus.Pending
              )}
              value={towingVehicle.status}
              defaultOption={
                towingVehicle.status === TowingVehicleStatus.Pending
                  ? { value: "Pending", text: "Pending" }
                  : undefined
              }
              mapping={(s) => {
                return { value: s, text: s };
              }}
              disabled={isExternal && editMode}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="formMake">
          <FormText
            required
            testId={"towvehicleform-make"}
            label="Make"
            field="make"
            pattern="^[a-zA-Z ]{1,20}"
            invalidMessage={
              towingVehicle.make.length > 20
                ? " must be 20 characters or less"
                : " must only contain letters and spaces"
            }
            value={towingVehicle.make}
            onChange={handleUpdate}
            disabled={isExternal && editMode}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formModel">
          <FormText
            required
            testId={"towvehicleform-model"}
            label="Model"
            field="model"
            pattern=".{1,20}"
            invalidMessage=" must be 20 characters or less"
            value={towingVehicle.model}
            onChange={handleUpdate}
            disabled={isExternal && editMode}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formYear">
          <FormText
            required
            testId={"towvehicleform-year"}
            type="number"
            label="Year"
            field="year"
            value={towingVehicle.year}
            pattern="^[0-9]{4}$"
            onChange={handleUpdate}
            min="1886"
            max={new Date().getFullYear() + 1}
            invalidMessage={
              towingVehicle.year
                ? " must be between 1886 and " + (new Date().getFullYear() + 1)
                : ""
            }
            disabled={isExternal && editMode}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formVin">
          <FormText
            required
            testId={"towvehicleform-vin"}
            label="VIN"
            field="vin"
            pattern="^[a-zA-Z0-9]{11,17}$"
            invalidMessage={
              towingVehicle.vin.length < 11 || towingVehicle.vin.length > 17
                ? " must be between 11-17 characters"
                : " must only contain letters and numbers"
            }
            value={towingVehicle.vin}
            onChange={handleUpdate}
            disabled={isExternal && editMode}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formPlate">
          <FormText
            required
            testId={"towvehicleform-plate"}
            label="License Plate"
            field="licensePlate"
            pattern="^[a-zA-Z0-9 -]{1,10}$"
            invalidMessage={
              towingVehicle.licensePlate !== undefined &&
              towingVehicle.licensePlate.length > 10
                ? " must be 10 characters or less"
                : " must only contains letters, numbers, spaces, hyphens"
            }
            value={towingVehicle.licensePlate}
            onChange={handleUpdate}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formRegisteredOwner">
          <FormText
            required
            testId={"towvehicleform-owner"}
            label="Registered Owner"
            field="registeredOwner"
            pattern=".{1,30}"
            invalidMessage=" must be 30 characters or less"
            value={towingVehicle.registeredOwner}
            onChange={handleUpdate}
            disabled={isExternal && editMode}
          />
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}
