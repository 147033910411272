import { useDispatch } from "react-redux";

interface Props {
    property: string,
    label: string,
    sortField: string,
    sortDirection: string,
    action: Function
}

function SortButton(props: Props) {
    const dispatch = useDispatch();
    
    const requestSort = (key: string) => {
        let direction = "ascending";
        if (props.sortField === key && props.sortDirection === "ascending") {
          direction = "descending";
        }
        dispatch(props.action(key, direction));
      };

    const sortSymbol = () => {
        if(props.property === props.sortField)
            return props.sortDirection === "ascending" ? "↑" : "↓"
        else
            return "⇵"
    }

    const sortButtonStyle = {
        border: 'none',
        background: sortSymbol() === "⇵" ? 'none' : 'gainsboro',
        borderBottom: 'solid grey',
        borderRadius: "3px"
    } as React.CSSProperties;

  return (
    <button style={sortButtonStyle} onClick={() => requestSort(props.property)}>
        {props.label} {sortSymbol()}
    </button>
  );
}

export default SortButton;
