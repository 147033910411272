import { useDispatch } from "react-redux";
import allActions from "Redux/allActions";
import { RequestStatus } from "Types/request";

export interface link {
  path: string;
  icon: string;
  text: string;
  onClick?: any;
}

export function GetInternalLinks(): link[] {
  const dispatch = useDispatch();

  return [
    {
      path: "/Admin",
      icon: "user-cog",
      text: "Admin Functions",
    },
    {
      path: "/GarageKeepers",
      icon: "warehouse",
      text: "Garage Keepers",
    },
    {
      path: "/ImpoundedVehicles",
      icon: "car",
      text: "Impounded Vehicles",
    },
    {
      path: "/ExpiredInsurance",
      icon: "file-alt",
      text: "Expired Insurance",
    },
    {
      path: "/RequestList",
      icon: "exchange-alt",
      text: "Requests",
      onClick: () => {
        dispatch(allActions.RequestActions.filterGk("ALL", "All"));
        dispatch(
          allActions.RequestActions.filterStatus(RequestStatus.AssignedToSgi)
        );
        dispatch(allActions.RequestActions.filterRecent(false));
        dispatch(allActions.RequestActions.filterType("ALL"));
      },
    },
    {
      path: "/leManSubNotification",
      icon: "upload",
      text: "Submit LE Notifications",
    },
  ];
}

export function GetGarageKeeperLinks(): link[] {
  const dispatch = useDispatch();

  return [
    {
      path: "/Profile",
      icon: "user",
      text: "Profile",
    },
    {
      path: "/Contacts",
      icon: "address-book",
      text: "Contacts",
    },
    {
      path: "/Compounds",
      icon: "garage-car",
      text: "Compounds",
    },
    {
      path: "/TowingVehicles",
      icon: "truck-pickup",
      text: "Towing Fleet",
    },
    {
      path: "/ImpoundedVehicles",
      icon: "car",
      text: "Impounded Vehicles",
    },
    {
      path: "/InsurancePolicies",
      icon: "newspaper",
      text: "Insurance Policies",
    },
    {
      path: "/RequestList",
      icon: "exchange-alt",
      text: "Requests",
      onClick: () => {
        dispatch(allActions.RequestActions.filterGk("ALL", "All"));
        dispatch(
          allActions.RequestActions.filterStatus(RequestStatus.AssignedToGk)
        );
        dispatch(allActions.RequestActions.filterRecent(false));
        dispatch(allActions.RequestActions.filterType("ALL"));
      },
    },
    {
      path: "/Policies",
      icon: "book-open",
      text: "Policies",
    },
    {
      path: "/Manuals",
      icon: "book",
      text: "Manuals",
    },
    {
      path: "/ContactUs",
      icon: "comments",
      text: "Contact Us",
    },
  ];
}

export const lawEnforcementLinks: link[] = [
  {
    path: "/ContactUs",
    icon: "comments",
    text: "Contact Us",
  },
];
