import {
  LOAD_LOCATIONS_SUCCESS,
  SAVE_LOCATION_SUCCESS,
  ERROR,
  SET_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
} from "./locationActions";
import { RequestStatus } from "../requestStatus";
import { Location } from "../../Types/location";

export interface LocationState {
  status: RequestStatus;
  locations: Location[];
}

const initialState: LocationState = {
  locations: [] as Location[],
  status: RequestStatus.IDLE,
};

export const locationReducer: any = (
  state: LocationState = initialState,
  action: any
): LocationState => {
  let type = action.type;

  switch (type) {
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.value,
      };
    case ADD_LOCATION:
      return {
        ...state,
        locations: [...state.locations, action.value],
      };
    case UPDATE_LOCATION:
      let index = state.locations.findIndex((l) => l.id === action.value.id);

      if (index === -1) return state;

      let tempLocations = [...state.locations];
      tempLocations.splice(index, 1, action.value);

      return {
        ...state,
        locations: tempLocations,
      };

    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        status: RequestStatus.IDLE,
        locations: action.value,
      };
    case SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        status: RequestStatus.IDLE,
        locations: [...state.locations, action.value],
      };
    case ERROR:
      return {
        ...state,
        status: RequestStatus.ERROR,
      };
    default:
      return state;
  }
};
