import { Attachment } from "../../Types/attachment";

export const SET_ATTACHMENTS = "ATTACHMENT/SET_ATTACHMENTS";
export const ADD_ATTACHMENT = "ATTACHMENT/ADD_ATTACHMENT";
export const UPDATE_ATTACHMENT = "ATTACHMENT/UPDATE_ATTACHMENT";
export const DELETE_ATTACHMENT = "ATTACHMENT/DELETE_ATTACHMENT";

const setAttachments = (attachments: Attachment[]) => {
  return {
    type: SET_ATTACHMENTS,
    attachments: attachments,
  };
};

const addAttachment = (attachment: Attachment) => {
  return {
    type: ADD_ATTACHMENT,
    attachment: attachment,
  };
};

const updateAttachment = (attachment: Attachment) => {
  return {
    type: UPDATE_ATTACHMENT,
    attachment: attachment,
  };
};

const deleteAttachment = (attachmentId: string) => {
  return {
    type: DELETE_ATTACHMENT,
    attachmentId: attachmentId,
  };
};

const AttachmentActions = {
  setAttachments,
  addAttachment,
  updateAttachment,
  deleteAttachment,
};

export default AttachmentActions;
