import {
  SET_TOWVEHICLES,
  ADD_TOWVEHICLE,
  UPDATE_TOWVEHICLE,
  DELETE_TOWVEHICLE_SUCCESS,
  DEACTIVATE_TOWING_VEHICLE_SUCCESS,
} from "./towingVehicleActions";
import { TowingVehicle } from "../../Types/towingVehicle";
import { TowingVehicleStatus } from "Types/towingVehicleStatus";

export interface TowingVehicleState {
  towingVehicles: TowingVehicle[];
}

const initialState: TowingVehicleState = {
  towingVehicles: [] as TowingVehicle[],
};

export const towingVehicleReducer: any = (
  state: TowingVehicleState = initialState,
  action: any
): TowingVehicleState => {
  let type = action.type;

  switch (type) {
    case SET_TOWVEHICLES:
      return {
        ...state,
        towingVehicles: action.value,
      };

    case ADD_TOWVEHICLE:
      return {
        ...state,
        towingVehicles: [...state.towingVehicles, action.value],
      };

    case UPDATE_TOWVEHICLE: {
      const index = state.towingVehicles.findIndex(
        (t) => t.id === action.value.id
      );
      if (index === -1) return state;

      let newTowingVehicles = [...state.towingVehicles];
      newTowingVehicles.splice(index, 1, action.value);

      return {
        ...state,
        towingVehicles: [...newTowingVehicles],
      };
    }

    case DELETE_TOWVEHICLE_SUCCESS:
      return {
        ...state,
        towingVehicles: state.towingVehicles.filter(
          (tv) => tv.id !== action.towingVehicleId
        ),
      };

    case DEACTIVATE_TOWING_VEHICLE_SUCCESS: {
      let index = state.towingVehicles.findIndex((v) => v.id === action.id);
      if (index === -1) return state;

      let temp = [...state.towingVehicles];
      temp[index] = {
        ...temp[index],
        status: TowingVehicleStatus.Inactive,
      };

      return { ...state, towingVehicles: [...temp] };
    }
    default:
      return state;
  }
};
