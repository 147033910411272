import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import RegularLayout from "Layouts/RegularLayout";
import VehicleImpoundmentForm from "Forms/VehicleImpoundmentForm/VehicleImpoundmentForm";
import ImpoundedVehicleListContainer from "./ImpoundedVehicleListContainer";
import ReusableModal from "Components/Modal/ReusableModal";
import GKProfileActions from "Redux/garagekeepers/GKProfileActions";
import { defaultGarageKeeper } from "Types/garageKeeper";
import CallBffApi from "Utils/CallBff";
import { CSVLink } from "react-csv";
import {
  defaultImpoundedVehicleListInfo,
  ImpoundedVehicleListInfo,
} from "Types/impoundedVehicleListInfo";
import { formatDate } from "Utils/format";
import { UserPermissions } from "Types/userInfo";

interface Props {
  history: any;
}
export function getExportImpoundmentFilteredList(
  impoundmentList: ImpoundedVehicleListInfo[]
) {
  return impoundmentList.map((x: ImpoundedVehicleListInfo) => ({
    VIN: x.vin.toString(),
    "Police File#": x.policeFileNumber ?? "",
    "Term Start Date": formatDate(x.termStartDate?.toString()),
    "Term End Date": formatDate(x.termEndDate?.toString()),
    "Days Impounded": x.daysImpounded.toString(),
    "Garage Keeper": x.garageKeeperName,
    Wrongful: x.wrongful,
    Status: x.status.toString(),
    "Inventory Form Missing": x.inventoryFormMissing,
  }));
}
function ImpoundedVehicleList(props: Props) {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [impoundmentList, setImpoundmentList] = useState([
    defaultImpoundedVehicleListInfo,
  ]);
  const hideForm = () => setShowForm(false);

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  const displayForm = () => {
    dispatch(GKProfileActions.setGK(defaultGarageKeeper));
    setShowForm(true);
  };

  return (
    <RegularLayout title="Impounded Vehicles">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {userPermissions.canCreateImpoundment &&
          <ReusableModal
            buttonText="Add New Impoundment"
            headerText="Add New Impoundment"
            open={() => displayForm()}
            close={hideForm}
            show={showForm}
          >
            <VehicleImpoundmentForm
              submit={CallBffApi}
              cancel={hideForm}
              history={props.history}
            />
          </ReusableModal>
        }
        <CSVLink
          data={getExportImpoundmentFilteredList(impoundmentList)}
          filename={
            "Garage Keeper Impoundment List - " +
            new Date().toDateString() +
            ".csv"
          }
          className="btn btn-primary"
        >
          Export Impoundment List
        </CSVLink>
      </div>

      <div className="card shadow mb-4 ">
        <div className="card-header py-3">
          <h4 className="m-0 font-weight-bold text-success">
            Impounded Vehicles
          </h4>
        </div>
        <div className="card-body">
          <ImpoundedVehicleListContainer
            setAllVehicleImpoundList={setImpoundmentList}
          />
        </div>
      </div>
    </RegularLayout>
  );
}

export default ImpoundedVehicleList;
