import BulletinListGarageKeeper from "Components/Bulletins/BulletinListGarageKeeper";
import Loader from "Components/Loader/Loader";
import RegularLayout from "Layouts/RegularLayout";
import { useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  acknowledgeBulletinTypes,
  Bulletin,
} from "Types/bulletin";
import { UserPermissions } from "Types/userInfo";
import CallBffApi from "Utils/CallBff";

interface Props {
  submit?: any; // for testing
  children: any;
}

function BulletinAcknowledgementProvider({
  submit = CallBffApi,
  children,
}: Props) {
  const userPermissions: UserPermissions = useSelector(
    (state: RootStateOrAny) => state.userInfo.userPermissions
  );
  const [loaded, setLoaded] = useState(false);
  const [bulletins, setBulletins] = useState<Bulletin[]>([]);

  const needsAcknowledgement = useMemo(() => {
    let ack = bulletins.length > 0 && loaded && userPermissions.isGarageKeeper;
    return ack;
  }, [bulletins.length, loaded, userPermissions.isGarageKeeper]);

  const bulletinTypesQueryString = acknowledgeBulletinTypes
    .map((t) => `bulletinTypes=${t}`)
    .join("&");

  return (
    <Loader
      url={`${window.REACT_APP_API_BASEURL}bulletins/external?${bulletinTypesQueryString}`}
      onLoadComplete={(bulletins: Bulletin[]) => {
        setBulletins(bulletins.filter((b) => !b.acknowledged));
        setLoaded(true);
      }}
    >
      {needsAcknowledgement ? (
        <RegularLayout title="Bulletin Acknowledgements">
          <h1 className="text-center pt-5">
            You must acknowledge all bulletins and policies
          </h1>
          <h1 className="text-center">before using the application</h1>
          <hr />
          <Container data-testid="bulletin-acknowledgements">
            <BulletinListGarageKeeper
              bulletins={bulletins}
              submit={async (url: string, data: any) => {
                const response = await submit(url, data);

                if (response.ok) {
                  const id = JSON.parse(data.body).bulletinId;
                  let index = bulletins.findIndex((b) => b.id === id);
                  let temp = [...bulletins];
                  temp.splice(index, 1);
                  setBulletins(temp);
                }

                return response;
              }}
            />
          </Container>
        </RegularLayout>
      ) : (
        <>{children}</>
      )}
    </Loader>
  );
}

export default BulletinAcknowledgementProvider;
