import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { defaultOperationHours, OperationHours } from "../../Types/location";

import { daysOfWeek as DAYS } from "../../Utils/consts";
import { patterns } from "../../Utils/regex";
import FormText from "../../Components/FormElements/FormText";
interface Props {
  onChange(event: any): void;
  hours?: OperationHours[];
}

export function validateOperationHoursTime(day: OperationHours) {
  if (day.closed) return true;

  if (!day.open.match(patterns.hour)) return false;
  if (!day.close.match(patterns.hour)) return false;

  const open = day.open.split(":");
  const close = day.close.split(":");

  const openHour = parseInt(open[0]);
  const openMinute = parseInt(open[1]);
  const closeHour = parseInt(close[0]);
  const closeMinute = parseInt(close[1]);

  if (openHour < closeHour) return true;
  if (openHour > closeHour) return false;
  if (openMinute <= closeMinute) return true;

  return false;
}

function HoursOfOperationForm({ onChange, ...props }: Props) {
  const [changed, setChanged] = useState<boolean>(false);
  const [hours, setHours] = useState<OperationHours[]>(
    props.hours && props.hours.length > 0
      ? props.hours
      : (new Array(7).fill(defaultOperationHours, 0, 7) as OperationHours[])
  );

  const updateHours = (index: number, field: string, value: string) => {
    let newHours = [...hours];
    newHours[index] = {
      ...newHours[index],
      [field]: value,
    };

    // If the open/close times are currently 0:00, blank out the field when changing the day
    //  from "closed" to "open" to that the user doesn't have to type over the existing value
    if (field === "closed" && value.toString() === "false") {
      if (newHours[index].open === "0:00") {
        newHours[index].open = "";
      }
      if (newHours[index].close === "0:00") {
        newHours[index].close = "";
      }
    }

    setHours(newHours);
  };

  const handleChange = (event: any) => {
    let [i, field] = event.target.name.split(".");
    if (field === "closed") {
      // Trigger change event on open/close toggle
      updateHours(i, field, event.target.checked);
      setChanged(true);
      return;
    }
    updateHours(i, field, event.target.value.replace(/[^0-9:]/g, ""));
  };

  const handleBlur = (event: any) => {
    let [i, field] = event.target.name.split(".");
    let value = autoCompleteTime(event.target.value);
    updateHours(i, field, value);

    setChanged(true);
  };

  const autoCompleteTime = (value: string): string => {
    // If blank or a complete time, do nothing
    if (value === "" || value.match(/\d{1}:\d{2}$/g)) return value;

    let [hour, minutes] = value.split(":");

    if (!hour) hour = "0";
    if (!minutes) minutes = "00";
    if (minutes.match(/^\d$/g)) minutes += "0";

    return hour + ":" + minutes;
  };

  const sendChangeEvent = useCallback(() => {
    let event = {
      target: {
        name: "operationHours",
        value: hours,
      },
    };
    setChanged(false);
    onChange(event);
  }, [onChange, hours]);

  useEffect(() => {
    // Notify parent component of change
    if (!changed) return;
    sendChangeEvent();
  }, [changed, sendChangeEvent]);

  return (
    <Form.Group as={Col}>
      <Form.Group as={Row}>
        <Form.Group as={Col} md={2}></Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>Closed?</Form.Label>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Open</Form.Label>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>Close</Form.Label>
        </Form.Group>
      </Form.Group>
      {hours.map((day, i) => {
        const isValid = validateOperationHoursTime(day);
        if (day.day === "")
          handleChange({ target: { name: i + ".day", value: i.toString() } });
        return (
          <Form.Group as={Row} key={i} controlId={i + ".isClosed"}>
            <Form.Label as={Col} md={2}>
              {DAYS[i]}
            </Form.Label>
            <Form.Group as={Col} md={1}>
              <Form.Check
                checked={day.closed}
                name={i + ".closed"}
                onChange={handleChange}
                data-testid={`${i}.isClosed`}
              />
            </Form.Group>
            <Form.Group as={Col} md={3} controlId={i + ".openTime"}>
              <FormText
                required
                field={i + ".open"}
                value={day.closed ? "" : day.open}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={day.closed ? "" : "ex: 8:00"}
                disabled={day.closed}
                pattern={patterns.hour}
                isValid={isValid}
                invalidMessage={
                  !day.open.match(patterns.hour)
                    ? "Invalid time."
                    : "Must open before close."
                }
                testId={`${i}.openTime`}
              />
            </Form.Group>
            <Form.Group as={Col} md={3} controlId={i + ".closeTime"}>
              <FormText
                required
                field={i + ".close"}
                value={day.closed ? "" : day.close}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={day.closed ? "" : "ex: 17:00"}
                disabled={day.closed}
                pattern={patterns.hour}
                isValid={isValid}
                invalidMessage={isValid ? "Invalid time." : ""}
              />
            </Form.Group>
          </Form.Group>
        );
      })}
    </Form.Group>
  );
}

export default HoursOfOperationForm;
