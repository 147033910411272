import { ImpoundmentAggregateStatus } from "Types/impoundedVehicleStatus";
import { ImpoundedVehicleListInfo } from "../../Types/impoundedVehicleListInfo";
import { RequestStatus } from "../requestStatus";
import {
  SET_LIST,
  FILTER_STATUS,
  FILTER_STATUS_ALL,
  FILTER_LOCATION,
  FILTER_LOCATION_ALL,
  SORT,
  LOAD_IMPOUNDED_VEHICLE_LIST,
  LOAD_IMPOUNDED_VEHICLE_LIST_SUCCESS,
  API_ERROR,
  ADD_IMPOUNDMENT,
  FILTER_BY_INVFORM_RECEIVED,
  FILTER_WRONGFUL,
  FILTER_TERM_ENDED,
  SET_SEARCH_VIN,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_TOTAL_COUNT,
  CLEAR_IMPOUNDED_VEHICLE_LIST_STATE,
  FILTER_LAW_ENFORCEMENT_FILE_MISSING,
  FILTER_DATE_RANGE_START,
  FILTER_DATE_RANGE_END,
} from "./ImpoundedVehicleListActions";

export interface ImpoundedVehicleState {
  statusFilter: string;
  locationFilter: string;
  invFormFilter?: boolean;
  wrongfulFilter: boolean;
  termEndedFilter: boolean;
  lawEnforcementFileMissingFilter: boolean;
  sortField: string;
  sortDirection: string;
  list: ImpoundedVehicleListInfo[];
  status: RequestStatus;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  hasPrev: boolean;
  hasNext: boolean;
  totalCount: number;
  searchVin: string;
  dateRangeFilterStart?: Date;
  dateRangeFilterEnd?: Date;
}

export const initialState: ImpoundedVehicleState = {
  statusFilter: "InCompound",
  locationFilter: "ALL",
  invFormFilter: false,
  wrongfulFilter: false,
  termEndedFilter: false,
  lawEnforcementFileMissingFilter: false,
  sortField: "termStartDate",
  sortDirection: "descending",
  list: [] as ImpoundedVehicleListInfo[],
  status: RequestStatus.IDLE,
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  hasPrev: false,
  hasNext: false,
  totalCount: 0,
  searchVin: "",
  dateRangeFilterStart: undefined,
  dateRangeFilterEnd: undefined,
};

export default function ImpoundedVehicleListReducer(
  state: ImpoundedVehicleState = initialState,
  action: any
) {
  let newState = { ...state };
  switch (action.type) {
    case FILTER_STATUS: {
      newState.statusFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_STATUS_ALL: {
      newState.statusFilter = ImpoundmentAggregateStatus.All.toString();
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_LOCATION: {
      newState.locationFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_LOCATION_ALL: {
      newState.locationFilter = "ALL";
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_BY_INVFORM_RECEIVED: {
      newState.invFormFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_WRONGFUL: {
      newState.wrongfulFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_TERM_ENDED: {
      newState.termEndedFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_LAW_ENFORCEMENT_FILE_MISSING: {
      newState.lawEnforcementFileMissingFilter = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_DATE_RANGE_START: {
      newState.dateRangeFilterStart = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case FILTER_DATE_RANGE_END: {
      newState.dateRangeFilterEnd = action.filter;
      newState.pageNumber = action.page;
      return newState;
    }

    case SORT: {
      newState.sortField = action.sortField;
      newState.sortDirection = action.sortDirection;
      newState.pageNumber = action.page;
      return newState;
    }

    case SET_LIST: {
      newState.list = action.impoundedVehicleList;
      return newState;
    }

    case LOAD_IMPOUNDED_VEHICLE_LIST: {
      newState.status = RequestStatus.LOADING;
      return newState;
    }

    case LOAD_IMPOUNDED_VEHICLE_LIST_SUCCESS: {
      newState.status = RequestStatus.IDLE;
      newState.list = action.value;
      return newState;
    }

    case ADD_IMPOUNDMENT: {
      return {
        ...state,
        list: [...state.list, action.impoundment],
        status: RequestStatus.IDLE,
      };
    }

    case SET_SEARCH_VIN: {
      newState.searchVin = action.searchVin;
      newState.pageNumber = action.page;
      return newState;
    }

    case SET_PAGE: {
      newState.pageNumber = action.page;
      return newState;
    }

    case SET_PAGE_SIZE: {
      newState.pageSize = action.pageSize;
      return newState;
    }

    case SET_TOTAL_COUNT: {
      newState.totalCount = action.totalCount;
      newState.totalPages = action.totalPages;
      newState.hasPrev = action.hasPrev;
      newState.hasNext = action.hasNext;
      return newState;
    }

    case CLEAR_IMPOUNDED_VEHICLE_LIST_STATE: {
      return initialState;
    }

    case API_ERROR: {
      newState.status = RequestStatus.ERROR;
      return newState;
    }

    default:
      return state;
  }
}
