import { Comment, AttentionStatus } from "Types/comment"

export const allCommentsFilter = (comment: Comment, email: string) => comment.createdBy === email
    && (comment.attentionStatus === AttentionStatus.Resolved || comment.attentionStatus === AttentionStatus.Required)
    || (comment.createdBy !== email && comment.attentionStatus === AttentionStatus.Required);

export const myCommentsFilter = (comment: Comment, email: string) => 
    comment.createdBy === email;

export const resolvedFilter = (comment: Comment, email: string) => 
    comment.createdBy === email && comment.attentionStatus === AttentionStatus.Resolved;