import { useState } from "react";
import CustomForm from "Components/FormElements/CustomForm";
import { doToast, ToastType } from "Utils/toastUtils";
import { Feedback, defaultFeedback, FeedbackType } from "Types/feedback"
import { Form, Col } from "react-bootstrap";
import FormSelect from "Components/FormElements/FormSelect";
import FormText from "Components/FormElements/FormText";

interface Props {
  submit: any;
  cancel: any;
  author: string;
}

export default function FeedbackForm({ submit, cancel, author }: Props) {
  const [feedback, setFeedback] = useState<Feedback>({...defaultFeedback, author: author});

  const handleCancel = () => {
    cancel();
  };

  const handleUpdate = (event: any) => {
    setFeedback({
      ...feedback,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) return false;

    let requestUrl = `${window.REACT_APP_API_BASEURL}feedback`;

    try {
      const response = await submit(requestUrl, {
        method: "POST",
        body: JSON.stringify(feedback),
      });

      if (response.ok) {
        doToast("Feedback Submitted successfully", ToastType.Success);
        return true;
      } else {
        doToast("An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  return (
    <CustomForm
      onSubmit={handleSubmit}
      cancel={handleCancel}
      openInEditMode={true}
    >
      <Form.Row>
      <Form.Group as={Col} md="4" controlId="feedbackType">
            <FormSelect
              required
              testId={"feedback-type"}
              label="Feedback Type"
              field="type"
              onChange={handleUpdate}
              data={Object.keys(FeedbackType)}
              value={feedback.type}
              mapping={(s) => {
                return { value: s, text: s };
              }}
            />
            <Form.Control.Feedback />
          </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="feedbackText">
          <FormText
            required
            as="textarea"
            testId="feedback-text"
            placeholder=""
            label="Feedback"
            field="feedbackText"
            value={feedback.feedbackText}
            onChange={handleUpdate}
          />
          <Form.Control.Feedback />
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}
