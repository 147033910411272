import GKList from "./garagekeepers/GKListActions";
import GKProfile from "./garagekeepers/GKProfileActions";
import Contact from "./contacts/ContactListActions";
import ImpoundedVehicleListActions from "./impoundedvehicles/ImpoundedVehicleListActions";
import LocationActions from "./locations/locationActions";
import TowingVehicleActions from "./towingVehicles/towingVehicleActions";
import InsurancePolicyActions from "./insurancePolicies/insurancePolicyActions";
import { DashboardActions } from "./dashboard/dashboardActions";
import CommentsActions from "./comments/CommentsActions";
import ExpiredInsuranceActions from "./expiredInsurance/ExpiredInsuranceActions";
import AttachmentActions from "./attachments/AttachmentActions";
import VehicleImpoundmentActions from "./vehicleImpoundment/vehicleImpoundmentActions";
import { UserInfoActions } from "./userInfo/userInfoActions";
import RequestActions from "./requests/requestActions";
import RequiredCommentsActions from "./comments/RequiredCommentsActions";

const allActions = {
  GKList,
  GKProfile,
  Contact,
  ImpoundedVehicleListActions,
  LocationActions,
  TowingVehicleActions,
  InsurancePolicyActions,
  DashboardActions,
  CommentsActions,
  ExpiredInsuranceActions,
  AttachmentActions,
  VehicleImpoundmentActions,
  UserInfoActions,
  RequestActions,
  RequiredCommentsActions
};

export default allActions;
