import VehicleImpoundmentForm from "Forms/VehicleImpoundmentForm/VehicleImpoundmentForm";
import ImpoundedVehicleListContainer from "./ImpoundedVehicleListContainer";
import ReusableModal from "Components/Modal/ReusableModal";
import { useState } from "react";
import CallBffApi from "Utils/CallBff";
import { UserPermissions } from "Types/userInfo";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  garageKeeperId: string;
  history: any;
}

function GarageKeeperImpoundedVehicleList({ garageKeeperId, history }: Props) {
  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  return (
    <>
      {userPermissions.canCreateImpoundment &&
        <ReusableModal
          buttonText="Add New Impoundment"
          headerText="Add New Impoundment"
          open={() => {
            displayForm();
          }}
          close={hideForm}
          show={showForm}
        >
          <VehicleImpoundmentForm submit={CallBffApi} cancel={hideForm} history={history} />
        </ReusableModal>
      }
      <br />
      <br />
      <ImpoundedVehicleListContainer
        garageKeeperId={garageKeeperId}
        profileView
      />
    </>
  );
}

export default GarageKeeperImpoundedVehicleList;
