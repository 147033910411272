import { Col, Form } from "react-bootstrap";

import FormLabel from "Components/FormElements/FormLabel";
import FormText from "Components/FormElements/FormText";
import { Contact, ContactType } from "Types/contact";
import { patterns } from "Utils/regex";

interface Props {
  contact: Contact;
  onChange(event: any): void;
}

export function ValidateOtherContact(contact: Contact): boolean {
  if (contact.contactType !== ContactType.Other) {
    return true;
  } else {
    return isAnyMethodEntered(contact) && isDescriptionValid(contact);
  }
}

function isAnyMethodEntered(contact: Contact): boolean {
  return !(!contact.emailAddress && !contact.phoneNumber && !contact.faxNumber);
}

function isDescriptionValid(contact: Contact): boolean {
  return (
    contact.description.toLowerCase() !== "main office" &&
    contact.description.toLowerCase() !== "law enforcement"
  );
}

export default function OtherContactForm({ contact, onChange }: Props) {
  return (
    <div data-testid="other-contact-form">
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="contactDescription">
          <FormText
            testId="contact-description"
            required
            label="Contact Name"
            field="description"
            value={contact.description}
            onChange={onChange}
            isValid={isDescriptionValid(contact)}
            invalidMessage={
              isDescriptionValid(contact) ? " is required" : " is invalid"
            }
          />
          <Form.Control.Feedback />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="contactPhoneNumber">
          <FormLabel text="Phone Number" />
          <FormText
            testId="contact-phone"
            field="phoneNumber"
            value={contact.phoneNumber}
            pattern={patterns.phoneNumber}
            onChange={onChange}
            isValid={isAnyMethodEntered(contact)}
            invalidMessage={
              isAnyMethodEntered(contact)
                ? "Phone Number is invalid"
                : "At least one contact method must be entered"
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="contactEmailAddress">
          <FormLabel text="Email Address" />
          <FormText
            testId="contact-email"
            field="emailAddress"
            value={contact.emailAddress}
            pattern={patterns.email}
            onChange={onChange}
            isValid={isAnyMethodEntered(contact)}
            invalidMessage={
              isAnyMethodEntered(contact)
                ? "Email Address is invalid"
                : "At least one contact method must be entered"
            }
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="contactFaxNumber">
          <FormLabel text="Fax Number" />
          <FormText
            testId="contact-fax"
            field="faxNumber"
            pattern={patterns.phoneNumber}
            value={contact.faxNumber}
            onChange={onChange}
            isValid={isAnyMethodEntered(contact)}
            invalidMessage={
              isAnyMethodEntered(contact)
                ? "Fax Number is invalid"
                : "At least one contact method must be entered"
            }
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
}
