import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import FormAttachment from "../FormElements/FormAttachment";
import CallBffApi from "Utils/CallBff";
import { buildQueryString } from "Utils/apiUtils";
import { emptyGuid } from "Utils/guid";
import { EntityType } from "Types/EntityType";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { doToast, ToastType } from "Utils/toastUtils";
import { UserPermissions } from "Types/userInfo";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  onSubmit?: any;
}

function LawEnforcementAttachment({ onSubmit = CallBffApi }: Props) {
  const [showValidation, setShowValidation] = useState(false);
  const [files, setFiles] = useState<
    { file: File; policeFileNumber: string }[]
  >([]);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo;

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  const handleSubmit = async (e: any) => {
    setShowValidation(true);
    e.preventDefault();

    if (!e.currentTarget.checkValidity()) return false;

    let filesToSubmit = [...files];
    const endpoint = window.REACT_APP_API_BASEURL + "lawEnforcement?";

    // Submit each file separately
    for (let i = 0; i < filesToSubmit.length; i++) {
      const fileToSend = filesToSubmit[i];
      if (!fileToSend) break;

      const url =
        endpoint +
        buildQueryString({
          entityId: emptyGuid,
          entityType: EntityType.LawEnforcement,
          description: "Law Enforcement Impoundment Form",
          policeFileNumber: fileToSend.policeFileNumber,
          fileName: fileToSend.file.name,
          author: account?.name ?? "",
          lastEditor: account?.name ?? "",
        });

      let data = new FormData();
      data.append("file", fileToSend.file);

      const response = await onSubmit(
        url,
        {
          method: "POST",
          body: data,
        },
        true
      );

      if (response.ok) {
        doToast(
          "File " + fileToSend.file.name + " saved successfully",
          ToastType.Success
        );
        // Remove successful files
        filesToSubmit.splice(i, 1);
        i--;
      } else {
        doToast("Error saving file " + fileToSend.file.name, ToastType.Error);
      }
    }
    // Keep any failed files
    setFiles(filesToSubmit);
    return true;
  };

  return (
    <>
      {
        userPermissions.canCreateLawEnforcementDocuments &&
        <Form onSubmit={handleSubmit} noValidate validated={showValidation}>
          <h4>Upload Vehicle Impound Form(s)</h4>
          <FormAttachment
            testId="law-enforcement-attachment-input"
            multiple
            onFileRemove={(i) => {
              setShowValidation(false);

              let temp = [...files];
              temp.splice(i, 1);
              setFiles(temp);
            }}
            onFileSelect={(files: File[]) => {
              setShowValidation(false);

              setFiles(
                files.map((f) => {
                  return { file: f, policeFileNumber: "" };
                })
              );
            }}
            selectedFiles={files.map((f) => {
              return f.file;
            })}
            useDescription
            descriptionRequired
            descriptionPlaceholder="Police file number"
            onDescriptionChange={(i, value) => {
              setShowValidation(false);
              let temp = [...files];
              temp[i].policeFileNumber = value;
              setFiles(temp);
            }}
          />
          {files.length > 0 && (
            <Button
              data-testid="law-enforcement-attachment-save"
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          )}
        </Form>
      }

      {
        !userPermissions.canCreateLawEnforcementDocuments &&
        <h4 style={{color: "red"}}>You don't have permissions to upload a document.</h4>
      }
    </>
  );
}

export default LawEnforcementAttachment;
