import { useState } from "react";
import {
  GarageKeeperListInfo,
  defaultGarageKeeperListInfo,
} from "Types/garageKeeperListInfo";
import { GarageKeeperStatus } from "Types/garageKeeperStatus";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import allActions from "Redux/allActions";
import Loader from "Components/Loader/Loader";
import GarageKeeperFormInternal from "Forms/GarageKeeperForm/GarageKeeperFormInternal";
import Table from "react-bootstrap/Table";
import { Container, Row, Dropdown, Alert } from "react-bootstrap";
import RegularLayout from "Layouts/RegularLayout";
import { formatPhoneNumber } from "Utils/format";
import SearchBox from "Components/Search/SearchBox";
import CallBffApi from "Utils/CallBff";
import SortButton from "Components/SortButton/SortButton";
import ReusableModal from "Components/Modal/ReusableModal";
import { CSVLink } from "react-csv";
import { emptyGuid } from "Utils/guid";
import PhoneEmail from "../../Utils/PhoneEmail";
import { LocationStatus } from "Types/location";
import { UserPermissions } from "Types/userInfo";

interface Props {
  history: any;
}

export function getGkExportContactListFormattedData(
  data: GarageKeeperListInfo[]
): any[] {

  let gkCompounds: GarageKeeperListInfo[] = [];
  data.filter(x => x.locations?.length > 1).forEach(gk => {
    gk.locations.filter(l => l.status === LocationStatus.Active && l.city.toLowerCase() !== gk.mailingAddressCity.toLowerCase()).forEach(l => {
      const compound = { ...gk, phoneNumber: l.phoneNumber ?? "", mailingAddressCity: l.city };
      gkCompounds.push(compound);
    })
  });

  const garageKeepers = [...data];
  gkCompounds.forEach(x => garageKeepers.push(x));
  var filteredSorted = garageKeepers
    .filter(
      (gk: GarageKeeperListInfo) => gk.status === GarageKeeperStatus.Active
    )
    .sort((a: GarageKeeperListInfo, b: GarageKeeperListInfo) => {
      const addressA = a.mailingAddressCity.toLowerCase();
      const addressB = b.mailingAddressCity.toLowerCase();
      if (addressA === addressB) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      }
      if (addressA < addressB) return -1;
      if (addressA > addressB) return 1;
      return 0;
    });

  var filteredSortedSpaced: GarageKeeperListInfo[] = [];

  //Insert an empty object in the sorted list in between objects with cities that have a different first letter
  filteredSorted.forEach(
    (
      element: GarageKeeperListInfo,
      index: number,
      array: GarageKeeperListInfo[]
    ) => {
      filteredSortedSpaced.push(element);

      if (
        index + 1 < array.length &&
        element.mailingAddressCity.toLowerCase() !==
        (
          array[index + 1] as GarageKeeperListInfo
        ).mailingAddressCity.toLowerCase()
      ) {
        filteredSortedSpaced.push(defaultGarageKeeperListInfo);
      }
    }
  );

  const response = filteredSortedSpaced.map((x: GarageKeeperListInfo) => ({
    City: x.mailingAddressCity,
    Name: x.name,
    "Law Enforcement Phone Number": formatPhoneNumber(
      x.lawEnforcementPhoneNumber
    ),
    "Main Office Phone Number":
      x.lawEnforcementPhoneNumber !== x.phoneNumber
        ? formatPhoneNumber(x.phoneNumber)
        : "",
    "Tow Heavy Vehicles":
      x.id !== emptyGuid ? (x.canTowSemis ? "Yes" : "No") : "",
  }));

  return response;
}

function GarageKeeperList(props: Props) {
  const { gkList, sortField, sortDirection, statusFilter, cityFilter } =
    useSelector((state: RootStateOrAny) => state.GKList);

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  const filteredList = gkList
    .filter(
      (gk: GarageKeeperListInfo) =>
        statusFilter === "ALL" || gk.status === statusFilter
    )
    .filter(
      (gk: GarageKeeperListInfo) =>
        cityFilter === "ALL" || gk.mailingAddressCity === cityFilter
    );

  const dispatch = useDispatch();

  const requestUrl = `${window.REACT_APP_API_BASEURL}garagekeepers/list`;

  function statusDropdownChanged(displayAll: boolean, status?: any) {
    if (displayAll === true) {
      dispatch(allActions.GKList.filterStatusAll());
    } else if (status !== undefined) {
      dispatch(allActions.GKList.filterStatus(status));
    }
  }

  function cityDropdownChanged(displayAll: boolean, city?: string) {
    if (displayAll === true) {
      dispatch(allActions.GKList.filterCityAll());
    } else if (city !== undefined) {
      dispatch(allActions.GKList.filterCity(city));
    }
  }

  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  return (
    <RegularLayout title="Garage Keepers">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {userPermissions.canCreateGarageKeeper &&
          <ReusableModal
            buttonText="Add New Garage Keeper"
            headerText="Add New Garage Keeper"
            open={() => {
              displayForm();
            }}
            close={hideForm}
            show={showForm}
          >
            <GarageKeeperFormInternal submit={CallBffApi} cancel={hideForm} />
          </ReusableModal>
        }
        <CSVLink
          data={getGkExportContactListFormattedData(gkList)}
          filename={
            "Garage Keeper Contact List - " + new Date().toDateString() + ".csv"
          }
          className="btn btn-primary"
        >
          Export Contact List
        </CSVLink>
      </div>
      <div className="card shadow mb-4 min-vh-100">
        <div className="card-header py-3">
          <h4 className="m-0 font-weight-bold text-success">Garage Keepers</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Loader
              url={requestUrl}
              onLoadComplete={(result: GarageKeeperListInfo[]) =>
                dispatch(allActions.GKList.setGkList(result))
              }
            >
              <Container fluid style={{ minHeight: "15em" }}>
                <Row>
                  <SearchBox
                    endpoint="garagekeepers/search"
                    onSearch={CallBffApi}
                    handleResult={(data: GarageKeeperListInfo[]) =>
                      dispatch(allActions.GKList.setGkList(data))
                    }
                    placeholder="Business Name/Phone #"
                    searchField="name"
                    testId="gksearch"
                  />
                  <div style={{ flexGrow: 1 }}></div>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="statusDropdown"
                      data-testid="status-filter"
                    >
                      Status: {statusFilter === "ALL" ? "<All>" : statusFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => statusDropdownChanged(true)}
                        data-testid={"status-ALL"}
                      >
                        {"<All>"}
                      </Dropdown.Item>
                      {Object.keys(GarageKeeperStatus)
                        .sort((a, b) => a.localeCompare(b))
                        .map((status: string) => {
                          return (
                            <Dropdown.Item
                              key={status}
                              data-testid={`status-${status}`}
                              onClick={() =>
                                statusDropdownChanged(false, status)
                              }
                            >
                              {status}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                  <Dropdown className="mb-2 ml-2">
                    <Dropdown.Toggle
                      id="cityDropdown"
                      data-testid="city-filter"
                    >
                      City: {cityFilter === "ALL" ? "<All>" : cityFilter}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => cityDropdownChanged(true)}
                        data-testid={"city-ALL"}
                      >
                        {"<All>"}
                      </Dropdown.Item>
                      {gkList
                        .filter(
                          (gk: GarageKeeperListInfo) =>
                            statusFilter === "ALL" || gk.status === statusFilter
                        )
                        .map((x: GarageKeeperListInfo) => x.mailingAddressCity)
                        .filter(
                          (value: any, index: number, self: any) =>
                            self.indexOf(value) === index
                        )
                        .sort((a: string, b: string) => a.localeCompare(b))
                        .map((distinctCity: string) => {
                          return (
                            <Dropdown.Item
                              key={distinctCity}
                              data-testid={`city-${distinctCity}`}
                              onClick={() =>
                                cityDropdownChanged(false, distinctCity)
                              }
                            >
                              {distinctCity}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Row>
                <Row>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <SortButton
                            property={"name"}
                            label={"Business Name"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.GKList.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"mailingAddressCity"}
                            label={"City"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.GKList.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"phoneNumber"}
                            label={"Phone Number"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.GKList.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"status"}
                            label={"Status"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.GKList.sortList}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody data-testid="garage-keeper-table">
                      {filteredList.length > 0 &&
                        filteredList
                          .sort((a: any, b: any) => {
                            if (a[sortField] < b[sortField]) {
                              return sortDirection === "ascending" ? -1 : 1;
                            }
                            if (a[sortField] > b[sortField]) {
                              return sortDirection === "ascending" ? 1 : -1;
                            }
                            return 0;
                          })
                          .map((gk: GarageKeeperListInfo) => {
                            return (
                              <tr
                                key={gk.id}
                                onClick={() => {
                                  dispatch(
                                    allActions.GKProfile.setGK({
                                      id: gk.id,
                                      name: gk.name,
                                    } as any)
                                  );

                                  props.history.push({
                                    pathname: `/GarageKeepers/${gk.id}/profile`,
                                    garageKeeper: { ...gk },
                                  });
                                }}
                              >
                                <td>{gk.name}</td>
                                <td>{gk.mailingAddressCity}</td>
                                <td>
                                  {
                                    <PhoneEmail
                                      type="phone"
                                      value={formatPhoneNumber(gk.phoneNumber)}
                                    />
                                  }
                                </td>
                                <td>{gk.status}</td>
                              </tr>
                            );
                          })}
                    </tbody>
                    <tfoot>
                      {gkList.length === 0 ? (
                        <tr>
                          <td colSpan={4}>
                            <Alert variant="dark">
                              <i>No Garage Keepers found</i>
                            </Alert>
                          </td>
                        </tr>
                      ) : (
                        filteredList.length === 0 && (
                          <tr>
                            <td colSpan={4}>
                              <Alert variant="dark">
                                <i>
                                  No Garage Keepers match the current filter
                                </i>
                              </Alert>
                            </td>
                          </tr>
                        )
                      )}
                    </tfoot>
                  </Table>
                </Row>
              </Container>
            </Loader>
          </div>
        </div>
      </div>
    </RegularLayout>
  );
}

export default GarageKeeperList;
