import { useState } from "react";
import { Alert, Dropdown, Table } from "react-bootstrap";
import { ImpoundmentFeeRule } from "../../Types/VehicleImpoundmentFee";
import {
  VehicleImpoundmentFeeLabel,
  VehicleImpoundmentFeeType,
} from "../../Types/VehicleImpoundmentFeeType";

import { formatDateStripTime } from "../../Utils/format";
import Loader from "../Loader/Loader";

interface dropdownData {
  displayValue: string;
  testId: string;
  onClick: any;
}

function HistoricalFeeRates() {
  const [fees, setFees] = useState<ImpoundmentFeeRule[]>([]);
  const requestUrl = `${window.REACT_APP_API_BASEURL}vehicleimpoundments/fees?past=true`;
  const [feeTypeFilter, setFeeTypeFilter] = useState("All");

  const filteredFees = fees.filter(
    (fee: ImpoundmentFeeRule) =>
      feeTypeFilter === "All" || fee.feeType.toString() === feeTypeFilter
  );

  function feeTypeDropdownChanged(displayAll: boolean, feetype?: any) {
    if (displayAll === true) {
      setFeeTypeFilter("All");
    } else if (feetype !== undefined) {
      setFeeTypeFilter(feetype);
    }
  }

  function buildFeeTypeDropdown(): dropdownData[] {
    let dropdownList: dropdownData[] = [];
    dropdownList.push({
      displayValue: "<All>",
      testId: "feetype-ALL",
      onClick: () => feeTypeDropdownChanged(true),
    });

    Object.keys(VehicleImpoundmentFeeType).forEach((feetype: string) => {
      dropdownList.push({
        displayValue:
          VehicleImpoundmentFeeLabel[
            feetype as keyof typeof VehicleImpoundmentFeeType
          ],
        testId: `feetype-${feetype}`,
        onClick: () => feeTypeDropdownChanged(false, feetype),
      });
    });

    return dropdownList.sort((a, b) =>
      a.displayValue.localeCompare(b.displayValue)
    );
  }

  return (
    <div>
      <Loader
        url={requestUrl}
        onLoadComplete={(result: ImpoundmentFeeRule[]) => {
          const expiredRule = result.filter((r) => r.isActive !== true);
          setFees(expiredRule);
        }}
      >
        <Dropdown className="mb-2">
          <Dropdown.Toggle
            id="feeTypeDropdown"
            data-testid="fee-type-filter-button"
          >
            Fee Type:{" "}
            {feeTypeFilter === "All"
              ? "All"
              : VehicleImpoundmentFeeLabel[
                  feeTypeFilter as keyof typeof VehicleImpoundmentFeeLabel
                ]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {buildFeeTypeDropdown().map((x) => {
              return (
                <Dropdown.Item
                  key={x.displayValue}
                  data-testid={x.testId}
                  onClick={x.onClick}
                >
                  {x.displayValue}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Table data-testid="historical-fee-rates-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Effective Date</th>
              <th>Expiry Date</th>
              <th>Base Rate</th>
              <th>Hourly Rate</th>
              <th>Mileage Rate (km)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredFees
              .sort((a, b) => {
                if (a.effectiveDate < b.effectiveDate) return 1;
                if (a.effectiveDate > b.effectiveDate) return -1;
                return 0;
              })
              .map((fee) => {
                return (
                  <tr
                    key={fee.feeType + "-" + fee.effectiveDate}
                    style={{
                      backgroundColor: "transparent",
                    }}
                    data-testid={fee.feeType + "-" + fee.id}
                  >
                    <td data-testid={`typefield-${fee.id}`}>
                      {fee.feeTypeDescription}
                    </td>
                    <td>{formatDateStripTime(fee.effectiveDate)}</td>
                    <td>
                      {fee.expiryDate
                        ? formatDateStripTime(fee.expiryDate)
                        : "N/A"}
                    </td>
                    <td>{fee.baseRate > 0 ? fee.baseRate : ""}</td>
                    <td>{fee.hourlyRate > 0 ? fee.hourlyRate : ""}</td>
                    <td>{fee.mileageRate > 0 ? fee.mileageRate : ""}</td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            {fees.length === 0 ? (
              <tr>
                <td colSpan={8}>
                  <Alert variant="dark">
                    <i>No historical fee rates found</i>
                  </Alert>
                </td>
              </tr>
            ) : (
              filteredFees.length === 0 && (
                <tr>
                  <td colSpan={8}>
                    <Alert variant="dark">
                      <i>No historical fee rates match current filter</i>
                    </Alert>
                  </td>
                </tr>
              )
            )}
          </tfoot>
        </Table>
      </Loader>
    </div>
  );
}

export default HistoricalFeeRates;
