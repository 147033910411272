import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  lawEnforcementLinks,
  GetInternalLinks,
  GetGarageKeeperLinks,
  link,
} from "Routing/links";
import { UserType } from "Types/userInfo";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { RootStateOrAny, useSelector } from "react-redux";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faExclamationCircle,
  faUpload,
  faBook,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import { getGarageKeeperInsuranceState } from "Components/GarageKeepers/GarageKeeperDetailInternal";
import { InsuranceState } from "Types/insurancePolicy";
import { RequestStatus } from "Redux/requestStatus";

function HamburgerMenu() {
  fontAwesomeLibrary.add(
    faExclamationTriangle,
    faExclamationCircle,
    faUpload,
    faBook,
    faBookOpen
  );

  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const internalLinks = GetInternalLinks();
  const garageKeeperLinks = GetGarageKeeperLinks();

  const links: link[] = useMemo(() => {
    if (userType === UserType.Admin) {
      return internalLinks;
    } else if (userType === UserType.GarageKeeper) {
      return garageKeeperLinks;
    } else if (userType === UserType.LawEnforcement) {
      return lawEnforcementLinks;
    } else {
      return [];
    }
  }, [garageKeeperLinks, internalLinks, userType]);

  const insurancePolicies = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.insurancePolicies
  );

  const insurancePoliciesStatus = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.status
  );

  const [gkInsuranceStatus, setGkInsuranceStatus] = useState<
    InsuranceState | undefined
  >(undefined);

  useEffect(() => {
    if (insurancePoliciesStatus !== RequestStatus.LOADING) {
      setGkInsuranceStatus(getGarageKeeperInsuranceState(insurancePolicies));
    }
  }, [insurancePolicies, insurancePoliciesStatus]);

  const getWarningIcon = (linkTitle: string) => {
    if (linkTitle === "Insurance Policies") {
      return gkInsuranceStatus === "Expiring" ? (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="1x"
          color="orange"
        />
      ) : gkInsuranceStatus === "Expired" ? (
        <FontAwesomeIcon icon={faExclamationCircle} size="1x" color="red" />
      ) : (
        ""
      );
    }
  };

  return (
    <li className="dropdown no-arrow">
      <a
        href="/"
        className="nav-link dropdown-toggle"
        id="hamburgerMenu"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon="bars" color="primary" />
      </a>
      <div className="dropdown-menu shadow" aria-labelledby="hamburgerMenu">
        {links.map((link, i) => {
          return (
            <div key={i}>
              <NavLink
                className="dropdown-item"
                to={link.path}
                onClick={link.onClick}
              >
                <FontAwesomeIcon
                  icon={link.icon as IconProp}
                  className="fa-sm fa-fw mr-2"
                  style={{ color: "grey" }}
                />
                {link.text}
                <span className="pl-1">{getWarningIcon(link.text)}</span>
              </NavLink>
              {i !== links.length - 1 && (
                <div className="dropdown-divider"></div>
              )}
            </div>
          );
        })}
      </div>
    </li>
  );
}

export default HamburgerMenu;
