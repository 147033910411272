import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

const DashboardCount = (props: any) => {
  return (
    <Button
      style={{ width: "95%" }}
      onClick={props.handleClick}
      variant="outline-secondary"
      className="m-1"
    >
      {props.countTitle ?? props.status}{" "}
      <Badge
        variant="light"
        style={{ float: "right", marginRight: "5px", marginTop: "3px" }}
      >
        {props.count}
      </Badge>
      <span className="sr-only">{props.countTitle ?? props.status}</span>
    </Button>
  );
};

export default DashboardCount;
