import { Attachment } from "./attachment";

export interface LawEnforcement {
    [index: string]: any;
    id: string;
    impoundmentId: string;
    policeFileNumber: string;
    officerName: string;
    createdOn: Date;
    createdBy: string;
    attachment?: Attachment;
  }
  
  export const defaultLawEnforcement: LawEnforcement = {
    id: "00000000-0000-0000-0000-000000000000",
    impoundmentId: "00000000-0000-0000-0000-000000000000",
    policeFileNumber: "",
    officerName: "",
    createdOn: new Date(),
    createdBy: "",
  };
  