import { RequestListInfo } from "Types/requestListInfo";
import {
  SET_REQUESTS,
  SORT_REQUESTS,
  FILTER_GK,
  FILTER_STATUS,
  FILTER_TYPE,
  FILTER_RECENT,
  UPDATE_REQUEST,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_TOTAL_COUNT,
  LOAD_REQUEST_LIST,
  SEARCH_POLICE_FILE_NUMBER,
  SEARCH_VIN_POLICE_FILE,
  SEARCHED_VIN_POLICE_FILE,
  SET_RELATED_IMPOUNDMENTS,
} from "./requestActions";
import { VehicleImpoundment } from "Types/vehicleImpoundment";

export interface RequestsState {
  requestList: RequestListInfo[];
  sortField: string;
  sortDirection: string;
  searchPoliceFileNumber: string;
  searchVINPoliceFile: string;
  searchedVINPoliceFile: boolean;
  gkFilter: string;
  gkName: string;
  statusFilter: string;
  typeFilter: string;
  recentFilter: boolean;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  hasPrev: boolean;
  hasNext: boolean;
  totalCount: number;
  relatedImpoundments: VehicleImpoundment[];
}

export const initialState: RequestsState = {
  requestList: [],
  sortField: "createdOn",
  sortDirection: "ascending",
  searchPoliceFileNumber: "",
  searchVINPoliceFile: "",
  searchedVINPoliceFile: false,
  gkFilter: "ALL",
  gkName: "All",
  statusFilter: "ALL",
  typeFilter: "ALL",
  recentFilter: false,
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  hasPrev: false,
  hasNext: false,
  totalCount: 0,
  relatedImpoundments: []
};

export default function RequestReducer(
  state: RequestsState = initialState,
  action: any
) {
  switch (action.type) {
    case SET_REQUESTS: {
      return {
        ...state,
        requestList: action.requestList,
      };
    }

    case SORT_REQUESTS: {
      return {
        ...state,
        sortField: action.sortField,
        sortDirection: action.sortDirection,
        pageNumber: action.page,
      };
    }

    case SEARCH_POLICE_FILE_NUMBER: {
      return {
        ...state,
        searchPoliceFileNumber: action.searchPoliceFileNumber,
        pageNumber: action.page,
      };
    }

    case SEARCH_VIN_POLICE_FILE: {
      return {
        ...state,
        searchVINPoliceFile: action.searchVINPoliceFile,
      };
    }

    case SEARCHED_VIN_POLICE_FILE: {
      return {
        ...state,
        searchedVINPoliceFile: action.searchedVINPoliceFile,
      };
    }

    case FILTER_GK: {
      return {
        ...state,
        gkFilter: action.filter,
        gkName: action.gkName,
        pageNumber: action.page,
      };
    }

    case FILTER_STATUS: {
      return {
        ...state,
        statusFilter: action.filter,
        pageNumber: action.page,
      };
    }

    case FILTER_TYPE: {
      return {
        ...state,
        typeFilter: action.filter,
        pageNumber: action.page,
      };
    }

    case FILTER_RECENT: {
      return {
        ...state,
        recentFilter: action.filter,
        pageNumber: action.page,
      };
    }

    case LOAD_REQUEST_LIST: {
      return {
        ...state,
        requestList: action.value,
      };
    }

    case UPDATE_REQUEST:
      const index = state.requestList.findIndex(
        (p) => p.id === action.value.id
      );

      if (index === -1) return state;

      let newRequestList = [...state.requestList];
      newRequestList.splice(index, 1, action.value);

      return {
        ...state,
        requestList: newRequestList,
      };

    default: {
      return state;
    }

    case SET_PAGE: {
      return {
        ...state,
        pageNumber: action.page,
      };
    }

    case SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.pageSize,
      };
    }

    case SET_TOTAL_COUNT: {
      return {
        ...state,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
        hasPrev: action.hasPrev,
        hasNext: action.hasNext,
      };
    }

    case SET_RELATED_IMPOUNDMENTS: {
      return {
        ...state,
        relatedImpoundments: action.payload
      };
    }
  }
}
