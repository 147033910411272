import allActions from "Redux/allActions";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { InputGroup, Button, FormControl } from "react-bootstrap";


function SideSearchBox(props: any) {
    const history = useHistory();
    const dispatch = useDispatch();
    const searchVinFileNo = useSelector((state: RootStateOrAny) => state.requests.searchVINPoliceFile );
    const setSearchVINPF = (searchText: any) => {
        dispatch(allActions.RequestActions.searchVINPoliceFile(searchText));
        dispatch(allActions.RequestActions.searchedVINPoliceFile(false));
    }
      
    const vinSearch = () => {
      if(searchVinFileNo.length > 0) {
        dispatch(allActions.ImpoundedVehicleListActions.setSearchVin(searchVinFileNo));
        dispatch(allActions.RequestActions.searchPoliceFileNumber(searchVinFileNo));
        dispatch(allActions.RequestActions.searchedVINPoliceFile(true));
        history.push({ pathname: "/ImpoundedVehicles" });
      }
    }

    return (
        <>
         <InputGroup className="mb-3">
            <Button variant="secondary" 
              data-testid={"sidesearch-button"}
              onClick={vinSearch}>
                Search
            </Button>
            <FormControl 
              data-testid={"sidesearch-input"}
              type="search"
              value={searchVinFileNo}
              onChange={(e) => setSearchVINPF(e.target.value)}
              placeholder="VIN/Police File #"/>
          </InputGroup>
        </>
    )
}


export default SideSearchBox;