import { useState, useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { locationActions } from "Redux/locations/locationActions";
import { defaultLocation, Location, LocationStatus } from "Types/location";
import HoursOfOperationForm, {
  validateOperationHoursTime,
} from "./HoursOfOperationForm";
import { provinces } from "Utils/consts";
import { patterns, formatting } from "Utils/regex";
import FormText from "Components/FormElements/FormText";
import FormSelect from "Components/FormElements/FormSelect";
import CustomForm from "Components/FormElements/CustomForm";
import { doToast, ToastType } from "Utils/toastUtils";
import { UserType } from "Types/userInfo";

interface Props {
  data?: Location;
  submit: any;
  cancel?: any;
}

export default function LocationForm(props: Props) {
  const isExternalUser =
    useSelector((state: RootStateOrAny) => state.userInfo.userType) ===
    UserType.GarageKeeper;

  const [location, setLocation] = useState<Location>(
    props.data ?? {
      ...defaultLocation,
      status: isExternalUser ? LocationStatus.Pending : defaultLocation.status,
    }
  );

  const [showHours, setShowHours] = useState<boolean>(true);

  const editMode = props.data ? true : false;

  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const dispatch = useDispatch();

  const handleUpdate = (event: any) => {
    // Handle updates to the data
    if (!event.target.name.includes(".")) {
      setLocation({
        ...location,
        [event.target.name]: event.target.value,
      });
    } else {
      // Address Update
      const [type, field] = event.target.name.split(".");
      setLocation({
        ...location,
        [type]: {
          ...location[type],
          [field]: event.target.value,
          country: "CA",
        },
      });
    }
  };

  const handleCancel = () => {
    props.cancel?.();
  };

  const operationHoursValid = useMemo(() => {
    return location.operationHours.every(validateOperationHoursTime);
  }, [location.operationHours]);

  const submit = async (event: any) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (!form.checkValidity() || !operationHoursValid) return false;

    let locationToSend = {
      ...location,
      garageKeeperId: garageKeeper.id,
      phoneNumber: location.phoneNumber
        ? formatting.formatPhoneNumber(location.phoneNumber)
        : null,
    };

    locationToSend.physicalAddress.postalCode = formatting.formatPostalCode(
      locationToSend.physicalAddress.postalCode
    );

    // Need to set the hours to 0:00 if the location is closed, otherwise redux
    //  gets out of sync with the database
    locationToSend.operationHours = locationToSend.operationHours.map((h) => {
      if (h.closed) {
        h.open = "0:00";
        h.close = "0:00";
      }

      return h;
    });

    let url = `${window.REACT_APP_API_BASEURL}locations`;

    if (isExternalUser) {
      url = `${url}/external`;
    }

    if (editMode) {
      url = `${url}/${location.id}`;
    }

    try {
      const response = await props.submit(url, {
        method: editMode ? "PUT" : "POST",
        body: JSON.stringify(locationToSend),
      });

      if (response.ok) {
        if (!editMode) {
          const result = await response.json();
          dispatch(locationActions.addLocation(result));
          setLocation({
            ...defaultLocation,
            status: isExternalUser
              ? LocationStatus.Pending
              : defaultLocation.status,
          });
        } else {
          dispatch(locationActions.updateLocation(location));
        }
        doToast("Compound saved successfully", ToastType.Success);
        return true;
      } else {
        doToast("An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const address = (type: string, disabled: boolean) => (
    <Form.Row>
      <Form.Group as={Col} md="4" controlId={`validation${type}Street`}>
        <FormText
          required
          testId={type + "Street"}
          label="Street"
          field={type + ".street"}
          value={location[type].street}
          onChange={handleUpdate}
          disabled={disabled}
        />
      </Form.Group>
      <Form.Group as={Col} md="2" controlId={`validation${type}City`}>
        <FormText
          required
          testId={type + "City"}
          label="City"
          field={type + ".city"}
          value={location[type].city}
          onChange={handleUpdate}
          disabled={disabled}
        />
      </Form.Group>
      <Form.Group as={Col} md="2" controlId={`validation${type}Province`}>
        <FormSelect
          required
          label="Province"
          testId={type + "Province"}
          field={type + ".province"}
          value={location[type].province}
          onChange={handleUpdate}
          disabled={disabled}
          data={provinces}
          mapping={(p) => {
            return { value: p, text: p };
          }}
        />
      </Form.Group>
      <Form.Group as={Col} md="2" controlId={`validation${type}PostalCode`}>
        <FormText
          required
          label="Postal Code"
          testId={type + "PostalCode"}
          field={type + ".postalCode"}
          value={location[type].postalCode}
          onChange={handleUpdate}
          disabled={disabled}
          pattern={patterns.postalCode}
        />
      </Form.Group>
    </Form.Row>
  );

  return (
    <CustomForm
      onSubmit={submit}
      cancel={handleCancel}
      edit={editMode}
      openInEditMode={true}
    >
      <Form.Label>
        <h4>Status</h4>
      </Form.Label>
      <Form.Row>
        <Form.Group as={Col} md={4} controlId="validationStatus">
          <FormSelect
            required
            testId="status"
            label="Status"
            field="status"
            onChange={handleUpdate}
            data={Object.keys(LocationStatus).filter(
              (s) => s !== LocationStatus.Pending || s === location.status
            )}
            value={location.status}
            mapping={(s) => {
              return { value: s, text: s };
            }}
            disabled={isExternalUser}
          />
        </Form.Group>
      </Form.Row>
      <Form.Label>
        <h4>Contact</h4>
      </Form.Label>
      <Form.Row>
        <Form.Group as={Col} md={4} controlId="validationPhoneNumber">
          <FormText
            testId="phoneNumber"
            label="Phone #"
            field="phoneNumber"
            value={location.phoneNumber}
            onChange={handleUpdate}
            pattern={patterns.phoneNumber}
            invalidMessage={
              location.phoneNumber && formatting.formatPhoneNumber(location.phoneNumber).length < 10
                ? " must contain 10 digits"
                : " is not a valid number"
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>
            <h4>Physical Address</h4>
          </Form.Label>
          {address("physicalAddress", editMode && isExternalUser)}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group>
          <Form.Label>
            <h4>Hours of Operation</h4>
          </Form.Label>{" "}
          <Button
            size="sm"
            variant="outline-primary"
            disabled={!operationHoursValid}
            onClick={() => {
              if (showHours) {
                if (operationHoursValid) setShowHours(false);
              } else {
                setShowHours(true);
              }
            }}
          >
            {showHours ? "Hide" : "Show"}
          </Button>
          {showHours && (
            <HoursOfOperationForm
              onChange={handleUpdate}
              hours={location.operationHours}
            />
          )}
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}
