import { Attachment } from "./attachment";
import { emptyGuid } from "Utils/guid";

export interface Bulletin {
  id: string;
  title: string;
  description: string;
  archived: boolean;
  createdOn: Date;
  attachment?: Attachment;
  acknowledged?: boolean;
  bulletinType?: BulletinType;
}

export const defaultBulletin: Bulletin = {
  id: emptyGuid,
  title: "",
  description: "",
  archived: false,
  createdOn: new Date(),
};

export enum BulletinType {
  Bulletin = "Bulletin",
  Manual = "Manual",
  Policy = "Policy",
}

export const acknowledgeBulletinTypes: BulletinType[] = [
  BulletinType.Bulletin,
  BulletinType.Policy,
];
