export interface Contact {
  [index: string]: any;
  id: string;
  garageKeeperId: string;
  description: string;
  phoneNumber?: string;
  faxNumber?: string;
  emailAddress?: string;
  communicationPreference?: CommPref;
  priority: number;
  contactType: ContactType;
}

export enum CommPref {
  Email = "Email",
  Fax = "Fax",
}

export enum ContactType {
  Other = "Other",
  MainOffice = "MainOffice",
  LawEnforcement = "LawEnforcement",
}

export const defaultContact: Contact = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperId: "00000000-0000-0000-0000-000000000000",
  description: "",
  phoneNumber: "",
  faxNumber: "",
  emailAddress: "",
  priority: 0,
  contactType: ContactType.Other,
};
