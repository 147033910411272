import { Col, Form, Button } from "react-bootstrap";
import { VehicleImpoundment } from "Types/vehicleImpoundment";
import {
  VehicleImpoundmentFee,
  defaultVehicleImpoundmentFee,
  ImpoundmentFeeRule,
} from "Types/VehicleImpoundmentFee";
import {
  VehicleImpoundmentFeeType,
  VehicleImpoundmentFeeLabel,
} from "Types/VehicleImpoundmentFeeType";
import FormText from "Components/FormElements/FormText";
import { useEffect, useState } from "react";
import { formatMoneyWithDecimals } from "Utils/format";
import CallBffApi from "Utils/CallBff";
import guid from "Utils/guid";
import Accordion from "Components/Accordion/Accordion";

interface Props {
  vehicleImpoundment: VehicleImpoundment;
  setVehicleImpoundment: Function;
  control: boolean;
}

interface Fee {
  feeType: VehicleImpoundmentFeeType;
  feeLabel: string;
  mileageRate: boolean;
  hourlyRate: boolean;
  dailyRate: boolean;
}

export function VehicleImpoundmentFeesForm({
  vehicleImpoundment,
  setVehicleImpoundment,
  control,
}: Props) {
  const [addFeeDisabled, setAddFeeDisabled] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (vehicleImpoundment.feesBreakdown === undefined)
      setVehicleImpoundment((vi: VehicleImpoundment) => {
        return {
          ...vi,
          feesBreakdown: [] as VehicleImpoundmentFee[],
        };
      });
  }, [
    vehicleImpoundment.id,
    vehicleImpoundment.feesBreakdown,
    setVehicleImpoundment,
  ]);

  const fees: Fee[] = [
    {
      feeType: VehicleImpoundmentFeeType.TowingLight,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.TowingLight],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.TowingHeavy,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.TowingHeavy],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.Storage,
      feeLabel: VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.Storage],
      mileageRate: false,
      hourlyRate: false,
      dailyRate: true,
    },
    {
      feeType: VehicleImpoundmentFeeType.DollyUse,
      feeLabel: VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.DollyUse],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.FlatDeck,
      feeLabel: VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.FlatDeck],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.Winching,
      feeLabel: VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.Winching],
      mileageRate: false,
      hourlyRate: true,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.PlateReturn,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.PlateReturn],
      mileageRate: false,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.Administration,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.Administration],
      mileageRate: false,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.Other,
      feeLabel: VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.Other],
      mileageRate: false,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.AuctionLight,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.AuctionLight],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
    {
      feeType: VehicleImpoundmentFeeType.AuctionHeavy,
      feeLabel:
        VehicleImpoundmentFeeLabel[VehicleImpoundmentFeeType.AuctionHeavy],
      mileageRate: true,
      hourlyRate: false,
      dailyRate: false,
    },
  ];

  useEffect(() => {
    setAddFeeDisabled(false);
    if (vehicleImpoundment.feesBreakdown !== undefined) {
      if (vehicleImpoundment.feesBreakdown.length === fees.length)
        setAddFeeDisabled(true);
    }

    if (vehicleImpoundment.feesBreakdown !== undefined) {
      let total = 0;
      vehicleImpoundment.feesBreakdown.forEach((f) => {
        total += +(f.total ?? 0);
      });

      setVehicleImpoundment((vi: VehicleImpoundment) => {
        return {
          ...vi,
          fees: total,
        };
      });
    }
  }, [vehicleImpoundment.feesBreakdown, fees.length, setVehicleImpoundment]);

  const feeStyle = {
    borderBottom: "1px solid gainsboro",
    borderRadius: "10px",
    paddingBottom: "5px",
    marginBottom: "5px",
  } as React.CSSProperties;

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [id, field] = event.target.name.split(".");

    if (field === "feeType") {
      addFee(event.target.value);
      return;
    }

    let newFees = [...vehicleImpoundment.feesBreakdown!];
    let index = newFees.findIndex((f) => f.id === id);

    let newFee = { ...newFees[index] };

    newFee = {
      ...newFee,
      [field]: event.target.value,
    };

    let calculatedHours = newFee.winchUsageHours - 0.5;

    if (
      VehicleImpoundmentFeeType[newFee.feeType].toString() ===
      VehicleImpoundmentFeeType.Storage.toString()
    ) {
      newFee.total = +(newFee.baseRate ?? 0) * +(newFee.daysStored ?? 0);
    } else {
      newFee.total =
        +(newFee.baseRate ?? 0) +
        +(newFee.mileageRate ?? 0) * +(newFee.mileageKM ?? 0) +
        +(newFee.hourlyRate ?? 0) *
          +(calculatedHours < 0 ? 0 : calculatedHours);
    }

    newFees[index] = {
      ...newFee,
    };
    setVehicleImpoundment((vi: VehicleImpoundment) => {
      return {
        ...vi,
        feesBreakdown: [...newFees],
      };
    });
  };

  const deleteFee = (id: string) => {
    if (window.confirm("Are you sure you want to delete this fee?")) {
      setVehicleImpoundment((vi: VehicleImpoundment) => {
        return {
          ...vi,
          feesBreakdown: vi.feesBreakdown.filter(
            (f) => f.id !== id
          ),
        };
      });
    }
  };

  const addFee = (feeType: any) => {
    const feeTypeString =
      VehicleImpoundmentFeeType[
        feeType as keyof typeof VehicleImpoundmentFeeType
      ];
    const requestUrl =
      `${window.REACT_APP_API_BASEURL}vehicleimpoundments/fees?type=${feeTypeString}` +
      `&effectiveDate=${vehicleImpoundment.impoundmentStart}`;

    CallBffApi(requestUrl, {
      method: "GET",
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong.");
      })
      .then(function (json: ImpoundmentFeeRule[]) {
        let newFee: VehicleImpoundmentFee = {
          ...defaultVehicleImpoundmentFee,
          id: guid(),
          feeType: feeType,
        };

        if (json[0] !== undefined) {
          newFee = {
            ...newFee,
            total: json[0].baseRate,
            feeType: json[0].feeType,
            baseRate: json[0].baseRate,
            mileageRate: json[0].mileageRate,
            hourlyRate: json[0].hourlyRate,
          };
        }

        setVehicleImpoundment((vi: VehicleImpoundment) => {
          return {
            ...vi,
            feesBreakdown: [...vi.feesBreakdown, newFee],
          };
        });
      })
      .catch(function (error) {
        //TODO: Handle errors
      })
      .finally(function () {
        setShowDropdown(false);
      });
  };

  const renderFee = (impoundmentFee: VehicleImpoundmentFee) => {
    const type: Fee | undefined = fees.find(
      (f) =>
        VehicleImpoundmentFeeType[f.feeType].toString() ===
        impoundmentFee.feeType.toString()
    );

    return (
      <Form.Row key={impoundmentFee.id} style={feeStyle}>
        <Col md={1}>
          <br />
          <Button
            variant="secondary"
            onClick={() => deleteFee(impoundmentFee.id)}
          >
            &#10060;
          </Button>
        </Col>
        <Col md={2}>
          <FormText
            label="Fee Type"
            field={`feeType`}
            value={type?.feeLabel}
            disabled
          />
        </Col>
        <Col md={2}>
          <FormText
            label={"Base Rate"}
            testId={`${impoundmentFee.id}-baseRate`}
            field={`${impoundmentFee.id}.baseRate`}
            value={impoundmentFee.baseRate}
            onChange={handleUpdate}
            type="number"
            min="0"
            step=".01"
            invalidMessage={
              impoundmentFee.baseRate < 0
                ? " cannot be negative"
                : " must be round to nearest cent"
            }
            required
          />
        </Col>
        {type?.mileageRate && (
          <>
            <Col md={2}>
              <FormText
                label={"Mileage Rate"}
                testId={`${impoundmentFee.id}-mileageRate`}
                field={`${impoundmentFee.id}.mileageRate`}
                value={impoundmentFee.mileageRate}
                onChange={handleUpdate}
                type="number"
                min="0"
                step=".01"
                invalidMessage={
                  impoundmentFee.mileageRate < 0
                    ? " cannot be negative"
                    : " must be round to nearest cent"
                }
                required
              />
            </Col>
            <Col md={2}>
              <FormText
                label={"Kilometers"}
                testId={`${impoundmentFee.id}-kms`}
                field={`${impoundmentFee.id}.mileageKM`}
                value={impoundmentFee.mileageKM}
                onChange={handleUpdate}
                type="number"
                min="0"
                step=".50"
                invalidMessage={
                  impoundmentFee.mileageKM < 0
                    ? " cannot be negative"
                    : " must be in .5km increments"
                }
                required
              />
            </Col>
          </>
        )}
        {type?.hourlyRate && (
          <>
            <Col md={2}>
              <FormText
                label={"Hourly Rate"}
                testId={`${impoundmentFee.id}-hourlyRate`}
                field={`${impoundmentFee.id}.hourlyRate`}
                value={impoundmentFee.hourlyRate}
                onChange={handleUpdate}
                type="number"
                min="0"
                step=".01"
                invalidMessage={
                  impoundmentFee.hourlyRate < 0
                    ? " cannot be negative"
                    : " must be round to nearest cent"
                }
                required
              />
            </Col>
            <Col md={2}>
              <FormText
                label={"Hours"}
                testId={`${impoundmentFee.id}-hours`}
                field={`${impoundmentFee.id}.winchUsageHours`}
                value={impoundmentFee.winchUsageHours}
                onChange={handleUpdate}
                type="number"
                min="0"
                step=".5"
                invalidMessage={
                  impoundmentFee.winchUsageHours < 0
                    ? " cannot be negative"
                    : " must be in .5 hour increments"
                }
                required
              />
            </Col>
          </>
        )}
        {type?.dailyRate && (
          <>
            <Col md={2}>
              <FormText
                label={"Days Stored"}
                testId={`${impoundmentFee.id}-days`}
                field={`${impoundmentFee.id}.daysStored`}
                value={impoundmentFee.daysStored}
                onChange={handleUpdate}
                type="number"
                min="0"
                step="1"
                invalidMessage={
                  impoundmentFee.daysStored < 0
                    ? " cannot be negative"
                    : " must be in 1 day increments"
                }
                required
              />
            </Col>
          </>
        )}
        {type?.feeType === VehicleImpoundmentFeeType.Other && (
          <Col md={4}>
            <FormText
              label="Description"
              testId={`${impoundmentFee.id}-description`}
              field={`${impoundmentFee.id}.description`}
              value={impoundmentFee.description}
              onChange={handleUpdate}
              required
            />
          </Col>
        )}
        <Col />
        <Col md={2}>
          <FormText
            label={"Fee total"}
            testId={`${impoundmentFee.id}-amount`}
            field={`${impoundmentFee.id}.total`}
            value={formatMoneyWithDecimals(impoundmentFee.total ?? 0)}
            disabled
          />
        </Col>
      </Form.Row>
    );
  };

  return (
    <>
      <Accordion title="Fees Breakdown" defaultShow control={control}>
        {vehicleImpoundment.feesBreakdown &&
          vehicleImpoundment.feesBreakdown.map((f) => renderFee(f))}
        {showDropdown && (
          <Form.Row style={feeStyle}>
            <Col md={2}>
              <Form.Label className="font-weight-bold">{"Fee Type"}</Form.Label>
              <Form.Control
                as="select"
                title="Fee select"
                onChange={handleUpdate}
                name={`.feeType`}
                value=""
              >
                <option value=""></option>
                {vehicleImpoundment.feesBreakdown !== undefined &&
                  fees
                    .sort(function (a, b) {
                      if (a.feeLabel > b.feeLabel) {
                        return 1;
                      }
                      if (a.feeLabel < b.feeLabel) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((f) => {
                      if (
                        vehicleImpoundment.feesBreakdown.filter(
                          (vif) =>
                            VehicleImpoundmentFeeType[
                              vif.feeType
                            ].toString() === f.feeType.toString()
                        ).length === 0 ||
                        f.feeType === VehicleImpoundmentFeeType.Other
                      )
                        return (
                          <option key={f.feeType} value={f.feeType}>
                            {f.feeLabel}
                          </option>
                        );
                      else return "";
                    })}
              </Form.Control>
            </Col>
          </Form.Row>
        )}
        <Form.Row>
          <Col>
            <Button
              variant="primary"
              onClick={() => setShowDropdown(true)}
              disabled={addFeeDisabled}
            >
              Add New Fee
            </Button>
          </Col>
          {(vehicleImpoundment.feesBreakdown?.length ?? 0) > 0 && (
            <>
              <Col md={1}>
                <Form.Label className="font-weight-bold">
                  Total Fees:
                </Form.Label>
              </Col>
              <Col md={2}>
                <Form.Control
                  type="text"
                  title={"totalFees"}
                  value={formatMoneyWithDecimals(vehicleImpoundment.fees ?? 0)}
                  disabled
                />
              </Col>
            </>
          )}
        </Form.Row>
      </Accordion>
    </>
  );
}

export default VehicleImpoundmentFeesForm;
