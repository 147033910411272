import React, { useState } from "react";
import { Col, Overlay, Popover, Row } from "react-bootstrap";
import { OperationHours } from "../../Types/location";
import { daysOfWeek } from "../../Utils/consts";
interface Props {
  hours: OperationHours[];
}

function HoursOfOperation({ hours }: Props) {
  const currentDay = daysOfWeek[new Date().getDay()];

  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);

  const togglePopover = (event: any) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const currentDayHours = () => {
    const day = hours.filter((h) => h.day === currentDay)[0];
    return <span>{day.closed ? "Closed" : `${day.open} - ${day.close}`}</span>;
  };

  return (
    <span
      onMouseEnter={togglePopover}
      onMouseLeave={togglePopover}
      data-testid="hours-of-operation"
    >
      {currentDayHours()}
      <Overlay
        show={showPopover}
        target={target}
        placement="top"
        containerPadding={20}
        transition={false}
      >
        <Popover
          id="hoursOfOperationPopover"
          style={{ minWidth: "300px" }}
          data-testid="full-hours-of-operation"
        >
          <Popover.Title as="h3">Hours of Operation</Popover.Title>
          <Popover.Content>
            {daysOfWeek.map((dayOfWeek: string, i) => {
              let day = hours.filter((h) => h.day === dayOfWeek)[0];

              return (
                <Row key={i}>
                  <Col md={6}>{day?.day}: </Col>
                  <Col md={6}>
                    {day?.closed ? "Closed" : `${day?.open} - ${day?.close}`}
                  </Col>
                </Row>
              );
            })}
          </Popover.Content>
        </Popover>
      </Overlay>
    </span>
  );
}

export default HoursOfOperation;
