import { PageItem, Pagination } from "react-bootstrap";
import { useDispatch } from "react-redux";

interface Props {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  hasPrev: boolean;
  hasNext: boolean;
  totalCount: number;
  setPageNum: (pageNum: number) => any;
  setPageSize: (pageSize: number) => any;
}

export default function ListPagination({
  pageNumber,
  pageSize,
  totalPages,
  hasPrev,
  hasNext,
  totalCount,
  setPageNum,
  setPageSize,
}: Props) {
  const dispatch = useDispatch();

  const changePage = (pageNumber: number) => {
    dispatch(setPageNum(pageNumber));
    window.scrollTo(0, 0);
  };

  const changePageSize = (size: number) => {
    dispatch(setPageSize(size));
    window.scrollTo(0, 0);
  };

  let paginationItems: any[] = [];
  for (let number = 1; number <= Math.ceil(totalCount / pageSize); number++) {
    paginationItems.push(
      <PageItem
        key={number}
        active={number === pageNumber}
        onClick={() => changePage(number)}
        data-testid={`pagination-page-${number}`}
      >
        {number}
      </PageItem>
    );
  }

  return (
    <div>
      <Pagination>
        <Pagination.First
          title="Go to the first page"
          disabled={pageNumber === 1}
          onClick={() => changePage(1)}
          data-testid="pagination-first"
        />
        <Pagination.Prev
          title="Go to the previous page"
          disabled={!hasPrev}
          onClick={() => changePage(pageNumber - 1)}
          data-testid="pagination-previous"
        />
        {paginationItems}
        <Pagination.Next
          title="Go to the next page"
          disabled={!hasNext}
          onClick={() => changePage(pageNumber + 1)}
          data-testid="pagination-next"
        />
        <Pagination.Last
          title="Go to the last page"
          disabled={pageNumber === totalPages}
          onClick={() => changePage(Math.ceil(totalCount / pageSize))}
          data-testid="pagination-last"
        />
      </Pagination>
      <span data-testid="pagination-text">
        {`Page ${pageNumber} of ${totalPages}, showing ${
          (pageNumber - 1) * pageSize + 1
        } - ${
          pageNumber === totalPages ? totalCount : pageNumber * pageSize
        } of ${totalCount} items`}
      </span>
      <br/>
      {`Items per page: `}
      <select
        data-testid="page-size"
        name="pageSize"
        id="pageSize"
        value={pageSize}
        onChange={(e) => changePageSize(Number(e.target.value))}>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </select>
    </div>
  );
}
