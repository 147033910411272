/**
 * The type probably shouldn't be used for permission checks and should be replaced by
 * checking individual permissions rather than the user type.
 */
export enum UserType {
  Admin = "Admin",
  DriverExamEmployee = "DriverExamEmployee",
  SGIEmployee = "SGIEmployee",
  GarageKeeper = "GarageKeeper",
  LawEnforcement = "LawEnforcement",
  NoAccess = "NoAccess",
}

export interface UserPermissions {
  isAdmin: boolean;
  isGarageKeeper: boolean;
  isDriverRecordsEmployee: boolean;
  isSGIEmployee: boolean;
  isLawEnforcement: boolean;
  gkPermissions: GkPermissions[];
  canViewAsGarageKeeper: boolean;
  canCreateFeedback: boolean;
  canUpdateRequestsForAllGarageKeepers: boolean;
  canCreateRequestsForAllGarageKeepers: boolean;
  canAcknowledgeBulletins: boolean;
  canUpdateBulletins: boolean;
  canDeleteBulletins: boolean;
  canCreateBulletins: boolean;
  canDeleteAttachments: boolean;
  canViewLawEnforcementDocuments: boolean;
  canViewImpoundmentFull: boolean;
  canCreateImpoundmentFee: boolean;
  canUpdateImpoundmentFee: boolean;
  canCreateGarageKeeper: boolean;
  canUpdateGarageKeeper: boolean;
  canCreateContact: boolean;
  canUpdateContact: boolean;
  canDeleteContact: boolean;
  canCreateCompound: boolean;
  canUpdateCompound: boolean;
  canCreateTowingVehicle: boolean;
  canUpdateTowingVehicle: boolean;
  canCreateImpoundment: boolean;
  canCreateInsurance: boolean;
  canUpdateInsurance: boolean;
  canDeleteInsurance: boolean;
  canCreateLawEnforcementDocuments: boolean;
  canUpdateImpoundment: boolean;
}

export const defaultUserPermissions: UserPermissions = {
  isAdmin: false,
  isGarageKeeper: false,
  isLawEnforcement: false,
  gkPermissions: [],
  canViewAsGarageKeeper: false,
  canCreateFeedback: false,
  canUpdateRequestsForAllGarageKeepers: false,
  canCreateRequestsForAllGarageKeepers: false,
  canAcknowledgeBulletins: false,
  canUpdateBulletins: false,
  canDeleteBulletins: false,
  canDeleteAttachments: false,
  canViewLawEnforcementDocuments: false,
  canViewImpoundmentFull: false,
  isDriverRecordsEmployee: false,
  isSGIEmployee: false,
  canCreateImpoundmentFee: false,
  canUpdateImpoundmentFee: false, 
  canCreateBulletins: false,
  canCreateGarageKeeper: false,
  canUpdateGarageKeeper: false,
  canCreateContact: false,
  canUpdateContact: false,
  canDeleteContact: false,
  canCreateCompound: false,
  canUpdateCompound: false,
  canCreateTowingVehicle: false,
  canUpdateTowingVehicle: false,
  canCreateImpoundment: false,
  canCreateInsurance: false,
  canUpdateInsurance: false,
  canDeleteInsurance: false,
  canCreateLawEnforcementDocuments: false,
  canUpdateImpoundment: false
};

export interface GkPermissions {
  id: string;
  name: string;
  securityOrganizationId: string;
}
