interface _ScreenSize {
	XS: _Screen;
	SM: _Screen;
	MD: _Screen;
	LG: _Screen;
	XL: _Screen;
}

interface _Screen {
	minWidth: string | number;
	maxWidth: string | number;
}

// Definition of screen sizes used by Bootstrap
export const ScreenSize: _ScreenSize = {
	XS: {
		minWidth: "",
		maxWidth: "575.98px",
	},
	SM: {
		minWidth: "576px",
		maxWidth: "767.98px",
	},
	MD: {
		minWidth: "768px",
		maxWidth: "992.98px",
	},
	LG: {
		minWidth: "992px",
		maxWidth: "1199.98px",
	},
	XL: {
		minWidth: "1200px",
		maxWidth: "",
	},
};

// Returns
export const getMediaQuery = (
	screenSize: _Screen,
	includeSmaller: boolean = false,
	includeLarger: boolean = false
): any => {
	return {
		minWidth: includeSmaller ? "0px" : screenSize.minWidth,
		maxWidth: includeLarger ? "10000em" : screenSize.maxWidth,
	};
};
