import { Col } from "react-bootstrap";
import { BulletinType } from "Types/bulletin";
import BulletinListGarageKeeper from "./BulletinListGarageKeeper";

interface Props {
  title: string;
  bulletinType: BulletinType;
}

export default function BulletinListDashboardCard({
  title,
  bulletinType,
}: Props) {
  return (
    <Col xl={8} lg={12} className="mb-4">
      <div className="card border-left-primary shadow py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="font-weight-bold text-dark text-uppercase mb-1">
                {title}
              </div>
              <div className="mb-2">
                <BulletinListGarageKeeper bulletinType={bulletinType} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
