import { Col, Form } from "react-bootstrap";
import FormLabel from "../../Components/FormElements/FormLabel";
import FormSelect from "../../Components/FormElements/FormSelect";
import FormText from "../../Components/FormElements/FormText";
import {
  ImpoundedVehicleStatus,
  IsApprovedOrSoldStatus,
} from "../../Types/impoundedVehicleStatus";
import { IreCheck } from "../../Types/ireCheck";
import { VehicleCondition } from "../../Types/vehicleCondition";
import { VehicleImpoundment } from "../../Types/vehicleImpoundment";
import { VmrStatus } from "../../Types/vmrStatus";
import { formatMoneyWithDecimals } from "../../Utils/format";
import { patterns } from "../../Utils/regex";
import Accordion from "../../Components/Accordion/Accordion";

export function ValidateApplicationToDispose(
  vehicleImpoundment: VehicleImpoundment
): string[] {
  let errors: string[] = [];

  if (
    (vehicleImpoundment.claims ?? false) &&
    !(vehicleImpoundment.adjusterNotified ?? false)
  ) {
    errors.push("Adjuster Notified must be 'Yes' when Claims is 'Yes'");
  }

  if (
    (vehicleImpoundment.lien ?? false) &&
    !(vehicleImpoundment.garageKeeperNotified ?? false)
  ) {
    errors.push("Garage Keeper Notified must be 'Yes' when Lien is 'Yes'");
  }

  return errors;
}

interface Props {
  onChange(event: any): void;
  vehicleImpoundment: VehicleImpoundment;
  control: boolean;
}

export function ApplicationToDisposeForm({
  onChange,
  vehicleImpoundment,
  control,
}: Props) {
  const formYesNo = (
    label: string,
    field: string,
    value: any,
    required: boolean = false
  ) => {
    return (
      <>
        <FormLabel required={required} text={label} />
        <Form.Control
          data-testid={field}
          as="select"
          name={field}
          value={value}
          onChange={(e) => {
            onChange({
              ...e,
              target: {
                name: e.target.name,
                id: e.target.id,
                value:
                  e.target.value === "true"
                    ? true
                    : e.target.value === "false"
                    ? false
                    : null,
              },
            });
          }}
          required={required}
        >
          <option key=""></option>
          <option value="false" key="No">
            No
          </option>
          <option value="true" key="Yes">
            Yes
          </option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {label}{" "}
          {value === undefined || value === null || value === ""
            ? "is required"
            : ""}
        </Form.Control.Feedback>
      </>
    );
  };

  const isApprovedOrSoldStatus = IsApprovedOrSoldStatus(
    vehicleImpoundment.status
  );

  if (
    !isApprovedOrSoldStatus &&
    vehicleImpoundment.status !== ImpoundedVehicleStatus.ApplicationToDispose
  )
    return <></>;

  return (
    <>
      <Accordion title="Disposal Details" defaultShow control={control}>
        <Form.Row>
          <Form.Group
            as={Col}
            md="5"
            controlId="applicationToDisposeReceivedDate"
          >
            <FormText
              type="date"
              testId="applicationToDisposeReceivedDate"
              onChange={onChange}
              label="Application to Dispose Received Date"
              field="applicationToDisposeReceivedDate"
              value={vehicleImpoundment.applicationToDisposeReceivedDate ?? ""}
              required={
                vehicleImpoundment.status ===
                  ImpoundedVehicleStatus.ApplicationToDispose ||
                isApprovedOrSoldStatus
              }
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formVechicleCondition">
            <FormSelect
              onChange={onChange}
              testId="vehicleCondition"
              label="Vehicle Condition"
              field="vehicleCondition"
              value={vehicleImpoundment.vehicleCondition ?? ""}
              defaultOption={{
                value: "",
                text: "Select Vehicle Condition",
              }}
              data={Object.keys(VehicleCondition)}
              mapping={(s) => {
                return {
                  value: s,
                  text: VehicleCondition[s as keyof typeof VehicleCondition],
                };
              }}
              required={isApprovedOrSoldStatus}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formInventory Notations">
            <FormText
              testId="inventoryNotations"
              onChange={onChange}
              label="Inventory Notations"
              field="inventoryNotations"
              value={vehicleImpoundment.inventoryNotations}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formVmrStatus">
            <FormSelect
              onChange={onChange}
              testId="vmrStatus"
              label="VMR Status"
              field="vmrStatus"
              value={vehicleImpoundment.vmrStatus ?? ""}
              defaultOption={{
                value: "",
                text: "Select VMR Status",
              }}
              data={Object.keys(VmrStatus)}
              mapping={(s) => {
                return {
                  value: s,
                  text: VmrStatus[s as keyof typeof VmrStatus],
                };
              }}
              required={isApprovedOrSoldStatus}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formVmrValue">
            <FormText
              testId="vmrValue"
              onChange={onChange}
              label="VMR Value"
              field="vmrValue"
              value={formatMoneyWithDecimals(vehicleImpoundment.vmrValue)}
              required={isApprovedOrSoldStatus}
              pattern={patterns.money}
              invalidMessage=" is invalid"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formIreCheck">
            <FormSelect
              onChange={onChange}
              testId="ireCheck"
              label="IRE Check"
              field="ireCheck"
              value={vehicleImpoundment.ireCheck ?? ""}
              defaultOption={{
                value: "",
                text: "Select IRE Check",
              }}
              data={Object.keys(IreCheck)}
              mapping={(s) => {
                return {
                  value: s,
                  text: IreCheck[s as keyof typeof IreCheck],
                };
              }}
              required={isApprovedOrSoldStatus}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formGarageKeeperDeclaredValue">
            <FormText
              onChange={onChange}
              testId="garageKeeperDeclaredValue"
              label="Garage Keeper Declared Value"
              field="garageKeeperDeclaredValue"
              value={formatMoneyWithDecimals(
                vehicleImpoundment.garageKeeperDeclaredValue
              )}
              required={isApprovedOrSoldStatus}
              pattern={patterns.money}
              invalidMessage=" is invalid"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="3" controlId="formClaimsYesNoUndefined">
            {formYesNo(
              "Claims",
              "claims",
              vehicleImpoundment.claims,
              isApprovedOrSoldStatus
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            md="3"
            controlId="formAdjusterNotifiedYesNoUndefined"
          >
            {formYesNo(
              "Adjuster Notified",
              "adjusterNotified",
              vehicleImpoundment.adjusterNotified,
              isApprovedOrSoldStatus && vehicleImpoundment.claims
            )}
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formLienYesNoUndefined">
            {formYesNo(
              "Lien",
              "lien",
              vehicleImpoundment.lien,
              isApprovedOrSoldStatus
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            md="3"
            controlId="formGarageKeeperNotifiedYesNoUndefined"
          >
            {formYesNo(
              "Garage Keeper Notified",
              "garageKeeperNotified",
              vehicleImpoundment.garageKeeperNotified,
              isApprovedOrSoldStatus && vehicleImpoundment.lien
            )}
          </Form.Group>
        </Form.Row>
      </Accordion>
    </>
  );
}

export default ApplicationToDisposeForm;
