import { VehicleImpoundment } from "Types/vehicleImpoundment";
import { months } from "./consts";

export function formatPhoneNumber(unformatted: string): string {
  if (
    unformatted === undefined ||
    unformatted === null ||
    unformatted === "" ||
    unformatted.length !== 10
  )
    return unformatted;

  let formatted =
    unformatted.substr(0, 3) +
    "-" +
    unformatted.substr(3, 3) +
    "-" +
    unformatted.substr(6, 4);

  return formatted;
}

export function formatPhoneNumberUndefined(
  unformatted: string | undefined
): string | undefined {
  if (typeof unformatted === "string") {
    return formatPhoneNumber(unformatted);
  } else {
    return unformatted ?? "";
  }
}

export function formatPostalCode(unformatted: string): string {
  if (
    unformatted === undefined ||
    unformatted === null ||
    unformatted === "" ||
    unformatted.length !== 6
  )
    return unformatted;

  let formatted = unformatted.substr(0, 3) + " " + unformatted.substr(3, 3);

  return formatted;
}

export function formatMoneyNoDecimals(unformatted: string | number): string {
  let formatted =
    "$" + unformatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return formatted;
}

export function formatMoneyWithDecimals(unformatted?: number): string {
  if (unformatted === undefined || unformatted === null) return "";

  return unformatted.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  });
}

export function formatDate(unformatted?: Date | string, noSpace: boolean = true): string {
  if (!unformatted) {
    return "";
  }

  if (typeof unformatted === "string") {
    unformatted = unformatted.split("T")[0] ?? unformatted;
    const [year, month, day] = unformatted.split("-");
    return noSpace ? day + months[parseInt(month) - 1] + year : `${day} ${months[parseInt(month) - 1]} ${year}`;
  }

  let formatted = unformatted
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).replace(/ /g, "");

  return formatted;
}

export function formatDateTime(
  unformatted?: Date | string,
  locale: string = "en-GB"
): string {
  if (!unformatted) {
    return "";
  }

  if (typeof unformatted === "string") {
    unformatted = new Date(unformatted);
  }

  let formatted = unformatted
    .toLocaleDateString(locale, {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
    })
    .replace(/ /g, "")
    .replace(/,/g, ", ");

  return formatted;
}

const formatDateToDateTime = (input: Date | string, locale: string = "en-CA"): string => {
  if (!input) {
    return "";
  }

  var dt = new Date(input);

  const date = dt.toLocaleDateString(locale);
  const time = dt.toLocaleTimeString(locale, { hour12: false });

  return `${date}T${time}`;
}

export function unformatMoney(
  formatted: string | undefined
): number | undefined {
  if (formatted === undefined || formatted === null) {
    return undefined;
  }

  let unformatted = parseFloat(formatted.replace(/[^0-9.]/g, ""));

  return unformatted;
}

export function formatVehicleImpoundment(vi: any): VehicleImpoundment {
  let formatted: VehicleImpoundment = {
    ...vi,
    impoundmentStart: vi.impoundmentStart?.split("T")[0],
    impoundmentEnd: vi.impoundmentEnd?.split("T")[0],
    impoundmentFormReceivedDate: vi.impoundmentFormReceivedDate?.split("T")[0],
    releaseDate: vi.releaseDate?.split("T")[0],
    applicationToDisposeReceivedDate:
      vi.applicationToDisposeReceivedDate?.split("T")[0],
    saleReceivedDate: vi.saleReceivedDate?.split("T")[0],
    saleDate: vi.saleDate?.split("T")[0],
    paymentDate: vi.paymentDate?.split("T")[0],
    locationPhoneNumber: vi.locationPhoneNumber,
  };

  if (vi.impoundedVehicle) {
    formatted.impoundedVehicle = {
      ...vi.impoundedVehicle,
      registeredOwnerPhoneNumber:
        vi.impoundedVehicle.registeredOwnerPhoneNumber,
      driverPhoneNumber: vi.impoundedVehicle.driverPhoneNumber,
      licensePlate: vi.impoundedVehicle.licensePlate ?? "",
    };
  }

  return formatted;
}

export function formatDateStripTime(unformated?: string): string | undefined {
  return formatDate(unformated?.split("T")[0]);
}

export function unformatVehicleImpoundment(
  vi: VehicleImpoundment
): VehicleImpoundment {
  let unformatted = {
    ...vi,
    impoundedVehicle: {
      ...vi.impoundedVehicle,
    },
  };

  unformatted.impoundedVehicle.registeredOwnerPhoneNumber =
    unformatted.impoundedVehicle.registeredOwnerPhoneNumber?.replace(/\D/g, "");

  unformatted.impoundedVehicle.driverPhoneNumber =
    unformatted.impoundedVehicle.driverPhoneNumber?.replace(/\D/g, "");

  unformatted.fees = unformatMoney(unformatted.fees?.toString());
  unformatted.garageKeeperDeclaredValue = unformatMoney(
    unformatted.garageKeeperDeclaredValue?.toString()
  );
  unformatted.vmrValue = unformatMoney(unformatted.vmrValue?.toString());

  if (unformatted.impoundedVehicle.licensePlate?.toString() === "") {
    unformatted.impoundedVehicle.licensePlate = undefined;
  }

  if (unformatted.vmrStatus?.toString() === "") {
    unformatted.vmrStatus = undefined;
  }

  if (unformatted.vehicleCondition?.toString() === "") {
    unformatted.vehicleCondition = undefined;
  }

  if (unformatted.ireCheck?.toString() === "") {
    unformatted.ireCheck = undefined;
  }

  return unformatted;
}

export function formatCustomerNumber(unformatted: number | null): string {
  let formatted: string = "";

  if (unformatted) {
    formatted = unformatted.toString().padStart(8, "0");
  }

  return formatted;
}

export { formatDateToDateTime }