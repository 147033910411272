import React from "react";
import parse from "html-react-parser";
import { sanitize } from "dompurify";
import { FormattingTypes } from "../../Types/commentFormatting";

interface Props {
  text: string;
}

const formatConfig = {
  ALLOWED_ATTR: ["class"],
  FORBID_ATTR: ["style"],
  FORBID_TAGS: ["style", "div"],
};

function CommentFormatter({ text }: Props) {
  let formattedText = text;

  // Apply all formatting rules
  Object.keys(FormattingTypes).forEach((key) => {
    let type = FormattingTypes[key];
    formattedText = formattedText.replace(
      new RegExp(type.matchPattern, "gm"),
      type.replacePattern
    );
  });

  return (
    <span data-testid="formatted-text">
      {parse(sanitize(formattedText, formatConfig))}
    </span>
  );
}

export default CommentFormatter;
