import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import CallBffApi from "Utils/CallBff";
import allActions from "Redux/allActions";
import { doToast, ToastType } from "Utils/toastUtils";
import { Attachment } from "Types/attachment";

interface Props {
  id: string;
  changeType: string;
  description: string | undefined;
  attachment: Attachment;
  updateCount: Function;
  onCancel: Function;
  setIdAttachmentInChange: Function;
  userName: string;
}

function AttachmentChangeReason(props: Props) {
  const [changeReason, setChangeReason] = useState("");
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.changeType === "PUT") {
      if (props.attachment.description === null && props.description?.trim()) {
        setDisableSubmit(false);
        return;
      }
      if (
        props.attachment.description !== null &&
        props.description?.trim() !== props.attachment.description
      ) {
        setDisableSubmit(false);
        return;
      }
    }
    if (props.changeType === "DELETE" && changeReason.trim() !== "") {
      setDisableSubmit(false);
      return;
    }
    setDisableSubmit(true);
  }, [
    changeReason,
    props.attachment.description,
    props.changeType,
    props.description,
  ]);

  const attachmentBox = {
    width: "99%",
    height: "75px",
  } as React.CSSProperties;

  const attachmentButtons = {
    float: "right",
  } as React.CSSProperties;

  const button = {
    width: "100%",
  } as React.CSSProperties;

  const noPaddingRight = {
    paddingRight: "0px",
  } as React.CSSProperties;

  const noPaddingLeft = {
    paddingLeft: "0px",
  } as React.CSSProperties;

  const submit = async (e: any) => {
    e.preventDefault();

    const deleteUrl = `${window.REACT_APP_API_BASEURL}attachments/${props.id}?deleteReason=${changeReason}`;

    const updateUrl = `${window.REACT_APP_API_BASEURL}attachments/${props.id}`;

    const attachmentToUpdate: Attachment = {
      ...props.attachment,
      description: props.description,
      changeReason: changeReason,
      lastEditor: props.userName,
      lastUpdatedOn: new Date(),
    };

    const response =
      props.changeType === "DELETE"
        ? await CallBffApi(deleteUrl, {
            method: props.changeType,
          })
        : await CallBffApi(updateUrl, {
            method: props.changeType,
            body: JSON.stringify(attachmentToUpdate),
          });

    if (response.ok) {
      if (props.changeType === "DELETE") {
        doToast(
          `Delete successful: ${props.attachment.fileName}`,
          ToastType.Success
        );
        dispatch(allActions.AttachmentActions.deleteAttachment(props.id));
        props.updateCount(false, 1);
      }
      if (props.changeType === "PUT") {
        doToast(
          `Update successful: ${props.attachment.fileName}`,
          ToastType.Success
        );
        dispatch(
          allActions.AttachmentActions.updateAttachment(attachmentToUpdate)
        );
        props.setIdAttachmentInChange();
      }
    } else {
      if (props.changeType === "DELETE")
        doToast(`Delete failed: ${props.attachment.fileName}`, ToastType.Error);
      if (props.changeType === "PUT")
        doToast(`Update failed: ${props.attachment.fileName}`, ToastType.Error);
    }
  };

  return (
    <div style={attachmentBox}>
      <Row>
        <Col md="11" style={noPaddingRight}>
          <textarea
            style={attachmentBox}
            data-testid={"change-reason"}
            placeholder={
              props.changeType === "DELETE"
                ? "(required) Enter reason for deleting attachment here..."
                : "(optional) Enter reason for changing description here..."
            }
            value={changeReason}
            onChange={(e) => {
              setChangeReason(e.target.value);
            }}
          ></textarea>
        </Col>
        <Col md="1" style={noPaddingLeft}>
          <div style={attachmentButtons}>
            <button
              style={button}
              type="button"
              title={props.changeType === "DELETE" ? "Delete" : "Save"}
              disabled={disableSubmit}
              className="btn btn-primary"
              onClick={submit}
            >
              &#10004;
            </button>
            <button
              style={button}
              type="button"
              title="Cancel"
              className="btn btn-secondary"
              onClick={() => props.onCancel?.("")}
            >
              &#10060;
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AttachmentChangeReason;
