import React from "react";
import RegularLayout from "./RegularLayout";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router";

interface Props {
  title: string;
  header: string;
  subheader: string;
  notification?: any;
  children?: any;
}

function DetailLayout(props: Props) {
  const history = useHistory();

  return (
    <RegularLayout title={props.title}>
      <div className="card shadow mb-4 min-vh-100">
        <div className="card-header py-3">
          <Row>
            <Col xs={3} sm={2} lg={1} className="align-bottom">
              <Button title="Back" onClick={() => history.goBack()}>
                Back
              </Button>
            </Col>
            <Col xs={6} sm={7} lg={8}>
              <h4 className="m-0 font-weight-bold text-success" data-testid="layout-header">
                {props.header}
              </h4>
              <small className="text-secondary" data-testid="layout-subheader">{props.subheader}</small>
            </Col>
            <Col xs={3} sm={3} lg={3}>
              <h6
                style={{ textAlign: "right" }}
                className="m-0 font-weight-bold text-success"
              >
                {props.notification}
              </h6>
            </Col>
          </Row>
        </div>
        <div className="card-body">{props.children}</div>
      </div>
    </RegularLayout>
  );
}

export default DetailLayout;
