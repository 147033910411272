import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { ReactComponent as Sgilogo } from "Resources/images/sgi-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faCar,
  faComments,
  faDatabase,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faNewspaper,
  faTruckPickup,
  faUpload,
  faUser,
  faUserCog,
  faWarehouse,
  faBook,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import { RootStateOrAny, useSelector } from "react-redux";
import { UserType } from "Types/userInfo";
import {
  lawEnforcementLinks,
  link,
  GetInternalLinks,
  GetGarageKeeperLinks,
} from "Routing/links";
import { RequestStatus } from "Redux/requestStatus";
import { InsuranceState } from "Types/insurancePolicy";
import { getGarageKeeperInsuranceState } from "Components/GarageKeepers/GarageKeeperDetailInternal";
import SideSearchBox from "Components/Search/SideSearchBox";
import { isIntenalUser } from "Utils/userUtils";

function SideBar(props: any) {
  fontAwesomeLibrary.add(
    faAddressBook,
    faCar,
    faComments,
    faDatabase,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faFileAlt,
    faNewspaper,
    faTruckPickup,
    faUpload,
    faUser,
    faUserCog,
    faWarehouse,
    faBook,
    faBookOpen
  );

  const history = useHistory();

  const insurancePolicies = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.insurancePolicies
  );

  const insurancePoliciesStatus = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.status
  );

  const [gkInsuranceStatus, setGkInsuranceStatus] = useState<
    InsuranceState | undefined
  >(undefined);

  useEffect(() => {
    if (insurancePoliciesStatus !== RequestStatus.LOADING) {
      setGkInsuranceStatus(getGarageKeeperInsuranceState(insurancePolicies));
    }
  }, [insurancePolicies, insurancePoliciesStatus]);

  const navbarClass =
    "navbar-nav pt-3 bg-gradient-primary sidebar sidebar-dark";

  const { fontSize, layout } = useMemo(() => {
    return props.mediumSize
      ? { fontSize: "10px", layout: "column" }
      : { fontSize: "13.6px", layout: "row" };
  }, [props.mediumSize]);

  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const internalLinks = GetInternalLinks();
  const garageKeeperLinks = GetGarageKeeperLinks();

  const links: link[] = useMemo(() => {
    if ((isIntenalUser(userType))) {
      return internalLinks;
    } else if (userType === UserType.GarageKeeper) {
      return garageKeeperLinks;
    } else if (userType === UserType.LawEnforcement) {
      return lawEnforcementLinks;
    } else {
      return [];
    }
  }, [garageKeeperLinks, internalLinks, userType]);

  const CustomLink = (props: any) => (
    <li
      className={
        history.location.pathname.match(props.path)
          ? "nav-item active"
          : "nav-item"
      }
      style={{
        padding: "0 1rem",
        marginBottom: "1rem",
        textAlign: "center",
      }}
    >
      <NavLink
        className="nav-link"
        to={props.path}
        id={`${props.text.replace(/\s/g, "")}SidebarLink`}
        onClick={props.onClick}
        style={{
          color: `rgba(255, 255, 255, ${
            history.location.pathname.match(props.path) ? 1 : 0.8
          })`,
          fontWeight: history.location.pathname.match(props.path) ? 700 : 400,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: layout as "row" | "column",
            alignItems: "center",
          }}
        >
          {props.icon && <i className={"fa fa-" + props.icon + " fa-fw"}></i>}

          <span className="pl-1" style={{ fontSize: fontSize }}>
            {props.text}
          </span>
          <span className="pl-1">{props.warning}</span>
        </div>
      </NavLink>
    </li>
  );

  const getWarningIcon = (linkTitle: string) => {
    if (linkTitle === "Insurance Policies") {
      return gkInsuranceStatus === "Expiring" ? (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="1x"
          color="orange"
        />
      ) : (
        gkInsuranceStatus === "Expired" && (
          <FontAwesomeIcon icon={faExclamationCircle} size="1x" color="red" />
        )
      );
    }
  };

  return (
    <div
      style={{
        width: props.width,
        height: "100vh",
        position: "fixed",
        backgroundColor: "#646464",
        overflow: "hidden",
      }}
    >
      <ul className={navbarClass}>
        <Link to="/">
          <div className="mr-3 ml-3">
            <Sgilogo title="SGI" />
          </div>
        </Link>
        {/* Sidebar - Brand */}
        <Link
          to="/"
          className="sidebar-brand d-flex align-items-center justify-content-center"
        >
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              margin: "2rem 0",
              fontSize: fontSize,
            }}
          >
            GARAGE KEEPER
          </div>
        </Link>

        {/* */}
        <hr
          className="sidebar-divider my-2"
          style={{
            borderBottom: "0.5px solid rgba(255,255,255,0.2)",
            width: "90%",
          }}
        ></hr>

        {/* Generate menu links, based on UserType */}
        {links.map((link) => {
          return (
            <CustomLink
              key={link.text}
              path={link.path}
              icon={link.icon}
              text={link.text}
              onClick={link.onClick}
              warning={getWarningIcon(link.text)}
            />
          );
        })}
        <li>
         <SideSearchBox />
        </li>
      </ul>
    </div>
  );
}

export default SideBar;
