import { useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { VehicleImpoundment, defaultVehicleImpoundment } from "../../Types/vehicleImpoundment";
import { Location } from "../../Types/location";
import {
    formatPhoneNumber,
    formatPostalCode
  } from "../../Utils/format";
import Accordion from "../../Components/Accordion/Accordion"
import FormText from "../../Components/FormElements/FormText";
import FormSelect from "../../Components/FormElements/FormSelect";
import { GarageKeeper } from "../../Types/garageKeeper";

interface Props {
  handleUpdate(event: any): void;
  vehicleImpoundment: VehicleImpoundment;
  locations: Location[];
  garageKeepers: GarageKeeper[];
  control: boolean;
}

export function GarageKeeperDetailsForm({ handleUpdate, vehicleImpoundment, locations, garageKeepers, control }: Props) {
    const locationPhoneNumber = useMemo(() => {
        let loc = locations.find(
          (l: Location) => l.id === vehicleImpoundment.locationId
        );
        return formatPhoneNumber(loc?.phoneNumber ?? "");
      }, [locations, vehicleImpoundment.locationId]);

  return (
    <>
      <Accordion title="Garage Keeper Details" defaultShow control={control}>
        <Form.Row>
        <Form.Group as={Col} md="4" controlId="formGarageKeeper">
            <FormSelect
              label="Garage Keeper"
              onChange={handleUpdate}
              field="garageKeeperId"
              value={
                vehicleImpoundment.garageKeeperId !==
                defaultVehicleImpoundment.garageKeeperId
                  ? vehicleImpoundment.garageKeeperId
                  : ""
              }
              required
              defaultOption={{
                value: "",
                text: "Select Garage Keeper",
              }}
              data={garageKeepers}
              mapping={(g) => {
                return { value: g.id, text: g.name };
              }}
              sorting={(a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                return 0;
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formLocation">
            <FormSelect
              required
              label="Location"
              field="locationId"
              onChange={handleUpdate}
              value={
                vehicleImpoundment.locationId !==
                defaultVehicleImpoundment.locationId
                  ? vehicleImpoundment.locationId
                  : ""
              }
              data={locations}
              defaultOption={{ value: "", text: "Select Location" }}
              mapping={(l) => {
                return {
                  value: l.id,
                  text:
                    l.physicalAddress.street +
                    ", " +
                    l.physicalAddress.city +
                    ", " +
                    l.physicalAddress.province +
                    " " +
                    formatPostalCode(l.physicalAddress.postalCode),
                };
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formCompoundPhoneNumber">
            <FormText
              label="Compound Phone Number"
              testId={"compoundPhoneNumber"}
              field="locationPhoneNumber"
              disabled
              value={locationPhoneNumber}
            />
          </Form.Group>
        </Form.Row>
      </Accordion>
    </>
  );
}

export default GarageKeeperDetailsForm;
