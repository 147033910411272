import {
    SET_REQUIRE_COMMENTS,
    COMPLETE_REQUIRE_COMMENTS
} from "./RequiredCommentsActions"
import { Comment } from "Types/comment";

export interface IRequiredCommentsState {
    list: Comment[]
}

export const initialState: IRequiredCommentsState = {
    list: []
}

export default function RequireCommentsReducer(
    state: IRequiredCommentsState = initialState,
    action: any
) {
    switch (action.type) {
        case SET_REQUIRE_COMMENTS:
            return {
                list: action.value
            }
        case COMPLETE_REQUIRE_COMMENTS:
            return {
                list: state.list.filter(x => x.id !== action.value.id)
            }
        default:
            return state;
    }
}
