import RegularLayout from "../../Layouts/RegularLayout";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

function UserProfile() {
  fontAwesomeLibrary.add(faUserCircle);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  let claims: any | undefined = account?.idTokenClaims;

  return (
    <RegularLayout title="User Profile">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">User Profile</h1>
      </div>

      <div className="container">
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <FontAwesomeIcon
                      icon="user-circle"
                      size="4x"
                      color="black"
                    />
                    <div className="mt-3">
                      <h4 data-testid="user-profile-name">{account?.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Full Name</h6>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      data-testid="user-profile-full-name"
                    >
                      {account?.name}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      data-testid="user-profile-email"
                    >
                      {(account?.idTokenClaims as any)?.email}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Role(s)</h6>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      data-testid="user-profile-roles"
                    >
                      {(claims?.roles &&
                        claims.roles.map((role: string) => {
                          return role;
                        })) ??
                        "None"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RegularLayout>
  );
}

export default UserProfile;
