import { Alert, Dropdown, Card, Col, Row } from "react-bootstrap";
import Loader from "Components/Loader/Loader";
import HoursOfOperation from "./HoursOfOperation";
import LocationForm from "Forms/LocationForm/LocationForm";
import { formatPhoneNumber } from "Utils/format";
import { Location, LocationStatus } from "Types/location";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import allActions from "Redux/allActions";
import CommentContainer from "Components/Comments/CommentContainer";
import { useState } from "react";
import ReusableModal from "Components/Modal/ReusableModal";
import CallBffApi from "Utils/CallBff";
import AttachmentContainer from "Components/Attachments/AttachmentContainer";
import { EntityType } from "Types/EntityType";
import PhoneEmail from "Utils/PhoneEmail";
import { UserPermissions, UserType } from "Types/userInfo";

interface Props {
  garageKeeperId?: string;
  locations?: Location[];
  history: any;
  external?: boolean;
}

export default function LocationList(props: Props) {
  const userType = useSelector((state: RootStateOrAny) => state.userInfo.userType);
  const locations = useSelector(
    (state: RootStateOrAny) => state.locations.locations
  );

  const { id: gkId } = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const location = useLocation<LocationStatus>();
  const status: LocationStatus | undefined = location.state;

  const [locationStatusFilter, setLocationStatusFilter] = useState(
    status ? status.toString() : "Active"
  );

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  const match = useRouteMatch<{ id: string }>();

  const filteredLocations = locations.filter(
    (location: Location) =>
      locationStatusFilter === "All" ||
      location.status.toString() === locationStatusFilter
  );

  const dispatch = useDispatch();

  const garageKeeperId = props.garageKeeperId ?? match.params.id;

  const requestUrl = `${window.REACT_APP_API_BASEURL}locations/garageKeeperId=${garageKeeperId}`;

  const [showModal, setShowModal] = useState(false);
  const open = (itemToEdit?: Location) => {
    setLocationToEdit(itemToEdit);
    setShowModal(true);
  };
  const close = () => {
    setShowModal(false);
  };

  const [locationToEdit, setLocationToEdit] = useState(
    undefined as Location | undefined
  );

  return (
    <>
      {
        (userPermissions.canCreateCompound || userPermissions.canUpdateCompound) &&
        <ReusableModal
          buttonText="Add New Compound"
          headerText={locationToEdit ? "Edit Compound" : "Add New Compound"}
          show={showModal}
          open={() => {
            open();
          }}
          close={close}>
          <LocationForm
            submit={CallBffApi}
            cancel={close}
            data={locationToEdit}
          />
        </ReusableModal>
      }
      <Loader
        url={requestUrl}
        onLoadComplete={(result: any) =>
          dispatch(allActions.LocationActions.setLocations(result))
        }
      >
        <Dropdown className="mb-2 mt-2">
          <Dropdown.Toggle data-testid="location-status-filter">
            Status:{" "}
            {locationStatusFilter === "All"
              ? "<All>"
              : LocationStatus[
              locationStatusFilter as keyof typeof LocationStatus
              ]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => setLocationStatusFilter("All")}
              data-testid="location-status-All"
            >
              {"<All>"}
            </Dropdown.Item>
            {Object.keys(LocationStatus)
              .sort()
              .map((status: string) => {
                return (
                  <Dropdown.Item
                    key={status}
                    data-testid={`location-status-${status}`}
                    onClick={() => setLocationStatusFilter(status)}
                  >
                    {LocationStatus[status as keyof typeof LocationStatus]}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        <br />
        <Row>
          {filteredLocations !== undefined && filteredLocations.length > 0 ? (
            filteredLocations.map((location: Location) => {
              return (
                <Col sm={12} md={6} lg={4} xl={3} key={location.id}>
                  <Card
                    style={{ minHeight: "275px", height: "95%" }}
                    data-testid={"locationCard"}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          borderBottom: "solid 1px gainsboro",
                          paddingBottom: "5px",
                        }}
                        data-testid={`PhysicalAddress-${location.id}`}
                      >
                        <Row>
                          <Col>
                            {location.physicalAddress.street},{" "}
                            {location.physicalAddress.city}{" "}
                            {location.physicalAddress.province}
                          </Col>
                          {userPermissions.canUpdateCompound &&
                            <Col className="col-auto">
                              <span
                                data-testid={`Edit-${location.id}`}
                                onClick={() => {
                                  open(location);
                                }}
                              >
                                <i className="far fa-edit edit-button"></i>
                              </span>
                            </Col>
                          }
                        </Row>
                      </Card.Title>
                      {location.phoneNumber && (
                        <>
                          <Card.Subtitle className="mb-2 text-muted">
                            Phone Number
                          </Card.Subtitle>
                          <Card.Text data-testid={`Phone-${location.id}`}>
                            <PhoneEmail
                              type="phone"
                              value={formatPhoneNumber(
                                location.phoneNumber
                              )}
                              testId={`PhoneLink-${location.id}`}
                            />
                          </Card.Text>
                        </>
                      )}
                      <Card.Subtitle className="mb-2 text-muted">
                        Hours
                      </Card.Subtitle>
                      <Card.Text data-testid={`Hours-${location.id}`}>
                        <HoursOfOperation hours={location.operationHours} />
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Status
                      </Card.Subtitle>
                      <Card.Text data-testid={`Status-${location.id}`}>
                        {location.status}
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Vehicles In Compound
                      </Card.Subtitle>
                      <Card.Text data-testid={`VehCount-${location.id}`}>
                        <span
                          className="requestCountButton"
                          data-testid={`impoundedVehicles-${location.id}`}
                          onClick={() => {
                            dispatch(
                              allActions.ImpoundedVehicleListActions.filterStatus(
                                "InCompound"
                              )
                            );
                            dispatch(
                              allActions.ImpoundedVehicleListActions.filterLocation(
                                location.id
                              )
                            );
                            props.history.push({
                              pathname: `${props.external
                                ? "/ImpoundedVehicles"
                                : `/GarageKeepers/${gkId}/impoundVehicles`
                                }`,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {location.impoundmentCount}
                        </span>
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Attachments/Comments
                      </Card.Subtitle>
                      <div style={{ paddingBottom: "10px" }}>
                        <AttachmentContainer
                          entityId={location.id}
                          entityType={EntityType.Location}
                          count={location.attachmentCount}
                          readOnly={!userPermissions.canUpdateCompound}
                        />
                        &nbsp;
                        <CommentContainer
                          id={location.id}
                          count={location.commentCount}
                          entityType={EntityType.Location}
                          orgId={userType === UserType.Admin ? garageKeeperId : undefined}
                          readOnly={!userPermissions.canUpdateCompound}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                  <br />
                </Col>
              );
            })
          ) : (
            <Alert variant="dark">
              <i>No locations found</i>
            </Alert>
          )}
        </Row>
      </Loader>
    </>
  );
}
