import { API_ERROR } from "Redux/garagekeepers/GKListActions";
import { PagedResponse } from "Types/pagination";
import { RequestListInfo } from "Types/requestListInfo";
import { VehicleImpoundment } from "Types/vehicleImpoundment";
import CallBffApi from "Utils/CallBff";

export const SET_REQUESTS = "REQUESTS/SET_REQUESTS";
export const SORT_REQUESTS = "REQUESTS/SORT_REQUESTS";
export const SEARCH_POLICE_FILE_NUMBER = "REQUESTS/SEARCH_POLICE_FILE_NUMBER";
export const SEARCH_VIN_POLICE_FILE = "REQUESTS/SEARCH_VIN_POLICE_FILE";
export const SEARCHED_VIN_POLICE_FILE = "REQUESTS/SEARCHED_VIN_POLICE_FILE";
export const FILTER_GK = "REWUESTS/FILTER_FILTERGK_NAME";
export const FILTER_STATUS = "REQUESTS/FILTER_STATUS";
export const FILTER_TYPE = "REQUESTS/FILTER_TYPE";
export const FILTER_RECENT = "REQUESTS/FILTER_RECENT";
export const LOAD_REQUEST_LIST = "REQUESTS/LOAD_REQUEST_LIST";
export const UPDATE_REQUEST = "REQUESTS/UPDATE_REQUEST";
export const SET_PAGE = "REQUESTS/SET_PAGE";
export const SET_PAGE_SIZE = "REQUESTS/SET_PAGE_SIZE";
export const SET_TOTAL_COUNT = "REQUESTS/SET_TOTAL_COUNT";
export const SET_RELATED_IMPOUNDMENTS = "REQUESTS/SET_RELATED_IMPOUNDMENTS";

const setRequestList = (requestList: RequestListInfo[]) => {
  return { type: SET_REQUESTS, requestList };
};

async function loadAllRequestList(url: string) {
  var response = await CallBffApi(
    `${window.REACT_APP_API_BASEURL}requests${url}`
  );

  if (response.ok) {
    var result: PagedResponse = await response.json();
    return result.data;
  }
  return [];
}

const loadRequestList = (url: string) => {
  return async function (dispatch: any) {
    var response = await CallBffApi(
      `${window.REACT_APP_API_BASEURL}requests${url}`
    );

    if (response.ok) {
      var result: PagedResponse = await response.json();

      if (result.pagination !== null) {
        dispatch({
          type: SET_TOTAL_COUNT,
          totalCount: result.pagination.totalCount,
          totalPages: result.pagination.totalPages,
          hasPrev: result.pagination.hasPrevious,
          hasNext: result.pagination.hasNext,
        });
      }
      dispatch({
        type: LOAD_REQUEST_LIST,
        value: result.data,
      });
    } else dispatch({ type: API_ERROR, value: response.statusText });
  };
};

const sortRequests = (sortField: string, sortDirection: string) => {
  return {
    type: SORT_REQUESTS,
    sortField,
    sortDirection,
    page: 1,
  };
};

const searchPoliceFileNumber = (searchPoliceFileNumber: string) => {
  return {
    type: SEARCH_POLICE_FILE_NUMBER,
    searchPoliceFileNumber,
    page: 1,
  };
};

const searchVINPoliceFile = (searchVINPoliceFile: string) => {
  return {
    type: SEARCH_VIN_POLICE_FILE,
    searchVINPoliceFile,
  };
};

const searchedVINPoliceFile = (searchedVINPoliceFile: boolean) => {
  return {
    type: SEARCHED_VIN_POLICE_FILE,
    searchedVINPoliceFile,
  };
};

const filterGk = (filter: string, gkName: string) => {
  return {
    type: FILTER_GK,
    filter: filter,
    gkName: gkName,
    page: 1,
  };
};

const filterStatus = (filter: string) => {
  return {
    type: FILTER_STATUS,
    filter: filter,
    page: 1,
  };
};

const filterType = (filter: string) => {
  return {
    type: FILTER_TYPE,
    filter: filter,
    page: 1,
  };
};

const filterRecent = (filter: boolean) => {
  return {
    type: FILTER_RECENT,
    filter: filter,
    page: 1,
  };
};

const updateRequest = (request: RequestListInfo) => {
  return {
    type: UPDATE_REQUEST,
    value: request,
  };
};

const setPageNum = (pageNum: number) => {
  return {
    type: SET_PAGE,
    page: pageNum,
  };
};

const setPageSize = (size: number) => {
  return {
    type: SET_PAGE_SIZE,
    pageSize: size,
  };
};

const setTotalCount = (
  totalCount: number,
  totalPages: number,
  hasPrev: boolean,
  hasNext: boolean
) => {
  return {
    type: SET_TOTAL_COUNT,
    totalCount: totalCount,
    totalPages: totalPages,
    hasPrev: hasPrev,
    hasNext: hasNext,
  };
};

const setRelatedImpoundments = (impoundments: VehicleImpoundment[]) => {
  return {
    type: SET_RELATED_IMPOUNDMENTS,
    payload: impoundments
  }
}

const RequestActions = {
  setRequestList,
  loadAllRequestList,
  loadRequestList,
  sortRequests,
  searchPoliceFileNumber,
  searchVINPoliceFile,
  searchedVINPoliceFile,
  filterGk,
  filterStatus,
  filterType,
  filterRecent,
  updateRequest,
  setPageNum,
  setPageSize,
  setTotalCount,
  setRelatedImpoundments
};

export default RequestActions;
