import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Modal, ListGroup, Row, Col } from "react-bootstrap";
import Loader from "Components/Loader/Loader";
import CommentActions from "Redux/comments/CommentsActions";
import { defaultLawEnforcement, LawEnforcement } from "Types/lawEnforcement";
import { formatDateStripTime } from "Utils/format";
import FormText from "Components/FormElements/FormText";
import CallBffApi from "Utils/CallBff";
import { doToast, ToastType } from "Utils/toastUtils";
import { Attachment } from "Types/attachment";
import { previewableExtensions } from "Utils/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

interface Props {
  id: string;
  policeFileNumber: string;
  createdBy: string;
  createdOn: Date;
  handleSuccess: Function;
}

function LawEnforcementForm({ id, policeFileNumber, createdBy, createdOn, handleSuccess }: Props) {
  const [show, setShow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [doc, setDoc] = useState<LawEnforcement>(defaultLawEnforcement);
  const [downloadUrl, setDownloadUrl] = useState("");
  const dispatch = useDispatch();

  library.add(faDownload);

  const isFilePreviewable = (fileName: string) => {
    return previewableExtensions.some((ext) =>
      fileName.toLowerCase().endsWith(ext)
    );
  };

  const retrieveAttachment = async (e: any, file: Attachment, download: boolean) => {
    e.preventDefault();
    
    const attachmentUrl = `${(window as any).REACT_APP_API_BASEURL}attachments/${file.id}/download`;

    if (downloadUrl === "")
      CallBffApi(attachmentUrl)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error(
              `${download ? "Download" : "Preview"} failed: ${
                file.fileName
              }`
            );
          }
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          setDownloadUrl(blobUrl);
          if (download === false) {
            window.open(blobUrl);
          } else {
            downloadAttchment(blobUrl, file);
          }
        })
        .catch((error) => {
          doToast(error.message, ToastType.Error);
        });
    else if (download === false) window.open(downloadUrl);
    else downloadAttchment(downloadUrl, file);
  };

  const downloadAttchment = (url: string, file: Attachment) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const requestUrl = `${window.REACT_APP_API_BASEURL}lawEnforcement/${id}`;

  const handleClose = () => {
    dispatch(CommentActions.clearCommentState());
    setShow(false);
    setDisableSave(false);
    setShowDelete(false);
    setDeleteReason("");
  };

  const handleUpdate = (event: any) => {
    event.target.value === policeFileNumber || event.target.value.trim() === "" ?
    setDisableSave(true) :
    setDisableSave(false)

    setDoc({
      ...doc,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setDisableSave(true);

    if (doc.policeFileNumber.trim() === "") return false;

    let submitUrl = `${window.REACT_APP_API_BASEURL}lawEnforcement/${id}`;

    let lawEnforcementDocumentToSubmit: LawEnforcement = {...doc};

    let response = await CallBffApi(submitUrl, {
      method: "PUT",
      body: JSON.stringify(lawEnforcementDocumentToSubmit),
    });

    if (response.ok) {
      handleSuccess();
      doToast("Law Enforcement Document saved successfully", ToastType.Success);
      handleClose();
      return true;
    }

    const errorResult = await response.json();
    doToast(errorResult, ToastType.Error);
    setDisableSave(false);
    return false;
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) return false;

    let submitUrl = `${window.REACT_APP_API_BASEURL}lawEnforcement/${id}/?deleteReason=${deleteReason}`;

    let response = await CallBffApi(submitUrl, {
      method: "DELETE"
    });

    if(response.ok){
      handleSuccess();
      doToast("Law Enforcement Document deleted successfully", ToastType.Success);
      handleClose();
      return true
    }

    doToast("An error occurred when trying to delete the law enforcement doc", ToastType.Error);
    return false;
  }

  const deleteRow = () => {
    if(showDelete){
      return (
        <ListGroup.Item>
          <Row>
            <Col md={3} className={"font-weight-bold"}>
              Delete Reason
            </Col>
            <Col md={6} data-testid={`deleteRow`}>
              <Form.Control
                data-testid="deleteReason"
                name="deleteReason"
                placeholder="Please enter a reason for deletion"
                value={deleteReason}
                onChange={(e) => setDeleteReason(e.target.value)}
                required
                style={{display: "inline-block", borderRadius: ".25rem 0px 0px .25rem"}}
              />
            </Col>
            <Col>
              <Button
                variant="danger"
                disabled={deleteReason.trim().length === 0}
                data-testid="deleteConfirm"
                style={{borderRadius: ".25rem 0px 0px .25rem"}}
                onClick={handleDelete}>
                Confirm
              </Button>
              <Button
                variant="secondary"
                data-testid="deleteCancel"
                style={{borderRadius: "0px .25rem .25rem 0px"}}
                onClick={() => {
                  setShowDelete(false)
                  setDeleteReason("")
                }}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      )
    }else{
      return (
        <Button 
          variant="danger"
          data-testid="deleteButton"
          onClick={() => setShowDelete(true)}
          style={{borderRadius: "0px 0px .25rem .25rem"}}>
          Delete Document
        </Button>
      )
    }
  }

  return (
    <>
      <Row className="law-enforcement-row"
           data-testid={`lawEnforcement-${id}`}
           style={{borderTop: "1px solid gainsboro", color: "#5a5c69"}}
           onClick={() => setShow(true)}>
        <Col sm={12} md={12} xl={5} style={{fontSize: "20px"}} data-testid="col-policeFileNumber">
          {policeFileNumber}
        </Col>
        <Col sm={12} md={12} xl={7} style={{textAlign: "right"}}>
          <div data-testid="col-createdBy">
            {createdBy}
          </div>
          <div data-testid="col-createdOn">
            {formatDateStripTime(createdOn.toString())}
          </div>
        </Col>
      </Row>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <h2>Notice of Impoundment - {policeFileNumber}</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Loader
              url={requestUrl}
              onLoadComplete={(result: LawEnforcement) => {
                setDoc(result)
              }}
            >
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col md={3} className={"font-weight-bold"}>
                      Police File #
                    </Col>
                    <Col>
                      <FormText
                        required
                        disabled={showDelete}
                        testId={"policeFileNumber"}
                        field="policeFileNumber"
                        value={doc?.policeFileNumber}
                        onChange={handleUpdate}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={3} className={"font-weight-bold"}>
                      Author
                    </Col>
                    <Col data-testid={"author"}>
                      {doc?.attachment?.author}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={3} className={"font-weight-bold"}>
                      Date Received
                    </Col>
                    <Col data-testid={"createdOn"}>
                      {formatDateStripTime(doc?.createdOn.toString())}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {deleteRow()}
              </ListGroup>
              <br />
              <div>
                {doc.attachment !== undefined && 
                isFilePreviewable(doc.attachment.fileName) ? (
                  <Button
                    onClick={(e) => retrieveAttachment(e, doc.attachment!, false)}
                    href={doc.attachment.id}
                    data-testid="previewLink"
                    title={"Open in a new tab"}
                    style={{borderRadius: ".25rem 0px 0px .25rem"}}
                  >
                    Preview Attachment
                  </Button>
                ) : (
                  <Button
                    disabled
                    data-testid="previewUnavailable"
                    style={{borderRadius: ".25rem 0px 0px .25rem"}}
                  >
                    Preview not available
                  </Button>
                )}
                {doc.attachment !== undefined && 
                  <Button
                    onClick={(e) => retrieveAttachment(e, doc.attachment!, true)}
                    href={doc.attachment.id}
                    data-testid="downloadLink"
                    title={"Download"}
                    variant="outline-primary"
                    style={{borderRadius: "0px .25rem .25rem 0px"}}
                  >
                    <FontAwesomeIcon icon="download" />
                  </Button>
                }
              </div>
            </Loader>
            <Modal.Footer>
              <Button
                disabled={
                  showDelete || disableSave ? true : false
                }
                variant="primary"
                data-testid="saveButton"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LawEnforcementForm;
