import { Col } from "react-bootstrap";

import DetailLayout from "Layouts/DetailLayout";
import { BulletinType } from "Types/bulletin";
import BulletinListGarageKeeper from "./BulletinListGarageKeeper";

interface Props {
  bulletinType: BulletinType;
  title: string;
}

export default function BulletinListStandalone({ bulletinType, title }: Props) {
  return (
    <DetailLayout title={title} header={title} subheader="">
      <Col xl={8} lg={12}>
        <BulletinListGarageKeeper
          key={bulletinType}
          bulletinType={bulletinType}
        />
      </Col>
    </DetailLayout>
  );
}
