import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const APP_INSIGHTS_CLOUD_ROLE = "GarageKeeper.UI";

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

const createAppInsightsService = () => {
  const initialize = (instrumentationKey: string) => {
    const browserHistory = createBrowserHistory({ basename: "" });
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ "ai.cloud.role": APP_INSIGHTS_CLOUD_ROLE });
    });
    return { appInsights, reactPlugin };
  };
  return { initialize };
};

export const ai = createAppInsightsService();
export { reactPlugin, appInsights };
