import { Address } from "./address";
import { ImpoundedVehicleStatus } from "./impoundedVehicleStatus";
import { ImpoundedVehicle, defaultImpoundedVehicle } from "./impoundedVehicle";
import { VehicleCondition } from "./vehicleCondition";
import { VmrStatus } from "./vmrStatus";
import { IreCheck } from "./ireCheck";
import { VehicleImpoundmentFee } from "./VehicleImpoundmentFee";
import { ImpoundmentCategory } from "./impoundmentType";
import { CommentCount } from "./comment";

export interface VehicleImpoundment {
  id: string;
  impoundedVehicleId: string;
  impoundedVehicle: ImpoundedVehicle;
  impoundmentStart: Date | string;
  impoundmentEnd?: Date | string;
  releaseDate?: Date | string;
  impoundmentFormReceivedDate: Date | string;
  applicationToDisposeReceivedDate?: Date | string;
  saleReceivedDate?: Date | string;
  saleDate?: Date | string;
  paymentDate?: Date | string;
  wrongful: boolean;
  status: ImpoundedVehicleStatus;
  garageKeeperId: string;
  locationId: string;
  garageKeeperName: string;
  locationPhoneNumber: string;
  locationAddress: Address;
  category: ImpoundmentCategory;
  type: string;
  termLength: number;
  daysImpounded: number;
  inventoryFormReceived?: boolean;
  vehicleCondition?: VehicleCondition;
  inventoryNotations: string;
  fees?: number;
  garageKeeperDeclaredValue?: number;
  vmrStatus?: VmrStatus;
  vmrValue?: number;
  ireCheck?: IreCheck;
  claims?: boolean;
  adjusterNotified?: boolean;
  lien?: boolean;
  garageKeeperNotified?: boolean;
  salesDocRecvd?: boolean;
  statutoryDeclRecvd?: boolean;
  invoiceRecvd?: boolean;
  feesBreakdown: VehicleImpoundmentFee[];
  mileageKM: number;
  winchUsageHours: number;
  daysStored: number;
  commentCount: CommentCount;
  attachmentCount: number;
  releaseDateReason?: ReleaseDateReason;
  releaseDateReasonDescription?: string;
  releaseFormReceived?: boolean;
  policeFileNumber?: string;
  lawEnforcementEntity?: string;
}

export const defaultVehicleImpoundment: VehicleImpoundment = {
  id: "00000000-0000-0000-0000-000000000000",
  impoundedVehicleId: "00000000-0000-0000-0000-000000000000",
  impoundedVehicle: defaultImpoundedVehicle,
  impoundmentStart: new Date("0001-01-01T00:00:00"),
  impoundmentEnd: undefined,
  releaseDate: undefined,
  impoundmentFormReceivedDate: new Date(),
  applicationToDisposeReceivedDate: undefined,
  saleReceivedDate: undefined,
  saleDate: undefined,
  paymentDate: undefined,
  wrongful: false,
  status: ImpoundedVehicleStatus.Active,
  garageKeeperId: "00000000-0000-0000-0000-000000000000",
  locationId: "00000000-0000-0000-0000-000000000000",
  garageKeeperName: "",
  locationPhoneNumber: "",
  locationAddress: {
    street: "",
    city: "",
    postalCode: "",
    province: "SK",
    country: "CA",
  },
  category: ImpoundmentCategory.Default,
  type: "",
  termLength: -1,
  daysImpounded: 0,
  inventoryFormReceived: undefined,
  vehicleCondition: undefined,
  inventoryNotations: "",
  fees: undefined,
  garageKeeperDeclaredValue: undefined,
  vmrStatus: undefined,
  vmrValue: undefined,
  ireCheck: undefined,
  claims: undefined,
  adjusterNotified: undefined,
  lien: undefined,
  garageKeeperNotified: undefined,
  salesDocRecvd: undefined,
  statutoryDeclRecvd: undefined,
  invoiceRecvd: undefined,
  feesBreakdown: new Array<VehicleImpoundmentFee>(),
  mileageKM: 0,
  winchUsageHours: 0,
  daysStored: 0,
  commentCount: {
    internal: 0,
    external: 0,
  },
  attachmentCount: 0,
  releaseDateReason: undefined,
  releaseDateReasonDescription: undefined,
  releaseFormReceived: undefined,
  policeFileNumber: undefined,
  lawEnforcementEntity: undefined,
};

export enum ReleaseDateReason {
  AppealGranted = "AppealGranted",
  PoliceWithdrawn = "PoliceWithdrawn",
  Stolen = "Stolen",
  ReleaseToClaim = "ReleaseToClaim",
  Other = "Other",
  InternationalStudent = "InternationalStudent",
  WorkPermit = "WorkPermit",
  WrongfulMedical = "WrongfulMedical",
  WrongfulOther = "WrongfulOther",
  ReleasedTermEnded = "ReleasedTermEnded",
}

export enum ReleaseDateReasonLabel {
  AppealGranted = "Appeal Granted",
  PoliceWithdrawn = "Police Withdrawn",
  Stolen = "Stolen",
  ReleaseToClaim = "Release To Claim",
  Other = "Other",
  InternationalStudent = "International Student",
  WorkPermit = "Work Permit",
  WrongfulMedical = "Wrongful Medical",
  WrongfulOther = "Wrongful Other",
  ReleasedTermEnded = "Released - Term Ended",
}
