import { Contact } from "../../Types/contact";
import CallBffApi from "../../Utils/CallBff";
import { doToast, ToastType } from "../../Utils/toastUtils";

export const SET_CONTACTS = "CONTACT/SET_CONTACTS";

export const ADD_CONTACT = "CONTACT/ADD_CONTACT";
export const UPDATE_CONTACT = "CONTACT/UPDATE_CONTACT";

export const DELETE_CONTACT = "CONTACT/DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "CONTACT/DELETE_CONTACT_SUCCESS";

export const ERROR = "CONTACT/API_ERROR";

const addContact = (contact: Contact) => {
  return {
    type: ADD_CONTACT,
    contact: contact,
  };
};

const updateContact = (contact: Contact) => {
  return {
    type: UPDATE_CONTACT,
    contact: contact,
  };
};

const setContacts = (contacts: Contact[]) => {
  return {
    type: SET_CONTACTS,
    contacts: contacts,
  };
};

const deleteContact = (contactId: string, deleteReason: string) => {
  return async function (dispatch: any) {
    const response = await CallBffApi(
      `${window.REACT_APP_API_BASEURL}contacts/${contactId}?deleteReason=${deleteReason}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      doToast("Contact deleted successfully", ToastType.Success);

      return dispatch({
        type: DELETE_CONTACT_SUCCESS,
        contactId: contactId,
      });
    }

    doToast("Error deleting contact", ToastType.Error);

    dispatch({ type: ERROR });
  };
};

const ContactListActions = {
  addContact,
  updateContact,
  setContacts,
  deleteContact,
};

export default ContactListActions;
