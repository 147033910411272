import { GkPermissions } from "Types/userInfo";

export function checkWorkAsGkFromLocalStorage(
  userEmailFormToken: string,
  userGKPermissionFromToken: GkPermissions[]
) {
  const prevWorkAsGk = JSON.parse(localStorage.getItem("workAsGk") ?? "{}");
  if (
    userEmailFormToken === prevWorkAsGk?.userEmail &&
    userGKPermissionFromToken.some(
      (p) =>
        p.securityOrganizationId ===
        prevWorkAsGk?.workAsGk?.securityOrganizationId
    )
  )
    return prevWorkAsGk.workAsGk;
  else localStorage.removeItem("workAsGk");
}
