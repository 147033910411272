import { Comment } from "Types/comment";
import CallBffApi from "Utils/CallBff";

const getRequireAttentionComments = async (garageKeeperId: string | undefined): Promise<Comment[]> => {
    const baseUrl = `${(window as any).REACT_APP_API_BASEURL}`;
    let requestUrl = `${baseUrl}comments/requireAttention`;

    if (garageKeeperId != undefined) {
        requestUrl = `${requestUrl}?garageKeeperId=${garageKeeperId}`;
    }

    const response = await CallBffApi(
        `${requestUrl}`,
        {
            method: "GET",
            body: null,
        }
    );

    if (response.ok) {
        var result = await response.json();
        return result;
    }

    return [];
}

const updateAttentionStatus = async (comment: Comment) : Promise<boolean> => {
    const baseUrl = `${(window as any).REACT_APP_API_BASEURL}`;
    let requestUrl = `${baseUrl}comments/${comment.id}/updatedAttentionStatus`;

    const response = await CallBffApi(
        `${requestUrl}`,
        {
            method: "PUT",
            body: JSON.stringify({
                Id: comment.id,
                AttentionStatus: comment.attentionStatus,
                CommentEntityType: comment.commentEntityType
            }),
        }
    );

    return response.ok;
}

export { getRequireAttentionComments, updateAttentionStatus }