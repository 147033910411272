export interface ImpoundedVehicle {
  id: string;
  vin: string;
  year?: number;
  make: string;
  model: string;
  licensePlate?: string;
  registeredOwner?: string;
  registeredOwnerCustNo?: string;
  registeredOwnerPhoneNumber?: string;
  isDriverTheSameAsRegisteredOwner?: boolean;
  driver?: string;
  driverCustNo?: string;
  driverPhoneNumber?: string;
}

export const defaultImpoundedVehicle: ImpoundedVehicle = {
  id: "00000000-0000-0000-0000-000000000000",
  vin: "",
  year: undefined,
  make: "",
  model: "",
  licensePlate: undefined,
  registeredOwner: undefined,
  registeredOwnerCustNo: undefined,
  registeredOwnerPhoneNumber: undefined,
  isDriverTheSameAsRegisteredOwner: false,
  driver: undefined,
  driverCustNo: undefined,
  driverPhoneNumber: undefined,
};

export interface ImpoundedVehicleSearchTerms {
  vin?: string;
  garageKeeperId?: string;
}
