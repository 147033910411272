import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import { formatDateStripTime } from "../../Utils/format";
import ReusableModal from "../Modal/ReusableModal";
import FeeRateForm from "../../Forms/AdminForms/FeeRateForm";
import { ImpoundmentFeeRule } from "../../Types/VehicleImpoundmentFee";
import { useMemo } from "react";
import { VehicleImpoundmentFeeType } from "../../Types/VehicleImpoundmentFeeType";
import { doToast, ToastType } from "../../Utils/toastUtils";
import { RootStateOrAny, useSelector } from "react-redux";
import { UserPermissions } from "Types/userInfo";

interface Props {
  submit: any;
}

function EditFeeRates(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [feeToEdit, setFeeToEdit] = useState<ImpoundmentFeeRule | undefined>(
    undefined
  );
  const [loading, setLoading] = useState("");

  const [fees, setFees] = useState<ImpoundmentFeeRule[]>([]);
  const { userPermissions }: { userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const loadFees = useCallback(async () => {
    setLoading("Loading");
    const response = await props.submit(
      window.REACT_APP_API_BASEURL + "vehicleimpoundments/fees?future=true"
    );

    if (response.ok) setFees(await response.json());

    setLoading("Loaded");
  }, [props]);

  useEffect(() => {
    if (fees.length === 0 && loading !== "Loaded") loadFees();
  }, [fees, loading, loadFees]);

  // List of current types to display
  const feeTypes = useMemo(() => {
    return fees
      .reduce((prev, current) => {
        if (!prev.includes(current.feeType)) prev.push(current.feeType);
        return prev;
      }, [] as VehicleImpoundmentFeeType[])
      .sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
  }, [fees]);

  const openModal = (feeToEdit?: ImpoundmentFeeRule) => {
    setModalOpen(true);
    setFeeToEdit(feeToEdit);
  };

  const closeModal = () => {
    setModalOpen(false);
    setFeeToEdit(undefined);
  };

  const handleSubmit = async (fee: ImpoundmentFeeRule): Promise<boolean> => {
    // Form validation has been run prior to this point

    const url =
      feeToEdit === undefined
        ? `${window.REACT_APP_API_BASEURL}vehicleimpoundments/fees`
        : `${window.REACT_APP_API_BASEURL}vehicleimpoundments/fees/${feeToEdit.id}`;
    const method = feeToEdit === undefined ? "POST" : "PUT";

    const response = await props.submit(url, {
      method: method,
      body: JSON.stringify(fee),
    });

    if (!response.ok) {
      doToast("An error occurred", ToastType.Error);
      return false;
    }

    let message = "Rate added successfully";

    // successful edit
    if (response.status === 204) message = "Rate updated successfully";
    loadFees();

    doToast(message, ToastType.Success);
    // toast success
    return true;
  };

  return (
    <>
      {userPermissions.canCreateImpoundmentFee &&
        <ReusableModal
          buttonText="Add New Rate"
          show={modalOpen}
          open={() => openModal()}
          close={closeModal}
          headerText={(feeToEdit?.feeTypeDescription ?? "New") + " Fee Rate"}>
          <FeeRateForm
            submit={handleSubmit}
            cancel={closeModal}
            data={feeToEdit}
            allFees={fees}
          />
        </ReusableModal>
      }
      <br />
      <br />
      {fees.length === 0 && loading === "Loaded" ? (
        <Alert>No fees found</Alert>
      ) : fees.length === 0 && loading === "Loading" ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Table data-testid="feesTable">
          <thead>
            <tr>
              <th>Type</th>
              <th>Effective Date</th>
              <th>Expiry Date</th>
              <th>Base Rate</th>
              <th>Hourly Rate</th>
              <th>Mileage Rate (km)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {feeTypes.map((feeType) => {
              let feesToPrint = fees
                .filter((f) => f.feeType === feeType)
                .sort((a, b) => {
                  if (a.effectiveDate < b.effectiveDate) return -1;
                  if (a.effectiveDate > b.effectiveDate) return 1;
                  return 0;
                });
              return feesToPrint.map((fee) => {
                return (
                  <tr
                    key={fee.feeType + "-" + fee.effectiveDate}
                    style={{
                      backgroundColor: fee.isActive
                        ? "transparent"
                        : "lightgrey",
                    }}
                    data-testid={fee.feeType + "-" + fee.id}
                  >
                    <td>{fee.feeTypeDescription}</td>
                    <td>{formatDateStripTime(fee.effectiveDate)}</td>
                    <td>
                      {fee.expiryDate
                        ? formatDateStripTime(fee.expiryDate)
                        : "N/A"}
                    </td>
                    <td>{fee.baseRate > 0 ? fee.baseRate : ""}</td>
                    <td>{fee.hourlyRate > 0 ? fee.hourlyRate : ""}</td>
                    <td>{fee.mileageRate > 0 ? fee.mileageRate : ""}</td>
                    {userPermissions.canUpdateImpoundmentFee &&
                      <td>
                        <Button onClick={() => openModal(fee)}>Edit</Button>
                      </td>
                    }
                  </tr>
                );
              });
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default EditFeeRates;
