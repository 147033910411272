import { Attachment } from "Types/attachment";
import CallBffApi from "Utils/CallBff";

const getAttachments = async (entityId: string, type: string): Promise<Attachment[]> => {
    const baseUrl = `${(window as any).REACT_APP_API_BASEURL}`;
    const requestUrl = `${baseUrl}attachments/entityId=${entityId}?entityType=${type}`;
    const response = await CallBffApi(
        `${requestUrl}`,
        {
            method: "GET",
            body: null,
        }
    );

    if (response.ok) {
        var result = await response.json();
        return result;
    }

    return [];
}

export { getAttachments }