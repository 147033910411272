import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import ContactUs from "Components/ContactUs/ContactUs";
import DashboardLawEnforcement from "Components/Dashboard/DashboardLawEnforcement";
import NotFound from "Components/NotFound/NotFound";
import UserProfile from "Components/UserProfile/UserProfile";
import { UserType } from "Types/userInfo";

export default function LawEnforcementRoutes() {
  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const history = useHistory();

  if (userType === UserType.LawEnforcement) {
    return (
      <Switch>
        <Route path="/" exact>
          <DashboardLawEnforcement history={history} />
        </Route>
        <Route path="/ContactUs" component={ContactUs} />
        <Route path="/UserProfile" component={UserProfile} />
        <Route component={NotFound} />
      </Switch>
    );
  } else {
    return <></>;
  }
}
