import React from "react";
import ReactDOM from "react-dom";
import "./Styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeMsalInstance } from "./Utils/initializeMsal";
import { ai } from "./AppInsights";

fetch("/config.json")
  .then((response) => {
    console.log(response);
    return response.json();
  })
  .then((data) => {
    (window as any)["runConfig"] = data;

    window.REACT_APP_API_BASEURL =
      (window as any)["runConfig"].REACT_APP_API_BASEURL ??
      "https://localhost:5001/v1/garagekeeperui/";

    window.REACT_APP_APPINSIGHTS_INSTR_KEY =
      (window as any)["runConfig"].REACT_APP_APPINSIGHTS_INSTR_KEY ??
      "12a61c71-97fd-449d-bb02-74c7ca5a9d92";

    ai.initialize(window.REACT_APP_APPINSIGHTS_INSTR_KEY);

    console.log("Config loaded...");

    initializeMsalInstance();

    ReactDOM.render(<App />, document.getElementById("root"));
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
