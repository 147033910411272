interface props {
  text: string;
  field: string;
  sortField: string;
  sortDirection: string;
  handleSort: Function;
}

const sortButtonStyle = {
  border: "none",
  borderBottom: "solid grey",
  borderRadius: "3px",
} as React.CSSProperties;

export default function StatelessSortButton({
  text,
  field,
  sortField,
  sortDirection,
  handleSort,
}: props) {
  return (
    <button
      data-testid={"header-" + field}
      onClick={() => handleSort(field)}
      style={{
        ...sortButtonStyle,
        background: sortField === field ? "gainsboro" : "none",
      }}
    >
      {text}
      <div
        style={{
          paddingLeft: "10px",
          width: "15px",
          display: "inline-block",
        }}
      >
        {sortField === field
          ? sortDirection === "ascending" || sortDirection === "asc"
            ? "↑"
            : "↓"
          : "⇵"}
      </div>
    </button>
  );
}
