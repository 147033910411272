import { Col, Card, Row } from "react-bootstrap";
import {
  InsurancePolicy,
  insurancePolicyTypeDescription,
} from "Types/insurancePolicy";
import { formatDate, formatMoneyNoDecimals } from "Utils/format";
import AttachmentContainer from "Components/Attachments/AttachmentContainer";
import CommentContainer from "Components/Comments/CommentContainer";
import { EntityType } from "Types/EntityType";
import { useSelector, RootStateOrAny } from "react-redux";
import { UserPermissions, UserType } from "Types/userInfo";

interface Props {
  insurancePolicy: InsurancePolicy;
  onEdit(policy: InsurancePolicy): any;
  onDelete(policy: InsurancePolicy): any;
  isExternalUser: boolean;
}

function InsuranceCard({
  insurancePolicy,
  onEdit,
  onDelete,
  isExternalUser,
}: Props) {

  const userType = useSelector((state: RootStateOrAny) => state.userInfo.userType);
  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  return (
    <Col
      sm={12}
      md={6}
      lg={4}
      xl={3}
      data-testid={"insurance-card-" + insurancePolicy.id}
    >
      <Card style={{ height: "95%" }}>
        <Card.Body>
          <Card.Title
            style={{
              borderBottom: "solid 1px gainsboro",
              paddingBottom: "5px",
            }}
          >
            <Row>
              <Col>
                {insurancePolicyTypeDescription(insurancePolicy.insuranceType)}
              </Col>
              {!isExternalUser && (
                <Col className="col-auto">
                  {userPermissions.canUpdateInsurance &&
                    <span
                      title="Edit"
                      onClick={() => {
                        onEdit(insurancePolicy);
                      }}
                      data-testid="edit-button"
                    >
                      <i className="far fa-edit edit-button"></i>
                    </span>
                  }
                  &nbsp;
                  {userPermissions.canDeleteInsurance &&
                    <span
                      title="Delete"
                      onClick={() => {
                        onDelete(insurancePolicy);
                      }}
                      data-testid="delete-button"
                    >
                      <i className="fas fa-trash-alt delete-button"></i>
                    </span>
                  }
                </Col>
              )}
            </Row>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Insurer</Card.Subtitle>
          <Card.Text>{insurancePolicy.insurer}</Card.Text>
          <Card.Subtitle className="mb-2 text-muted">Term</Card.Subtitle>
          <Card.Text>
            <span>{formatDate(insurancePolicy.effectiveDate)}</span>
            &nbsp;to&nbsp;
            <span>{formatDate(insurancePolicy.expiryDate)}</span>
          </Card.Text>
          <Card.Subtitle className="mb-2 text-muted">
            Liability Amount
          </Card.Subtitle>
          <Card.Text>
            {formatMoneyNoDecimals(insurancePolicy.liabilityAmount)}
          </Card.Text>
          <Card.Subtitle className="mb-2 text-muted">Status</Card.Subtitle>
          <Card.Text>{insurancePolicy.status}</Card.Text>
          <Card.Subtitle className="mb-2 text-muted">
            Attachments/Comments
          </Card.Subtitle>
          <div style={{ paddingBottom: "10px" }}>
            <AttachmentContainer
              entityId={insurancePolicy.id}
              entityType={EntityType.InsurancePolicy}
              count={insurancePolicy.attachmentCount}
              readOnly={!(userPermissions.canUpdateInsurance || isExternalUser)}
            />
            &nbsp;
            <CommentContainer
              id={insurancePolicy.id}
              count={insurancePolicy.commentCount}
              entityType={EntityType.InsurancePolicy}
              orgId={userType === UserType.Admin ? insurancePolicy.garageKeeperId : undefined}
              readOnly={!(userPermissions.canUpdateInsurance || isExternalUser)}
            />
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default InsuranceCard;
