import { Contact } from "../../Types/contact";
import {
  SET_CONTACTS,
  ADD_CONTACT,
  DELETE_CONTACT_SUCCESS,
  ERROR,
  UPDATE_CONTACT,
} from "./ContactListActions";
import { RequestStatus } from "../requestStatus";

export interface ContactListState {
  contacts: Contact[];
  status: RequestStatus;
}

const initialState: ContactListState = {
  contacts: [] as Contact[],
  status: RequestStatus.IDLE,
};

export default function ContactListReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.contact],
        status: RequestStatus.IDLE,
      };

    case UPDATE_CONTACT:
      const index = state.contacts.findIndex((c) => c.id === action.contact.id);
      if (index === -1) return state; // not found
      let newContacts = [...state.contacts];
      newContacts.splice(index, 1, action.contact);

      return {
        ...state,
        contacts: [...newContacts],
      };

    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
        status: RequestStatus.IDLE,
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter((c) => c.id !== action.contactId),
        status: RequestStatus.IDLE,
      };

    case ERROR:
      return {
        ...state,
        status: RequestStatus.ERROR,
      };

    default:
      return state;
  }
}
