import {
  API_ERROR,
  LOAD_GARAGEKEEPER_COUNTS_SUCCESS,
  LOAD_IMPOUNDEDVEHICLE_COUNTS_SUCCESS,
  LOAD_IMPOUNDEDVEHICLE_FORM_COUNTS_SUCCESS,
  LOAD_INSURANCE_POLICY_COUNTS_SUCCESS,
  LOAD_GK_IMPOUNDEDVEHICLE_COUNTS_SUCCESS,
  LOAD_REQUEST_COUNTS_SUCCESS,
  LOAD_GARAGEKEEPER_FYI_COUNTS_SUCCESS,
} from "./dashboardActions";
import { GkCounts, defaultGkCounts } from "../../Types/gkCounts";
import { VICounts, defaultVICounts } from "../../Types/VICounts";
import { gkFYICounts, defaultGkFYICounts } from "../../Types/gkFYICounts";
import {
  GkImpoundmentCounts,
  defaultGkImpoundmentCounts,
} from "../../Types/gkImpoundmentCounts";
import {
  InsurancePolicyCounts,
  defaultInsurancePolicyCounts,
} from "../../Types/insurancePolicyCounts";
import {
  RequestCountBreakdown,
  defaultRequestCountBreakdown,
} from "../../Types/requestCounts";
import { RequestStatus } from "../requestStatus";

interface DashboardState {
  garageKeeperStatusCounts: GkCounts;
  impoundedVehicleCounts: VICounts;
  impoundedVehicleFormCounts: number;
  insurancePolicyCounts: InsurancePolicyCounts;
  requestCounts: RequestCountBreakdown;
  status: RequestStatus;
  gKImpoundmentCounts: GkImpoundmentCounts;
  gkFYICounts: gkFYICounts;
}

export const initialState: DashboardState = {
  garageKeeperStatusCounts: defaultGkCounts,
  impoundedVehicleCounts: defaultVICounts,
  impoundedVehicleFormCounts: 0,
  insurancePolicyCounts: defaultInsurancePolicyCounts,
  requestCounts: defaultRequestCountBreakdown,
  status: RequestStatus.IDLE,
  gKImpoundmentCounts: defaultGkImpoundmentCounts,
  gkFYICounts: defaultGkFYICounts,
};

export const dashboardReducer: any = (
  state: DashboardState = initialState,
  action: any
): DashboardState => {
  switch (action.type) {
    case LOAD_GARAGEKEEPER_COUNTS_SUCCESS:
      return {
        ...state,
        garageKeeperStatusCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case LOAD_IMPOUNDEDVEHICLE_COUNTS_SUCCESS:
      return {
        ...state,
        impoundedVehicleCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case LOAD_IMPOUNDEDVEHICLE_FORM_COUNTS_SUCCESS:
      return {
        ...state,
        impoundedVehicleFormCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case LOAD_INSURANCE_POLICY_COUNTS_SUCCESS:
      return {
        ...state,
        insurancePolicyCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case LOAD_REQUEST_COUNTS_SUCCESS:
      return {
        ...state,
        requestCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case API_ERROR:
      return {
        ...state,
        status: RequestStatus.ERROR,
      };
    case LOAD_GK_IMPOUNDEDVEHICLE_COUNTS_SUCCESS:
      return {
        ...state,
        gKImpoundmentCounts: action.value,
        status: RequestStatus.IDLE,
      };
    case LOAD_GARAGEKEEPER_FYI_COUNTS_SUCCESS:
      return {
        ...state,
        gkFYICounts: action.value,
        status: RequestStatus.IDLE,
      };
    default:
      return state;
  }
};
