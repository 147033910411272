import { Comment } from "Types/comment";
import {
  SET_EDIT_ID,
  SET_DELETE_ID,
  CLEAR_STATE,
  SET_LIST,
  UPDATE
} from "./CommentsActions";

export interface ContactListState {
  editId: string;
  deleteId: string;
  list: Comment[]
}

const initialState: ContactListState = {
  editId: "",
  deleteId: "",
  list: []
};

export default function CommentsReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_EDIT_ID:
      return {
        ...state,
        deleteId: "",
        editId: action.value,
      };
    case SET_DELETE_ID:
      return {
        ...state,
        deleteId: action.value,
        editId: "",
      };
    case SET_LIST:
      return {
        ...state,
        list: action.value
      }
    case CLEAR_STATE:
      return initialState;
    case UPDATE:

      const comments = state.list;
      const index = state.list.findIndex(x => x.id === action.value.id)

      if (index === -1) {
        return state;
      }

      comments[index] = action.value;
      return {
        ...state,
        list: [...comments]
      }
    default:
      return state;
  }
}
