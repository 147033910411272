import { GarageKeeper } from "Types/garageKeeper";
import ContactInfoList from "./ContactInfoList";
import DetailLayout from "Layouts/DetailLayout";

interface Props {
  garageKeeper: GarageKeeper;
}

export default function ContactInfoListExternal({ garageKeeper }: Props) {
  return (
    <DetailLayout
      title="Contacts"
      header={garageKeeper.name}
      subheader="Contacts"
    >
      <ContactInfoList garageKeeperId={garageKeeper.id} />
    </DetailLayout>
  );
}
