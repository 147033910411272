import {
  FILTER_STATUS,
  FILTER_STATUS_ALL,
  FILTER_TYPE,
  FILTER_TYPE_ALL,
  SORT,
} from "./ExpiredInsuranceActions";

export interface ExpiredInsurnaceState {
  statusFilter: string;
  typeFilter: string;
  sortField: string;
  sortDirection: string;
}

export const initialState: ExpiredInsurnaceState = {
  statusFilter: "ALL",
  typeFilter: "ALL",
  sortField: "daysExpired",
  sortDirection: "descending",
};

export default function ExpiredInsuranceReducer(
  state: ExpiredInsurnaceState = initialState,
  action: any
) {
  switch (action.type) {
    case FILTER_STATUS:
      return {
        ...state,
        statusFilter: action.filter,
      };
    case FILTER_STATUS_ALL:
      return {
        ...state,
        statusFilter: "ALL",
      };
    case FILTER_TYPE:
      return {
        ...state,
        typeFilter: action.filter,
      };
    case FILTER_TYPE_ALL:
      return {
        ...state,
        typeFilter: "ALL",
      };
    case SORT:
      return {
        ...state,
        sortField: action.sortField,
        sortDirection: action.sortDirection,
      };
    default:
      return state;
  }
}
