export const ROOT_CHANGE_USER_TYPE = "ROOT_CHANGE_USER_TYPE";

const changeUserType = () => {
  return {
    type: ROOT_CHANGE_USER_TYPE,
  };
};

const RootActions = {
  changeUserType: changeUserType,
};
export default RootActions;
