import Accordion from "Components/Accordion/Accordion";
import { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { acknowledgeBulletinTypes, Bulletin } from "Types/bulletin";
import { formatDate } from "Utils/format";
import CallBffApi from "Utils/CallBff";
import { doToast, ToastType } from "Utils/toastUtils";
import { RootStateOrAny, useSelector } from "react-redux";
import { UserPermissions } from "Types/userInfo";
import { EntityType } from "Types/EntityType";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import BulletinEditForm from "Forms/AdminForms/BulletinEditForm";
import ReusableModal from "Components/Modal/ReusableModal";

interface Props {
  submit?: any;
  bulletin: Bulletin;
  onDelete?: any;
  onSuccess?: any;
}

export default function BulletinElement({
  bulletin,
  submit = CallBffApi,
  onDelete,
  onSuccess,
}: Props) {
  const userPermissions: UserPermissions = useSelector(
    (state: RootStateOrAny) => state.userInfo.userPermissions
  );

  const [acknowledged, setAcknowledged] = useState<boolean>(
    bulletin.acknowledged ?? false
  );
  const [disableAcknowledgeButton, setDisableAcknowledgeButton] =
    useState<boolean>(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const [downloadUrl, setDownloadUrl] = useState("");

  const attachmentUrl = `${(window as any).REACT_APP_API_BASEURL}attachments/${
    bulletin.attachment?.id
  }/download?entityType=${EntityType.Bulletin}`;

  const cancelEdit = () => {
    setShowEditForm(false);
  };

  const retrieveAttachment = async (e: any, download: boolean) => {
    e.preventDefault();

    if (downloadUrl === "")
      CallBffApi(attachmentUrl)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error(
              `${download ? "Download" : "Preview"} failed: ${
                bulletin.attachment?.fileName
              }`
            );
          }
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          setDownloadUrl(blobUrl);
          if (download === false) {
            window.open(blobUrl);
          } else {
            downloadAttchment(blobUrl);
          }
        })
        .catch((error) => {
          doToast(error.message, ToastType.Error);
        });
    else if (download === false) window.open(downloadUrl);
    else downloadAttchment(downloadUrl);
  };

  const downloadAttchment = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = bulletin.attachment!.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const acknowledgeBulletin = async () => {
    setDisableAcknowledgeButton(true);

    const response = await submit(
      `${window.REACT_APP_API_BASEURL}bulletins/acknowledgement`,
      {
        method: "POST",
        body: JSON.stringify({ bulletinId: bulletin.id }),
      }
    );

    if (response.ok) {
      setAcknowledged(true);
    } else {
      doToast("Error updating acknowledgement", ToastType.Error);
      setDisableAcknowledgeButton(false);
    }
  };

  const deleteBulletin = async (bulletinId: string, deleteReason: string) => {
    const response = await CallBffApi(
      `${window.REACT_APP_API_BASEURL}bulletins/${bulletinId}?deleteReason=${deleteReason}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      doToast(
        `${bulletin.bulletinType} deleted successfully`,
        ToastType.Success
      );
      onDelete(bulletinId);
    } else {
      doToast(`Error deleting ${bulletin.bulletinType}`, ToastType.Error);
    }
  };

  const header = (bulletin: Bulletin) => {
    return (
      <div>
        {formatDate(bulletin.createdOn)} - {bulletin.title}
        {bulletin.archived && (
          <Badge
            variant="secondary"
            style={{ margin: "7px", fontSize: "16px", float: "right" }}
          >
            ARCHIVED
          </Badge>
        )}
      </div>
    );
  };

  return (
    <>
      <Accordion title={header(bulletin)}>
        <div data-testid="bulletin-description">{bulletin.description}</div>
        {bulletin.attachment && (
          <div>
            <a
              onClick={(e) => retrieveAttachment(e, false)}
              href={bulletin.attachment!.id}
              data-testid="preview-link"
              title={"Open in a new tab"}
            >
              View document
            </a>
          </div>
        )}
        {userPermissions.canAcknowledgeBulletins &&
          !acknowledged &&
          acknowledgeBulletinTypes.includes(bulletin.bulletinType!) && (
            <div>
              <br />
              <Button
                disabled={disableAcknowledgeButton}
                data-testid="bulletin-acknowledge"
                onClick={() => acknowledgeBulletin()}
                variant="primary"
              >
                Acknowledge
              </Button>
            </div>
          )}
        {userPermissions.canUpdateBulletins && (
          <>
            <ReusableModal
              headerText={`Edit ${bulletin.bulletinType}`}
              useIcon
              iconClassName="far fa-edit edit-button"
              show={showEditForm}
              open={() => setShowEditForm(true)}
              close={() => setShowEditForm(false)}
            >
              <BulletinEditForm
                onSubmit={submit}
                editBulletin={bulletin}
                onSubmitSuccess={onSuccess!}
                onCancel={cancelEdit}
              />
            </ReusableModal>
            &nbsp;
          </>
        )}
        {userPermissions.canDeleteBulletins && (
          <span
            style={{ fontSize: "25px" }}
            title="Delete"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
            data-testid={`delete-button-${bulletin.id}`}
          >
            <i className="fas fa-trash-alt delete-button"></i>
          </span>
        )}
        <ConfirmDelete
          show={showDeleteConfirm}
          headerText={`Delete ${bulletin.bulletinType}`}
          dialogText={`Are you sure you wish to delete '${bulletin.title}'?`}
          requireDeleteReason={true}
          onClickDelete={(deleteReason: string) => {
            deleteBulletin(bulletin.id, deleteReason);
            setShowDeleteConfirm(false);
          }}
          onClickCancel={() => setShowDeleteConfirm(false)}
        />
      </Accordion>
    </>
  );
}
