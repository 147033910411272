import { GarageKeeper } from "Types/garageKeeper";
import { GarageKeeperListInfo } from "Types/garageKeeperListInfo";
import { GarageKeeperStatus } from "Types/garageKeeperStatus";
import { RequestStatus } from "../requestStatus";
import {
  SET_GKLIST,
  FILTER_STATUS,
  FILTER_STATUS_ALL,
  FILTER_CITY,
  FILTER_CITY_ALL,
  SORT,
  LOAD_GARAGE_KEEPER_LIST,
  LOAD_GARAGE_KEEPER_LIST_SUCCESS,
  ADD_GK,
  UPDATE_GK,
} from "./GKListActions";

export interface GKListState {
  statusFilter: string;
  cityFilter: string;
  sortField: string;
  sortDirection: string;
  gkList: GarageKeeperListInfo[];
}

export const initialState: GKListState = {
  statusFilter: GarageKeeperStatus.Active,
  cityFilter: "ALL",
  sortField: "name",
  sortDirection: "ascending",
  gkList: [] as GarageKeeperListInfo[],
};

function mapGkToGkListInfo(gk: GarageKeeper): GarageKeeperListInfo {
  const gkListInfo: GarageKeeperListInfo = {
    id: gk.id,
    name: gk.name,
    mailingAddress: gk.mailingAddress.street,
    mailingAddressCity: gk.mailingAddress.city,
    phoneNumber: gk.mainOfficePhoneNumber,
    lawEnforcementPhoneNumber: gk.lawEnforcementPhoneNumber,
    status: gk.status,
    canTowSemis: gk.canTowSemis,
    locations: []
  };

  return gkListInfo;
}

export default function GKListReducer(
  state: GKListState = initialState,
  action: any
) {
  switch (action.type) {
    case SET_GKLIST:
      return {
        ...state,
        gkList: action.gkList,
      };

    case FILTER_STATUS:
      return {
        ...state,
        statusFilter: action.filter,
      };

    case FILTER_STATUS_ALL:
      return {
        ...state,
        statusFilter: "ALL",
      };

    case FILTER_CITY:
      return {
        ...state,
        cityFilter: action.filter,
      };

    case FILTER_CITY_ALL:
      return {
        ...state,
        cityFilter: "ALL",
      };

    case SORT:
      return {
        ...state,
        sortField: action.sortField,
        sortDirection: action.sortDirection,
      };

    case LOAD_GARAGE_KEEPER_LIST:
      return {
        ...state,
        status: RequestStatus.LOADING,
      };
    case LOAD_GARAGE_KEEPER_LIST_SUCCESS:
      return {
        ...state,
        status: RequestStatus.IDLE,
        gkList: action.value,
      };

    case ADD_GK:
      return {
        ...state,
        gkList: [mapGkToGkListInfo(action.value), ...state.gkList],
      };

    case UPDATE_GK:
      const index = state.gkList.findIndex((gk) => gk.id === action.value.id);

      if (index === -1) return state;

      let newGkList = [...state.gkList];
      newGkList.splice(index, 1, mapGkToGkListInfo(action.value));

      return {
        ...state,
        gkList: newGkList,
      };

    default:
      return state;
  }
}
