import DashboardCard from "./DashboardCard";
import {
  InsurancePolicyCounts,
  InsurancePolicyStatus,
  getInsuranceStatusAndType,
} from "Types/insurancePolicyCounts";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import allActions from "Redux/allActions";
import { Table } from "react-bootstrap";

function DashboardInsuranceCount(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const InsurancePolicyCounts: InsurancePolicyCounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.insurancePolicyCounts
  );

  const handleClick = (status: InsurancePolicyStatus) => {
    history.push({
      pathname: "/ExpiredInsurance",
    });
    let { insuranceStatus, insuranceType } = getInsuranceStatusAndType(
      InsurancePolicyStatus[status as keyof typeof InsurancePolicyStatus]
    );
    dispatch(allActions.ExpiredInsuranceActions.filterStatus(insuranceStatus));
    dispatch(allActions.ExpiredInsuranceActions.filterType(insuranceType));
  };

  return (
    <DashboardCard testId="insurance-policy-counts">
      <Table style={{ textAlign: "center" }}>
        <tbody>
          <tr>
            <td className="border-top-0">
              <div className="font-weight-bold text-dark text-uppercase mb-1 text-left">
                Insurance Policies
              </div>
            </td>
          </tr>
          <tr>
            <td
              className="text-left"
              style={{
                borderTop: "none",
                paddingTop: 0,
                fontWeight: "bold",
              }}
            >
              Type
            </td>
            <td
              style={{
                borderTop: "none",
                paddingTop: 0,
                fontWeight: "bold",
              }}
            >
              Expiring
            </td>
            <td
              style={{
                borderTop: "none",
                paddingTop: 0,
                fontWeight: "bold",
              }}
            >
              Expired
            </td>
          </tr>
          <tr>
            <td className="text-left">Collision/Upset</td>
            <td
              data-testid="collisionupset-expiring"
              className="dashboardTableCountButton"
              onClick={(e) =>
                handleClick(InsurancePolicyStatus.ExpiringCollisionUpset)
              }
            >
              {InsurancePolicyCounts.ExpiringCollisionUpset}
            </td>
            <td
              data-testid="collisionupset-expired"
              className="dashboardTableCountButton"
              onClick={(e) =>
                handleClick(InsurancePolicyStatus.ExpiredCollisionUpset)
              }
            >
              {InsurancePolicyCounts.ExpiredCollisionUpset}
            </td>
          </tr>
          <tr>
            <td className="text-left">Comprehensive</td>
            <td
              data-testid="comprehensive-expiring"
              className="dashboardTableCountButton"
              onClick={(e) =>
                handleClick(InsurancePolicyStatus.ExpiringComprehensive)
              }
            >
              {InsurancePolicyCounts.ExpiringComprehensive}
            </td>
            <td
              data-testid="comprehensive-expired"
              className="dashboardTableCountButton"
              onClick={(e) =>
                handleClick(InsurancePolicyStatus.ExpiredComprehensive)
              }
            >
              {InsurancePolicyCounts.ExpiredComprehensive}
            </td>
          </tr>
        </tbody>
      </Table>
    </DashboardCard>
  );
}

export default DashboardInsuranceCount;
