import CallBffApi from "../../Utils/CallBff";

export const LOAD_GARAGEKEEPER_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_GARAGEKEEPER_COUNTS_SUCCESS";
export const LOAD_IMPOUNDEDVEHICLE_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_IMPOUNDED_VEHICLE_COUNTS_SUCCESS";
export const LOAD_IMPOUNDEDVEHICLE_FORM_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_IMPOUNDED_VEHICLE_FORM_COUNTS_SUCCESS";
export const LOAD_INSURANCE_POLICY_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_INSURANCE_POLICY_COUNTS_SUCCESS";
export const LOAD_GK_IMPOUNDEDVEHICLE_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_GK_IMPOUNDED_VEHICLE_COUNTS_SUCCESS";
export const LOAD_REQUEST_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_REQUEST_COUNTS_SUCCESS";
export const API_ERROR = "DASHBOARD/API_ERROR";
export const LOAD_GARAGEKEEPER_FYI_COUNTS_SUCCESS =
  "DASHBOARD/LOAD_GARAGEKEEPER_FYI_COUNTS_SUCCESS";

export const DashboardActions = {
  loadGarageKeeperCounts: () => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL + "garagekeepers/counts"
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_GARAGEKEEPER_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
  loadImpoundedVehicleCounts: () => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}vehicleimpoundments/counts`
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_IMPOUNDEDVEHICLE_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
  loadImpoundedVehicleFormCounts: () => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}vehicleimpoundments/formcounts`
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_IMPOUNDEDVEHICLE_FORM_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
  loadInsurancePolicyCounts: () => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL + "insurancepolicies/counts"
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_INSURANCE_POLICY_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
  loadRequestCounts: (id?: string) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}requests/counts${id ? `/${id}` : ""}`
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_REQUEST_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
  loadGKImpoundedVehicleCounts: (gkId: string) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}vehicleimpoundments/gkcounts/${gkId}`
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_GK_IMPOUNDEDVEHICLE_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },

  loadGarageKeeperFYICounts: () => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL + "garagekeepers/fyicounts"
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_GARAGEKEEPER_FYI_COUNTS_SUCCESS,
          value: result,
        });
      }
      return dispatch({ type: API_ERROR });
    };
  },
};
