export const patterns = {
  phoneNumber: "^\\(?\\d{3}\\)?[-\\s]?\\d{3}[-\\s]?\\d{4}$",
  postalCode: "^[A-Za-z]\\d[A-Za-z]\\s?\\d[A-Za-z]\\d$",
  hour: "^([01]?[0-9]|2[0-3]):[0-5]\\d$",
  email: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
  money: "^[$]?[0-9]{1,3}(,?[0-9]{3})*(\\.[0-9]{2})?$"
};

export const formatting = {
  formatPhoneNumber: (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, "");
  },
  formatPostalCode: (postalCode: string): string => {
    return postalCode.replace(/ /g, "").toUpperCase();
  },
};
