import { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "Redux/allActions";
import {
  defaultVehicleImpoundment,
  VehicleImpoundment,
} from "Types/vehicleImpoundment";
import { unformatVehicleImpoundment } from "Utils/format";
import ApplicationToDisposeForm, {
  ValidateApplicationToDispose,
} from "./ApplicationToDisposeForm";
import SaleProcessForm from "./SaleProcessForm";
import CallBffApi from "Utils/CallBff";
import { ImpoundmentType } from "Types/impoundmentType";
import VehicleDetailsForm from "./VehicleDetailsForm";
import ImpoundmentDetailsForm from "./ImpoundmentDetailsForm";
import GarageKeeperDetailsForm from "./GarageKeeperDetailsForm";
import {
  ImpoundedVehicleStatus,
  ImpoundedVehicleStatusLabel,
  IsApprovedOrSoldStatus,
  isTermEnded,
} from "Types/impoundedVehicleStatus";
import AccordionExpandButton from "Components/Accordion/AccordionExpandButton";
import VehicleImpoundmentFeesForm from "./VehicleImpoundmentFeesForm";
import { Badge, Col, Container, Form } from "react-bootstrap";
import FormSelect from "Components/FormElements/FormSelect";
import { doToast, ToastType } from "Utils/toastUtils";
import CustomForm from "Components/FormElements/CustomForm";
import { GarageKeeper } from "Types/garageKeeper";
import {
  ImpoundedVehicleListInfo,
  defaultImpoundedVehicleListInfo,
} from "Types/impoundedVehicleListInfo";
import { getBadRequestErrorsAsList } from "Utils/apiErrorUtils";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { EntityType } from "Types/EntityType";
import guid from "Utils/guid";
import { ImpoundmentAttachment } from "./ImpoundmentAttachment";
import { formatDateToDateTime } from "Utils/format";

function calculateImpoundmentEndDate(
  startDate: Date | string,
  termLength: number
): Date {
  let endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + termLength);
  return endDate;
}

interface Props {
  data?: any;
  requestId?: string;
  garageKeeperId?: string;
  cancel?: any;
  submit: any;
  history: any;
  policeFileNumber?: string;
}

export default function VehicleImpoundmentForm(props: Props) {
  const { data, requestId, garageKeeperId, cancel, submit, policeFileNumber } =
    props;

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const [keepOpen, setKeepOpen] = useState(false);
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // Attachment related
  const [selectedFiles, setSelectedFiles] = useState([] as any[]);
  const [descriptionList, setDescriptionList] = useState([] as any[]);
  const [inputKey, setInputKey] = useState(Date.now());

  const dispatch = useDispatch();

  const locations = useSelector(
    (state: RootStateOrAny) => state.locations.locations
  );
  const garageKeepers = useSelector(
    (state: RootStateOrAny) => state.GKList.gkList
  );
  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const editMode = data ? true : false;

  const unchangedData = useSelector((state: RootStateOrAny) =>
    editMode ? state.vehicleImpoundment.vehicleImpoundment : undefined
  );

  const [vehicleImpoundment, setVehicleImpoundment] =
    useState<VehicleImpoundment>(
      unchangedData ?? {
        ...defaultVehicleImpoundment,
        garageKeeperName: garageKeeper.name,
        garageKeeperId:
          garageKeeperId == null ? garageKeeper.id : garageKeeperId,
        policeFileNumber,
      }
    );

  useEffect(() => {
    dispatch(
      allActions.LocationActions.loadLocations(
        vehicleImpoundment.garageKeeperId
      )
    );
  }, [dispatch, vehicleImpoundment.garageKeeperId]);

  useEffect(() => {
    if (garageKeepers.length === 0) {
      dispatch(allActions.GKList.loadGarageKeeperList());
    }
  }, [dispatch, garageKeepers]);

  const [impoundmentTypes, setImpoundmentTypes] = useState<ImpoundmentType[]>(
    []
  );

  async function fetchImpoundmentTypes() {
    const response = await CallBffApi(
      window.REACT_APP_API_BASEURL + "vehicleimpoundments/impoundmentTypes"
    );

    if (response.ok) {
      var result = await response.json();
      setImpoundmentTypes(result);
    }
  }

  useEffect(() => {
    if (impoundmentTypes.length === 0) {
      fetchImpoundmentTypes();
    }
  }, [impoundmentTypes.length]);

  useEffect(() => {
    let impoundmentStart =
      new Date(vehicleImpoundment.impoundmentStart).getTime() + 1000 * 3600 * 6;
    let difference = 0;

    if (impoundmentStart === -62135550084000) {
      impoundmentStart = new Date().getTime() + 1000 * 3600 * 6;
    }

    if (vehicleImpoundment.releaseDate !== undefined) {
      const releaseDate =
        new Date(vehicleImpoundment.releaseDate).getTime() + 1000 * 3600 * 6;
      difference = Math.ceil(
        (releaseDate - impoundmentStart) / (1000 * 3600 * 24)
      );
    } else if (vehicleImpoundment.saleDate !== undefined) {
      const saleDate =
        new Date(vehicleImpoundment.saleDate).getTime() + 1000 * 3600 * 6;
      difference = Math.ceil(
        (saleDate - impoundmentStart) / (1000 * 3600 * 24)
      );
    } else {
      difference = Math.ceil(
        (new Date().getTime() - impoundmentStart) / (1000 * 3600 * 24)
      );
    }
    setVehicleImpoundment((vi) => {
      return { ...vi, daysImpounded: difference };
    });
  }, [
    vehicleImpoundment.impoundmentStart,
    vehicleImpoundment.releaseDate,
    vehicleImpoundment.saleDate,
  ]);

  useEffect(() => {
    if (
      vehicleImpoundment.impoundmentStart !==
      defaultVehicleImpoundment.impoundmentStart &&
      vehicleImpoundment.termLength !== -1
    ) {
      setVehicleImpoundment((vi) => {
        return {
          ...vi,
          impoundmentEnd: calculateImpoundmentEndDate(
            vehicleImpoundment.impoundmentStart,
            vehicleImpoundment.termLength
          )
            .toISOString()
            .split("T")[0],
        };
      });
    }
  }, [vehicleImpoundment.impoundmentStart, vehicleImpoundment.termLength]);

  const isApprovedOrSoldStatus = IsApprovedOrSoldStatus(
    vehicleImpoundment.status
  );

  const saveAttachments = async (entityId: string) => {
    let failedFiles: any[] = [];
    let failedDescription: any[] = [];
    let succeedCount: number = 0;

    await Promise.all(
      selectedFiles.map(async (file: File, index: number) => {
        let requestUrl = `${(window as any).REACT_APP_API_BASEURL
          }attachments?entityId=${encodeURIComponent(
            entityId
          )}&entityType=${encodeURIComponent(
            EntityType.VehicleImpoundment
          )}&fileName=${encodeURIComponent(
            file.name
          )}&author=${encodeURIComponent(
            account?.name!
          )}&lastEditor=${encodeURIComponent(account?.name!)}`;

        const data = new FormData();

        data.append("file", file);

        const response = await CallBffApi(
          requestUrl,
          {
            method: "POST",
            body: data,
          },
          true
        );

        if (response.ok) {
          const result = await response.json();
          doToast(`Upload successful: ${file.name}`, ToastType.Success);
          dispatch(allActions.AttachmentActions.addAttachment(result));
          succeedCount = succeedCount + 1;
        } else {
          doToast(`Upload failed: ${file.name}`, ToastType.Error);
          failedFiles.push(file);
          failedDescription.push(descriptionList[index]);
        }
      })
    );

    setSelectedFiles(failedFiles);
    setDescriptionList(failedDescription);
    setInputKey(Date.now());
  };

  const handleUpdate = useCallback(
    (event: any) => {
      if (event.target.name.startsWith("impoundedVehicle.")) {
        const [, field] = event.target.name.split(".");
        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            impoundedVehicle: {
              ...vehicleImpoundment.impoundedVehicle,
              [field]:
                field === "licensePlate" || field === "vin"
                  ? event.target.value.toUpperCase()
                  : event.target.value,
            },
          };
        });
      } else if (event.target.name === "garageKeeperId") {
        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            [event.target.name]: event.target.value,
            locationId: defaultVehicleImpoundment.locationId,
            locationPhoneNumber: "",
          };
        });
      } else if (event.target.name === "category") {
        let impoundmentTypesForCategory = impoundmentTypes
          .filter((x) => x.category === event.target.value)
          .map((x) => x.type)
          .filter(
            (value: any, index: number, self: any) =>
              self.indexOf(value) === index
          );

        let termLengthsForType: number[] = [];

        if (impoundmentTypesForCategory.length === 1) {
          termLengthsForType = impoundmentTypes
            .filter(
              (x) =>
                x.category === event.target.value &&
                x.type === impoundmentTypesForCategory[0]
            )
            .map((x) => x.termLength);
        }

        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            [event.target.name]: event.target.value,
            type:
              impoundmentTypesForCategory.length === 1
                ? impoundmentTypesForCategory[0]
                : "",
            termLength:
              termLengthsForType.length === 1 ? termLengthsForType[0] : -1,
          };
        });
      } else if (event.target.name === "type") {
        let termLengthsForType = impoundmentTypes
          .filter(
            (x) =>
              x.category === vehicleImpoundment.category &&
              x.type === event.target.value
          )
          .map((x) => x.termLength);

        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            [event.target.name]: event.target.value,
            termLength:
              termLengthsForType.length === 1 ? termLengthsForType[0] : -1,
          };
        });
      } else if (event.target.name === "termLength") {
        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            [event.target.name]: parseInt(event.target.value),
          };
        });
      }
      else if (event.target.name === "isDriverTheSameAsRegisteredOwner") {
        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            impoundedVehicle: {
              ...vehicleImpoundment.impoundedVehicle,
              isDriverTheSameAsRegisteredOwner: !vehicleImpoundment.impoundedVehicle.isDriverTheSameAsRegisteredOwner
            },
          };
        });
      }
      else {
        setVehicleImpoundment((vehicleImpoundment) => {
          return {
            ...vehicleImpoundment,
            [event.target.name]:
              event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value,
          };
        });
      }
    },
    [impoundmentTypes, vehicleImpoundment.category]
  );

  useEffect(() => {
    if (
      locations.length === 1 &&
      locations[0].garageKeeperId === vehicleImpoundment.garageKeeperId
    ) {
      setVehicleImpoundment((vi) => {
        return {
          ...vi,
          locationId: locations[0].id,
        };
      });
    }
  }, [locations, vehicleImpoundment.garageKeeperId]);

  const handleSubmit = async (event: any): Promise<boolean> => {
    event.preventDefault();

    const isFormValid = event.currentTarget.checkValidity();

    // Ensure this runs prior to the below check, so that it will clean
    //  any custom validation messages that are no longer applicable
    const customValidationPassed = customValidation();

    if (!isFormValid || !customValidationPassed) return false;

    let dataToSubmit: any = unformatVehicleImpoundment(vehicleImpoundment);
    if (dataToSubmit.impoundedVehicle.isDriverTheSameAsRegisteredOwner) {
      dataToSubmit.impoundedVehicle.driver = dataToSubmit.impoundedVehicle.registeredOwner;
      dataToSubmit.impoundedVehicle.driverCustNo = dataToSubmit.impoundedVehicle.registeredOwnerCustNo;
      dataToSubmit.impoundedVehicle.driverPhoneNumber = dataToSubmit.impoundedVehicle.registeredOwnerPhoneNumber;
    }

    if (dataToSubmit.policeFileNumber === "") {
      dataToSubmit.policeFileNumber = undefined;
    }

    if (!editMode) dataToSubmit.id = guid();

    const viRequestUrl = `${window.REACT_APP_API_BASEURL}vehicleimpoundments${editMode
      ? `/${vehicleImpoundment.id}`
      : requestId != null
        ? `?requestId=${requestId}`
        : ""
      }`;

    try {
      const response = await submit(viRequestUrl, {
        method: editMode ? "PUT" : "POST",
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        dispatch(
          allActions.VehicleImpoundmentActions.setVehicleImpoundment(
            vehicleImpoundment
          )
        );
        doToast("Impoundment saved successfully.", ToastType.Success);

        let impoundmentId = vehicleImpoundment.id;
        let impoundmentVin = dataToSubmit.impoundedVehicle.vin;
        if (!editMode) {
          var result = await response.json();
          impoundmentId = result.id;
          impoundmentVin = result.vin;
        }

        if (!editMode && selectedFiles.length > 0)
          await saveAttachments(impoundmentId);

        if (!keepOpen && !editMode) {
          var vi: ImpoundedVehicleListInfo = {
            ...defaultImpoundedVehicleListInfo,
            vehicleImpoundmentId: impoundmentId,
            vin: impoundmentVin,
          };
          props.history.push({
            pathname: `/ImpoundedVehicles/${impoundmentId}`,
            state: { vi },
          });
        }

        return true;
      } else {
        setVehicleImpoundment(vehicleImpoundment);
        const formattedApiErrors = await getBadRequestErrorsAsList(response);
        doToast(formattedApiErrors ?? "An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const customValidation = useCallback(() => {
    let errors: string[] = [];

    if (
      vehicleImpoundment.impoundmentEnd !== undefined &&
      vehicleImpoundment.impoundmentStart > vehicleImpoundment.impoundmentEnd
    ) {
      errors.push("Term Start Date must be before Term End Date");
    }

    if (!vehicleImpoundment.inventoryFormReceived && isApprovedOrSoldStatus) {
      errors.push(
        "Inventory Form Received must be 'Yes' to change Status to 'Approved'"
      );
    }

    if (
      !vehicleImpoundment.releaseFormReceived &&
      vehicleImpoundment.status === "Released"
    ) {
      errors.push(
        "Release Form Received must be 'Yes' to change Status to 'Released'"
      );
    }

    let applicationToSellErrors =
      ValidateApplicationToDispose(vehicleImpoundment);
    errors = errors.concat(applicationToSellErrors);

    setErrorMessages(errors);

    return errors.length === 0;
  }, [isApprovedOrSoldStatus, vehicleImpoundment]);

  const handleCancel = () => {
    cancel?.();
  };

  const removeAttachment = (index: number) => {
    setSelectedFiles(prevFiles => {
      var files = prevFiles.filter((fileValue, fileIndex) => fileIndex !== index);

      return [...files];
    })
  }

  return (
    <CustomForm
      edit={editMode}
      onSubmit={handleSubmit}
      cancel={handleCancel}
      openInEditMode={true}
      setKeepOpenOverride={setKeepOpen}
    >
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="formStatus">
          <FormSelect
            required
            label="Status"
            testId={"status"}
            field="status"
            value={vehicleImpoundment.status}
            onChange={handleUpdate}
            data={Object.keys(ImpoundedVehicleStatus).filter(
              (x) =>
                x === ImpoundedVehicleStatus.Active ||
                x === ImpoundedVehicleStatus.Released ||
                vehicleImpoundment
            )}
            mapping={(s) => {
              return {
                value: s,
                text: ImpoundedVehicleStatusLabel[
                  s as keyof typeof ImpoundedVehicleStatusLabel
                ],
              };
            }}
          />
        </Form.Group>
        <Col md={1} />
        <Form.Group as={Col} md="3" controlId="wrongful">
          <Form.Label className="edit-form-label">Wrongful</Form.Label>
          <Form.Check
            name="wrongful"
            onChange={handleUpdate}
            checked={vehicleImpoundment.wrongful}
          />
        </Form.Group>
        {isTermEnded(
          new Date(),
          vehicleImpoundment.status,
          vehicleImpoundment.impoundmentEnd
        ) && (
            <Col md={1}>
              <Badge variant="danger" data-testid="term-ended-badge">
                Term Ended
              </Badge>
            </Col>
          )}
      </Form.Row>
      <AccordionExpandButton control={expandAll} controlToggle={setExpandAll} />
      <VehicleDetailsForm
        handleUpdate={handleUpdate}
        vehicleImpoundment={vehicleImpoundment}
        control={expandAll}
      />
      <ImpoundmentDetailsForm
        handleUpdate={handleUpdate}
        vehicleImpoundment={vehicleImpoundment}
        impoundmentTypes={impoundmentTypes}
        control={expandAll}
      />
      <GarageKeeperDetailsForm
        handleUpdate={handleUpdate}
        vehicleImpoundment={vehicleImpoundment}
        locations={locations}
        garageKeepers={
          garageKeeperId == null
            ? garageKeepers
            : garageKeepers.filter((x: GarageKeeper) => {
              return x.id === garageKeeperId;
            })
        }
        control={expandAll}
      />
      <VehicleImpoundmentFeesForm
        vehicleImpoundment={vehicleImpoundment}
        setVehicleImpoundment={setVehicleImpoundment}
        control={expandAll}
      />
      <ApplicationToDisposeForm
        onChange={handleUpdate}
        vehicleImpoundment={vehicleImpoundment}
        control={expandAll}
      />
      <SaleProcessForm
        onChange={handleUpdate}
        vehicleImpoundment={vehicleImpoundment}
        control={expandAll}
      />
      {!editMode &&
        <ImpoundmentAttachment
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          descriptionList={descriptionList}
          setDescriptionList={setDescriptionList}
          inputKey={inputKey}
          setInputKey={setInputKey}
          control={expandAll}
          removeFile={removeAttachment}
        />
      }
      <br />
      <ErrorMessageList errorMessages={errorMessages} />
    </CustomForm>
  );
}

interface ErrorMessageListProps {
  errorMessages: string[];
}

function ErrorMessageList({ errorMessages }: ErrorMessageListProps) {
  return (
    <Container>
      {errorMessages.map((err, i) => {
        return (
          <p key={i}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              color="red"
              className="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            {" " + err}
          </p>
        );
      })}
    </Container>
  );
}
