import { useState } from "react";
import { Alert, Badge, Col, Row } from "react-bootstrap";
import Loader from "Components/Loader/Loader";
import {
  CommPref,
  Contact,
  ContactType,
  defaultContact,
} from "Types/contact";
import ContactForm from "Forms/ContactForm/ContactForm";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import allActions from "Redux/allActions";
import { formatPhoneNumberUndefined } from "Utils/format";
import ReusableModal from "Components/Modal/ReusableModal";
import CallBffApi from "Utils/CallBff";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import { Card } from "react-bootstrap";
import PhoneEmail from "../../Utils/PhoneEmail";
import { UserPermissions } from "Types/userInfo";

export interface Props {
  garageKeeperId: string;
}

function ContactInfoList(props: Props) {
  const contactList = useSelector(
    (state: RootStateOrAny) => state.Contacts.contacts
  );

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  const dispatch = useDispatch();

  const requestUrl =
    window.REACT_APP_API_BASEURL +
    "contacts/garageKeeperId=" +
    props.garageKeeperId;

  const [contactToEdit, setContactToEdit] = useState(
    undefined as Contact | undefined
  );

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(defaultContact);

  return (
    <>
      {userPermissions.canCreateContact &&
        <ReusableModal
          buttonText="Add New Contact"
          headerText={contactToEdit ? "Edit Contact" : "Add New Contact"}
          open={() => {
            setContactToEdit(undefined);
            openModal();
          }}
          close={closeModal}
          show={showModal}
        >
          <ContactForm
            submit={CallBffApi}
            cancel={closeModal}
            data={contactToEdit}
          />
        </ReusableModal >
      }
      <ConfirmDelete
        show={showDeleteConfirm}
        headerText={"Delete Contact"}
        dialogText={`Are you sure you wish to delete '${contactToDelete.description}'?`}
        requireDeleteReason={true}
        onClickDelete={(deleteReason: string) => {
          dispatch(
            allActions.Contact.deleteContact(contactToDelete.id, deleteReason)
          );
          setShowDeleteConfirm(false);
        }}
        onClickCancel={() => setShowDeleteConfirm(false)}
      />
      <Loader
        url={requestUrl}
        onLoadComplete={(result: Contact[]) =>
          dispatch(allActions.Contact.setContacts(result))
        }
      >
        <br />
        <br />
        <Row>
          {contactList !== undefined && contactList.length > 0 ? (
            contactList
              .sort((a: Contact, b: Contact) => {
                //Main office should always be first
                if (a.contactType === ContactType.MainOffice) return -1;
                if (b.contactType === ContactType.MainOffice) return 1;
                // description only matters when contact type is the same
                if (a.contactType === b.contactType)
                  return a.description > b.description ? 1 : -1;
                // Else sort by type
                return a.contactType > b.contactType ? 1 : -1;
              })
              .map((contact: Contact) => {
                return (
                  <Col key={contact.id} sm={12} md={6} lg={4} xl={3}>
                    <Card
                      style={{ minHeight: "275px", height: "95%" }}
                      data-testid={"contactCard"}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            borderBottom: "solid 1px gainsboro",
                            paddingBottom: "5px",
                          }}
                        >
                          <Row>
                            <Col>{contact.description}</Col>
                            <Col className="col-auto">
                              {userPermissions.canUpdateContact &&
                                <span
                                  data-testid={`Edit-${contact.id}`}
                                  title="Edit"
                                  onClick={() => {
                                    setContactToEdit(contact);
                                    openModal();
                                  }}
                                >
                                  <i className="far fa-edit edit-button"></i>
                                </span>
                              }
                              {userPermissions.canDeleteAttachments &&
                                contact.contactType === ContactType.Other && (
                                  <span
                                    className="ml-1"
                                    data-testid={`Delete-${contact.id}`}
                                    title={`Delete ${contact.description}`}
                                    onClick={() => {
                                      setContactToDelete(contact);
                                      setShowDeleteConfirm(true);
                                    }}
                                  >
                                    <i className="fas fa-trash-alt delete-button"></i>
                                  </span>
                                )
                              }
                            </Col>
                          </Row>
                        </Card.Title>
                        {contact.phoneNumber && (
                          <>
                            <Card.Subtitle className="mb-2 text-muted">
                              Phone Number
                            </Card.Subtitle>
                            <Card.Text data-testid={`Phone-${contact.id}`}>
                              <PhoneEmail type="phone" value={formatPhoneNumberUndefined(contact.phoneNumber)} testId={`PhoneLink-${contact.id}`} />
                            </Card.Text>
                          </>
                        )}
                        {contact.faxNumber && (
                          <>
                            <Card.Subtitle className="mb-2 text-muted">
                              Fax Number
                              {contact.communicationPreference ===
                                CommPref.Fax && (
                                  <>
                                    &nbsp;
                                    <Badge
                                      variant="secondary"
                                      data-testid={`FaxPref-${contact.id}`}
                                    >
                                      Pref
                                    </Badge>
                                  </>
                                )}
                            </Card.Subtitle>
                            <Card.Text data-testid={`Fax-${contact.id}`}>
                              {formatPhoneNumberUndefined(contact.faxNumber)}
                            </Card.Text>
                          </>
                        )}
                        {contact.emailAddress && (
                          <>
                            <Card.Subtitle className="mb-2 text-muted">
                              Email Address
                              {contact.communicationPreference ===
                                CommPref.Email && (
                                  <>
                                    &nbsp;
                                    <Badge
                                      variant="secondary"
                                      data-testid={`EmailPref-${contact.id}`}
                                    >
                                      Pref
                                    </Badge>
                                  </>
                                )}
                            </Card.Subtitle>
                            <Card.Text data-testid={`Email-${contact.id}`}>
                              <PhoneEmail type="email" value={contact.emailAddress} testId={`EmailLink-${contact.id}`} />
                            </Card.Text>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                    <br />
                  </Col>
                );
              })
          ) : (
            <Alert variant="dark">
              <i>No Contacts found</i>
            </Alert>
          )}
        </Row>
      </Loader>
    </>
  );
}

export default ContactInfoList;
