import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { useState } from "react";
import { Form } from "react-bootstrap";

import CustomForm from "Components/FormElements/CustomForm";
import FormAttachment from "Components/FormElements/FormAttachment";
import FormText from "Components/FormElements/FormText";
import { Bulletin, BulletinType, defaultBulletin } from "Types/bulletin";
import { EntityType } from "Types/EntityType";
import { buildQueryString } from "Utils/apiUtils";
import { emptyGuid } from "Utils/guid";
import { doToast, ToastType } from "Utils/toastUtils";

interface Props {
  onCancel?: any;
  onSubmit: any;
  onSubmitSuccess(bulletin: Bulletin): any;
  bulletinType: BulletinType;
}

export default function BulletinForm({
  onCancel,
  onSubmit,
  onSubmitSuccess,
  bulletinType,
}: Props) {
  const [bulletin, setBulletin] = useState<Bulletin>({
    ...defaultBulletin,
    bulletinType,
  });
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<boolean>(false);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isValid = e.currentTarget.checkValidity();

    if (file === undefined) {
      setFileError(true);
      return false;
    }

    if (!isValid) return false;

    const requestUrl =
      (window as any).REACT_APP_API_BASEURL +
      "bulletins?" +
      buildQueryString({
        entityId: emptyGuid,
        entityType: EntityType.Bulletin,
        fileName: file.name,
        author: account?.name ?? "",
        lastEditor: account?.name ?? "",
        description: bulletin.description,
        title: bulletin.title,
        bulletinType: bulletin.bulletinType,
      });

    const data = new FormData();

    data.append("file", file);

    const response = await onSubmit(
      requestUrl,
      {
        method: "POST",
        body: data,
      },
      true
    );
    let result = await response.json();

    if (response.ok) {
      doToast(`Saved ${bulletinType} successfully`, ToastType.Success);
      onSubmitSuccess(result);
      setFile(undefined);
      setBulletin({...defaultBulletin, bulletinType: bulletinType});
      return true;
    }

    doToast(result, ToastType.Error);
    return false;
  };

  const handleCancel = () => {
    setFile(undefined);
    setBulletin({...defaultBulletin, bulletinType: bulletinType});
    onCancel?.();
  };

  const handleChange = (e: any) => {
    setBulletin((bulletin) => {
      return { ...bulletin, [e.target.name]: e.target.value };
    });
  };

  return (
    <div style={{ margin: "100px 10%" }}>
      <CustomForm onSubmit={handleSubmit} cancel={handleCancel}>
        <Form.Row>
          <FormText
            required
            testId="bulletin-title"
            label="Title"
            placeholder=""
            field="title"
            value={bulletin.title}
            onChange={handleChange}
            pattern={"^.{5,40}$"}
            invalidMessage=" must be between 5-40 characters"
          />
        </Form.Row>
        <Form.Row>
          <FormText
            required
            as="textarea"
            testId="bulletin-description"
            label="Description"
            placeholder=""
            field="description"
            value={bulletin.description}
            onChange={handleChange}
          />
        </Form.Row>
        <br />
        <FormAttachment
          accept=".pdf"
          testId="bulletin-attachment"
          onFileSelect={(file: any) => {
            setFile(file);
            setFileError(false);
          }}
          onFileRemove={() => setFile(undefined)}
          selectedFiles={file ? [file] : []}
        />
        <Form.Row>
          {fileError && (
            <span
              className="danger"
              style={{ color: "rgb(220, 53, 69)", fontSize: "12.8px" }}
            >
              Document is required
            </span>
          )}
        </Form.Row>
        <br />
      </CustomForm>
    </div>
  );
}
