import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import CallBffApi from "Utils/CallBff";
import FormText from "Components/FormElements/FormText";
import { doToast, ToastType } from "Utils/toastUtils";
import { useDispatch } from "react-redux";
import allActions from "Redux/allActions";
import { validateFileType } from "Utils/fileUtils";
import { EntityType } from "Types/EntityType";
import FormSelect from "Components/FormElements/FormSelect";
import FormLabel from "Components/FormElements/FormLabel";

interface Props {
  entityId: string;
  entityType: EntityType;
  userName: string;
  updateCount: Function;
  showFileSelectButton?: Boolean;
  requiredDocumentTypes?: string[];
  onPreSave?: () => Promise<void>;
}

function AttachmentInput(props: Props) {
  const [selectedFiles, setSelectedFiles] = useState([] as any[]);
  const [descriptionList, setDescriptionList] = useState([] as any[]);
  const [documentTypeList, setDocumentTypeList] = useState([] as any[]);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [inputKey, setInputKey] = useState(Date.now());
  const dispatch = useDispatch();

  const onFileChangeHandler = (e: any) => {
    let files: File[] = Array.from(e.target.files);
    let validatedFiles: File[] = [];

    files.forEach((f) => {
      if (validateFileType(f)) validatedFiles.push(f);
    });

    setSelectedFiles(validatedFiles);
    setDescriptionList(Array(validatedFiles.length).fill(""));
    setDocumentTypeList(Array(validatedFiles.length).fill(""));
    setValidated(false);
  };

  const onDocumentTypeChangeHandler = (e: any, index: number) => {
    let newDocumentTypeList: any = [...documentTypeList];
    newDocumentTypeList.splice(index, 1, e.target.value);
    setDocumentTypeList(newDocumentTypeList);
  };

  const onDescriptionChangeHandler = (e: any, index: number) => {
    let newDescriptionList: any = [...descriptionList];
    newDescriptionList.splice(index, 1, e.target.value);
    setDescriptionList(newDescriptionList);
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    if (submitting)
      return false;

    if (!submitting) {
      setSubmitting(true);
    }

    setValidated(true);
    if (!e.currentTarget.checkValidity()) return false;

    if (props.onPreSave !== undefined) {
      await props.onPreSave();
    }

    let failedFiles: any[] = [];
    let failedDescription: any[] = [];
    let succeedCount: number = 0;

    await Promise.all(
      selectedFiles.map(async (file: File, index: number) => {
        const requestUrl = `${(window as any).REACT_APP_API_BASEURL
          }attachments?entityId=${encodeURIComponent(
            props.entityId
          )}&entityType=${encodeURIComponent(
            props.entityType
          )}&fileName=${encodeURIComponent(
            file.name
          )}&description=${encodeURIComponent(
            documentTypeList[index] +
            `${documentTypeList[index] && descriptionList[index] ? ` - ` : ""
            }` +
            descriptionList[index]
          )}&author=${encodeURIComponent(
            props.userName
          )}&lastEditor=${encodeURIComponent(props.userName)}`;

        const data = new FormData();

        data.append("file", file);

        const response = await CallBffApi(
          requestUrl,
          {
            method: "POST",
            body: data,
          },
          true
        );

        if (response.ok) {
          const result = await response.json();
          doToast(`Upload successful: ${file.name}`, ToastType.Success);
          dispatch(allActions.AttachmentActions.addAttachment(result));
          succeedCount = succeedCount + 1;
        } else {
          doToast(`Upload failed: ${file.name}`, ToastType.Error);
          failedFiles.push(file);
          failedDescription.push(descriptionList[index]);
        }
      })
    );

    setSelectedFiles(failedFiles);
    setDescriptionList(failedDescription);
    setInputKey(Date.now());
    props.updateCount(true, succeedCount);
    setSubmitting(false);
  };

  return (
    <Form noValidate onSubmit={onSubmitHandler} validated={validated}>
      {(props.showFileSelectButton === undefined ||
        props.showFileSelectButton) && (
          <div>
            <div className="inputWrapper">
              Click here to select a file, or drag and drop
              <input
                type="file"
                name="fileUpload"
                id="fileUpload"
                className="fileInput"
                data-testid="attachment-input"
                multiple
                onChange={(e) => onFileChangeHandler(e)}
                key={inputKey}
                disabled={submitting}
              />
            </div>
          </div>
        )}
      <div>
        {selectedFiles.map((f: File, index: number) => {
          return (
            <ListGroup.Item
              key={`selected-file-${index}`}
              data-testid={`selected-file-list-item-${index}`}
            >
              <FormLabel
                text={f.name}
                testId={`selected-file-label-${index}`}
              />
              {props.requiredDocumentTypes && (
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="documentType">
                    <FormSelect
                      label="Document Type"
                      required
                      testId={`document-type-dropdown-${index}`}
                      field="documentType"
                      onChange={(e: any) =>
                        onDocumentTypeChangeHandler(e, index)
                      }
                      data={props.requiredDocumentTypes}
                      value={documentTypeList[index]}
                      defaultOption={{
                        value: "",
                        text: "Select document type: ",
                      }}
                      mapping={(s) => {
                        return { value: s, text: s };
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              )}
              <FormText
                field="description"
                placeholder={"(optional) Description:"}
                value={descriptionList[index]}
                onChange={(e: any) => onDescriptionChangeHandler(e, index)}
                testId={`description-field-${index}`}
              />
            </ListGroup.Item>
          );
        })}
      </div>
      <br />
      <div>
        {selectedFiles.length > 0 && (
          <Button
            variant="primary"
            data-testid="attachment-submit"
            type="submit"
          >
            Save
          </Button>
        )}
      </div>
    </Form>
  );
}

export default AttachmentInput;
