import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EntityType } from "Types/EntityType";
import CallBffApi from "Utils/CallBff";

interface Props {
  id: string;
  onCancel: Function;
  onDelete: Function;
  entityType: EntityType;
}

function CommentDelete({ id, onCancel, onDelete, entityType }: Props) {
  const [deleteReason, setDeleteReason] = useState("");

  const [errors, setErrors] = useState<string[]>([]);
  const [disableDelete, setDisableDelete] = useState<boolean>(true);
  const [disableInput, setDisableInput] = useState<boolean>(false);

  useEffect(() => {
    setErrors([]);
    if (deleteReason.trim() !== "") {
      setDisableDelete(false);
      return;
    }
    setDisableDelete(true);
  }, [deleteReason]);

  const commentBox = {
    width: "99%",
    height: "75px",
  } as React.CSSProperties;

  const commentButtons = {
    float: "right",
  } as React.CSSProperties;

  const button = {
    width: "100%",
  } as React.CSSProperties;

  const noPaddingRight = {
    paddingRight: "0px",
  } as React.CSSProperties;

  const noPaddingLeft = {
    paddingLeft: "0px",
  } as React.CSSProperties;

  const errorBox = {
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "3px",
    borderColor: "DarkRed",
    backgroundColor: "pink",
    paddingLeft: "10px",
    color: "DarkRed",
  } as React.CSSProperties;

  const validate = () => {
    let newErrors: string[] = [];
    if (deleteReason === "")
      newErrors.push(
        "Please enter a reason for deleting this comment before submitting"
      );

    if (newErrors.length > 0) {
      setErrors([...newErrors]);
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    const requestUrl = `${
      window.REACT_APP_API_BASEURL
    }comments${`/${id}`}?deleteReason=${deleteReason}&entityType=${entityType}`;
    setDisableDelete(true);
    setDisableInput(true);

    //Call Validation here
    if (!validate()) return false;

    const response = await CallBffApi(requestUrl, {
      method: "DELETE",
    });

    if (response.ok) {
      onDelete();
      return;
    } else setErrors([...errors, "Error saving Comment"]);

    setDisableInput(false);
  };

  return (
    <div style={commentBox}>
      <Row>
        <Col md="11" style={noPaddingRight}>
          <textarea
            style={commentBox}
            data-testid={"delete-reason"}
            placeholder="Enter reason for deleting comment here..."
            value={deleteReason}
            disabled={disableInput}
            onChange={(e) => {
              setDeleteReason(e.target.value);
            }}
          ></textarea>
        </Col>
        <Col md="1" style={noPaddingLeft}>
          <div style={commentButtons}>
            <button
              data-testid={"comment-confirm-delete"}
              style={button}
              type="button"
              title="Delete"
              disabled={disableDelete}
              className="btn btn-primary"
              onClick={() => submit()}
            >
              &#10004;
            </button>
            <button
              style={button}
              type="button"
              title="Cancel"
              disabled={disableInput}
              className="btn btn-secondary"
              onClick={() => onCancel?.("")}
            >
              &#10060;
            </button>
          </div>
        </Col>
      </Row>
      {errors.length > 0 ? (
        <div style={errorBox}>
          {errors.map((x) => {
            return <li key={x}>{x}</li>;
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CommentDelete;
