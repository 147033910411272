import { useState } from "react";
import { Alert, Badge, Button, Modal } from "react-bootstrap";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import Loader from "Components/Loader/Loader";
import { Attachment } from "Types/attachment";
import { EntityType } from "Types/EntityType";
import AttachmentInput from "./AttachmentInput";
import AttachmentElement from "./AttachmentElement";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import allActions from "Redux/allActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

interface Props {
  entityId: string;
  entityType: EntityType;
  count: number;
  readOnly?: boolean;
}

function AttachmentContainer(props: Props) {
  library.add(faPaperclip);

  const { readOnly = false } = props;

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const [show, setShow] = useState(false);

  const [idAttachmentInChange, setIdAttachmentInChange] = useState("");

  const [attachmentCount, setAttachmentCount] = useState(props.count);

  const attachmentList = useSelector(
    (state: RootStateOrAny) => state.attachments.attachments
  );

  const { userType } = useSelector((state: RootStateOrAny) => state.userInfo);

  const dispatch = useDispatch();

  const requestUrl = `${(window as any).REACT_APP_API_BASEURL
    }attachments/entityId=${props.entityId}?entityType=${props.entityType}`;

  const updateCount = (increment: boolean, difference: number) => {
    if (increment) setAttachmentCount(attachmentCount + difference);
    else setAttachmentCount(attachmentCount - difference);
  };

  const handleClose = () => {
    setIdAttachmentInChange("");
    setShow(false);
  };

  const attachmentListStyle = {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "10px 10px 10px 10px",
    maxHeight: "400px",
  } as React.CSSProperties;

  const countStyle = {
    position: "absolute",
    left: "10px",
    top: "20px",
    fontSize: "10px",
  } as React.CSSProperties;

  const buttonStyle = {
    cursor: "pointer",
    fontSize: "25px",
    position: "relative",
    padding: "0px 5px 0px 5px",
  } as React.CSSProperties;

  return (
    <>
      <span
        style={buttonStyle}
        className="edit-button"
        onClick={() => setShow(true)}
        data-testid="attachmentButton"
        title="Attachments"
      >
        <FontAwesomeIcon icon="paperclip" />
        {attachmentCount > 0 && (
          <Badge
            style={countStyle}
            pill
            data-testid="attachmentCount"
            variant="danger"
          >
            {attachmentCount}
          </Badge>
        )}
      </span>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName="modal-90w"
        data-testid="attachment-modal"
      >
        <Modal.Header>
          <h2>Attachments</h2>
        </Modal.Header>
        <Modal.Body>
          <Loader
            url={requestUrl}
            onLoadComplete={(result: Attachment[]) =>
              dispatch(allActions.AttachmentActions.setAttachments(result))
            }
          >
            {
              !readOnly &&
              <div style={{ padding: "10px" }}>
                <AttachmentInput
                  entityId={props.entityId}
                  entityType={props.entityType}
                  userName={account?.name!}
                  updateCount={updateCount}
                />
              </div>
            }
            {attachmentList.length > 0 ? (
              <div style={attachmentListStyle}>
                {attachmentList.map((file: Attachment) => {
                  return (
                    <AttachmentElement
                      attachment={file}
                      key={file.id}
                      userName={account?.name!}
                      idAttachmentInChange={idAttachmentInChange}
                      setIdAttachmentInChange={setIdAttachmentInChange}
                      updateCount={updateCount}
                      readonly={readOnly}
                    />
                  );
                })}
              </div>
            ) : (
              <div style={{ padding: "10px" }}>
                <Alert variant="dark">
                  <i>No attachments found</i>
                </Alert>
              </div>
            )}
          </Loader>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AttachmentContainer;
