import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import FormText from "../FormElements/FormText";

export type ConfirmDeleteProps = {
  show: boolean;
  headerText?: string;
  dialogText?: string;
  deleteButtonText?: string;
  requireDeleteReason?: boolean;
  onClickDelete: (deleteReason: string) => void;
  onClickCancel: () => void;
};

export default function ConfirmDelete(props: ConfirmDeleteProps) {
  const [deleteReason, setDeleteReason] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const reset = () => {
    setDeleteReason("");
    setErrors([]);
  };

  const validate = (): boolean => {
    setErrors([]);

    let newErrors: string[] = [];
    if (props.requireDeleteReason && (!deleteReason || !deleteReason.trim())) {
      newErrors.push("Reason for deleting is required");
    }

    if (newErrors.length > 0) {
      setErrors([...newErrors]);
      return false;
    } else {
      return true;
    }
  };

  const onClickDelete = () => {
    if (!validate()) return;

    props.onClickDelete(deleteReason);
    reset();
  };

  const onClickCancel = () => {
    props.onClickCancel();
    reset();
  };

  return (
    <Modal show={props.show} animation={false} data-testid="confirm-delete">
      <Modal.Header>
        <h2 data-testid="header-text">
          {props.headerText ?? "Confirm Delete"}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="dialog-text">
          {props.dialogText ?? "Are you sure you wish to delete this item?"}
        </div>
        {(props.requireDeleteReason ?? false) && (
          <>
            <br />
            <FormText
              testId="confirm-delete-reason"
              label="Reason for deleting"
              field="deleteReason"
              value={deleteReason}
              placeholder=""
              onChange={(e: any) => {
                setDeleteReason(e.target.value);
              }}
            />
          </>
        )}
        {errors.length > 0 && (
          <div style={{ fontSize: "80%", color: "#dc3545" }}>
            {errors.map((e) => {
              return <li key={e}>{e}</li>;
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={onClickDelete}
          data-testid="confirm-delete-delete-button"
        >
          {props.deleteButtonText ?? "Delete"}
        </Button>
        <Button
          variant="secondary"
          onClick={onClickCancel}
          data-testid="confirm-delete-cancel-button"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
