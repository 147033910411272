export interface VICounts {
  [index: string]: any;
  Active: number;
  Released: number;
  Wrongful: number;
  TermEnded: number;
  ApplicationToDispose: number;
  ApprovedForAuction: number;
  ApprovedForTransfer: number;
  ApprovedUndefined: number;
}

export const defaultVICounts: VICounts = {
  Active: 0,
  Released: 0,
  Wrongful: 0,
  TermEnded: 0,
  ApplicationToDispose: 0,
  ApprovedForAuction: 0,
  ApprovedForTransfer: 0,
  ApprovedUndefined: 0,
};
