import { Button } from "react-bootstrap";
import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";

import { UserType } from "Types/userInfo";

export default function NoAccessRoutes() {
  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});

  function handleLogout(e: any) {
    instance.logoutRedirect({
      account: account,
    });
  }

  if (userType === UserType.NoAccess) {
    return (
      <Switch>
        <Route>
          <h1>You are not authorized to use this application.</h1>

          <Button onClick={handleLogout}>Logout</Button>
        </Route>
      </Switch>
    );
  } else {
    return <></>;
  }
}
