import LocationList from "./LocationList";
import { GarageKeeper } from "Types/garageKeeper";
import DetailLayout from "Layouts/DetailLayout";

interface Props {
  garageKeeper: GarageKeeper;
  history: any;
}

export default function LocationListExternal({ garageKeeper, history }: Props) {
  return (
    <DetailLayout
      title="Compounds"
      header={garageKeeper.name}
      subheader="Compounds"
    >
      <LocationList
        garageKeeperId={garageKeeper.id}
        history={history}
        external
      />
    </DetailLayout>
  );
}
