import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { default as actions } from "../../../Redux/impoundedvehicles/ImpoundedVehicleListActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Location } from "Types/location";
import { Address } from "Types/address";

interface DropdownData {
  value: string;
  displayValue: string;
}

function LocationFilter() {
  const dispatch = useDispatch();
  const locationFilter = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList.locationFilter
  );
  const locations: Location[] = useSelector(
    (state: RootStateOrAny) => state.locations.locations
  );

  const buildAddress = (address: Address | undefined) => {
    return address ? `${address.street}, ${address.city}, ${address.province}` : ""
  }

  function getLocation(location: DropdownData) {
    return location.value === locationFilter;
  }

  const dropdownOptions: DropdownData[] = [
    ...locations.map((l: Location) => {
      return {
        value: l.id,
        displayValue: buildAddress(l.physicalAddress)
      };
    })
  ]
  .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

  function locationDropdownChanged(displayAll: boolean, locationId?: string) {
    if (displayAll === true) {
      dispatch(actions.filterLocationAll());
    } else if (locationId !== undefined) {
      dispatch(actions.filterLocation(locationId));
    }
  }

  useEffect(() => {
    if(!dropdownOptions.find(getLocation))
    dispatch(actions.filterLocationAll());
  }, [locations])

  return (
    <Dropdown className="mb-2 ml-2">
      <Dropdown.Toggle
          id="locationDropdown"
          data-testid="location-filter"
      >
          Location: {locationFilter === "ALL" ? "<All>" : dropdownOptions.find(getLocation)?.displayValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
          <Dropdown.Item
          onClick={() => locationDropdownChanged(true)}
          data-testid={"location-ALL"}
          >
          {"<All>"}
          </Dropdown.Item>
          {dropdownOptions
          .map((option: DropdownData) => {
            return (
            <Dropdown.Item
                key={option.value}
                data-testid={`location-${option.value}`}
                onClick={() =>
                  locationDropdownChanged(false, option.value)
                }
            >
                {option.displayValue}
            </Dropdown.Item>
            );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LocationFilter;
