export interface Props {
    type?: string; 
    value: string | number | undefined;
    testId?: any;
}

function PhoneEmail(props:Props) {
    return (<>{props.value} {(props.value && props.type === "phone") ? 
    <a href={`tel:${props.value}`}
        className="view-action ml-md-2"
        data-testid={props.testId}>
        <i className="fas fa-phone"></i>
    </a> : 
    (props.value && props.type === "email") ? 
    <a href={`mailto:${props.value}`}
        data-testid={props.testId}>
        <i className="far fa-envelope"></i>
    </a> : ""}
    </>);
}

export default PhoneEmail;
