import { useState } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Dropdown, Alert } from "react-bootstrap";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

import allActions from "Redux/allActions";
import RegularLayout from "Layouts/RegularLayout";
import Loader from "Components/Loader/Loader";

import { ExpiredInsuranceListInfo } from "Types/expiredInsuranceListInfo";
import {
  InsurancePolicyTypes,
  insurancePolicyTypeDescription,
  InsuranceState,
} from "Types/insurancePolicy";
import { formatDateStripTime } from "Utils/format";
import SearchBox from "Components/Search/SearchBox";
import CallBffApi from "Utils/CallBff";
import SortButton from "Components/SortButton/SortButton";

interface Props {
  history: any;
}

export default function ExpiredInsuranceList(props: Props) {
  const [expiredInsuranceList, setExpiredInsuranceList] = useState<
    ExpiredInsuranceListInfo[]
  >([]);

  const { sortField, sortDirection, statusFilter, typeFilter } = useSelector(
    (state: RootStateOrAny) => state.ExpiredInsurance
  );

  const filteredInsuranceList = expiredInsuranceList
    .filter((e) => statusFilter === "ALL" || e.insuranceStatus === statusFilter)
    .filter(
      (e) =>
        typeFilter === "ALL" ||
        insurancePolicyTypeDescription(e.insuranceType) === typeFilter
    );

  const dispatch = useDispatch();

  const requestUrl = `${
    (window as any).REACT_APP_API_BASEURL
  }insurancepolicies/insurancelist`;

  function statusDropdownChanged(displayAll: boolean, status?: string) {
    if (displayAll === true) {
      dispatch(allActions.ExpiredInsuranceActions.filterStatusAll());
    } else if (status !== undefined) {
      dispatch(allActions.ExpiredInsuranceActions.filterStatus(status));
    }
  }

  function typeDropdownChanged(displayAll: boolean, type?: string) {
    if (displayAll === true) {
      dispatch(allActions.ExpiredInsuranceActions.filterTypeAll());
    } else if (type !== undefined) {
      dispatch(allActions.ExpiredInsuranceActions.filterType(type));
    }
  }

  return (
    <RegularLayout title="Expired Insurance">
      <div className="card shadow mb-4 min-vh-100">
        <div className="card-header py-3">
          <h4 className="m-0 font-weight-bold text-success">
            Expired Insurance
          </h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Loader
              url={requestUrl}
              onLoadComplete={(result: ExpiredInsuranceListInfo[]) =>
                setExpiredInsuranceList(result)
              }
            >
              <Container fluid>
                <Row>
                  <SearchBox
                    endpoint="insurancepolicies/search"
                    onSearch={CallBffApi}
                    handleResult={(data: ExpiredInsuranceListInfo[]) =>
                      setExpiredInsuranceList(data)
                    }
                    placeholder="Business Name"
                    searchField="name"
                    testId="gksearch"
                  />
                  <div style={{ flexGrow: 1 }}></div>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="statusDropdown"
                      data-testid="insuranceStatusFilter"
                    >
                      Insurance State: {statusFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => statusDropdownChanged(true)}
                        data-testid="status-all"
                      >
                        ALL
                      </Dropdown.Item>
                      {Object.keys(InsuranceState)
                        .filter(
                          (x) =>
                            x !== InsuranceState.Active &&
                            x !== InsuranceState.Future
                        )
                        .map((status: string) => {
                          return (
                            <Dropdown.Item
                              key={status}
                              data-testid={status}
                              onClick={() =>
                                statusDropdownChanged(false, status)
                              }
                            >
                              {status}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div>&nbsp;</div>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="policyTypeDropdown"
                      data-testid="insuranceTypeFilter"
                    >
                      Insurance Type: {typeFilter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => typeDropdownChanged(true)}
                        data-testid="insuranceType-all"
                      >
                        ALL
                      </Dropdown.Item>
                      {Object.keys(InsurancePolicyTypes).map((type: string) => {
                        let insType = insurancePolicyTypeDescription(type);
                        return (
                          <Dropdown.Item
                            key={insType}
                            data-testid={insType}
                            onClick={() => typeDropdownChanged(false, insType)}
                          >
                            {insType}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Row>
                <Row>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <SortButton
                            property={"garageKeeperName"}
                            label={"Business Name"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.ExpiredInsuranceActions.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"insuranceType"}
                            label={"Insurance Type"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.ExpiredInsuranceActions.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"insuranceStatus"}
                            label={"Insurance State"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.ExpiredInsuranceActions.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"insuranceExpiryDate"}
                            label={"Expiry Date"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.ExpiredInsuranceActions.sortList}
                          />
                        </th>
                        <th>
                          <SortButton
                            property={"daysExpired"}
                            label={"Days Expired"}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            action={allActions.ExpiredInsuranceActions.sortList}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody data-testid="expired-insurance-table">
                      {filteredInsuranceList.length > 0 &&
                        filteredInsuranceList
                          .sort((a: any, b: any) => {
                            if (a[sortField] < b[sortField]) {
                              return sortDirection === "ascending" ? -1 : 1;
                            }
                            if (a[sortField] > b[sortField]) {
                              return sortDirection === "ascending" ? 1 : -1;
                            }
                            if (a[sortField] === null && b[sortField] === null)
                              return 0;
                            if (a[sortField] === null)
                              return sortDirection === "ascending" ? -1 : 1;
                            if (b[sortField] === null)
                              return sortDirection === "ascending" ? 1 : -1;
                            return 0;
                          })
                          .map((e) => {
                            return (
                              <tr
                                key={`${e.garageKeeperId}${e.insuranceType}`}
                                onClick={() => {
                                  dispatch(
                                    allActions.GKProfile.setGK({
                                      id: e.garageKeeperId,
                                      name: e.garageKeeperName,
                                    } as any)
                                  );

                                  props.history.push({
                                    pathname: `/GarageKeepers/${e.garageKeeperId}/insuranceinfo`,
                                  });
                                }}
                              >
                                <td>{e.garageKeeperName}</td>
                                <td>
                                  {insurancePolicyTypeDescription(
                                    e.insuranceType
                                  )}
                                </td>
                                <td
                                  style={
                                    e.insuranceStatus === "Expired"
                                      ? { color: "red" }
                                      : undefined
                                  }
                                >
                                  {e.insuranceStatus}
                                </td>
                                <td>
                                  {formatDateStripTime(
                                    e.insuranceExpiryDate?.toString()
                                  )}
                                </td>
                                <td>{e.daysExpired}</td>
                              </tr>
                            );
                          })}
                    </tbody>
                    <tfoot>
                      {expiredInsuranceList.length === 0 ? (
                        <tr>
                          <td colSpan={5}>
                            <Alert variant="dark">
                              <i>No Garage Keepers found</i>
                            </Alert>
                          </td>
                        </tr>
                      ) : (
                        filteredInsuranceList.length === 0 && (
                          <tr>
                            <td colSpan={5}>
                              <Alert variant="dark">
                                <i>
                                  No Garage Keepers match the current filter
                                </i>
                              </Alert>
                            </td>
                          </tr>
                        )
                      )}
                    </tfoot>
                  </Table>
                </Row>
              </Container>
            </Loader>
          </div>
        </div>
      </div>
    </RegularLayout>
  );
}
