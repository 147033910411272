import { UserPermissions, UserType } from "Types/userInfo";

export const SET_USER_TYPE = "USERINFO/SETUSERTYPE";
export const SET_USER_PERMISSIONS = "USERINFO/SETUSERPERMISSIONS";

const setUserType = (userType: UserType) => {
  return { type: SET_USER_TYPE, value: userType };
};

const setUserPermissions = (userPermissions: UserPermissions) => {
  return { type: SET_USER_PERMISSIONS, value: userPermissions };
};

export const UserInfoActions = {
  setUserType,
  setUserPermissions,
};
