import React from "react";
import { Button, Form } from "react-bootstrap";
import ImpoundedVehicleListActions, {
  default as actions,
} from "Redux/impoundedvehicles/ImpoundedVehicleListActions";
import Checkbox from "Components/Checkbox/Checkbox";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

interface ButtonProps {
  id: string;
  testId: string;
  onClick: any;
  checked: boolean;
  text: string;
}

// Generic filter button base
export const FilterButton = (props: ButtonProps) => (
  <Button
    id={props.id}
    data-testid={props.testId}
    className="mb-2 ml-2"
    onClick={props.onClick}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox disabled={true} checked={props.checked} />
      {props.text}
    </div>
  </Button>
);

export const DateRangeFilter = () => {
  const dispatch = useDispatch();
  const { dateRangeFilterStart, dateRangeFilterEnd } = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>Term Start Date Between</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form.Control
          className="ml-2"
          data-testid={"date-range-start-date-input"}
          type="date"
          name="dateFilterStart"
          onChange={(e: any) =>
            dispatch(
              ImpoundedVehicleListActions.filterDateRangeStart(e.target.value)
            )
          }
          value={dateRangeFilterStart}
        />
        <Button
          className="mr-2"
          data-testid={"date-range-start-date-clear"}
          onClick={() =>
            dispatch(ImpoundedVehicleListActions.filterDateRangeStart(""))
          }
        >
          X
        </Button>
      </div>
      <div>and</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form.Control
          className="ml-2"
          data-testid={"date-range-end-date-input"}
          type="date"
          name="dateFilterStart"
          onChange={(e: any) =>
            dispatch(
              ImpoundedVehicleListActions.filterDateRangeEnd(e.target.value)
            )
          }
          value={dateRangeFilterEnd}
        />
      </div>
      <Button
        data-testid={"date-range-end-date-clear"}
        onClick={() =>
          dispatch(ImpoundedVehicleListActions.filterDateRangeEnd(""))
        }
      >
        X
      </Button>
    </div>
  );
};

export const WrongfulFilter = () => {
  const dispatch = useDispatch();
  const wrongfulFilter = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList.wrongfulFilter
  );

  return (
    <FilterButton
      id="wrongfulFilter"
      testId="wrongfulFilter"
      text="Wrongful"
      onClick={() => dispatch(actions.filterWrongful(!wrongfulFilter))}
      checked={wrongfulFilter}
    />
  );
};

export const TermEndedFilter = () => {
  const dispatch = useDispatch();
  const termEndedFilter = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList.termEndedFilter
  );

  return (
    <FilterButton
      id="termEndedFilter"
      testId="termEndedFilter"
      text="Term Ended"
      onClick={() => dispatch(actions.filterTermEnded(!termEndedFilter))}
      checked={termEndedFilter}
    />
  );
};

export const InventoryFormFilter = () => {
  const dispatch = useDispatch();
  const invFormReceived = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList.invFormFilter
  );

  return (
    <FilterButton
      id="inventoryformFilter"
      testId="invFormFilter"
      text="Inventory Form Missing"
      onClick={() => dispatch(actions.filterInvFormReceived(!invFormReceived))}
      checked={invFormReceived}
    />
  );
};

export const LawEnforcementFormFilter = () => {
  const dispatch = useDispatch();
  const lawEnforcementFileMissingFilter = useSelector(
    (state: RootStateOrAny) =>
      state.ImpoundedVehicleList.lawEnforcementFileMissingFilter
  );

  return (
    <FilterButton
      id="lawEnforcementFormFilter"
      testId="lawEnforcementFormFilter"
      text="LE File Missing"
      onClick={() => {
        dispatch(
          actions.filterLawEnforcementFileMissing(
            !lawEnforcementFileMissingFilter
          )
        );
      }}
      checked={lawEnforcementFileMissingFilter}
    />
  );
};
