import { useCallback, useEffect } from "react";
import { Row } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import RegularLayout from "Layouts/RegularLayout";
import { defaultGarageKeeper } from "Types/garageKeeper";
import RouteSwitcher from "Components/Admin/RouteSwitcher";
import allActions from "Redux/allActions";
import { useIsAuthenticated } from "@azure/msal-react";
import DashboardCount from "./DashboardCount";
import { faCar, faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  garagekeeperRequestTypes,
  impoundmentRequestTypes,
  RequestType,
} from "Types/requestType";
import { GkImpoundmentCounts } from "Types/gkImpoundmentCounts";
import {
  ImpoundedVehicleStatus,
  ImpoundedVehicleStatusLabel,
} from "Types/impoundedVehicleStatus";
import DashboardCard from "./DashboardCard";
import DashboardRequestCountTable from "./DashboardRequestCountTable";
import DashboardGkFyiRequestCount from "./DashboardGkFyiRequestCount";
import BulletinListDashboardCard from "Components/Bulletins/BulletinListDashboardCard";
import { BulletinType } from "Types/bulletin";
import DashboardRequireAttentionCommentsCount from "./DashboardRequireAttentionCommentsCount";

interface Props {
  history: any;
}

export default function DashboardGarageKeeper({ history }: Props) {
  fontAwesomeLibrary.add(faCar, faHandPaper);

  const dispatch = useDispatch();

  const { id: gkid, name: gkName } = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const gKImpoundmentCounts: GkImpoundmentCounts = useSelector(
    (state: RootStateOrAny) => state.dashboard.gKImpoundmentCounts
  );

  const getCount = (type: any, filter: string) => {
    return type[filter] ?? type[filter.toLowerCase()];
  };

  const refreshStats = useCallback(() => {
    dispatch(allActions.DashboardActions.loadGKImpoundedVehicleCounts(gkid));
    dispatch(allActions.DashboardActions.loadRequestCounts(gkid));
  }, [dispatch, gkid]);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) refreshStats();
  }, [gkid, isAuthenticated, refreshStats]);

  return (
    <RegularLayout title="Dashboard">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          {`${
            gkid === defaultGarageKeeper.id ? "Garage Keeper" : gkName
          } - Dashboard`}
        </h1>
      </div>
      <RouteSwitcher />
      {gkid !== defaultGarageKeeper.id ? (
        <Row>
          <DashboardCard
            title="Impounded Vehicles"
            icon="car"
            testId="gk-impounded-vehicle-counts"
          >
            {Object.keys(ImpoundedVehicleStatus)
              .filter(
                (status) =>
                  status === ImpoundedVehicleStatus.Active ||
                  status === ImpoundedVehicleStatus.Released
              )
              .map((status) => {
                return (
                  <DashboardCount
                    key={status}
                    handleClick={() => {
                      history.push({
                        pathname: "/ImpoundedVehicles",
                      });
                      dispatch(
                        allActions.ImpoundedVehicleListActions.filterTermEnded(
                          false
                        )
                      );
                      dispatch(
                        allActions.ImpoundedVehicleListActions.filterWrongful(
                          false
                        )
                      );
                      dispatch(
                        allActions.ImpoundedVehicleListActions.filterStatus(
                          status
                        )
                      );
                    }}
                    countTitle={
                      ImpoundedVehicleStatusLabel[
                        status as keyof typeof ImpoundedVehicleStatusLabel
                      ]
                    }
                    status={status}
                    count={getCount(gKImpoundmentCounts, status)}
                  />
                );
              })}
            <DashboardCount
              key="Wrongful"
              handleClick={() => {
                history.push({
                  pathname: "/ImpoundedVehicles",
                });
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterStatusAll()
                );
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterTermEnded(false)
                );
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterWrongful(true)
                );
              }}
              countTitle="Wrongful"
              status="Wrongful"
              count={getCount(gKImpoundmentCounts, "Wrongful")}
            />
            <DashboardCount
              key="TermEnded"
              handleClick={() => {
                history.push({
                  pathname: "/ImpoundedVehicles",
                });
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterStatusAll()
                );
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterWrongful(false)
                );
                dispatch(
                  allActions.ImpoundedVehicleListActions.filterTermEnded(true)
                );
              }}
              countTitle="Term Ended"
              status="TermEnded"
              count={getCount(gKImpoundmentCounts, "TermEnded")}
            />
          </DashboardCard>
          <DashboardCard testId="gk-impoundment-request-counts">
            <DashboardRequestCountTable
              types={impoundmentRequestTypes}
              title="Impoundment Requests"
              history={history}
              internal={false}
            />
          </DashboardCard>
          <DashboardCard testId="gk-garagekeeper-request-counts">
            <DashboardRequestCountTable
              types={garagekeeperRequestTypes}
              title="Garage Keeper Requests"
              history={history}
              internal={false}
            />
          </DashboardCard>
          <DashboardCard testId="gk-recently-completed-request-counts">
            <DashboardGkFyiRequestCount
              types={Object.values(RequestType)}
              title="Requests completed in the last 7 days"
              history={history}
            />
          </DashboardCard>
          <BulletinListDashboardCard
            title="Bulletins"
            bulletinType={BulletinType.Bulletin}
          />
          <DashboardRequireAttentionCommentsCount garageKeeperId={gkid}/>
        </Row>
      ) : (
        ""
      )}
    </RegularLayout>
  );
}
