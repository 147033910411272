import { emptyGuid } from "Utils/guid";
import { Location } from "Types/location";
import CallBffApi from "Utils/CallBff";

export const SET_LOCATIONS = "LOCATION/SET_LOCATION";
export const ADD_LOCATION = "LOCATION/ADD_LOCATION";
export const UPDATE_LOCATION = "LOCATION/UPDATE_LOCATION";

export const SAVE_LOCATION = "LOCATION/SAVE_LOCATION";
export const SAVE_LOCATION_SUCCESS = "LOCATION/SAVE_LOCATION_SUCCESS";

export const LOAD_LOCATIONS = "LOCATION/LOAD_LOCATIONS";
export const LOAD_LOCATIONS_SUCCESS = "LOCATION/LOAD_LOCATIONS_SUCCESS";

export const ERROR = "LOCATION/API_ERROR";

export const locationActions = {
  setLocations: (locations: Location[]) => {
    return {
      type: SET_LOCATIONS,
      value: locations,
    };
  },
  addLocation: (location: Location) => {
    return {
      type: ADD_LOCATION,
      value: location,
    };
  },
  updateLocation: (location: Location) => {
    return {
      type: UPDATE_LOCATION,
      value: location,
    };
  },
  loadLocations: (gkId: string) => {
    return async function (dispatch: any) {
      if (gkId === emptyGuid) {
        return dispatch({
          type: LOAD_LOCATIONS_SUCCESS,
          value: [],
        });
      }

      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL + "locations/garageKeeperId=" + gkId
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: LOAD_LOCATIONS_SUCCESS,
          value: result,
        });
      }
      dispatch({
        type: ERROR,
      });
    };
  },
  saveLocation: (location: Location) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL + "locations",
        {
          method: "POST",
          body: JSON.stringify(location),
        }
      );

      if (response.ok) {
        const result = await response.json();
        return dispatch({
          type: SAVE_LOCATION_SUCCESS,
          value: result,
        });
      }

      console.log("Error saving location in thunk");
      dispatch({ type: ERROR });
    };
  },
};

export default locationActions;
