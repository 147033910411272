import CallBffApi from "../../Utils/CallBff";
import { GarageKeeper } from "../../Types/garageKeeper";
import { GarageKeeperListInfo } from "../../Types/garageKeeperListInfo";

export const SET_GKLIST = "GKLIST/SET_GKLIST";

export const FILTER_STATUS = "GKLIST/FILTER_STATUS";
export const FILTER_STATUS_ALL = "GKLIST/FILTER_STATUS_ALL";

export const FILTER_CITY = "GKLIST/FILTER_CITY";
export const FILTER_CITY_ALL = "GKLIST/FILTER_CITY_ALL";

export const SORT = "GKLIST/SORT";

export const LOAD_GARAGE_KEEPER_LIST = "GKLIST/LOAD_LIST";
export const LOAD_GARAGE_KEEPER_LIST_SUCCESS = "GKLIST/LOAD_LIST_SUCCESS";

export const API_ERROR = "GKLIST/API_ERROR";

export const ADD_GK = "GKLIST/ADD_GK";
export const UPDATE_GK = "GKLIST/UPDATE_GK";

const setGkList = (gkList: GarageKeeperListInfo[]) => {
  return {
    type: SET_GKLIST,
    gkList: gkList,
  };
};

const loadGarageKeeperList = () => {
  return async function (dispatch: any) {
    dispatch({ type: LOAD_GARAGE_KEEPER_LIST });

    const response = await CallBffApi(
      window.REACT_APP_API_BASEURL + "garagekeepers/list"
    );

    if (response.ok) {
      var result = await response.json();
      dispatch({ type: LOAD_GARAGE_KEEPER_LIST_SUCCESS, value: result });
    } else dispatch({ type: API_ERROR, value: response.statusText });
  };
};

const filterStatus = (filter: string) => {
  return {
    type: FILTER_STATUS,
    filter: filter,
  };
};

const filterCity = (filter: string) => {
  return {
    type: FILTER_CITY,
    filter: filter,
  };
};

const filterStatusAll = () => {
  return {
    type: FILTER_STATUS_ALL,
  };
};

const filterCityAll = () => {
  return {
    type: FILTER_CITY_ALL,
  };
};

const sortList = (sortField: string, sortDirection: string) => {
  return {
    type: SORT,
    sortField: sortField,
    sortDirection: sortDirection,
  };
};

const addGarageKeeper = (garageKeeper: GarageKeeper) => {
  return {
    type: ADD_GK,
    value: garageKeeper,
  };
};

const updateGarageKeeper = (garageKeeper: GarageKeeper) => {
  return {
    type: UPDATE_GK,
    value: garageKeeper,
  };
};

const GKListActions = {
  setGkList,
  loadGarageKeeperList,
  filterStatus,
  filterCity,
  filterStatusAll,
  filterCityAll,
  sortList,
  addGarageKeeper,
  updateGarageKeeper,
};

export default GKListActions;
