import React from 'react';
import { Location } from "history";
import { useLocation } from "react-router";
import { Button } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "../../Redux/allActions";

import Loader from "../../Components/Loader/Loader";
import {
  formatPhoneNumberUndefined,
  formatDateStripTime,
  formatMoneyWithDecimals,
  formatVehicleImpoundment,
  formatDate,
} from "../../Utils/format";

import {
  defaultVehicleImpoundment,
  ReleaseDateReasonLabel,
  VehicleImpoundment,
} from "../../Types/vehicleImpoundment";
import { ImpoundedVehicleListInfo } from "../../Types/impoundedVehicleListInfo";

import DetailLayout from "../../Layouts/DetailLayout";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";

import Accordion from "../../Components/Accordion/Accordion";
import AccordionExpandButton from "../../Components/Accordion/AccordionExpandButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import VehicleImpoundmentForm from "../../Forms/VehicleImpoundmentForm/VehicleImpoundmentForm";
import CommentContainer from "../../Components/Comments/CommentContainer";
import {
  ImpoundedVehicleStatus,
  ImpoundedVehicleStatusLabel,
  IsApprovedOrSoldStatus,
  IsApprovedStatus,
  IsSoldStatus,
  isTermEnded,
} from "../../Types/impoundedVehicleStatus";
import { useEffect, useState } from "react";
import FeeBreakdown from "./FeeBreakdown";
import { ImpoundmentCategoryLabel, ImpoundmentType } from "../../Types/impoundmentType";
import { UserPermissions, UserType } from "../../Types/userInfo";
import { RequestStatus, RequestStatusLabel } from "../../Types/request";
import { RequestType } from "../../Types/requestType";
import ReusableModal from "../../Components/Modal/ReusableModal";
import CallBffApi from "../../Utils/CallBff";
import ConfirmDelete from "../../Components/ConfirmDelete/ConfirmDelete";
import { doToast, ToastType } from "../../Utils/toastUtils";
import { EntityType } from "../../Types/EntityType";
import { RequestListInfo } from "../../Types/requestListInfo";
import ImpoundmentLawEnforcementList from "./ImpoundmentLawEnforcementList";
import { PagedResponse } from "../../Types/pagination";
import ImpoundedVehicleAttachmentContainer from "./ImpoundedVehicleAttachmentContainer";
import { Attachment } from "../../Types/attachment";
import { getAttachments } from "../../api/attachmentApi";

export function IsExpired(impoundmentEnd?: Date): boolean {
  if (impoundmentEnd === undefined || impoundmentEnd === null) {
    return false;
  }

  if (typeof impoundmentEnd === "string") {
    impoundmentEnd = new Date(impoundmentEnd);
  }

  impoundmentEnd = new Date(impoundmentEnd.setHours(0, 0, 0, 0));

  let currentDate = new Date(new Date().toDateString());

  if (currentDate >= impoundmentEnd) return true;

  return false;
}

const badgeStyle = {
  position: "absolute",
  left: `${16}px`,
  top: `${20}px`,
  fontSize: "10px",
} as React.CSSProperties;

interface Props {
  history: any;
}

function ImpoundedVehicleDetail(props: Props) {
  fontAwesomeLibrary.add(faPhone);
  const dispatch = useDispatch();

  const [expandAll, setExpandAll] = useState<boolean>(true);

  const [validRequestTypes, setValidRequestTypes] = useState<RequestType[]>([]);

  const vehicleImpoundment: VehicleImpoundment = useSelector(
    (state: RootStateOrAny) => state.vehicleImpoundment.vehicleImpoundment
  );

  const {
    userType,
    userPermissions,
  }: { userType: UserType; userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const setVehicleImpoundment = (vehicleImpoundment: VehicleImpoundment) => {
    dispatch(
      allActions.VehicleImpoundmentActions.setVehicleImpoundment(
        vehicleImpoundment
      )
    );
  };

  const location = useLocation<Location<ImpoundedVehicleListInfo>>();

  const vi: any | undefined = location.state;

  const isApprovedOrSoldStatus = IsApprovedOrSoldStatus(
    vehicleImpoundment.status
  );

  const isSoldStatus = IsSoldStatus(vehicleImpoundment.status);

  const tag = (label: string, value: boolean = false) => (
    <Badge variant={value ? "primary" : "secondary"}>
      {value ? "✓" : "X"} {label}
    </Badge>
  );

  const row = (
    label: string,
    value: string | undefined,
    testId: string,
    phone?: string
  ) => (
    <ListGroup.Item>
      <Row>
        <Col md={3} className={"font-weight-bold"}>
          {label}
          {phone && (
            <a href={`tel:${phone}`} className="view-action ml-md-2">
              <FontAwesomeIcon icon="phone" size="1x" color="#007bff" />
            </a>
          )}
        </Col>
        <Col data-testid={testId}>{value}</Col>
      </Row>
    </ListGroup.Item>
  );

  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  const requestUrl = userPermissions.canViewImpoundmentFull
    ? `${window.REACT_APP_API_BASEURL}vehicleimpoundments/${vi.vi.vehicleImpoundmentId}`
    : `${window.REACT_APP_API_BASEURL}vehicleimpoundments/${vi.vi.vehicleImpoundmentId}/external`;

  const buttonStyle = {
    cursor: "pointer",
    fontSize: "25px",
    position: "relative",
    padding: "0px 5px 0px 5px",
  } as React.CSSProperties;

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [viToDelete, setViToDelete] = useState(defaultVehicleImpoundment);
  const [inProgressRequest, setInProgressRequest] = useState<RequestListInfo>();
  const [associatedRequests, setAssociatedRequests] = useState<RequestListInfo[]>([]);
  const [associatedAttachments, setAssociatedAttachments] = useState<Attachment[]>([]);
  const [attachmentsCount, setAttachmentCount] = useState(vehicleImpoundment.attachmentCount);
  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);
  const [associatedAttachmentsLoaded, setassociatedAttachmentsLoaded] = useState(false);
  const [associatedRequestsLoaded, setAssociatedRequestsLoaded] = useState(false);

  const deleteVehicleImpoundment = async (
    vehicleImpoundmentId: string,
    deleteReason: string
  ) => {
    const response = await CallBffApi(
      `${window.REACT_APP_API_BASEURL}vehicleImpoundments/${vehicleImpoundmentId}?deleteReason=${deleteReason}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      doToast("Vehicle Impoundment deleted successfully", ToastType.Success);
      setShowDeleteConfirm(false);
      props.history.goBack();
    } else {
      doToast("Error deleting vehicle impoundment", ToastType.Error);
    }
  };

  const anyRequestButtonsVisible = (): boolean => {

    if (!(userPermissions.canUpdateImpoundment || userPermissions.isGarageKeeper))
      return false;

    return (
      //Disposal Request Button
      (vehicleImpoundment.status === ImpoundedVehicleStatus.Active &&
        isTermEnded(
          new Date(),
          vehicleImpoundment.status,
          vehicleImpoundment.impoundmentEnd
        ) &&
        validRequestTypes.includes(RequestType.Disposal)) ||
      //Release Request Button
      ((vehicleImpoundment.status === ImpoundedVehicleStatus.Active
        || vehicleImpoundment.status === ImpoundedVehicleStatus.ApplicationToDispose
        || vehicleImpoundment.status === ImpoundedVehicleStatus.ApprovedForAuction
        || vehicleImpoundment.status === ImpoundedVehicleStatus.ApprovedForTransfer
        || vehicleImpoundment.status === ImpoundedVehicleStatus.ApprovedUndefined) &&
        validRequestTypes.includes(RequestType.Release)) ||
      //Payment Request Button
      (IsApprovedStatus(vehicleImpoundment.status) &&
        validRequestTypes.includes(RequestType.Payment))
    );
  };

  useEffect(() => {
    if (associatedRequestsLoaded) {
      if (associatedRequests.length > 0) {
        const fetchAttachments = async () => {
          await Promise.all(associatedRequests.map(async (req) => {
            const attachments = await getAttachments(req.id, 'Request');
            if (attachments.length > 0)
              setAssociatedAttachments(prev => {
                const newAttachments = attachments.filter(a => !prev.find(p => p.id === a.id));
                return [...prev, ...newAttachments];
              })
          }));

          setassociatedAttachmentsLoaded(true);
        }

        fetchAttachments();
      }
      else {
        setassociatedAttachmentsLoaded(true);
      }
    }
  }, [associatedRequestsLoaded])

  useEffect(() => {
    if (associatedAttachmentsLoaded) {
      setAttachmentCount(vehicleImpoundment.attachmentCount + associatedAttachments.length);
      setAttachmentsLoaded(true);
    }
  }, [associatedAttachmentsLoaded]);

  const getPayableImpoundDays = (term: number): number => {
    switch (term) {
      case 3:
      case 7:
      case 14:
      case 30:
        return 45;
      case 60:
        return 75;
      default:
        return 45;
    }
  }

  const showDaysPayableMessage = () => {
    return userType == UserType.Admin && inProgressRequest?.type === RequestType.Payment;
  }

  return (
    <DetailLayout
      title="Impounded Vehicle Detail"
      header={
        vi.vi.vin ?? vi.vi.vehicleImpoundmentId === vehicleImpoundment.id
          ? vehicleImpoundment.impoundedVehicle.vin
          : ""
      }
      subheader="Vehicle Impoundment"
    >
      <Loader
        url={requestUrl}
        onLoadComplete={(result: VehicleImpoundment) =>
          setVehicleImpoundment(formatVehicleImpoundment(result))
        }
      >
        <Loader
          url={`${window.REACT_APP_API_BASEURL}requests/listbyentity?garageKeeperId=${vehicleImpoundment.garageKeeperId}&entityId=${vehicleImpoundment.id}`}
          onLoadComplete={(pagedResult: PagedResponse) => {

            var result: RequestListInfo[] = pagedResult?.data;
            setInProgressRequest(
              result?.find(
                (x) =>
                  x.status !== RequestStatus.Completed &&
                  x.status !== RequestStatus.Cancelled
              )
            );
            setAssociatedRequests(result?.filter((x) => x.status !== RequestStatus.Cancelled) ?? []);
            setAssociatedRequestsLoaded(true);
          }}
        />
        <Row>
          <Col style={{ paddingLeft: "25px" }}>
            {userType === UserType.Admin && (
              <ReusableModal
                iconClassName="far fa-edit edit-button"
                useIcon={true}
                iconTitle="Edit"
                headerText="Edit Impoundment"
                open={() => {
                  displayForm();
                }}
                close={hideForm}
                show={showForm}
              >
                <VehicleImpoundmentForm
                  submit={CallBffApi}
                  cancel={hideForm}
                  data={vehicleImpoundment}
                  history={props.history}
                />
              </ReusableModal>
            )}
            <span
              style={buttonStyle}
              className="requestHistory-button"
              data-testid="requestHistoryButton"
              title="Request History"
              onClick={() => {
                dispatch(allActions.RequestActions.filterStatus("ALL"));
                dispatch(allActions.RequestActions.filterRecent(false));
                dispatch(allActions.RequestActions.filterType("ALL"));
                dispatch(
                  allActions.RequestActions.sortRequests(
                    "createdOn",
                    "descending"
                  )
                );
                props.history.push({
                  pathname: `/RequestHistory`,
                  state: {
                    garageKeeperId: vehicleImpoundment.garageKeeperId,
                    garageKeeperName: vehicleImpoundment.garageKeeperName,
                    entityId: vehicleImpoundment.id,
                    impoundedVehicleVin:
                      vehicleImpoundment.impoundedVehicle.vin,
                  },
                });
              }}
            >
              <i className="fas fa-history"></i>
              {inProgressRequest && (
                <Badge
                  style={badgeStyle}
                  data-testid="in-progress-request-badge"
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="lg"
                    color="orange"
                  />
                </Badge>
              )}
            </span>
            {attachmentsLoaded &&
              <ImpoundedVehicleAttachmentContainer
                entityId={vehicleImpoundment.id}
                entityType={EntityType.VehicleImpoundment}
                count={attachmentsCount}
                associatedRequests={associatedRequests}
                associatedAttachments={associatedAttachments}
                readonly={!(userPermissions.canUpdateImpoundment || userPermissions.isGarageKeeper)} />}
            &nbsp;
            <CommentContainer
              id={vehicleImpoundment.id}
              count={vehicleImpoundment.commentCount}
              entityType={EntityType.VehicleImpoundment}
              orgId={userType === UserType.Admin ? vehicleImpoundment.garageKeeperId : undefined}
              readOnly={!(userPermissions.canUpdateImpoundment || userPermissions.isGarageKeeper)}
            />
            {userType === UserType.Admin && (
              <span
                style={buttonStyle}
                className="ml-1"
                data-testid={`Delete-${vehicleImpoundment.id}`}
                title={`Delete`}
                onClick={() => {
                  setViToDelete(vehicleImpoundment);
                  setShowDeleteConfirm(true);
                }}
              >
                <i className="fas fa-trash-alt delete-button"></i>
              </span>
            )}
            {inProgressRequest && (
              <span style={{ float: "right" }}>
                <div data-testid="in-progress-request-message">
                  {inProgressRequest.type} Request In Progress -{" "}
                  {
                    RequestStatusLabel[
                    inProgressRequest.status as keyof typeof RequestStatus
                    ]
                  }
                </div>

                {showDaysPayableMessage() &&
                  <div data-testid="payable-days-message" style={{ color: 'green' }}>Payable Impound Days {getPayableImpoundDays(vehicleImpoundment.termLength)}</div>
                }
              </span>
            )}
          </Col>{" "}
          {userType === UserType.GarageKeeper && (
            <Loader
              url={`${window.REACT_APP_API_BASEURL}requests/getvalidcreationtypes?garageKeeperId=${vehicleImpoundment.garageKeeperId}&entityId=${vehicleImpoundment.id}`}
              onLoadComplete={(result: RequestType[]) =>
                setValidRequestTypes(result)
              }
            >
              <Col>
                {anyRequestButtonsVisible() && (
                  <>
                    {vehicleImpoundment.status ===
                      ImpoundedVehicleStatus.Active &&
                      isTermEnded(
                        new Date(),
                        vehicleImpoundment.status,
                        vehicleImpoundment.impoundmentEnd
                      ) && (
                        <div data-testid="disposalRequestButton-container">
                          {validRequestTypes.includes(RequestType.Disposal) && (
                            <Button
                              data-testid="disposalRequestButton"
                              style={{ margin: 5, float: "right" }}
                              onClick={() => {
                                props.history.push({
                                  pathname: `/RequestDetail`,
                                  state: {
                                    garageKeeperId:
                                      vehicleImpoundment.garageKeeperId,
                                    entityId: vehicleImpoundment.id,
                                    type: RequestType.Disposal,
                                    status: RequestStatus.New,
                                  },
                                });
                              }}
                            >
                              Disposal Request
                            </Button>
                          )}
                        </div>
                      )}
                    {(userType === UserType.GarageKeeper) && (
                      <div data-testid="releaseRequestButton-container">
                        {validRequestTypes.includes(RequestType.Release) && (
                          <Button
                            data-testid="releaseRequestButton"
                            style={{ margin: 5, float: "right" }}
                            onClick={() => {
                              props.history.push({
                                pathname: `/RequestDetail`,
                                state: {
                                  garageKeeperId:
                                    vehicleImpoundment.garageKeeperId,
                                  entityId: vehicleImpoundment.id,
                                  type: RequestType.Release,
                                  status: RequestStatus.New,
                                },
                              });
                            }}
                          >
                            Release Request
                          </Button>
                        )}
                      </div>
                    )}
                    {IsApprovedStatus(vehicleImpoundment.status) && (
                      <div data-testid="paymentRequestButton-container">
                        {validRequestTypes.includes(RequestType.Payment) && (
                          <Button
                            data-testid="paymentRequestButton"
                            style={{ margin: 5, float: "right" }}
                            onClick={() => {
                              props.history.push({
                                pathname: `/RequestDetail`,
                                state: {
                                  garageKeeperId:
                                    vehicleImpoundment.garageKeeperId,
                                  entityId: vehicleImpoundment.id,
                                  type: RequestType.Payment,
                                  status: RequestStatus.New,
                                },
                              });
                            }}
                          >
                            Payment Request
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                )}
                {
                  (userPermissions.canUpdateImpoundment || userPermissions.isGarageKeeper) &&
                  <div data-testid="infoRequestButton-container">
                    {(validRequestTypes.includes(RequestType.NewInfo)) && (
                      <Button
                        data-testid="infoRequestButton"
                        style={{ margin: 5, float: "right" }}
                        onClick={() => {
                          props.history.push({
                            pathname: `/RequestDetail`,
                            state: {
                              garageKeeperId: vehicleImpoundment.garageKeeperId,
                              entityId: vehicleImpoundment.id,
                              type: RequestType.NewInfo,
                              status: RequestStatus.New,
                            },
                          });
                        }}
                      >
                        Info Request
                      </Button>
                    )}
                  </div>
                }
              </Col>
            </Loader>
          )}
        </Row>
        <br />
        <ListGroup.Item>
          <Row>
            <Col md={1} className={"font-weight-bold"}>
              Status
            </Col>
            <Col data-testid="status">
              {
                ImpoundedVehicleStatusLabel[
                vehicleImpoundment.status as keyof typeof ImpoundedVehicleStatusLabel
                ]
              }
              {vehicleImpoundment.wrongful && (
                <>
                  &nbsp;
                  <Badge variant="danger">Wrongful</Badge>
                </>
              )}
              {isTermEnded(
                new Date(),
                vehicleImpoundment.status,
                vehicleImpoundment.impoundmentEnd
              ) && (
                  <>
                    &nbsp;
                    <Badge variant="danger" data-testid="term-ended-badge">
                      Term Ended
                    </Badge>
                  </>
                )}
            </Col>
          </Row>
        </ListGroup.Item>
        <br />
        <AccordionExpandButton
          control={expandAll}
          controlToggle={setExpandAll}
        />
        <Accordion title="Vehicle Details" defaultShow control={expandAll}>
          <ListGroup data-testid="vehicle-group">
            {row("VIN", vehicleImpoundment.impoundedVehicle.vin, "vin")}
            {row(
              "License Plate",
              vehicleImpoundment.impoundedVehicle.licensePlate,
              "licensePlate"
            )}
            {row(
              "Vehicle",
              `${vehicleImpoundment.impoundedVehicle.year ?? ""} ${vehicleImpoundment.impoundedVehicle.make
              } ${vehicleImpoundment.impoundedVehicle.model}`,
              "vehicle"
            )}
            {row(
              "Owner",
              `${vehicleImpoundment.impoundedVehicle.registeredOwner ?? ""
              } ${formatPhoneNumberUndefined(
                vehicleImpoundment.impoundedVehicle.registeredOwnerPhoneNumber
              )}`,
              "registeredOwner",
              vehicleImpoundment.impoundedVehicle.registeredOwnerPhoneNumber
            )}
            {userPermissions.canViewImpoundmentFull &&
              row(
                "Owner Customer Number",
                vehicleImpoundment.impoundedVehicle.registeredOwnerCustNo,
                "registeredOwnerCustNo"
              )}
            {row(
              "Driver",
              `${vehicleImpoundment.impoundedVehicle.driver ?? ""
              } ${formatPhoneNumberUndefined(
                vehicleImpoundment.impoundedVehicle.driverPhoneNumber
              )}`,
              "driver",
              vehicleImpoundment.impoundedVehicle.driverPhoneNumber
            )}
            {userPermissions.canViewImpoundmentFull &&
              row(
                "Driver Customer Number",
                vehicleImpoundment.impoundedVehicle.driverCustNo,
                "driverCustNo"
              )}
          </ListGroup>
        </Accordion>
        <Accordion title="Impoundment Details" defaultShow control={expandAll}>
          <ListGroup data-testid="impoundment-group">
            <ListGroup.Item>
              <Row>
                <Col md={3} className={"font-weight-bold"}>
                  Police File Number
                </Col>
                <Col data-testid="policeFileNumber">
                  {vehicleImpoundment.policeFileNumber}
                </Col>
                <Col md={7}>
                  <ImpoundmentLawEnforcementList
                    impoundmentId={vehicleImpoundment.id}
                  />
                </Col>
              </Row>
            </ListGroup.Item>
            {row(
              "Law Enforcement Entity",
              vehicleImpoundment.lawEnforcementEntity,
              "lawEnforcementEntity"
            )}
            {row(
              "Category",
              ImpoundmentCategoryLabel[
              vehicleImpoundment.category as keyof typeof ImpoundmentCategoryLabel
              ],
              "category"
            )}
            {row("Type", vehicleImpoundment.type, "type")}
            {row(
              "Term",
              `${formatDateStripTime(
                "" + vehicleImpoundment.impoundmentStart
              )} to ${formatDateStripTime(
                "" + vehicleImpoundment.impoundmentEnd
              )} (${vehicleImpoundment.termLength} day${vehicleImpoundment.termLength !== 1 ? "s" : ""
              })`,
              "term"
            )}
            {row(
              "Release Date",
              formatDateStripTime(vehicleImpoundment.releaseDate?.toString()),
              "releaseDate"
            )}
            {vehicleImpoundment.releaseDate &&
              row(
                "Release Reason",
                `${ReleaseDateReasonLabel[
                vehicleImpoundment.releaseDateReason as keyof typeof ReleaseDateReasonLabel
                ] ?? ""
                }${vehicleImpoundment.releaseDateReasonDescription
                  ? ` - ${vehicleImpoundment.releaseDateReasonDescription}`
                  : ""
                }`,
                "releaseDateReason"
              )}
            {row(
              "Days Impounded",
              `${vehicleImpoundment.daysImpounded} day${vehicleImpoundment.daysImpounded !== 1 ? "s" : ""
              }`,
              "termLength"
            )}
            <ListGroup.Item>
              <Row>
                <Col md={3} className={"font-weight-bold"}>
                  Forms Received
                </Col>
                <Col>
                  {tag(
                    "Inventory Form",
                    vehicleImpoundment.inventoryFormReceived
                  )}
                  &nbsp;
                  {tag("Release Form", vehicleImpoundment.releaseFormReceived)}
                </Col>
              </Row>
            </ListGroup.Item>
            {row(
              "Impound Form Date Received",
              formatDateStripTime(
                "" + vehicleImpoundment.impoundmentFormReceivedDate
              ),
              "impoundmentFormReceivedDate"
            )}
            <ListGroup.Item>
              <Row>
                <Col md={3} className={"font-weight-bold"}>
                  Total Fees&nbsp;
                  {vehicleImpoundment.feesBreakdown.length > 0 && (
                    <FeeBreakdown fees={vehicleImpoundment.feesBreakdown} />
                  )}
                </Col>
                <Col>
                  {formatMoneyWithDecimals(vehicleImpoundment.fees ?? 0)}
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Accordion>
        <Accordion
          title="Garage Keeper Details"
          defaultShow
          control={expandAll}
        >
          <ListGroup data-testid="garageKeeper-group">
            {row("Name", vehicleImpoundment.garageKeeperName, "name")}
            {row(
              "Location",
              `${vehicleImpoundment.locationAddress.street}, ${vehicleImpoundment.locationAddress.city}, ` +
              `${vehicleImpoundment.locationAddress.province} ${vehicleImpoundment.locationAddress.postalCode}`,
              "location"
            )}
            {row(
              "Phone Number",
              formatPhoneNumberUndefined(
                vehicleImpoundment.locationPhoneNumber
              ),
              "compoundPhone",
              vehicleImpoundment.locationPhoneNumber
            )}
          </ListGroup>
        </Accordion>
        {(isApprovedOrSoldStatus ||
          vehicleImpoundment.status ===
          ImpoundedVehicleStatus.ApplicationToDispose) && (
            <Accordion title="Disposal Details" defaultShow control={expandAll}>
              <ListGroup data-testid="disposal-group">
                {row(
                  "Application to Dispose Received Date",
                  formatDateStripTime(
                    "" + vehicleImpoundment.applicationToDisposeReceivedDate
                  ),
                  "disposalDate"
                )}
                {row(
                  "Vehicle Condition",
                  vehicleImpoundment.vehicleCondition,
                  "condition"
                )}
                {row(
                  "Inventory Notations",
                  vehicleImpoundment.inventoryNotations,
                  "notations"
                )}
                {row("VMR Status", vehicleImpoundment.vmrStatus, "vmrStatus")}
                {row(
                  "VMR Value",
                  formatMoneyWithDecimals(vehicleImpoundment.vmrValue),
                  "vmrValue"
                )}
                {row("IRE Check", vehicleImpoundment.ireCheck, "ireCheck")}
                {row(
                  "Garage Keeper Declared Value",
                  formatMoneyWithDecimals(
                    vehicleImpoundment.garageKeeperDeclaredValue
                  ),
                  "declValue"
                )}
                <ListGroup.Item>
                  <Row>
                    <Col md={3} className={"font-weight-bold"}>
                      Disposal Statuses
                    </Col>
                    <Col>
                      {tag("Claims", vehicleImpoundment.claims)}
                      &nbsp;
                      {tag(
                        "Adjuster Notified",
                        vehicleImpoundment.adjusterNotified
                      )}
                      &nbsp;
                      {tag("Lien", vehicleImpoundment.lien)}
                      &nbsp;
                      {tag(
                        "Garage Keeper Notified",
                        vehicleImpoundment.garageKeeperNotified
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Accordion>
          )}
        {isSoldStatus && (
          <Accordion title="Sales Details" defaultShow control={expandAll}>
            <ListGroup data-testid="sales-group">
              <ListGroup.Item>
                <Row>
                  <Col md={3} className={"font-weight-bold"}>
                    Sales Statuses
                  </Col>
                  <Col>
                    {tag(
                      "Sales Document Received",
                      vehicleImpoundment.salesDocRecvd
                    )}
                    &nbsp;
                    {tag(
                      "Statutory Decl. of Sale Received",
                      vehicleImpoundment.statutoryDeclRecvd
                    )}
                    &nbsp;
                    {tag("Invoice Received", vehicleImpoundment.invoiceRecvd)}
                  </Col>
                </Row>
              </ListGroup.Item>
              {row(
                "Sales Document Received Date",
                formatDate(vehicleImpoundment.saleReceivedDate),
                "salesDocRecvdDate"
              )}
              {row(
                "Sale Date",
                formatDate(vehicleImpoundment.saleDate),
                "saleDate"
              )}
              {row(
                "Payment Date",
                formatDate(vehicleImpoundment.paymentDate),
                "paymentDate"
              )}
            </ListGroup>
          </Accordion>
        )}
        <ConfirmDelete
          show={showDeleteConfirm}
          headerText={"Delete Vehicle Impoudment"}
          dialogText={`Are you sure you wish to delete '${viToDelete.impoundedVehicle.vin}'?`}
          requireDeleteReason={true}
          onClickDelete={(deleteReason: string) => {
            deleteVehicleImpoundment(viToDelete.id, deleteReason);
          }}
          onClickCancel={() => setShowDeleteConfirm(false)}
        />
      </Loader>
    </DetailLayout>
  );
}

export default ImpoundedVehicleDetail;
