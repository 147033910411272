import { Table } from "react-bootstrap";
import { Comment } from "Types/comment";
import { formatDateTime } from "Utils/format";
import { Button } from 'react-bootstrap';

interface Props {
    comments: Comment[],
    onCompleteComment: (comment: Comment) => Promise<void>,
    onCommentClick: (comment: Comment) => Promise<void>,
    user: string
}

const RequireAttentionCommentsList =
    ({ comments,
        onCompleteComment,
        onCommentClick,
        user
    }: Props) => {

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            {"Comment"}
                        </th>
                        <th>
                            {"Related To"}
                        </th>
                        <th>
                            {"Created By"}
                        </th>
                        <th>
                            {"Created On"}
                        </th>
                    </tr>
                </thead>
                <tbody data-testid="request-table">
                    {comments.length > 0 &&
                        comments.map((c: Comment) => {
                            return (
                                <tr
                                    key={c.id}
                                    data-testid={`comment-row-${c.id}`}
                                    onClick={async () => { await onCommentClick(c) }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <td data-testid={`comment-${c.id}`}>
                                        {c.commentText}
                                    </td>
                                    <td>
                                        {c.commentEntityType}
                                    </td>
                                    <td data-testid={`createdBy-${c.id}`}>
                                        {c.createdBy}
                                    </td>
                                    <td data-testid={`createdOn-${c.id}`}>
                                        {formatDateTime(c.createdOn)}
                                    </td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            style={{ width: "100%" }}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await onCompleteComment(c);
                                            }}
                                            data-testid={`complete-button-${c.id}`}
                                        >
                                            {"Complete"}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        )
    }

export default RequireAttentionCommentsList;