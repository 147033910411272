import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

import FormText from "Components/FormElements/FormText";
import FormSelect from "Components/FormElements/FormSelect";
import CustomForm from "Components/FormElements/CustomForm";
import insurancePolicyActions from "Redux/insurancePolicies/insurancePolicyActions";
import { GarageKeeper } from "Types/garageKeeper";
import {
  defaultInsurancePolicy,
  formatInsurancePolicy,
  InsurancePolicy,
  InsurancePolicyStatus,
  InsurancePolicyTypes,
} from "Types/insurancePolicy";
import { doToast, ToastType } from "Utils/toastUtils";
import FormAttachment from "Components/FormElements/FormAttachment";
import { buildQueryString } from "Utils/apiUtils";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { emptyGuid } from "Utils/guid";
import FormLabel from "Components/FormElements/FormLabel";
import { EntityType } from "Types/EntityType";

interface Props {
  data?: any;
  cancel?: any;
  submit: any;
  isExternalUser: boolean;
}

const InsurancePolicyForm = ({
  data,
  cancel,
  submit,
  isExternalUser,
}: Props) => {
  const { id: gkId } = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const [insurancePolicy, setInsurancePolicy] = useState<InsurancePolicy>(
    data ?? {
      ...defaultInsurancePolicy,
      status: isExternalUser
        ? InsurancePolicyStatus.Pending
        : defaultInsurancePolicy.status,
      garageKeeperId: gkId,
    }
  );

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo;

  const [attachment, setAttachment] = useState<File>();
  const [inputKey, setInputKey] = useState(Date.now());
  const [fileError, setFileError] = useState<boolean>(false);

  const garageKeeper: GarageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const dispatch = useDispatch();

  const addCommas = (num: string) => {
    for (let i = num.length; i > 3; i -= 3) {
      num = num.substr(0, i - 3) + "," + num.substr(i - 3);
    }
    return num;
  };

  const editMode = data ? true : false;

  const isLiabilityValid =
    insurancePolicy.liabilityAmount === "" ||
    insurancePolicy.liabilityAmount >= 20000;

  const areDatesValid =
    !insurancePolicy.effectiveDate ||
    !insurancePolicy.expiryDate ||
    insurancePolicy.effectiveDate < insurancePolicy.expiryDate;

  const handleUpdate = (event: any) => {
    // Handle updates to the data
    if (event.target.name === "liabilityAmount") {
      setInsurancePolicy({
        ...insurancePolicy,
        [event.target.name]: event.target.value.replace(/,/g, ""),
      });
    } else {
      setInsurancePolicy({
        ...insurancePolicy,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event: any): Promise<boolean> => {
    event.preventDefault();

    let isValid = event.currentTarget.checkValidity();

    if (!editMode && attachment === undefined) {
      setFileError(true);
      return false;
    }

    if (!isValid || !isLiabilityValid || !areDatesValid) return false;

    let insurancePolicyToSubmit: InsurancePolicy = {
      ...insurancePolicy,
      garageKeeperId: garageKeeper.id,
    };

    let requestUrl = `${window.REACT_APP_API_BASEURL}insurancepolicies`;

    if (isExternalUser) {
      requestUrl = `${requestUrl}/external`;
    }

    let response = undefined;

    if (editMode) {
      requestUrl = `${requestUrl}/${insurancePolicyToSubmit.id}`;
      response = await submit(requestUrl, {
        method: "PUT",
        body: JSON.stringify(insurancePolicyToSubmit),
      });
    } else {
      if (attachment === undefined) return false;

      let data = new FormData();
      data.append("file", attachment);

      response = await submit(
        requestUrl +
          "?" +
          buildQueryString({
            entityId: emptyGuid,
            entityType: EntityType.InsurancePolicy,
            fileName: attachment.name,
            author: account?.name ?? "",
            lastEditor: account?.name ?? "",
            description: "Proof of insurance",
            ...insurancePolicyToSubmit,
          }),
        {
          method: "POST",
          body: data,
        },
        true
      );
    }

    if (response.ok) {
      if (!editMode) {
        const result = await response.json();
        dispatch(
          insurancePolicyActions.addInsurancePolicy(
            formatInsurancePolicy(result)
          )
        );
        setInsurancePolicy({
          ...defaultInsurancePolicy,
          status: isExternalUser
            ? InsurancePolicyStatus.Pending
            : defaultInsurancePolicy.status,
          garageKeeperId: gkId,
        });
        setAttachment(undefined);
        setInputKey(Date.now());
      } else {
        dispatch(
          insurancePolicyActions.updateInsurancePolicy(
            formatInsurancePolicy(insurancePolicyToSubmit)
          )
        );
      }
      doToast("Insurance Policy saved successfully", ToastType.Success);
      return true;
    }

    const errorResult = await response.json();
    doToast(errorResult, ToastType.Error);
    return false;
  };

  return (
    <CustomForm
      edit={editMode}
      onSubmit={handleSubmit}
      cancel={cancel}
      openInEditMode={true}
    >
      <Form.Row>
        <Form.Group as={Col} md="4" controlId="formStatus">
          <FormSelect
            required
            testId="status"
            label="Status"
            field="status"
            onChange={handleUpdate}
            data={Object.keys(InsurancePolicyStatus).filter(
              (s) =>
                s !== InsurancePolicyStatus.Pending ||
                s === insurancePolicy.status
            )}
            value={insurancePolicy.status}
            mapping={(s) => {
              return { value: s, text: s };
            }}
            disabled={
              isExternalUser ||
              insurancePolicy.status === InsurancePolicyStatus.Active
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="4" controlId="formInsurer">
          <FormText
            required
            label="Insurer"
            field="insurer"
            value={insurancePolicy.insurer}
            pattern=".{1,30}"
            invalidMessage=" must be less than 30 characters"
            onChange={handleUpdate}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="formInsuranceType">
          <FormSelect
            required
            label="Type"
            field="insuranceType"
            value={insurancePolicy.insuranceType}
            defaultOption={{ value: "", text: "Select insurance type" }}
            data={Object.keys(InsurancePolicyTypes)}
            mapping={(key) => {
              return {
                value: key,
                text: InsurancePolicyTypes[
                  key as keyof typeof InsurancePolicyTypes
                ],
              };
            }}
            onChange={handleUpdate}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="4" controlId="formEffectiveDate">
          <FormText
            required
            type="date"
            label="Effective Date"
            field="effectiveDate"
            value={insurancePolicy.effectiveDate}
            onChange={handleUpdate}
            isValid={areDatesValid}
            invalidMessage=" must be before Expiry Date"
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="formExpiryDate">
          <FormText
            required
            type="date"
            label="Expiry Date"
            field="expiryDate"
            value={insurancePolicy.expiryDate}
            onChange={handleUpdate}
            isValid={areDatesValid}
            invalidMessage=" must be after Effective Date"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="4" controlId="formLiabilityAmount">
          <FormText
            required
            label="Liability Amount"
            field="liabilityAmount"
            value={addCommas(insurancePolicy.liabilityAmount.toString())}
            pattern="^[0-9,]+$"
            onChange={handleUpdate}
            isValid={isLiabilityValid}
            invalidMessage=" must be greater than or equal to $20,000"
          />
        </Form.Group>
      </Form.Row>
      {!editMode && (
        <Form.Row>
          <Form.Group as={Col} md="8" controlId="formAttachment">
            <FormLabel required text="Proof of Insurance" />
            <FormAttachment
              testId="formAttachmentInput"
              onFileSelect={(file: File) => {
                setAttachment(file);
                setFileError(false);
              }}
              onFileRemove={() => setAttachment(undefined)}
              selectedFiles={attachment ? [attachment] : []}
              key={inputKey}
            />
            {fileError && (
              <span
                className="danger"
                style={{ color: "rgb(220, 53, 69)", fontSize: "12.8px" }}
              >
                Proof of Insurance must be attached
              </span>
            )}
          </Form.Group>
        </Form.Row>
      )}
    </CustomForm>
  );
};

export default InsurancePolicyForm;
