import FormLabel from "Components/FormElements/FormLabel";
import StatelessSortButton from "Components/StatelessSortButton/StatelessSortButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Form, FormControl, InputGroup, Spinner, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AuthUserInfo } from "Types/AuthUserInfo";
import { Organization } from "Types/organization";
import CallBffApi from "Utils/CallBff";

enum LoadingStatus {
  Initial,
  Loading,
  Loaded,
  Failed,
}

export default function UserAccountList() {
  const [securityOrgs, setSecurityOrgs] = useState<Organization[]>([]);
  const [orgLoadingStatus, setOrgLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.Initial
  );
  const [selectedSecurityOrgId, setSelectedSecurityOrgId] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [userAccounts, setUserAccounts] = useState<AuthUserInfo[]>([]);
  const [userLoadingStatus, setUserLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.Initial
  );
  const [sortField, setSortField] = useState("displayName");
  const [sortDirection, setSortDirection] = useState("ascending");

  const sortedUserAccounts = useMemo(() => {
    return userAccounts.sort((ua1: any, ua2: any) => {
      let val = 0;
      const f1 = ua1[sortField] ?? "";
      const f2 = ua2[sortField] ?? "";
      if(f1 > f2) val = 1
      if(f1 < f2) val = -1
      if (sortDirection === "descending") val *= -1;
      return val;
    });
  }, [userAccounts, sortDirection, sortField]);

  const securityOrgUrl = `${
    (window as any).REACT_APP_API_BASEURL
  }auth/orgs`;

  const loadSecurityOrgs = useCallback(async () => {
    const response = await CallBffApi(securityOrgUrl);

    if (response.ok) {
      var result: Organization[] = await response.json();

      setSecurityOrgs(result);
      setOrgLoadingStatus(LoadingStatus.Loaded);
    } else {
      setOrgLoadingStatus(LoadingStatus.Failed);
    }
  }, [securityOrgUrl]);

  const loadAuthUsers = async (url: string) => {
    setUserLoadingStatus(LoadingStatus.Loading);
    const response = await CallBffApi(url);

    if (response.ok) {
      var result: AuthUserInfo[] = await response.json();

      result.forEach(
        (x) => (x.profileNames = x.profiles.map((x) => x.name).join(", "))
      );
      setUserAccounts(result);
      setUserLoadingStatus(LoadingStatus.Loaded);
    } else {
      setUserAccounts([]);
      setUserLoadingStatus(LoadingStatus.Failed);
    }
  };

  const getNumberOfDays = (date: Date ) => {
    if(date === null) return null;
    var t1 = new Date(date).getTime();
    var t2 = new Date().getTime();
    return Math.floor((t2-t1)/(24*3600*1000));
  }

  const getLastLogin = (date: Date ) => {
    var days = getNumberOfDays(date);
    return days === null ? "Never" :
    days === 0 ? "Today" :
    days === 1 ? "Yesterday" :
    `${days} days ago`;
  }

  const handleChange = (orgId: string) => {
    setSelectedSecurityOrgId(orgId);
    const url = orgId === "All" ?
      `${
        (window as any).REACT_APP_API_BASEURL
      }auth/users` :
      `${
        (window as any).REACT_APP_API_BASEURL
      }auth/users?organizationId=${orgId}`
    
    setEmail("")
    loadAuthUsers(url)
  }

  const searchEmail = () => {
    if(email.length > 4){
      setSelectedSecurityOrgId("");
      loadAuthUsers(`${
        (window as any).REACT_APP_API_BASEURL
      }auth/users?emailAddress=${email}`)
    }
  }

  useEffect(() => {
    if (orgLoadingStatus === LoadingStatus.Initial) {
      setOrgLoadingStatus(LoadingStatus.Loading);
      loadSecurityOrgs();
    }
  }, [
    orgLoadingStatus,
    loadSecurityOrgs,
  ]);

  const handleSort = (field: string) => {
    // toggle sort direction if same field is clicked
    if (field === sortField)
      setSortDirection((sort) =>
        sort === "ascending" ? "descending" : "ascending"
      );
    else setSortField(field);
  };

  return (
    <>
    <CSVLink
        data-testid="csv-export-button"
        data={sortedUserAccounts.map((ua) => {
          return {
            Name: ua.displayName,
            Email: ua.email,
            Roles: ua.profileNames,
          };
        })}
        filename={
          "User Accounts - " +
          securityOrgs.find((x) => x.organizationId === selectedSecurityOrgId)
            ?.name +
          " - " +
          new Date().toDateString() +
          ".csv"
        }
        className="btn btn-primary"
        style={{ float: "right" }}
      >
        Export to CSV
      </CSVLink>
      <h1>User Accounts</h1>

      {orgLoadingStatus === LoadingStatus.Loading ? (
        <Spinner animation="border">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
        <Form>
          <Form.Group as={Col} lg="3" controlId="formSecurityOrganization">
            <FormLabel
              data-testid="org-dropdown-label"
              text="Organization"
            />
            <Form.Control
              data-testid="org-dropdown"
              as="select"
              onChange={(event: any) => {
                handleChange(event.target.value);
              }}
              name="securityOrganizationId"
              value={selectedSecurityOrgId ?? ""}
              >
                <option
                  data-testid="org-dropdown-default"
                  disabled
                  value=""/>

                <option
                  data-testid="org-dropdown-all"
                  key={"All"}
                  value={"All"}>
                  {"<ALL>"}
                </option>
              {securityOrgs.sort((a: Organization, b: Organization) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                return 0;
              }).map((item: Organization, i: number) => {
                return (
                  <option
                    data-testid={"org-dropdown-" + i}
                    key={i}
                    value={item.organizationId}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            searchEmail();
            }}>
          <Form.Group as={Col} lg="3">
            <InputGroup>
              <InputGroup.Prepend>
                <Button
                  variant="secondary"
                  data-testid="email-search-button"
                  onClick={() => searchEmail()}
                  disabled={email.length < 5}
                >
                  Search
                </Button>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Email Search"
                data-testid="email-search-box"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </Form>
        </>
      )}
      <br />
      {userLoadingStatus === LoadingStatus.Loading ? (
        <Spinner animation="border">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : sortedUserAccounts.length > 0 ? (
        <Table striped bordered data-testid="users-table">
          <thead>
            <tr>
            {selectedSecurityOrgId === "" || selectedSecurityOrgId === "All" ?
              <th>
                <StatelessSortButton
                  field="orgName"
                  text="Organization"
                  sortField={sortField}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                />
              </th>
              :""}
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="displayName"
                  text="Name"
                  sortField={sortField}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="email"
                  text="Email"
                  sortField={sortField}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="profileNames"
                  text="Roles"
                  sortField={sortField}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="lastLogin"
                  text="Last Login"
                  sortField={sortField}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedUserAccounts.map((u) => (
              <tr key={`${u.userId}:${u.orgName}`} data-testid={"user-" + u.userId}>
                {selectedSecurityOrgId === "" || selectedSecurityOrgId === "All" ?
                <td data-testid="col-orgName">{u.orgName}</td>
                :""}
                <td data-testid="col-displayName">{u.displayName}</td>
                <td data-testid="col-email">{u.email}</td>
                <td data-testid="col-profileNames">
                  {u.profiles.map((x) => (
                    <div key={x.id}>{x.name}</div>
                  ))}
                </td>
                <td
                  data-testid="col-lastLogin"
                  style={getNumberOfDays(u.lastLogin) === null || getNumberOfDays(u.lastLogin)! > 60 ? 
                  {color: "red"}:
                  {}}>
                  {getLastLogin(u.lastLogin)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        userLoadingStatus !== LoadingStatus.Initial && (
          <Alert variant="dark">
            <div data-testid="userlist-message">
              {userLoadingStatus === LoadingStatus.Failed
                ? "Error loading user accounts"
                : "No User Accounts Found"}
            </div>
          </Alert>
        )
      )}
    </>
  );
}
