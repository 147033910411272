import { ImpoundedVehicleListInfo } from "../../Types/impoundedVehicleListInfo";
import { PagedResponse } from "../../Types/pagination";
import CallBffApi from "../../Utils/CallBff";

export const LOAD_IMPOUNDED_VEHICLE_LIST = "IMPOUNDVEHICLES/LOAD_LIST";
export const LOAD_IMPOUNDED_VEHICLE_LIST_SUCCESS =
  "IMPOUNDVEHICLES/LOAD_LIST_SUCCESS";
export const SET_LIST = "IMPOUNDVEHICLES/SET_LIST";
export const ADD_IMPOUNDMENT = "IMPOUNDVEHICLES/ADD_IMPOUNDMENT";
export const FILTER_STATUS = "IMPOUNDVEHICLES/FILTER_STATUS";
export const FILTER_STATUS_ALL = "IMPOUNDVEHICLES/FILTER_STATUS_ALL";
export const FILTER_STATUS_ALL_SOLD = "IMPOUNDVEHICLES/FILTER_STATUS_ALL_SOLD";
export const FILTER_STATUS_ALL_APPROVED =
  "IMPOUNDVEHICLES/FILTER_STATUS_ALL_APPROVED";
export const FILTER_LOCATION = "IMPOUNDEDVEHICLES/FILTER_LOCATION";
export const FILTER_LOCATION_ALL = "IMPOUNDEDVEHICLES/FILTER_LOCATION_ALL";
export const SORT = "IMPOUNDVEHICLES/SORT";
export const API_ERROR = "IMPOUNDVEHICLES/API_ERROR";
export const FILTER_BY_INVFORM_RECEIVED =
  "IMPOUNDVEHICLES/FILTER_BY_INVFORM_RECEIVED";
export const FILTER_WRONGFUL = "IMPOUNDVEHICLES/FILTER_WRONGFUL";
export const FILTER_TERM_ENDED = "IMPOUNDVEHICLES/FILTER_TERM_ENDED";
export const FILTER_LAW_ENFORCEMENT_FILE_MISSING =
  "IMPOUNDVEHICLES/FILTER_LAW_ENFORCEMENT_FILE_MISSING";
export const FILTER_DATE_RANGE_START =
  "IMPOUNDVEHICLES/FILTER_DATE_RANGE_START";
export const FILTER_DATE_RANGE_END = "IMPOUNDVEHICLES/FILTER_DATE_RANGE_END";
export const SET_SEARCH_VIN = "IMPOUNDVEHICLES/SET_SEARCH_VIN";
export const SET_PAGE = "IMPOUNDVEHICLES/SET_PAGE";
export const SET_PAGE_SIZE = "IMPOUNDVEHICLES/SET_PAGE_SIZE";
export const SET_TOTAL_COUNT = "IMPOUNDVEHICLES/SET_TOTAL_COUNT";
export const CLEAR_IMPOUNDED_VEHICLE_LIST_STATE =
  "IMPOUNDVEHICLES/CLEAR_IMPOUNDED_VEHICLE_LIST_STATE";

const setImpoundedVehicleList = (
  impoundedVehicleList: ImpoundedVehicleListInfo[]
) => {
  return {
    type: SET_LIST,
    impoundedVehicleList: impoundedVehicleList,
  };
};

async function loadAllImpoundedVehicleList(searchUrl: string) {
  var response = await CallBffApi(
    window.REACT_APP_API_BASEURL + `vehicleImpoundments/list?` + searchUrl
  );
  if (response.ok) {
    var result: PagedResponse = await response.json();
    return result.data;
  }
  return [];
}

const loadImpoundedVehicleList = (searchUrl: string) => {
  return async function (dispatch: any) {
    dispatch({ type: LOAD_IMPOUNDED_VEHICLE_LIST });

    var response = await CallBffApi(
      window.REACT_APP_API_BASEURL + `vehicleImpoundments/list?` + searchUrl
    );
    if (response.ok) {
      var result: PagedResponse = await response.json();

      if (result.pagination !== null) {
        dispatch({
          type: SET_TOTAL_COUNT,
          totalCount: result.pagination.totalCount,
          totalPages: result.pagination.totalPages,
          hasPrev: result.pagination.hasPrevious,
          hasNext: result.pagination.hasNext,
        });
      }
      dispatch({
        type: LOAD_IMPOUNDED_VEHICLE_LIST_SUCCESS,
        value: result.data,
      });
    } else dispatch({ type: API_ERROR, value: response.statusText });
  };
};

const loadImpoundedVehicleListExternal = (searchUrl: string) => {
  return async (dispatch: any) => {
    dispatch({ type: LOAD_IMPOUNDED_VEHICLE_LIST });
    var response = await CallBffApi(
      window.REACT_APP_API_BASEURL +
        `vehicleimpoundments/list/external?` +
        searchUrl
    );

    if (response.ok) {
      var result: PagedResponse = await response.json();
      if (result.pagination !== null) {
        dispatch({
          type: SET_TOTAL_COUNT,
          totalCount: result.pagination.totalCount,
          totalPages: result.pagination.totalPages,
          hasPrev: result.pagination.hasPrevious,
          hasNext: result.pagination.hasNext,
        });
      }
      dispatch({
        type: LOAD_IMPOUNDED_VEHICLE_LIST_SUCCESS,
        value: result.data,
      });
    } else dispatch({ type: API_ERROR, value: response.statusText });
  };
};

const addImpoundedVehicle = (impoundment: ImpoundedVehicleListInfo) => {
  return {
    type: ADD_IMPOUNDMENT,
    impoundment: impoundment,
  };
};

const filterStatus = (filter: string) => {
  return {
    type: FILTER_STATUS,
    filter: filter,
    page: 1,
  };
};

const filterInvFormReceived = (filter: boolean | undefined) => {
  return {
    type: FILTER_BY_INVFORM_RECEIVED,
    filter,
    page: 1,
  };
};

const filterStatusAll = () => {
  return {
    type: FILTER_STATUS_ALL,
    page: 1,
  };
};

const filterLocation = (filter: string) => {
  return {
    type: FILTER_LOCATION,
    filter: filter,
    page: 1,
  };
};

const filterLocationAll = () => {
  return {
    type: FILTER_LOCATION_ALL,
    page: 1,
  };
};

const filterWrongful = (filter: boolean) => {
  return {
    type: FILTER_WRONGFUL,
    filter: filter,
    page: 1,
  };
};

const filterTermEnded = (filter: boolean) => {
  return {
    type: FILTER_TERM_ENDED,
    filter: filter,
    page: 1,
  };
};

const filterLawEnforcementFileMissing = (filter: boolean) => {
  return {
    type: FILTER_LAW_ENFORCEMENT_FILE_MISSING,
    filter: filter,
    page: 1,
  };
};

const filterDateRangeStart = (filter: Date | string | undefined) => {
  return {
    type: FILTER_DATE_RANGE_START,
    filter: filter,
    page: 1,
  };
};

const filterDateRangeEnd = (filter: Date | string | undefined) => {
  return {
    type: FILTER_DATE_RANGE_END,
    filter: filter,
    page: 1,
  };
};

const sortList = (sortField: string, sortDirection: string) => {
  return {
    type: SORT,
    sortField: sortField,
    sortDirection: sortDirection,
    page: 1,
  };
};

const setSearchVin = (searchVin: string) => {
  return {
    type: SET_SEARCH_VIN,
    searchVin: searchVin,
    page: 1,
  };
};

const setPageNum = (pageNum: number) => {
  return {
    type: SET_PAGE,
    page: pageNum,
  };
};

const setPageSize = (size: number) => {
  return {
    type: SET_PAGE_SIZE,
    pageSize: size,
  };
};

const setTotalCount = (
  totalCount: number,
  totalPages: number,
  hasPrev: boolean,
  hasNext: boolean
) => {
  return {
    type: SET_TOTAL_COUNT,
    totalCount: totalCount,
    totalPages: totalPages,
    hasPrev: hasPrev,
    hasNext: hasNext,
  };
};

const ImpoundedVehicleListActions = {
  setImpoundedVehicleList,
  loadAllImpoundedVehicleList,
  loadImpoundedVehicleList,
  loadImpoundedVehicleListExternal,
  addImpoundedVehicle,
  filterStatus,
  filterStatusAll,
  filterLocation,
  filterLocationAll,
  sortList,
  filterInvFormReceived,
  filterWrongful,
  filterTermEnded,
  filterLawEnforcementFileMissing,
  filterDateRangeStart,
  filterDateRangeEnd,
  setPageNum,
  setPageSize,
  setSearchVin,
  setTotalCount,
};

export default ImpoundedVehicleListActions;
