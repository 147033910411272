import React, { useState } from "react";
import { Col, Overlay, Popover, Row } from "react-bootstrap";
import { VehicleImpoundmentFee } from "../../Types/VehicleImpoundmentFee";
import {
    formatMoneyWithDecimals,
  } from "../../Utils/format";
import {
    VehicleImpoundmentFeeLabel,
} from "../../Types/VehicleImpoundmentFeeType";

interface Props {
  fees: VehicleImpoundmentFee[];
}

function FeeBreakdown({ fees }: Props) {
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);

  const togglePopover = (event: any) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  return (
    <div
      onMouseEnter={togglePopover}
      onMouseLeave={togglePopover}
      data-testid="fee-breakdown"
      style={{ display: "inline" }}
    >
      <i className="fas fa-question-circle"></i>
      <Overlay
        show={showPopover}
        target={target}
        placement="top"
        containerPadding={20}
        transition={false}
      >
        <Popover
          id="feeBreakdownPopover"
          style={{ minWidth: "300px" }}
          data-testid="fee-breakdown-full"
        >
          <Popover.Title as="h3">Fee Breakdown</Popover.Title>
          <Popover.Content>
            {fees.map((fee: VehicleImpoundmentFee, i) => {
              return (
                <Row key={i}>
                  <Col md={8} style={{textAlign: "right"}}>
                      {VehicleImpoundmentFeeLabel[fee.feeType as keyof typeof VehicleImpoundmentFeeLabel]}:
                    </Col>
                  <Col md={4}>
                    {formatMoneyWithDecimals(fee.total)}
                  </Col>
                </Row>
              );
            })}
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}

export default FeeBreakdown;
