import React, { useState, useEffect } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "Redux/allActions";

interface Props {
  endpoint: string;
  searchField: string;
  placeholder?: string;
  onSearch: any;
  testId?: string;
  handleResult: any;
  garageKeeperId?: string;
  searchTermInRedux?: string;
  impoundmentListSearch?: boolean;
  requestListSearch?: boolean;
}

const clearButton = {
  marginLeft: "10px",
  padding: "0px 10px",
  border: "1px solid grey",
  borderRadius: "4px",
  cursor: "pointer",
  color: "white",
  backgroundColor: "var(--secondary)",
} as React.CSSProperties;

function SearchBox({
  endpoint,
  onSearch,
  placeholder = "",
  searchField,
  testId,
  handleResult,
  garageKeeperId,
  searchTermInRedux,
  impoundmentListSearch,
  requestListSearch,
}: Props) {
  const dispatch = useDispatch();
  const searchVinFileNo = useSelector(
    (state: RootStateOrAny) => state.requests.searchVINPoliceFile
  );
  const searchedVinFileNo = useSelector(
    (state: RootStateOrAny) => state.requests.searchedVINPoliceFile
  );
  const [resultCount, setResultCount] = useState<number | undefined>(0);
  const { totalCount } = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList
  );
  
  async function search(value: string): Promise<void> {
    let url = window.REACT_APP_API_BASEURL + endpoint;

    setResultCount(undefined);

    impoundmentListSearch &&
      dispatch(allActions.ImpoundedVehicleListActions.setSearchVin(value));

    requestListSearch &&
      dispatch(allActions.RequestActions.searchPoliceFileNumber(value));

    dispatch(allActions.RequestActions.searchVINPoliceFile(value));
    let body = {
      garageKeeperId,
      [searchField]: value,
    };
    try {
      value ? dispatch(allActions.RequestActions.searchedVINPoliceFile(true)) : dispatch(allActions.RequestActions.searchedVINPoliceFile(false));
      const response = await onSearch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const data = await response.json();
        setResultCount(data.length);
        handleResult(data);
        // Show success toast
      }
    } catch { }
    // show error toast
  }
  
  useEffect(() => {
    if(!searchedVinFileNo) {
      search("");
    }
  },[searchedVinFileNo]);

  const searchInputOnChange = (e: any) => {
    dispatch(allActions.RequestActions.searchVINPoliceFile(e.target.value));
    dispatch(allActions.RequestActions.searchedVINPoliceFile(false));
  };
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        search(searchVinFileNo);
      }}
      data-testid="search-form"
    >
      <InputGroup>
        <InputGroup.Prepend>
          <Button
            type="submit"
            variant="secondary"
            data-testid={testId ? testId + "-button" : undefined}
          >
            Search
          </Button>
        </InputGroup.Prepend>
        <FormControl
          data-testid={testId ? testId + "-input" : undefined}
          placeholder={placeholder}
          value={searchVinFileNo}
          onChange={(e) => searchInputOnChange(e)}
        />
        {searchedVinFileNo && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "2em",
            }}
          >
            <span style={{ marginRight: "10px" }}>
              Showing results for "{searchVinFileNo}"
            </span>
            {placeholder === "VIN/Police File #" ? (
              <span>({totalCount} total results)</span>
            ) : (
              <span>({resultCount} rows)</span>
            )}
            <div
              data-testid="clear-search"
              style={clearButton}
              onClick={() => {
                search("");
              }}
            >
              Clear
            </div>
          </div>
        )}
      </InputGroup>
    </Form>
  );
}

export default SearchBox;
