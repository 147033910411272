import RequestListContainer from "./RequestListContainer";
import RegularLayout from "Layouts/RegularLayout";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { defaultRequestListInfo, RequestListInfo } from "Types/requestListInfo";
import { formatDate } from "Utils/format";

interface Props {
  history: any;
}

export function getExportRequestsFilteredList(requestList: RequestListInfo[]) {
  return requestList.map((x: RequestListInfo) => ({
    "Garage Keeper Name": x.garageKeeperName?.toString(),
    "Police File#": x.policeFileNumber ?? "",
    Status: x.status.toString(),
    Type: x.type.toString(),
    Created: formatDate(x.createdOn?.toString()),
    "Last Updated": formatDate(x.lastUpdatedOn?.toString()),
    "Assigned To": x.assignedToUserName?.toString(),
  }));
}
export default function RequestListInternal({ history }: Props) {
  const [allRequestList, setAllRequestList] = useState([
    defaultRequestListInfo,
  ]);
  return (
    <RegularLayout title="Requests">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <CSVLink
          data={getExportRequestsFilteredList(allRequestList)}
          filename={
            "Garage Keeper Request List - " + new Date().toDateString() + ".csv"
          }
          className="btn btn-primary requestButton"
        >
          Export Request List
        </CSVLink>
      </div>
      <div className="card shadow mb-4 ">
        <div className="card-header py-3">
          <h4
            className="m-0 font-weight-bold text-success"
            data-testid="layout-header"
          >
            Requests
          </h4>
        </div>
        <div className="card-body">
          <RequestListContainer
            history={history}
            internal={true}
            setAllRequestList={setAllRequestList}
          />
        </div>
      </div>
    </RegularLayout>
  );
}
