import { GarageKeeperStatus } from "./garageKeeperStatus";
import { Location } from "./location";

export interface GarageKeeperListInfo {
  id: string;
  name: string;
  mailingAddress: string;
  mailingAddressCity: string;
  phoneNumber: string;
  lawEnforcementPhoneNumber: string;
  status: GarageKeeperStatus;
  canTowSemis: boolean;
  locations: Location[]
}

export const defaultGarageKeeperListInfo = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "",
  mailingAddress: "",
  mailingAddressCity: "",
  phoneNumber: "",
  lawEnforcementPhoneNumber: "",
  status: GarageKeeperStatus.Active,
  canTowSemis: false,
  locations: []
};