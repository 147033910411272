import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { Button, Modal } from "react-bootstrap";

type Props = {
  buttonText?: string;
  headerText?: string;
  children?: any;
  show: boolean;
  open(): any;
  close(): any;
  useIcon?: boolean;
  iconClassName?: string;
  iconTitle?: string;
  variant?: string;
  disabled?: boolean;
  dataTestId?: string
};

const ReusableModal = ({
  buttonText,
  headerText,
  children,
  show,
  open,
  close,
  useIcon = false,
  iconClassName,
  iconTitle,
  variant = "primary",
  disabled = false,
  dataTestId
}: Props) => {
  fontAwesomeLibrary.add(faTimes);

  return (
    <>
      {useIcon ? (
        <span
          style={{ fontSize: "25px" }}
          title={iconTitle}
          onClick={open}
          data-testid="modal-open-icon"
        >
          <i className={iconClassName} />
        </span>
      ) : (
        <Button
          variant={variant}
          className="font-weight-bold"
          onClick={open}
          data-testid="modal-open-button"
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
      <Modal
        size="lg"
        show={show}
        onHide={close}
        animation={false}
        dialogClassName="modal-90w"
        data-testid={dataTestId}
      >
        <Modal.Header>
          <h2>{headerText ?? buttonText}</h2>
          <FontAwesomeIcon
            data-testid="modal-close-button"
            onClick={close}
            icon="times"
            size="2x"
            className="text-gray-300"
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default ReusableModal;
