import { Col, Container, Row } from "react-bootstrap";
import { Attachment } from "Types/attachment";
import { formatDateTime } from "Utils/format";
import { useState } from "react";
import AttachmentChangeReason from "./AttachmentChangeReason";
import FormText from "Components/FormElements/FormText";
import { useEffect } from "react";
import { isEqual } from "Utils/dateUtils";
import { UserPermissions } from "Types/userInfo";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  downloadAttchment,
  isFilePreviewable,
  previewAttachment,
  retrieveAttachmentUrl,
} from "Utils/fileUtils";

interface Props {
  attachment: Attachment;
  idAttachmentInChange: string;
  setIdAttachmentInChange: Function;
  userName: string;
  updateCount: Function;
  readonly?: boolean;
}

function AttachmentElement(props: Props) {
  const [changeType, setChangeType] = useState("");
  const [description, setDescription] = useState(
    props.attachment.description ?? ""
  );
  const [downloadUrl, setDownloadUrl] = useState("");

  const userPermissions: UserPermissions = useSelector(
    (state: RootStateOrAny) => state.userInfo.userPermissions
  );

  const { readonly = false } = props;

  const AttachmentBox = {
    border: "1px solid grey",
    borderRadius: "10px",
    fontSize: "small",
    marginBottom: "5px",
  } as React.CSSProperties;

  const header = {
    borderBottom: "1px solid grey",
    backgroundColor: "Gainsboro",
    borderRadius: "10px",
  } as React.CSSProperties;

  const subtext = {
    color: "grey",
    fontSize: "small",
  } as React.CSSProperties;

  const body = {
    margin: "10px 0px 10px 0px",
    whiteSpace: "pre-wrap",
  } as React.CSSProperties;

  const buttons = {
    display: "inline-block",
    cursor: "pointer",
    float: "right",
    margin: "2px",
  } as React.CSSProperties;

  useEffect(() => {
    setDescription(props.attachment.description ?? "");
  }, [props.attachment.description, props.idAttachmentInChange, changeType]);

  const getAttachment = async (e: any, download: boolean) => {
    e.preventDefault();

    let url = downloadUrl;

    if (url === "") {
      url = await retrieveAttachmentUrl(props.attachment);
      setDownloadUrl(url);
    }

    if (download) downloadAttchment(url, props.attachment.fileName);
    else previewAttachment(url);
  };

  return (
    <Container
      style={AttachmentBox}
      data-testid={`attachment-element-${props.attachment.id}`}
    >
      <Row style={header}>
        <Col md="10">
          <div>
            Added - {props.attachment.author}
            <span>&nbsp;</span>
            <span style={subtext}>
              {formatDateTime(props.attachment.createdOn)}
            </span>
          </div>
          {(props.attachment.author !== props.attachment.lastEditor ||
            !isEqual(
              props.attachment.createdOn,
              props.attachment.lastUpdatedOn
            )) && (
              <div>
                Last Updated - {props.attachment.lastEditor}
                <span>&nbsp;</span>
                <span data-testid="last-updated-date" style={subtext}>
                  {formatDateTime(props.attachment.lastUpdatedOn)}
                </span>
              </div>
            )}
        </Col>
        <Col md="2">
          {
            !readonly &&
            <>
              {userPermissions.canDeleteAttachments && (
                <div
                  style={buttons}
                  onClick={() => {
                    props.setIdAttachmentInChange(props.attachment.id);
                    setChangeType("DELETE");
                  }}
                  data-testid={"delete-button"}
                >
                  <i className="fas fa-trash-alt delete-button" />
                </div>
              )}
              <div
                style={buttons}
                onClick={() => {
                  props.setIdAttachmentInChange(props.attachment.id);
                  setChangeType("PUT");
                }}
                data-testid={"edit-button"}
              >
                <i className="far fa-edit edit-button" />
              </div>
            </>
          }
        </Col>
      </Row>
      <Row style={body}>
        {props.idAttachmentInChange === props.attachment.id &&
          changeType === "PUT" ? (
          <FormText
            data-testid={"description-field"}
            field="description"
            placeholder={"(optional) Description:"}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        ) : (
          <Col>
            {props.attachment.description && props.attachment.description !== ""
              ? props.attachment.description
              : "<No Description>"}
          </Col>
        )}
      </Row>
      <Row style={body}>
        <Col>
          <div>{props.attachment.fileName}</div>
        </Col>
      </Row>
      <Row style={body}>
        <Col md="auto">
          {isFilePreviewable(props.attachment.fileName) ? (
            <a
              onClick={(e) => getAttachment(e, false)}
              href={props.attachment.id}
              data-testid="preview-link"
              title={"Open in a new tab"}
            >
              Preview in a new tab
            </a>
          ) : (
            <div data-testid="preview-not-available">
              {"Preview not available"}
            </div>
          )}
        </Col>
        <Col>
          <a
            onClick={(e) => getAttachment(e, true)}
            href={props.attachment.id}
            data-testid="download-link"
            title={"Download file"}
          >
            Download
          </a>
        </Col>
      </Row>
      <Row style={body}>
        {props.idAttachmentInChange === props.attachment.id && (
          <AttachmentChangeReason
            id={props.idAttachmentInChange}
            changeType={changeType}
            updateCount={props.updateCount}
            onCancel={props.setIdAttachmentInChange}
            description={description}
            attachment={props.attachment}
            setIdAttachmentInChange={props.setIdAttachmentInChange}
            userName={props.userName}
          />
        )}
      </Row>
    </Container>
  );
}

export default AttachmentElement;
