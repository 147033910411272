import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Overlay, Popover, Row } from "react-bootstrap";
import CommentFormatter from "./CommentFormatter";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { FormattingTypes } from "../../Types/commentFormatting";

function CommentFormatHelp() {
  fontAwesomeLibrary.add(faQuestionCircle);

  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);

  const togglePopover = (event: any) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  return (
    <div
      data-testid="format-help-icon"
      onMouseEnter={togglePopover}
      onMouseLeave={togglePopover}
    >
      <FontAwesomeIcon
        icon="question-circle"
        size="lg"
        style={{
          alignSelf: "center",
          justifySelf: "center",
          marginRight: "20px",
          cursor: "help",
        }}
      />
      <Overlay
        show={showPopover}
        target={target}
        placement="left"
        containerPadding={20}
        transition={false}
      >
        <Popover
          id="commentFormattingPopover"
          style={{ minWidth: "400px" }}
          data-testid="format-help-popover"
        >
          <Popover.Title as="h3">Comment Formatting</Popover.Title>
          <Popover.Content>
            <Row>
              <Col md={4}>
                <b>
                  <u>Name</u>
                </b>
              </Col>
              <Col md={5}>
                <b>
                  <u>Example</u>
                </b>
              </Col>
              <Col md={3}>
                <b>
                  <u>Output</u>
                </b>
              </Col>
            </Row>
            {[
              ...Object.keys(FormattingTypes).map((key) => {
                return FormattingTypes[key];
              }),
              { name: "Combination", startSymbol: "_*", endSymbol: "*_" },
            ].map((rule) => {
              let example = rule.startSymbol + "sample" + rule.endSymbol;
              return (
                <Row key={rule.name}>
                  <Col md={4}>{rule.name}</Col>
                  <Col md={5}>{example}</Col>
                  <Col md={3}>
                    <CommentFormatter text={example} />
                  </Col>
                </Row>
              );
            })}
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CommentFormatHelp;
