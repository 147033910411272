import { Route, Switch, useHistory } from "react-router-dom";
import DashboardInternal from "Components/Dashboard/DashboardInternal";
import GarageKeeperList from "Components/GarageKeepers/GarageKeeperList";
import ImpoundedVehiclesList from "Components/ImpoundedVehicles/ImpoundedVehiclesList";
import ImpoundedVehicleDetail from "Components/ImpoundedVehicles/ImpoundedVehicleDetail";
import UserProfile from "Components/UserProfile/UserProfile";
import GarageKeeperDetailInternal from "Components/GarageKeepers/GarageKeeperDetailInternal";
import ExpiredInsuranceList from "Components/ExpiredInsuranceList/ExpiredInsuranceList";
import AdminPage from "Components/Admin/AdminPage";
import { RootStateOrAny, useSelector } from "react-redux";
import { UserType } from "Types/userInfo";
import RequestListInternal from "Components/Requests/RequestListInternal";
import RequestDetail from "Components/Requests/RequestDetail";
import RequestListByEntity from "Components/Requests/RequestListByEntity";
import NotFound from "Components/NotFound/NotFound";
import ManualLawEnforcementSubmission from "Components/LawEnforcement/ManualLawEnforcementSubmission";
import RequireAttentionCommentsListContainer from "Components/Comments/RequireAttentionCommentsListContainer";
import { isIntenalUser } from "Utils/userUtils";

function AdminRoutes() {
  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const history = useHistory();

  if (isIntenalUser(userType)) {
    return (
      <Switch>
        <Route exact path="/" component={DashboardInternal} />
        <Route path="/ExpiredInsurance" component={ExpiredInsuranceList} />
        <Route
          path="/GarageKeepers/:id/:tabKey?"
          component={GarageKeeperDetailInternal}
        />
        <Route path="/GarageKeepers" component={GarageKeeperList} />
        <Route
          path="/ImpoundedVehicles/:id"
          component={ImpoundedVehicleDetail}
        />
        <Route path="/ImpoundedVehicles">
          <ImpoundedVehiclesList history={history} />
        </Route>
        <Route path="/RequestDetail" component={RequestDetail} />
        <Route path="/RequestList">
          <RequestListInternal history={history} />
        </Route>
        <Route path="/RequestHistory" component={RequestListByEntity} />
        <Route path="/UserProfile" component={UserProfile} />
        <Route path="/Admin" component={AdminPage} />
        <Route
          path="/leManSubNotification"
          component={ManualLawEnforcementSubmission}
        />
        <Route
          path="/RequireAttentionComments/:filter?"
          component={RequireAttentionCommentsListContainer}
        />
        <Route component={NotFound} />
      </Switch>
    );
  } else {
    return <></>;
  }
}

export default AdminRoutes;
