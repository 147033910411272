import { CommentCount } from "./comment";
import { RequestType } from "./requestType";

export interface Request {
  id: string;
  garageKeeperId: string;
  entityId: string | null;
  type: RequestType;
  status: RequestStatus;
  commentCount: CommentCount;
  numberOfAttachments: number;
  attachmentNames: string[];
  createdOn: Date;
  lastUpdatedOn: Date;
  assignedToUserId?: string;
  assignedToUserName?: string;
  policeFileNumber?: string;
  vinNumber?: string;
}

export enum RequestStatus {
  New = "New",
  AssignedToSgi = "AssignedToSgi",
  AssignedToGk = "AssignedToGk",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export const RequestStatusLabel: {
  [key in RequestStatus]: string;
} = {
  [RequestStatus.New]: "New",
  [RequestStatus.AssignedToSgi]: "Assigned To SGI",
  [RequestStatus.AssignedToGk]: "Assigned To Garage Keeper",
  [RequestStatus.Completed]: "Completed",
  [RequestStatus.Cancelled]: "Cancelled",
};

export const defaultRequest: Request = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperId: "00000000-0000-0000-0000-000000000000",
  entityId: null,
  type: RequestType.Impoundment,
  status: RequestStatus.New,
  commentCount: {
    internal: 0,
    external: 0,
  },
  numberOfAttachments: 0,
  attachmentNames: [],
  createdOn: new Date(),
  lastUpdatedOn: new Date()
};
