export interface gkFYICounts {
  [index: string]: any;
  garageKeeper: number;
  contacts: number;
  towFleet: number;
}

export const defaultGkFYICounts: gkFYICounts = {
  garageKeeper: 0,
  contacts: 0,
  towFleet: 0,
};
