import { toast, ToastOptions } from "react-toastify";

export enum ToastType {
  Success,
  Error,
}

export function doToast(
  message: string | React.ReactNode,
  toastType: ToastType,
  autoClose: number = 3000
) {
  let toastOptions: ToastOptions = { autoClose };

  switch (toastType) {
    case ToastType.Success:
      toastOptions = { ...toastOptions, type: toast.TYPE.SUCCESS };
      break;
    case ToastType.Error:
      toastOptions = { ...toastOptions, type: toast.TYPE.ERROR };
      break;
  }

  toast(message, toastOptions);
}
