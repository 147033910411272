import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { GkPermissions, UserPermissions } from "Types/userInfo";
import { SetNewGk } from "Routing/GlobalRouter";

interface Props {
  account: any;
  handleLogout: any;
}

function UserInfoDropdown({ account, handleLogout }: Props) {
  const gkProfile = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );
  const { userPermissions }: { userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );
  const [showGkList, setShowGkList] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <li className="dropdown no-arrow">
      <a
        href="/"
        className="nav-link dropdown-toggle"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="mr-2 d-none d-sm-inline align-middle text-gray-600 small">
          {`${account?.name} ${
            userPermissions.isGarageKeeper && gkProfile
              ? `- ${gkProfile.name}`
              : ""
          }`}
        </div>
        <FontAwesomeIcon icon="user-circle" size="2x" color="black" />
      </a>
      {/* Dropdown - User Information */}
      <div
        className="dropdown-menu dropdown-menu-right shadow"
        aria-labelledby="userDropdown"
        onMouseLeave={() => setShowGkList(false)}
      >
        <Link className="dropdown-item" to="/UserProfile">
          <FontAwesomeIcon
            icon="user"
            className="fa-sm fa-fw mr-2 text-gray-400"
          />
          Profile
        </Link>
        {userPermissions.gkPermissions.length > 1 && (
          <>
            <div className="dropdown-divider"></div>
            <div
              className="dropdown-item"
              onMouseOver={() => setShowGkList(true)}
            >
              <FontAwesomeIcon
                icon="warehouse"
                className="fa-sm fa-fw mr-2 text-gray-400"
              />
              Switch GK
            </div>
          </>
        )}
        {showGkList &&
          userPermissions.gkPermissions.map((p: GkPermissions) => {
            return (
              <>
                <div className="dropdown-divider"></div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    SetNewGk(dispatch, p);
                    localStorage.setItem(
                      "workAsGk",
                      JSON.stringify({
                        userEmail: (account?.idTokenClaims as any)?.email,
                        workAsGk: p,
                      })
                    );
                    setShowGkList(false);
                  }}
                >
                  {p.name}
                </div>
              </>
            );
          })}
        <div className="dropdown-divider"></div>
        <div className="dropdown-item" onClick={handleLogout}>
          <FontAwesomeIcon
            icon="sign-out-alt"
            className="fa-sm fa-fw mr-2 text-gray-400"
          />
          Logout
        </div>
      </div>
    </li>
  );
}

export default UserInfoDropdown;
