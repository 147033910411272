import LawEnforcementAttachment from "Components/LawEnforcement/LawEnforcementAttachment";
import RegularLayout from "Layouts/RegularLayout";

interface Props {
  history: any;
}

export default function ManualLawEnforcementSubmission({ history }: Props) {
  return (
    <RegularLayout title="Manual Law Enforcement Submission">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          {"Manual Law Enforcement Submission"}
        </h1>
      </div>
      <br />
      <br />
      <LawEnforcementAttachment />
    </RegularLayout>
  );
}
