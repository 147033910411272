import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import CustomForm from "Components/FormElements/CustomForm";
import allActions from "Redux/allActions";
import { defaultContact, Contact, CommPref, ContactType } from "Types/contact";
import { doToast, ToastType } from "Utils/toastUtils";
import MainOfficeContactForm, {
  ValidateMainOfficeContact,
} from "./MainOfficeContactForm";
import OtherContactForm, { ValidateOtherContact } from "./OtherContactForm";
import LawEnforcementContactForm from "./LawEnforcementContactForm";

interface Props {
  submit: any;
  cancel?: any;
  data?: Contact;
}

export default function ContactForm({ data, submit, cancel }: Props) {
  const editMode = data ? true : false;

  const originalData: Contact = data
    ? {
        ...data,
        // MainOffice requires a preferred method, so if one isn't selected, default to email
        communicationPreference:
          !data.communicationPreference &&
          data.contactType === ContactType.MainOffice
            ? CommPref.Email
            : data.communicationPreference,
        phoneNumber: data.phoneNumber ?? "",
        faxNumber: data.faxNumber ?? "",
        emailAddress: data.emailAddress ?? "",
      }
    : defaultContact;

  const [contact, setContact] = useState<Contact>(originalData);

  const [saved, setSaved] = useState(false);

  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );
  const dispatch = useDispatch();

  const handleUpdate = (event: any) => {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    if (editMode && !saved) {
      setContact(originalData);
    }
    cancel?.();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) return false;

    if (!ValidateMainOfficeContact(contact)) return false;

    if (!ValidateOtherContact(contact)) return false;

    var contactToSubmit = {
      ...contact,
      garageKeeperId: garageKeeper.id,
      phoneNumber: contact.phoneNumber?.replace(/\D/g, ""),
      faxNumber: contact.faxNumber?.replace(/\D/g, ""),
    };

    if (contactToSubmit.phoneNumber === "") {
      contactToSubmit.phoneNumber = undefined;
    }
    if (contactToSubmit.faxNumber === "") {
      contactToSubmit.faxNumber = undefined;
    }
    if (contactToSubmit.emailAddress === "") {
      contactToSubmit.emailAddress = undefined;
    }

    let requestUrl = `${window.REACT_APP_API_BASEURL}contacts`;

    if (editMode) requestUrl += "/" + contactToSubmit.id;

    try {
      const response = await submit(requestUrl, {
        method: editMode ? "PUT" : "POST",
        body: JSON.stringify(contactToSubmit),
      });

      if (response.ok) {
        if (!editMode) {
          const result = await response.json();
          dispatch(allActions.Contact.addContact(result));
          setContact(defaultContact);
        } else {
          dispatch(allActions.Contact.updateContact(contactToSubmit));
          dispatch(allActions.GKProfile.updateContact(contactToSubmit));
        }
        setSaved(true);
        doToast("Contact saved successfully", ToastType.Success);
        return true;
      } else {
        doToast("An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  return (
    <CustomForm
      onSubmit={handleSubmit}
      cancel={handleCancel}
      edit={editMode}
      openInEditMode={true}
    >
      {contact.contactType === ContactType.MainOffice && (
        <MainOfficeContactForm contact={contact} onChange={handleUpdate} />
      )}
      {contact.contactType === ContactType.LawEnforcement && (
        <LawEnforcementContactForm contact={contact} onChange={handleUpdate} />
      )}
      {contact.contactType === ContactType.Other && (
        <OtherContactForm contact={contact} onChange={handleUpdate} />
      )}
    </CustomForm>
  );
}
