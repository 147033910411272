import { TowingVehicle } from "../../Types/towingVehicle";
import CallBffApi from "../../Utils/CallBff";
import { doToast, ToastType } from "../../Utils/toastUtils";

export const SET_TOWVEHICLES = "TOWVEHICLE/SET_TOWVEHICLE";

export const ADD_TOWVEHICLE = "TOWVEHICLE/ADD_TOWVEHICLE";
export const UPDATE_TOWVEHICLE = "TOWVEHICLE/UPDATE_TOWVEHICLE";

export const DELETE_TOWVEHICLE_SUCCESS = "TOWVEHICLE/DELETE_TOWVEHICLE_SUCCESS";
export const DEACTIVATE_TOWING_VEHICLE_SUCCESS =
  "TOWVEHICLE/DEACTIVATE_TOWING_VEHICLE_SUCCESS";

const towingVehicleActions = {
  setTowingVehicles: (towingVehicles: TowingVehicle[]) => {
    return {
      type: SET_TOWVEHICLES,
      value: towingVehicles,
    };
  },

  addTowingVehicle: (towingVehicle: TowingVehicle) => {
    return {
      type: ADD_TOWVEHICLE,
      value: towingVehicle,
    };
  },

  updateTowingVehicle: (towingVehicle: TowingVehicle) => {
    return {
      type: UPDATE_TOWVEHICLE,
      value: towingVehicle,
    };
  },

  deleteTowingVehicle: (towingVehicleId: string, deleteReason: string) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}towingvehicles/${towingVehicleId}?deleteReason=${deleteReason}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        doToast("Towing vehicle deleted successfully", ToastType.Success);

        return dispatch({
          type: DELETE_TOWVEHICLE_SUCCESS,
          towingVehicleId: towingVehicleId,
        });
      }

      doToast("Error deleting towing vehicle", ToastType.Error);
    };
  },

  deactivateTowingVehicle: (id: string) => {
    return async function (dispatch: any) {
      const response = await CallBffApi(
        window.REACT_APP_API_BASEURL +
          `towingVehicles/external/${id}/deactivate`,
        {
          method: "PUT",
        }
      );

      if (response.ok) {
        doToast("Vehicle deactivated", ToastType.Success);
        return dispatch({
          type: DEACTIVATE_TOWING_VEHICLE_SUCCESS,
          id,
        });
      } else {
        doToast("Could not deactivate vehicle", ToastType.Error);
      }
    };
  },
};

export default towingVehicleActions;
