import DetailLayout from "Layouts/DetailLayout";
import RequestListContainer from "./RequestListContainer";

interface Props {
  history: any;
}

export default function RequestListByEntity({ history }: Props) {
  return (
    <DetailLayout
      title="Request History"
      header={history.location.state.impoundedVehicleVin}
      subheader="Request History"
    >
      <RequestListContainer
        garageKeeperId={history.location.state.garageKeeperId}
        entityId={history.location.state.entityId}
        history={history}
      />
    </DetailLayout>
  );
}
