import SearchBox from "Components/Search/SearchBox";
import { ImpoundedVehicleListInfo } from "Types/impoundedVehicleListInfo";
import { RootStateOrAny, useSelector } from "react-redux";
import { doToast, ToastType } from "Utils/toastUtils";
import { defaultGarageKeeper } from "Types/garageKeeper";
import { ImpoundedVehicleState } from "Redux/impoundedvehicles/ImpoundedVehicleListReducer";

interface Props {
  garageKeeperId?: string;
  external?: boolean;
}

export function getVehicleImpoundmentsSearchUrl(
  statusFilter: string,
  sortField: string,
  sortDirection: string,
  invFormFilter: boolean | undefined,
  wrongfulFilter: boolean,
  termEndedFilter: boolean,
  page: number,
  pageSize: number,
  searchVin: string,
  lawEnforcementFileMissingFilter: boolean,
  garageKeeperId?: string,
  locationId?: string,
  dateRangeFilterStart?: Date,
  dateRangeFilterEnd?: Date
): string {
  var generateURL = `${
    garageKeeperId ? `searchGarageKeeperId=${garageKeeperId}` : ""
  }${
    statusFilter !== "All"
      ? statusFilter === "InCompound" ||
        statusFilter === "ApprovedAll" ||
        statusFilter === "SoldAll"
        ? `&filterAggregateStatus=${statusFilter}`
        : `&filterStatus=${statusFilter}`
      : ""
  }&sort=${encodeURIComponent(sortField)}&sortDirection=${encodeURIComponent(
    sortDirection
  )}${
    invFormFilter
      ? `&filterInventoryFormMissing=${encodeURIComponent(invFormFilter)}`
      : ""
  }${
    wrongfulFilter
      ? `&filterWrongful=${encodeURIComponent(wrongfulFilter)}`
      : ""
  }${
    termEndedFilter
      ? `&filterTermEnded=${encodeURIComponent(termEndedFilter)}`
      : ""
  }${
    searchVin ? `&searchVin=${encodeURIComponent(searchVin)}` : ""
  }&pageNumber=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(
    pageSize
  )}${
    locationId
      ? locationId !== "ALL"
        ? `&searchLocationId=${encodeURIComponent(locationId)}`
        : ""
      : ""
  }${
    lawEnforcementFileMissingFilter
      ? `&filterIsLawEnforcementFileMissing=${encodeURIComponent(
          lawEnforcementFileMissingFilter
        )}`
      : ""
  }${
    dateRangeFilterStart
      ? `&filterDateRangeStart=${encodeURIComponent(
          dateRangeFilterStart.toString()
        )}`
      : ""
  }${
    dateRangeFilterEnd
      ? `&filterDateRangeEnd=${encodeURIComponent(
          dateRangeFilterEnd.toString()
        )}`
      : ""
  }`;

  return generateURL;
}

function ImpoundmentSearch({ garageKeeperId, external = false }: Props) {
  const {
    sortField,
    sortDirection,
    statusFilter,
    invFormFilter,
    wrongfulFilter,
    termEndedFilter,
    pageNumber,
    pageSize,
    searchVin,
    lawEnforcementFileMissingFilter,
    locationFilter,
  } = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList
  ) as ImpoundedVehicleState;

  var searchUrl = getVehicleImpoundmentsSearchUrl(
    statusFilter,
    sortField,
    sortDirection,
    invFormFilter,
    wrongfulFilter,
    termEndedFilter,
    pageNumber,
    pageSize,
    searchVin,
    lawEnforcementFileMissingFilter,
    locationFilter
  );

  // Checks that the ID field is populated when flagged as external
  const onSubmit =
    external && (!garageKeeperId || garageKeeperId === defaultGarageKeeper.id)
      ? () => doToast("An error occurred", ToastType.Error)
      : "";

  return (
    <SearchBox
      endpoint={searchUrl}
      onSearch={onSubmit}
      handleResult={(data: ImpoundedVehicleListInfo[]) => {}}
      placeholder="VIN/Police File #"
      searchField="vin"
      testId="search"
      garageKeeperId={garageKeeperId}
      searchTermInRedux={searchVin}
      impoundmentListSearch={true}
    />
  );
}

export default ImpoundmentSearch;
