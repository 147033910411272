import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import GKListReducer from "./garagekeepers/GKListReducer";
import GKReducer from "./garagekeepers/GKReducer";
import ContactListReducer from "./contacts/ContactListReducer";
import ImpoundedVehicleListReducer from "./impoundedvehicles/ImpoundedVehicleListReducer";
import { combineReducers } from "redux";
import { locationReducer } from "./locations/locationReducer";
import { insurancePolicyReducer } from "./insurancePolicies/insurancePolicyReducer";
import { towingVehicleReducer } from "./towingVehicles/towingVehicleReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import CommentsReducer from "./comments/CommentsReducer";
import ExpiredInsuranceReducer from "./expiredInsurance/ExpiredInsuranceReducer";
import AttachmentReducer from "./attachments/AttachmentReducer";
import { vehicleImpoundmentReducer } from "./vehicleImpoundment/vehicleImpoundmentReducer";
import userInfoReducer from "./userInfo/userInfoReducer";
import RequestReducer from "./requests/requestReducer";
import RequireCommentsReducer from "./comments/RequiredCommentsReducer";

export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools



  const appReducer = combineReducers({
    GKList: GKListReducer,
    GKProfile: GKReducer,
    Contacts: ContactListReducer,
    ImpoundedVehicleList: ImpoundedVehicleListReducer,
    locations: locationReducer,
    insurancePolicies: insurancePolicyReducer,
    towingVehicles: towingVehicleReducer,
    dashboard: dashboardReducer,
    comments: CommentsReducer,
    ExpiredInsurance: ExpiredInsuranceReducer,
    attachments: AttachmentReducer,
    vehicleImpoundment: vehicleImpoundmentReducer,
    userInfo: userInfoReducer,
    requests: RequestReducer,
    RequireComments: RequireCommentsReducer
  });

  const rootReducer = (state, action) => {
    if(action.type === 'ROOT_CHANGE_USER_TYPE') {
      //undefined as the first argument should trigger all reducers to return their initial state 
      return appReducer(undefined, action)
    }

    return appReducer(state, action)
  }

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
}
