import { FilterButton } from "Components/ImpoundedVehicles/Elements/FilterButtons";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import RequestActions from "Redux/requests/requestActions";
import { GarageKeeper } from "Types/garageKeeper";
import { RequestStatus, RequestStatusLabel } from "Types/request";
import { RequestType, RequestTypeLabel } from "Types/requestType";
import { UserType } from "Types/userInfo";
import allActions from "../../Redux/allActions";

interface Props {
  history: any;
}

//Broke this logic out so we can use it to disable the gk filtering when the filter dropdown is hidden
export function IsRequestListGkFilterActive(
  userType: UserType,
  historyPath: string
): Boolean {
  if (userType === UserType.Admin && historyPath !== "/RequestHistory") {
    return true;
  } else {
    return false;
  }
}

export function IsRequestListRecentlyCompletedFilterActive(
  userType: UserType,
  statusfilter: RequestStatus
): Boolean {
  if (
    userType === UserType.GarageKeeper &&
    statusfilter === RequestStatus.Completed
  ) {
    return true;
  } else {
    return false;
  }
}

export default function RequestListFilterButtons({ history }: Props) {
  const { gkFilter, gkName, statusFilter, typeFilter, recentFilter } =
    useSelector((state: RootStateOrAny) => state.requests);

  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const gkList = useSelector((state: RootStateOrAny) => state.GKList.gkList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      gkList.length === 0 &&
      IsRequestListGkFilterActive(userType, history.location.pathname)
    ) {
      dispatch(allActions.GKList.loadGarageKeeperList());
    }
  }, [dispatch, gkList.length, userType, history.location.pathname]);

  return (
    <>
      <span>&nbsp;</span>
      {IsRequestListGkFilterActive(userType, history.location.pathname) && (
        <>
          <Dropdown className="mb-2">
            <Dropdown.Toggle data-testid="request-gk-filter">
              Garage Keeper: {gkFilter === "ALL" ? "<All>" : gkName}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ height: "50vh", overflowY: "scroll" }}>
              <Dropdown.Item
                onClick={() => dispatch(RequestActions.filterGk("ALL", "All"))}
                data-testid="gk-ALL"
              >
                {"<All>"}
              </Dropdown.Item>
              {gkList
                .sort((gk1: GarageKeeper, gk2: GarageKeeper) => {
                  if (gk1.name < gk2.name) return -1;
                  if (gk1.name > gk2.name) return 1;
                  return 0;
                })
                .map((gk: GarageKeeper) => {
                  return (
                    <Dropdown.Item
                      key={gk.id}
                      data-testid={`gk-${gk.id}`}
                      onClick={() =>
                        dispatch(RequestActions.filterGk(gk.id, gk.name))
                      }
                    >
                      {gk.name}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
          <span>&nbsp;</span>
        </>
      )}
      <Dropdown className="mb-2">
        <Dropdown.Toggle data-testid="request-status-filter">
          Status:{" "}
          {statusFilter === "ALL"
            ? "<All>"
            : RequestStatusLabel[statusFilter as keyof typeof RequestStatus]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              dispatch(RequestActions.filterStatus("ALL"));
              dispatch(RequestActions.filterRecent(false));
            }}
            data-testid="status-ALL"
          >
            {"<All>"}
          </Dropdown.Item>
          {Object.keys(RequestStatus)
            .sort()
            .map((status: string) => {
              return (
                <Dropdown.Item
                  key={status}
                  data-testid={`status-${status}`}
                  onClick={() => {
                    dispatch(RequestActions.filterStatus(status));
                    if (status !== statusFilter)
                      dispatch(RequestActions.filterRecent(false));
                  }}
                >
                  {RequestStatusLabel[status as keyof typeof RequestStatus]}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      <span>&nbsp;</span>
      <Dropdown className="mb-2">
        <Dropdown.Toggle data-testid="request-type-filter">
          Type:{" "}
          {typeFilter === "ALL"
            ? "<All>"
            : RequestTypeLabel[typeFilter as keyof typeof RequestType]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => dispatch(RequestActions.filterType("ALL"))}
            data-testid="type-ALL"
          >
            {"<All>"}
          </Dropdown.Item>
          {Object.keys(RequestType)
            .sort()
            .map((type: string) => {
              return (
                <Dropdown.Item
                  key={type}
                  data-testid={`type-${type}`}
                  onClick={() => dispatch(RequestActions.filterType(type))}
                >
                  {RequestTypeLabel[type as keyof typeof RequestType]}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      <span>&nbsp;</span>
      {IsRequestListRecentlyCompletedFilterActive(userType, statusFilter) && (
        <FilterButton
          id="recentlyCompletedFilter"
          testId="request-recentlyCompleted-filter"
          text="show completed last 7 days"
          onClick={() => dispatch(RequestActions.filterRecent(!recentFilter))}
          checked={recentFilter}
        />
      )}
    </>
  );
}
