import React from 'react';
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Modal } from "react-bootstrap";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import Loader from "../../Components/Loader/Loader";
import { Attachment } from "../../Types/attachment";
import { EntityType } from "../../Types/EntityType";
import AttachmentInput from "../Attachments/AttachmentInput";
import AttachmentElement from "../Attachments/AttachmentElement";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import allActions from "../../Redux/allActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RequestListInfo } from "../../Types/requestListInfo";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { UserType } from "../../Types/userInfo";

interface Props {
    entityId: string;
    entityType: EntityType;
    count: number;
    associatedRequests: RequestListInfo[];
    associatedAttachments: Attachment[];
    readonly?: boolean;
}

const ImpoundedVehicleAttachmentContainer = (props: Props) => {
    library.add(faPaperclip);

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) as AccountInfo | null;
    const [show, setShow] = useState(false);
    const userType = useSelector((state: RootStateOrAny) => state.userInfo.userType);

    const [idAttachmentInChange, setIdAttachmentInChange] = useState("");

    const [attachmentCount, setAttachmentCount] = useState(props.count);

    const attachmentList = useSelector(
        (state: RootStateOrAny) => state.attachments.attachments
    );

    const { readonly = false } = props;

    const dispatch = useDispatch();

    const requestUrl = `${(window as any).REACT_APP_API_BASEURL
        }attachments/entityId=${props.entityId}?entityType=${props.entityType}`;

    const updateCount = (increment: boolean, difference: number) => {
        if (increment) setAttachmentCount(attachmentCount + difference);
        else setAttachmentCount(attachmentCount - difference);
    };

    const associatedRequestAttachments = props.associatedRequests.reduce((group: any, request: RequestListInfo) => {
        group[request.type.toString()] = group[request.type] ?? [];
        group[request.type.toString()].push(...props.associatedAttachments.filter(a => a.entityId === request.id));

        return group;
    }, {});

    const handleClose = () => {
        setIdAttachmentInChange("");
        setShow(false);
    };

    const attachmentListStyle = {
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "10px 10px 10px 10px",
        maxHeight: "400px",
    } as React.CSSProperties;

    const countStyle = {
        position: "absolute",
        left: "10px",
        top: "20px",
        fontSize: "10px",
    } as React.CSSProperties;

    const buttonStyle = {
        cursor: "pointer",
        fontSize: "25px",
        position: "relative",
        padding: "0px 5px 0px 5px",
    } as React.CSSProperties;

    return (
        <span data-testid="maintest">
            <span
                style={buttonStyle}
                className="edit-button"
                onClick={() => setShow(true)}
                data-testid="attachmentButton"
                title="Attachments"
            >
                <FontAwesomeIcon icon="paperclip" />
                {attachmentCount > 0 && (
                    <Badge
                        style={countStyle}
                        pill
                        data-testid="attachmentCount"
                        variant="danger"
                    >
                        {attachmentCount}
                    </Badge>
                )}
            </span>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                animation={false}
                dialogClassName="modal-90w"
                data-testid="attachment-modal"
            >
                <Modal.Header>
                    <h2>Attachments</h2>
                </Modal.Header>
                <Modal.Body>
                    <Loader
                        url={requestUrl}
                        onLoadComplete={(result: Attachment[]) =>
                            dispatch(allActions.AttachmentActions.setAttachments(result))
                        }
                    >
                        {userType === UserType.Admin &&
                            <div style={{ paddingTop: "10px" }}>
                                <AttachmentInput
                                    entityId={props.entityId}
                                    entityType={props.entityType}
                                    userName={account?.name!}
                                    updateCount={updateCount}
                                />
                            </div>
                        }
                        {(attachmentList.length > 0 || props.associatedAttachments.length > 0) ? (
                            <div style={attachmentListStyle}>
                                {Object.keys(associatedRequestAttachments).map((key) => {
                                    return (
                                        <div key={`request-group-${key}`}>
                                            <h4>{key} request attachments</h4>
                                            {associatedRequestAttachments[key].map((file: Attachment) => {
                                                return (
                                                    <AttachmentElement
                                                        attachment={file}
                                                        key={file.id}
                                                        userName={account?.name!}
                                                        idAttachmentInChange={idAttachmentInChange}
                                                        setIdAttachmentInChange={setIdAttachmentInChange}
                                                        updateCount={updateCount}
                                                        readonly={readonly}
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                <h4>Impoundment attachments</h4>
                                {attachmentList.map((file: Attachment) => {
                                    return (
                                        <AttachmentElement
                                            attachment={file}
                                            key={file.id}
                                            userName={account?.name!}
                                            idAttachmentInChange={idAttachmentInChange}
                                            setIdAttachmentInChange={setIdAttachmentInChange}
                                            updateCount={updateCount}
                                            readonly={readonly}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <div style={{ padding: "10px" }}>
                                <Alert variant="dark">
                                    <i>No attachments found</i>
                                </Alert>
                            </div>
                        )}
                    </Loader>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </span>
    );
}

export default ImpoundedVehicleAttachmentContainer;