import BulletinListAdmin from "Components/Bulletins/BulletinListAdmin";
import { Tab, Tabs } from "react-bootstrap";
import DetailLayout from "Layouts/DetailLayout";
import CallBffApi from "Utils/CallBff";
import EditFeeRates from "./EditFeeRates";
import HistoricalFeeRates from "./HistoricalFeeRates";
import FeedbackList from "../Feedback/FeedbackList";
import UserAccountList from "./UserAccountList";
import { BulletinType } from "Types/bulletin";

export default function AdminPage(props: any) {
  return (
    <DetailLayout header="Admin Functions" title="Admin Functions" subheader="">
      <Tabs
        defaultActiveKey="feeRates"
        transition={false}
        style={{ marginBottom: "1vh" }}
      >
        <Tab
          eventKey="feeRates"
          title="Edit Fee Rates"
          data-testid="editFeeRatesTab"
        >
          <EditFeeRates submit={CallBffApi} />
        </Tab>
        <Tab
          eventKey="historicalFeeRates"
          title="Historical Fee Rates"
          data-testid="historicalFeeRatesTab"
        >
          <HistoricalFeeRates />
        </Tab>
        <Tab
          eventKey="bulletinList"
          title="Bulletins"
          data-testid="bulletinsListTab"
        >
          <BulletinListAdmin
            submit={CallBffApi}
            bulletinType={BulletinType.Bulletin}
          />
        </Tab>
        <Tab
          eventKey="policyList"
          title="Policies"
          data-testid="policiesListTab"
        >
          <BulletinListAdmin
            submit={CallBffApi}
            bulletinType={BulletinType.Policy}
          />
        </Tab>
        <Tab
          eventKey="manualList"
          title="Manuals"
          data-testid="manualsListTab"
        >
          <BulletinListAdmin
            submit={CallBffApi}
            bulletinType={BulletinType.Manual}
          />
        </Tab>
        <Tab
          eventKey="feedbackList"
          title="Feedback"
          data-testid="bulletinsListTab"
        >
          <FeedbackList />
        </Tab>
        <Tab
          eventKey="authUserAccountsList"
          title="User Accounts"
          data-testid="authUserAccountsListTab"
        >
          <UserAccountList />
        </Tab>
      </Tabs>
    </DetailLayout>
  );
}
