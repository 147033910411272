export interface GkCounts {
  [index: string]: any;
  active: number;
  deactivated: number;
  suspended: number;
}

export const defaultGkCounts: GkCounts = {
  active: 0,
  deactivated: 0,
  suspended: 0,
};
