import CallBffApi from "Utils/CallBff";

const getLocation = async (id: string) => {
    const baseUrl = `${(window as any).REACT_APP_API_BASEURL}locations/${id}`;
    const response = await CallBffApi(
        `${baseUrl}`,
        {
            method: "GET",
            body: null,
        }
    );

    if (response.ok) {
        var result = await response.json();
        return result;
    }

    return undefined;
}

export { getLocation }