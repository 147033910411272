import { ImpoundedVehicleStatus } from "./impoundedVehicleStatus";

export interface ImpoundedVehicleListInfo {
  [index: string]: any;
  vehicleImpoundmentId: string;
  vin: string;
  termStartDate: Date | string;
  termEndDate: Date | string;
  daysImpounded: number;
  garageKeeperName: string;
  address: string;
  inventoryFormMissing: boolean;
  status: ImpoundedVehicleStatus;
  wrongful: boolean;
  isLawEnforcementFileMissing: boolean;
  policeFileNumber?: string;
}

export const defaultImpoundedVehicleListInfo: ImpoundedVehicleListInfo = {
  vehicleImpoundmentId: "00000000-0000-0000-0000-000000000000",
  vin: "",
  termStartDate: "",
  termEndDate: "",
  daysImpounded: 0,
  garageKeeperName: "",
  address: "",
  inventoryFormMissing: false,
  status: ImpoundedVehicleStatus.Active,
  wrongful: false,
  isLawEnforcementFileMissing: false,
};
