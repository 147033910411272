import React from "react";
import { Form } from "react-bootstrap";
import FormLabel from "./FormLabel";

interface Props {
  testId?: string;
  type?: string;
  className?: string;
  label?: string;
  field: string;
  value: any;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  invalidMessage?: string;
  onChange?: any;
  onBlur?: any;
  isValid?: boolean;
  disabled?: boolean;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  as?: any;
}

function FormText({
  testId,
  type = "text",
  label = "",
  className = "",
  placeholder,
  field,
  required = false,
  pattern = ".*",
  invalidMessage = "",
  value = "",
  isValid = true,
  onChange,
  onBlur,
  disabled = false,
  min,
  max,
  step,
  as,
}: Props) {
  return (
    <>
      {label && (
        <FormLabel
          testId={testId ? testId + "-label" : undefined}
          text={label}
          required={required}
          phoneNumber={field.toLowerCase().includes("phonenumber") ? value : ""}
        />
      )}
      <Form.Control
        data-testid={testId}
        as={as}
        type={type}
        className={className}
        placeholder={placeholder ?? label}
        name={field}
        value={value ?? ""}
        pattern={pattern}
        onChange={onChange}
        onBlurCapture={onBlur}
        required={required}
        isInvalid={!isValid}
        disabled={disabled}
        max={max}
        min={min}
        step={step}
      />
      <Form.Control.Feedback
        type="invalid"
        data-testid={testId ? testId + "-error" : undefined}
      >
        {!disabled &&
          (label || invalidMessage) &&
          label + (value === "" && required ? " is required" : invalidMessage)}
      </Form.Control.Feedback>
    </>
  );
}

export default FormText;
