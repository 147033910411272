import DetailLayout from "Layouts/DetailLayout";
import { Col, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import Loader from "Components/Loader/Loader";
import { GarageKeeper } from "Types/garageKeeper";
import LocationList from "Components/Location/LocationList";
import ContactInfoList from "Components/Contact/ContactInfoList";
import TowingVehicleList from "Components/TowingVehicles/TowingVehicleList";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  formatCustomerNumber,
  formatPhoneNumber,
  formatPostalCode,
} from "Utils/format";
import InsurancePoliciesList from "Components/InsurancePolicies/InsurancePoliciesList";
import GarageKeeperImpoundedVehicleList from "Components/ImpoundedVehicles/GarageKeeperImpoundedVehicles";
import allActions from "Redux/allActions";
import CommentContainer from "Components/Comments/CommentContainer";
import AttachmentContainer from "Components/Attachments/AttachmentContainer";

import ReusableModal from "Components/Modal/ReusableModal";
import GarageKeeperFormInternal from "Forms/GarageKeeperForm/GarageKeeperFormInternal";
import CallBffApi from "Utils/CallBff";
import {
  expiringSoonDaysThreshold,
  InsurancePolicy,
  InsuranceState,
  InsurancePolicyTypes,
  InsurancePolicyStatus,
} from "Types/insurancePolicy";
import { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { RequestStatus } from "Redux/requestStatus";
import { EntityType } from "Types/EntityType";
import PhoneEmail from "Utils/PhoneEmail";
import { CommPref } from "Types/contact";
import { UserPermissions, UserType } from "Types/userInfo";

interface Props {
  history: any;
}

function getInsurancePolicyState(
  insurancePolicy: InsurancePolicy
): InsuranceState {
  let today = new Date();
  let expiringSoonDate = new Date();
  expiringSoonDate.setDate(
    expiringSoonDate.getDate() + expiringSoonDaysThreshold
  );
  let expDate = new Date(insurancePolicy.expiryDate);
  if (expDate <= today) {
    return InsuranceState.Expired;
  } else if (expDate <= expiringSoonDate) {
    return InsuranceState.Expiring;
  } else {
    return InsuranceState.Active;
  }
}

export function getGarageKeeperInsuranceState(
  insurancePolicies: InsurancePolicy[]
): InsuranceState {
  let gkInsuranceState: InsuranceState = InsuranceState.Active;
  let policyState = "";
  let currentPolicyTypeState: InsuranceState | undefined;

  if (insurancePolicies.length === 0) {
    return InsuranceState.Expired;
  }

  for (var policyType of Object.keys(InsurancePolicyTypes)) {
    currentPolicyTypeState = undefined;

    for (var policy of insurancePolicies) {
      if (
        policy.insuranceType !== policyType ||
        policy.status !== InsurancePolicyStatus.Active
      ) {
        continue;
      }

      policyState = getInsurancePolicyState(policy);

      if (policyState === InsuranceState.Active) {
        currentPolicyTypeState = InsuranceState.Active;
        break;
      } else if (policyState === InsuranceState.Expiring) {
        currentPolicyTypeState = InsuranceState.Expiring;
      } else if (policyState === InsuranceState.Expired) {
        if (currentPolicyTypeState !== InsuranceState.Expiring) {
          currentPolicyTypeState = InsuranceState.Expired;
        }
      }
    }

    if (
      currentPolicyTypeState === undefined ||
      currentPolicyTypeState === InsuranceState.Expired
    ) {
      gkInsuranceState = InsuranceState.Expired;
      break;
    } else if (currentPolicyTypeState === InsuranceState.Expiring) {
      gkInsuranceState = InsuranceState.Expiring;
    }
  }

  return gkInsuranceState;
}

export default function GarageKeeperDetailInternal(props: Props) {
  let match = useRouteMatch<{ id: string; tabKey: string }>();

  fontAwesomeLibrary.add(faExclamationTriangle, faExclamationCircle);

  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const insurancePolicies = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.insurancePolicies
  );

  const insurancePoliciesStatus = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.status
  );

  const [gkInsuranceState, setGkInsuranceState] = useState<
    InsuranceState | undefined
  >(undefined);

  const [displayInsuranceNotification, setDisplayInsuranceNotification] =
    useState<boolean>(false);

  const { userType } = useSelector((state: RootStateOrAny) => state.userInfo);

  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  useEffect(() => {
    if (
      insurancePoliciesStatus !== RequestStatus.LOADING &&
      displayInsuranceNotification
    ) {
      setGkInsuranceState(getGarageKeeperInsuranceState(insurancePolicies));
    }

    if (insurancePoliciesStatus === RequestStatus.LOADING) {
      setDisplayInsuranceNotification(true);
    }
  }, [
    displayInsuranceNotification,
    insurancePolicies,
    insurancePoliciesStatus,
  ]);

  const dispatch = useDispatch();

  const gkid = match.params.id;

  const requestUrl = useMemo(() => {
    const url = `${window.REACT_APP_API_BASEURL}garagekeepers/${gkid}`;
    return url;
  }, [gkid]);

  const mailingAddress = `${garageKeeper?.mailingAddress?.street} ${garageKeeper?.mailingAddress?.city
    }, ${garageKeeper?.mailingAddress?.province} ${formatPostalCode(
      garageKeeper?.mailingAddress?.postalCode
    )}`;

  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  return (
    <DetailLayout
      title={garageKeeper?.name ?? ""}
      header={garageKeeper?.name ?? ""}
      subheader="Garage Keeper"
      notification={
        gkInsuranceState === InsuranceState.Expiring ? (
          <div
            data-testid="insurance-notification-expiring"
            style={{ color: "orange" }}
          >
            <div>Expiring</div>
            <div>Insurance</div>
          </div>
        ) : gkInsuranceState === InsuranceState.Expired ? (
          <div
            data-testid="insurance-notification-expired"
            style={{ color: "red" }}
          >
            <div>Expired</div>
            <div>Insurance</div>
          </div>
        ) : (
          ""
        )
      }
    >
      <Tabs
        defaultActiveKey="profile"
        activeKey={match.params.tabKey}
        transition={false}
        style={{ marginBottom: "1vh" }}
        onSelect={(k) => {
          if (k) {
            const url = props.history.location.pathname.split("/");
            props.history.replace(`/${url[1]}/${gkid}/${k}`);
          }
        }}
      >
        <Tab eventKey="profile" title="Profile">
          <Loader
            url={requestUrl}
            onLoadComplete={(result: GarageKeeper) =>
              dispatch(allActions.GKProfile.setGK(result))
            }
          >
            {userType === UserType.Admin &&
              <ReusableModal
                buttonText="Edit Garage Keeper"
                headerText="Edit Garage Keeper"
                open={() => {
                  displayForm();
                }}
                close={hideForm}
                show={showForm}
              >
                <GarageKeeperFormInternal
                  submit={CallBffApi}
                  cancel={hideForm}
                  data={garageKeeper}
                />
              </ReusableModal>
            }
            <div className="mb-4"></div>
            <ListGroup>
              <ProfileRow
                title="Customer Number"
                value={formatCustomerNumber(garageKeeper.customerNumber)}
                testId="customer-number"
              />
              <ProfileRow
                title="Legal Entity"
                value={garageKeeper.legalEntity}
                testId="legal-entity"
              />
              <ProfileRow
                title="Business Name"
                value={garageKeeper.name}
                testId="business-name"
              />
              <ProfileRow
                title="Primary Contact Name"
                value={garageKeeper.primaryContactPerson}
                testId="primary-contact"
              />
              <ProfileRow
                title="Tow Heavy Vehicles (over 4,600KG/ 10,000lbs)"
                value={garageKeeper.canTowSemis ? "Yes" : "No"}
                testId="can-tow-semis"
              />
              <ProfileRow
                title="Main Office Phone"
                value={formatPhoneNumber(garageKeeper.mainOfficePhoneNumber)}
                testId="main-office-phone"
                type="phone"
              />
              <ProfileRow
                title="Law Enforcement Phone"
                value={formatPhoneNumber(
                  garageKeeper.lawEnforcementPhoneNumber
                )}
                testId="law-enforcement-phone"
                type="phone"
              />
              {garageKeeper.mainOfficeCommunicationPreference ===
                CommPref.Email && (
                  <ProfileRow
                    title="Main Office Email"
                    value={garageKeeper.mainOfficeEmailAddress}
                    testId="main-office-email"
                    type="email"
                  />
                )}
              {garageKeeper.mainOfficeCommunicationPreference ===
                CommPref.Fax && (
                  <ProfileRow
                    title="Main Office Fax"
                    value={formatPhoneNumber(garageKeeper.mainOfficeFaxNumber)}
                    testId="main-office-fax"
                  />
                )}
              <ProfileRow
                title="Status"
                value={garageKeeper.status}
                testId="status"
              />
              <ProfileRow
                title="Mailing Address"
                value={mailingAddress}
                testId="mailing-address"
              />
            </ListGroup>
            <br />
            <AttachmentContainer
              entityId={gkid}
              entityType={EntityType.GarageKeeper}
              count={garageKeeper.attachmentCount}
              readOnly={!userPermissions.canUpdateGarageKeeper}
            />
            &nbsp;
            <CommentContainer
              id={gkid}
              count={garageKeeper.commentCount}
              entityType={EntityType.GarageKeeper}
              orgId={gkid}
              readOnly={!userPermissions.canUpdateGarageKeeper}
            />{" "}
          </Loader>
        </Tab>
        <Tab eventKey="contact" title="Contact">
          <ContactInfoList garageKeeperId={gkid} />
        </Tab>
        <Tab eventKey="compounds" title="Compounds">
          <LocationList garageKeeperId={gkid} history={props.history} />
        </Tab>
        <Tab eventKey="towVehicles" title="Towing Fleet">
          <TowingVehicleList garageKeeperId={gkid} />
        </Tab>
        <Tab eventKey="impoundVehicles" title="Impound Vehicles">
          <GarageKeeperImpoundedVehicleList
            garageKeeperId={gkid}
            history={props.history}
          />
        </Tab>
        <Tab
          eventKey="insuranceinfo"
          title={
            gkInsuranceState === "Expiring" ? (
              <div data-testid="insurance-tab-expiring">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="1x"
                  color="orange"
                />
                <span> Insurance Info</span>
              </div>
            ) : gkInsuranceState === "Expired" ? (
              <div data-testid="insurance-tab-expired">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="1x"
                  color="red"
                />
                <span> Insurance Info</span>
              </div>
            ) : (
              <div>Insurance Info</div>
            )
          }
        >
          <InsurancePoliciesList garageKeeperId={gkid} />
        </Tab>
      </Tabs>
    </DetailLayout>
  );
}

interface RowProps {
  title: string;
  value: string | number;
  testId: string;
  type?: string;
}

const ProfileRow = ({ title, value, testId, type }: RowProps) => (
  <ListGroup.Item>
    <Row>
      <Col md={3} className={"font-weight-bold"}>
        {title}
      </Col>
      <Col data-testid={testId}>
        {type ? <PhoneEmail value={value} type={type} /> : value}
      </Col>
    </Row>
  </ListGroup.Item>
);
