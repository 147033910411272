import ListPagination from "Components/ListPagination/ListPagination";
import { useEffect } from "react";
import { Alert, Container } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import allActions from "Redux/allActions";
import { ImpoundedVehicleState } from "Redux/impoundedvehicles/ImpoundedVehicleListReducer";
import { ScreenSize, getMediaQuery } from "Utils/mediaQueries";
import { getVehicleImpoundmentsSearchUrl } from "./Elements/ImpoundmentSearch";
import ImpoundedVehicleCardList from "./ImpoundedVehicleCardList";
import ImpoundedVehicleTable from "./ImpoundedVehicleTable";

interface Props {
  garageKeeperId?: string;
  profileView?: boolean;
  external?: boolean;
  setAllVehicleImpoundList?: any;
}

export default function ImpoundedVehicleListContainer({
  garageKeeperId,
  profileView,
  external,
  setAllVehicleImpoundList,
}: Props) {
  const {
    list: vehicleImpoundmentList,
    sortField,
    sortDirection,
    statusFilter,
    invFormFilter,
    wrongfulFilter,
    termEndedFilter,
    pageNumber,
    pageSize,
    totalPages,
    hasPrev,
    hasNext,
    totalCount,
    searchVin,
    lawEnforcementFileMissingFilter,
    locationFilter,
    dateRangeFilterStart,
    dateRangeFilterEnd,
  } = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList
  ) as ImpoundedVehicleState;
  // LG and bigger
  const bigScreen = useMediaQuery(getMediaQuery(ScreenSize.LG, false, true));
  const history = useHistory();
  const dispatch = useDispatch();

  const filteredImpoundmentList = vehicleImpoundmentList;

  var searchUrl = getVehicleImpoundmentsSearchUrl(
    statusFilter,
    sortField,
    sortDirection,
    invFormFilter,
    wrongfulFilter,
    termEndedFilter,
    pageNumber,
    pageSize,
    searchVin,
    lawEnforcementFileMissingFilter,
    garageKeeperId,
    locationFilter,
    dateRangeFilterStart,
    dateRangeFilterEnd
  );

  useEffect(() => {
    if (external) {
      dispatch(
        allActions.ImpoundedVehicleListActions.loadImpoundedVehicleListExternal(
          searchUrl
        )
      );
    } else {
      dispatch(
        allActions.ImpoundedVehicleListActions.loadImpoundedVehicleList(
          searchUrl
        )
      );

      if (setAllVehicleImpoundList !== undefined) {
        Promise.resolve(
          allActions.ImpoundedVehicleListActions.loadAllImpoundedVehicleList(
            searchUrl + "&getAllList=true"
          )
        )
          .then((result) => {
            setAllVehicleImpoundList(result);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [
    sortField,
    sortDirection,
    statusFilter,
    invFormFilter,
    wrongfulFilter,
    termEndedFilter,
    pageNumber,
    pageSize,
    searchVin,
    locationFilter,
    dateRangeFilterStart,
    dateRangeFilterEnd,
    searchUrl,
    external,
    dispatch,
  ]);

  useEffect(() => {
    if (!profileView)
      dispatch(allActions.ImpoundedVehicleListActions.filterLocationAll());
  }, [profileView, dispatch]);

  return (
    <Container fluid style={{ minHeight: "15em" }}>
      {bigScreen ? (
        <ImpoundedVehicleTable
          impoundments={vehicleImpoundmentList}
          history={history}
          sortDirection={sortDirection}
          sortField={sortField}
          profileView={profileView}
          garageKeeperId={garageKeeperId}
          external={external}
        />
      ) : (
        <ImpoundedVehicleCardList
          impoundments={filteredImpoundmentList}
          history={history}
          profileView={profileView}
          garageKeeperId={garageKeeperId}
          external={external}
        />
      )}
      {totalPages > 0 ? (
        <ListPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          hasPrev={hasPrev}
          hasNext={hasNext}
          totalCount={totalCount}
          setPageNum={allActions.ImpoundedVehicleListActions.setPageNum}
          setPageSize={allActions.ImpoundedVehicleListActions.setPageSize}
        />
      ) : (
        <Alert variant="dark">
          <i>No Impoundments match current filter</i>
        </Alert>
      )}
    </Container>
  );
}
