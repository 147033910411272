import { BrowserRouter as Router } from "react-router-dom";

import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalInstance } from "Utils/initializeMsal";
import React from "react";
import { Provider } from "react-redux";
import configureStore from "Redux/configureStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "Utils/authConfig";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import GlobalRouter from "Routing/GlobalRouter";
import ScrollToTop from "Components/ScrollToTop/ScrollToTop";

declare global {
  interface Window {
    REACT_APP_API_BASEURL: string;
    REACT_APP_BASEURL: string;
    REACT_APP_APPINSIGHTS_INSTR_KEY: string;
    REACT_APP_B2C_CLIENT_ID: string;
    REACT_APP_B2C_TENANT_ID: string;
    REACT_APP_B2C_SIGNUPSIGNIN_POLICY: string;
    REACT_APP_B2C_FORGOTPASSWORD_POLICY: string;
    REACT_APP_B2C_EDITPROFILE_POLICY: string;
    REACT_APP_B2C_POLICY_AUTHORITY_ROOT: string;
    REACT_APP_B2C_AUTHORITYDOMAIN: string;
    REACT_APP_B2C_BFF_API_SCOPES: string;
  }
}

function App() {
  const store = configureStore();

  return (window as any)["runConfig"] ? (
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Provider store={store}>
          <ToastContainer />
          <Router>
            <ScrollToTop />
            <MsalProvider instance={msalInstance}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
              >
                <AuthenticatedTemplate>
                  <GlobalRouter />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <h1>Error, you are not signed-in.</h1>
                </UnauthenticatedTemplate>
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </Router>
        </Provider>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  ) : (
    <div>Loading... </div>
  );
}

export default App;
