import CallBffApi from "Utils/CallBff";
import { Request } from "Types/request";
import { EntityType } from "Types/EntityType";

const getAttacmentTypes = async (type: string): Promise<string[] | undefined> => {
  const baseUrl = `${(window as any).REACT_APP_API_BASEURL}requests/GetAttachmentTypes`;
  const response = await CallBffApi(
    `${baseUrl}?type=${type}`,
    {
      method: "GET",
      body: null,
    }
  );

  if (response.ok) {
    var result = await response.json();
    return result;
  }

  return undefined;
}

const createRequest = async (id: string, request: Request): Promise<Request | undefined> => {
  const baseUrl = `${(window as any).REACT_APP_API_BASEURL}requests`;
  const response = await CallBffApi(
    baseUrl,
    {
      method: "POST",
      body: JSON.stringify({ ...request, id: id }),
    }
  );

  if (response.ok) {
    var result = await response.json();
    return result
  }

  return undefined;
}

const getRequest = async (id: string, type: EntityType): Promise<string[] | undefined> => {
  const baseUrl = `${(window as any).REACT_APP_API_BASEURL}requests/${id}`;
  const response = await CallBffApi(
    `${baseUrl}?entityType=${type}`,
    {
      method: "GET",
      body: null,
    }
  );

  if (response.ok) {
    var result = await response.json();
    return result;
  }

  return undefined;
}

const assignRequest = async (id: string, entityId: string): Promise<void> => {
  const baseUrl = `${(window as any).REACT_APP_API_BASEURL}requests/${id}/assign`;
  const response = await CallBffApi(
    `${baseUrl}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        id: id,
        entityId: entityId
      }),
    }
  );

  if (response.ok) {
    return;
  }

  return;
}


export { 
  getAttacmentTypes, 
  createRequest, 
  getRequest, 
  assignRequest 
}