import FormLabel from "Components/FormElements/FormLabel";
import FormText from "Components/FormElements/FormText";
import { Form, ListGroup } from "react-bootstrap";
import { validateFileType } from "Utils/fileUtils";
import Accordion from "../../Components/Accordion/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface Props {
  control: boolean;
  selectedFiles: any[];
  setSelectedFiles(event: any): void;
  descriptionList: any[];
  setDescriptionList(event: any): void;
  inputKey: any;
  setInputKey(event: any): void;
  removeFile(index: number) : void;
}

export function ImpoundmentAttachment({
  control,
  selectedFiles,
  setSelectedFiles,
  descriptionList,
  setDescriptionList,
  inputKey,
  setInputKey,
  removeFile
}: Props) {
  const onFileChangeHandler = (e: any) => {
    let files: File[] = Array.from(e.target.files);
    let validatedFiles: File[] = [];

    files.forEach((f) => {
      if (validateFileType(f) && !selectedFiles.find(x=>x.name === f.name)) 
        validatedFiles.push(f);
    });    

    setSelectedFiles([...selectedFiles, ...validatedFiles]);
    setDescriptionList([...descriptionList, ...Array(validatedFiles.length).fill("")]);
    
    e.target.value = "";
  };

  const onDescriptionChangeHandler = (e: any, index: number) => {
    let newDescriptionList: any = [...descriptionList];
    newDescriptionList.splice(index, 1, e.target.value);
    setDescriptionList(newDescriptionList);
  };

  const handleRemoveAttachmentClick = (index: number) => {
    removeFile(index);
  }

  return (
    <>
      <Accordion title="Attachments" defaultShow control={control}>
        <div style={{ padding: "10px" }}>
          <div>
            <div className="inputWrapper">
              Click here to select a file, or drag and drop
              <input
                type="file"
                name="fileUpload"
                id="impoundmentFileUpload"
                className="fileInput"
                data-testid="impoundment-attachment-input"
                multiple
                onChange={(e) => onFileChangeHandler(e)}
                key={inputKey}
              />
            </div>
          </div>
          <div>
            {selectedFiles.map((f: File, index: number) => {
              return (
                <ListGroup.Item
                  key={`selected-file-${index}`}
                  data-testid={`selected-impoundment-file-list-item-${index}`}
                >
                  <FormLabel
                    text={f.name}
                    testId={`selected-impoundment-file-label-${index}`}
                  />
                  <span
                    style={{"cursor": 'pointer', "marginLeft": "5px"}}
                    onClick={() => handleRemoveAttachmentClick(index)}>
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  <FormText
                    field="description"
                    placeholder={"(optional) Description:"}
                    value={descriptionList[index]}
                    onChange={(e: any) => onDescriptionChangeHandler(e, index)}
                    testId={`impoundment-description-field-${index}`}
                  />
                </ListGroup.Item>
              );
            })}
          </div>
        </div>
      </Accordion>
    </>
  );
}
