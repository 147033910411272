import { RequestType } from "./requestType";
import { RequestStatus } from "./request";

export interface RequestListInfo {
  id: string;
  garageKeeperName?: string;
  type: RequestType;
  status: RequestStatus;
  createdOn: Date;
  lastUpdatedOn: Date;
  assignedToUserId?: string;
  assignedToUserName?: string;
  policeFileNumber?: string;
}

export const defaultRequestListInfo: RequestListInfo = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperName: "",
  type: RequestType.Impoundment,
  status: RequestStatus.AssignedToSgi,
  createdOn: new Date(),
  lastUpdatedOn: new Date(),
  assignedToUserId: "00000000-0000-0000-0000-000000000000",
  assignedToUserName: "",
  policeFileNumber: "",
};
