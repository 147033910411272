import ReusableModal from "Components/Modal/ReusableModal";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  TermEndedFilter,
  WrongfulFilter,
  InventoryFormFilter,
} from "./FilterButtons";
import StatusFilter from "./StatusFilter";

interface Props {
  garageKeeperId?: string;
}

function FilterModal(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <ReusableModal
      open={() => {
        setShowModal(true);
      }}
      close={() => {
        setShowModal(false);
      }}
      headerText="Impoundment Filters"
      buttonText="Filters"
      show={showModal}
    >
      <Container fluid>
        <StatusFilter />
        <InventoryFormFilter />
        <WrongfulFilter />
        <TermEndedFilter />
      </Container>
    </ReusableModal>
  );
}

export default FilterModal;
