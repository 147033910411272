export interface ImpoundmentType {
  category: ImpoundmentCategory;
  type: string;
  termLength: number;
}

export enum ImpoundmentCategory {
  DrinkingAndDriving = "DrinkingAndDriving",
  Section280 = "Section280",
  UnauthorizedDriver = "UnauthorizedDriver",
  NotImpoundable = "NotImpoundable",
  Default = "",
}

export enum ImpoundmentCategoryLabel {
  DrinkingAndDriving = "Drinking And Driving",
  Section280 = "Section 280",
  UnauthorizedDriver = "Unauthorized Driver",
  NotImpoundable = "Not Impoundable",
  Default = "",
}
