import { GarageKeeper } from "Types/garageKeeper";
import DetailLayout from "Layouts/DetailLayout";
import TowingVehicleList from "./TowingVehicleList";

interface Props {
  garageKeeper: GarageKeeper;
}

export default function TowingVehicleListExternal({ garageKeeper }: Props) {
  return (
    <DetailLayout
      title="Towing Fleet"
      header={garageKeeper.name}
      subheader="Towing Fleet"
    >
      <TowingVehicleList garageKeeperId={garageKeeper.id} />
    </DetailLayout>
  );
}
