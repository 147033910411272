import { VehicleImpoundment } from "../../Types/vehicleImpoundment";

export const SET_VEHICLEIMPOUNDMENT =
  "VEHICLEIMPOUNDMENT/SET_VEHICLEIMPOUNDMENT";

export const CLEAR_VEHICLEIMPOUNDMENT =
  "VEHICLEIMPOUNDMENT/CLEAR_VEHICLEIMPOUNDMENT";

const vehicleImpoundmentActions = {
  setVehicleImpoundment: (vehicleImpoundment: VehicleImpoundment) => {
    return {
      type: SET_VEHICLEIMPOUNDMENT,
      value: vehicleImpoundment,
    };
  },
  clearVehicleImpoundment: () => {
    return {
      type: CLEAR_VEHICLEIMPOUNDMENT,
    };
  },
};

export default vehicleImpoundmentActions;
