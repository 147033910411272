import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import Footer from "Components/Footer/Footer";
import SideBar from "Components/SideBar/SideBar";
import TopBar from "Components/TopBar/TopBar";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useAccount, useMsal, AuthenticatedTemplate } from "@azure/msal-react";

//Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { getMediaQuery, ScreenSize } from "Utils/mediaQueries";
import "@fortawesome/fontawesome-svg-core/styles.css";
interface Props {
  title: string;
  children?: any;
}

function RegularLayout(props: Props) {
  fontAwesomeLibrary.add(faAngleUp);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});

  const bigScreen = useMediaQuery(getMediaQuery(ScreenSize.LG, false, true));
  const tinyScreen = useMediaQuery(getMediaQuery(ScreenSize.SM, true, false));
  const navbarWidth = useMemo(() => {
    if (bigScreen) return "14rem";
    else if (tinyScreen) return "0rem";
    else return "6.5rem";
  }, [bigScreen, tinyScreen]);
  const mediumSize = useMemo(() => {
    return !bigScreen && !tinyScreen;
  }, [bigScreen, tinyScreen]);

  function handleLogout(e: any) {
    instance.logoutRedirect({
      account: account,
    });
  }
  return (
    <>
      <AuthenticatedTemplate>
        <Helmet>
          <title>{props.title} - GarageKeeper</title>
          <meta
            content="width=device-width, initial scale=1.0"
            name="viewport"
          />
          <meta content="" name="keywords" />
          <meta content="" name="description" />
          <link
            href="/vendor/fontawesome-free/css/all.min.css"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
            rel="stylesheet"
          />

          {/* Custom styles for this template */}
        </Helmet>
        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Content Wrapper */}
          <SideBar width={navbarWidth} mediumSize={mediumSize} />
          <div
            id="content-wrapper"
            className="d-flex flex-column"
            style={{ marginLeft: navbarWidth, overflow: "hidden" }}
          >
            {/* Main Content */}
            <div id="content" className="mb-4">
              <TopBar handleShow={handleShow} sidebarWidth={navbarWidth} />

              {/* Begin Page Content */}
              <Container fluid style={{ marginTop: "6em" }}>
                {props.children}
              </Container>
              {/* /.container-fluid */}
            </div>
            {/* End of Main Content */}

            <Footer
              CopyrightMessage={
                "Copyright " +
                new Date().getFullYear() +
                " © Garage Keeper Team"
              }
            />
          </div>
          {/* End of Content Wrapper */}
        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button */}
        <a className="scroll-to-top rounded" href="#page-top">
          <FontAwesomeIcon icon="angle-up" />
        </a>

        <React.StrictMode>
          <Modal animation={false} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Ready to Leave?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Select "Logout" below if you are ready to end your current
                session.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="success" onClick={handleLogout}>
                Logout
              </Button>
            </Modal.Footer>
          </Modal>
        </React.StrictMode>
      </AuthenticatedTemplate>
    </>
  );
}

export default RegularLayout;
