import React from "react";
import { Col } from "react-bootstrap";
interface CardProps {
  children: any;
  title?: string;
  icon?: string;
  testId?: string;
}

const DashboardCard = ({ title, children, icon, testId }: CardProps) => {
  return (
    <Col xl={4} md={6} className="mb-4" data-testid={testId}>
      <div className="card border-left-secondary shadow py-2 bg-light">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <Col md={12}>
              {title && (
                <div className="font-weight-bold text-dark text-uppercase mb-1">
                  {title}
                </div>
              )}
              <div className="mb-2">{children}</div>
            </Col>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default DashboardCard;
