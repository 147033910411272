import { Card, Col, Row } from "react-bootstrap";
import { RootStateOrAny, useSelector } from "react-redux";
import { RequestStatusLabel } from "Types/request";
import { RequestListInfo } from "Types/requestListInfo";
import { RequestTypeLabel } from "Types/requestType";
import { UserType } from "Types/userInfo";
import { formatDateTime } from "Utils/format";

interface Props {
  requests: RequestListInfo[];
  history: any;
}

export default function RequestListCards({ requests, history }: Props) {
  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  return (
    <>
      <Row data-testid="card-container">
        {requests.map((r: RequestListInfo) => {
          return (
            <Col key={r.id} sm={12} md={6} lg={4} xl={3}>
              <Card
                style={{ height: "95%", cursor: "pointer" }}
                data-testid={`card-${r.id}`}
                onClick={() => {
                  history.push({
                    pathname: `/RequestDetail`,
                    state: {
                      ...r,
                    },
                  });
                }}
              >
                <Card.Body>
                  <Card.Title
                    style={{
                      borderBottom: "solid 1px gainsboro",
                      paddingBottom: "5px",
                    }}
                    data-testid={`card-title-${r.id}`}
                  >
                    {RequestTypeLabel[r.type]}
                  </Card.Title>
                  {userType === UserType.Admin &&
                    history.location.pathname !== "/RequestHistory" && (
                      <CardItem
                        label="Garage Keeper"
                        text={r.garageKeeperName ?? ""}
                        testId={`gkName-${r.garageKeeperName}`}
                      />
                    )}
                  <CardItem
                    label="Police File #"
                    text={r.policeFileNumber ?? ""}
                    testId={`policeFileNumber-${r.id}`}
                  />
                  <CardItem
                    label="Status"
                    text={RequestStatusLabel[r.status]}
                    testId={`status-${r.id}`}
                  />
                  <CardItem
                    label="Created"
                    text={formatDateTime(r.createdOn)}
                    testId={`created-${r.id}`}
                  />
                  <CardItem
                    label="Last Updated"
                    text={formatDateTime(r.lastUpdatedOn)}
                    testId={`updated-${r.id}`}
                  />
                </Card.Body>
              </Card>
              <br />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

interface CardItemProps {
  label: string;
  text: string | number;
  testId: string;
}

const CardItem = ({ label, text, testId }: CardItemProps) => (
  <>
    <Card.Subtitle className="mb-2 text-muted">{label}</Card.Subtitle>
    <Card.Text data-testid={testId}>{text}</Card.Text>
  </>
);
