import ReusableModal from "Components/Modal/ReusableModal";
import FeedbackForm from "Forms/FeedbackForm/FeedbackForm";
import CallBffApi from "Utils/CallBff";
import { useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { Row, Col } from "react-bootstrap";
import { UserPermissions } from "Types/userInfo";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  CopyrightMessage: string;
}

export default function Footer({ CopyrightMessage }: Props) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const {
    userPermissions,
  }: { userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    /* Footer */
    <footer className="sticky-footer bg-white">
      <Row>
        <Col md={2}/>
        <Col>
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>{CopyrightMessage}</span>
            </div>
          </div>
        </Col>
        <Col md={2}>
          {userPermissions.canCreateFeedback &&
          <div style={{float: "right", paddingRight: "100px"}}>
            <ReusableModal
              buttonText="Feedback"
              headerText={"Feedback"}
              open={() => {
                openModal();
              }}
              close={closeModal}
              show={showModal}
            >
              <FeedbackForm
                submit={CallBffApi}
                cancel={closeModal}
                author={account?.name!}
              />
            </ReusableModal>
          </div>}
        </Col>
      </Row>
    </footer>
    /* End of Footer */
  );
}