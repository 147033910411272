import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  ImpoundedVehicleStatus,
  ImpoundedVehicleStatusLabel,
  ImpoundmentAggregateStatus,
  ImpoundmentAggregateStatusLabel,
} from "Types/impoundedVehicleStatus";
import { default as actions } from "Redux/impoundedvehicles/ImpoundedVehicleListActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

interface DropdownData {
  value: string;
  displayValue: string;
}

function StatusFilter() {
  const dispatch = useDispatch();
  const statusFilter = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList.statusFilter
  );

  const handleChange = (status: string) => {
    dispatch(actions.filterStatus(status));
  };

  const dropdownOptions: DropdownData[] = [
    ...Object.keys(ImpoundmentAggregateStatus).map((status: string) => {
      return {
        value: status,
        displayValue:
          ImpoundmentAggregateStatusLabel[
            status as keyof typeof ImpoundmentAggregateStatusLabel
          ],
      };
    }),
    ...Object.keys(ImpoundedVehicleStatus).map((status: string) => {
      return {
        value: status,
        displayValue:
          ImpoundedVehicleStatusLabel[
            status as keyof typeof ImpoundedVehicleStatusLabel
          ],
      };
    }),
  ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));

  return (
    <Dropdown className="mb-2 ml-2">
      <Dropdown.Toggle id="statusDropdown" data-testid="status-filter">
        {`Status: ${
          dropdownOptions.find((x) => x.value === statusFilter)?.displayValue
        }`}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dropdownOptions.map((x) => {
          return (
            <Dropdown.Item
              key={x.displayValue}
              data-testid={"status-" + x.value}
              onClick={() => {
                handleChange(x.value);
              }}
            >
              {x.displayValue}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StatusFilter;
