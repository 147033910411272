import { GarageKeeper } from "Types/garageKeeper";
import InsurancePoliciesList from "./InsurancePoliciesList";
import DetailLayout from "Layouts/DetailLayout";
import { InsuranceState, InsurancePolicy } from "Types/insurancePolicy";
import { getGarageKeeperInsuranceState } from "Components/GarageKeepers/GarageKeeperDetailInternal"
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RequestStatus } from "Redux/requestStatus";

interface Props {
  garageKeeper: GarageKeeper;
  insurancePolicies?: InsurancePolicy[];
}

export default function InsurancePoliciesListExternal({ garageKeeper }: Props) {
  const insurancePolicies = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.insurancePolicies
  );

  const insurancePoliciesStatus = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.status
  );
  
  const [gkInsuranceStatus, setGkInsuranceStatus] = useState<
  InsuranceState | undefined
  >(undefined);

  const [displayInsuranceNotification, setDisplayInsuranceNotification] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      insurancePoliciesStatus !== RequestStatus.LOADING &&
      displayInsuranceNotification
    ) {
      setGkInsuranceStatus(getGarageKeeperInsuranceState(insurancePolicies));
    }

    if (insurancePoliciesStatus === RequestStatus.LOADING) {
      setDisplayInsuranceNotification(true);
    }
  }, [
    displayInsuranceNotification,
    insurancePolicies,
    insurancePoliciesStatus,
  ]);

  return (
    <DetailLayout
      title="Insurance Policies"
      header={garageKeeper.name}
      subheader="Insurance Policies"
      notification={
        gkInsuranceStatus === InsuranceState.Expiring ? (
          <div
            data-testid="insurance-notification-expiring"
            style={{ color: "orange" }}
          >
            <div>Expiring</div>
            <div>Insurance</div>
          </div>
        ) : gkInsuranceStatus === InsuranceState.Expired ? (
          <div
            data-testid="insurance-notification-expired"
            style={{ color: "red" }}
          >
            <div>Expired</div>
            <div>Insurance</div>
          </div>
        ) : (
          ""
        )
      }
    >
      <InsurancePoliciesList garageKeeperId={garageKeeper.id} />
    </DetailLayout>
  );
}
