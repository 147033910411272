import { Alert, Container, Row } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import RequestListTable from "./RequestListTable";
import RequestListCards from "./RequestListCards";
import { useMediaQuery } from "react-responsive";
import { getMediaQuery, ScreenSize } from "Utils/mediaQueries";
import allActions from "Redux/allActions";
import { useEffect } from "react";
import ListPagination from "Components/ListPagination/ListPagination";
import SearchBox from "Components/Search/SearchBox";
import { RequestListInfo } from "Types/requestListInfo";
import RequestListFilterButtons from "./RequestListFilterButtons";
import RequestListSortButtons from "./RequestListSortButtons";

interface Props {
  history: any;
  garageKeeperId?: string;
  entityId?: string;
  internal?: boolean;
  setAllRequestList?: any;
}

export function getPagedUrl(
  sortField: string,
  sortDirection: string,
  searchPoliceFileNumber: string,
  gkFilter: string,
  statusFilter: string,
  typeFilter: string,
  recentFilter: boolean,
  pageNumber: number,
  pageSize: number
): string {
  return `sort=${encodeURIComponent(
    sortField
  )}&sortDirection=${encodeURIComponent(sortDirection)}${
    searchPoliceFileNumber && searchPoliceFileNumber !== ""
      ? `&searchPoliceFileNumber=${encodeURIComponent(searchPoliceFileNumber)}`
      : ""
  }${
    gkFilter && gkFilter !== "ALL"
      ? `&filterGarageKeeperId=${encodeURIComponent(gkFilter)}`
      : ""
  }${
    statusFilter && statusFilter !== "ALL"
      ? `&filterStatus=${encodeURIComponent(statusFilter)}`
      : ""
  }${
    typeFilter && typeFilter !== "ALL"
      ? `&filterType=${encodeURIComponent(typeFilter)}`
      : ""
  }${
    recentFilter ? `&filterRecent=${encodeURIComponent(recentFilter)}` : ""
  }&pageNumber=${encodeURIComponent(pageNumber)}&pageSize=${encodeURIComponent(
    pageSize
  )}`;
}

export default function RequestListContainer({
  history,
  garageKeeperId,
  entityId,
  internal,
  setAllRequestList,
}: Props) {
  const {
    sortField,
    sortDirection,
    requestList,
    searchPoliceFileNumber,
    gkFilter,
    statusFilter,
    typeFilter,
    recentFilter,
    pageNumber,
    pageSize,
    totalPages,
    hasPrev,
    hasNext,
    totalCount,
  } = useSelector((state: RootStateOrAny) => state.requests);

  const dispatch = useDispatch();

  const urlPrefix = `${
    garageKeeperId === undefined && internal === true
      ? "/list?"
      : entityId
      ? `/listbyentity?garageKeeperId=${garageKeeperId}&entityId=${entityId}&`
      : `/listbygaragekeeper?garageKeeperId=${garageKeeperId}&`
  }`;

  var pagedUrlOptions = getPagedUrl(
    sortField,
    sortDirection,
    searchPoliceFileNumber,
    gkFilter,
    statusFilter,
    typeFilter,
    recentFilter,
    pageNumber,
    pageSize
  );

  useEffect(() => {
    dispatch(
      allActions.RequestActions.loadRequestList(urlPrefix + pagedUrlOptions)
    );
    if (garageKeeperId === undefined && internal === true) {
      Promise.resolve(
        allActions.RequestActions.loadAllRequestList(
          "/list?" + pagedUrlOptions + "&getAllList=true"
        )
      ).then((result) => {
        setAllRequestList(result);
      });
    }
  }, [
    sortField,
    sortDirection,
    statusFilter,
    gkFilter,
    statusFilter,
    typeFilter,
    recentFilter,
    pageNumber,
    pageSize,
    urlPrefix,
    pagedUrlOptions,
    dispatch,
  ]);

  const mdScreen = useMediaQuery(getMediaQuery(ScreenSize.MD, true));

  return (
    <Container fluid style={{ minHeight: "15em" }}>
      <Row>
        <SearchBox
          endpoint={urlPrefix + pagedUrlOptions}
          searchField="policeFileNumber"
          placeholder="Police File #"
          handleResult={(data: RequestListInfo[]) => {
            console.log(JSON.stringify(data));
          }}
          onSearch={() => void 0}
          requestListSearch={true}
        />
        {mdScreen && <RequestListSortButtons history={history} />}
        <RequestListFilterButtons history={history} />
      </Row>
      {mdScreen ? (
        <RequestListCards
          history={history}
          requests={requestList}
          data-testid="request-list-cards"
        />
      ) : (
        <RequestListTable
          data-testid="request-list-table"
          history={history}
          requests={requestList}
          sortField={sortField}
          sortDirection={sortDirection}
        />
      )}
      {totalPages > 0 ? (
        <ListPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          hasPrev={hasPrev}
          hasNext={hasNext}
          totalCount={totalCount}
          setPageNum={allActions.RequestActions.setPageNum}
          setPageSize={allActions.RequestActions.setPageSize}
        />
      ) : (
        <Alert variant="dark">
          <i>No requests match the current filter</i>
        </Alert>
      )}
    </Container>
  );
}
