export enum EntityType {
  GarageKeeper = "GarageKeeper",
  VehicleImpoundment = "VehicleImpoundment",
  InsurancePolicy = "InsurancePolicy",
  Location = "Location",
  Request = "Request",
  Bulletin = "Bulletin",
  TowingVehicle = "TowingVehicle",
  LawEnforcement = "LawEnforcement"
}
