import { Table } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getRequireAttentionComments } from "api/commentsApi";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { allCommentsFilter, myCommentsFilter, resolvedFilter } from "Utils/commentUtil";

interface IProps {
    garageKeeperId?: string;
}

const DashboardRequireAttentionCommentsCount = ({ garageKeeperId }: IProps) => {
    const history = useHistory();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) as AccountInfo | null;

    const [commentsCount, setCommentsCount] = useState(0);
    const [myCommentsCount, setMyCommentsCount] = useState(0);
    const [resolvedCount, setResolvedCount] = useState(0);

    useEffect(() => {
        const loadComments = async () => {
            var response = await getRequireAttentionComments(garageKeeperId);

            const email = (account?.idTokenClaims as any)?.email;
            setCommentsCount(response.filter(x => allCommentsFilter(x, email)).length);
            setMyCommentsCount(response.filter(x => myCommentsFilter(x, email)).length);
            setResolvedCount(response.filter(x => resolvedFilter(x, email)).length)
        }

        loadComments();
    }, []);

    return (
        <DashboardCard testId="require-attention-comments-count">
            <Table style={{ textAlign: "center" }}>
                <tbody>
                    <tr>
                        <td className="border-top-0">
                            <div className="font-weight-bold text-dark text-uppercase mb-1 text-left">
                                Require Attention Comments
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="text-left"
                            style={{
                                borderTop: "none",
                                paddingTop: 0,
                                fontWeight: "bold",
                            }}
                        >
                            Type
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">My Comments</td>
                        <td
                            data-testid="my-comments-testid"
                            className="dashboardTableCountButton"
                            onClick={(e) => history.push({ pathname: "/RequireAttentionComments/mycomments" })}>
                            {myCommentsCount}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Comments</td>
                        <td
                            data-testid="comments-testid"
                            className="dashboardTableCountButton"
                            onClick={(e) => history.push({ pathname: "/RequireAttentionComments" })}>
                            {commentsCount}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Resolved</td>
                        <td
                            data-testid="comments-testid"
                            className="dashboardTableCountButton"
                            onClick={(e) => history.push({ pathname: "/RequireAttentionComments/resolved" })}>
                            {resolvedCount}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </DashboardCard >
    );
}

export default DashboardRequireAttentionCommentsCount;