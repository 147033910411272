// Provides a centralized definition of all the comment formatting rules.
// Allows for dynamically applying as well as dynamically displaying buttons

export interface FormatType {
  name: string;
  startSymbol: string;
  endSymbol: string;
  matchPattern: string;
  replacePattern: string;
}

export interface FormattingTypesList {
  [index: string]: FormatType;
  bold: FormatType;
  ital: FormatType;
  under: FormatType;
  strike: FormatType;
  red: FormatType;
  blue: FormatType;
  green: FormatType;
}

export const FormattingTypes: FormattingTypesList = {
  bold: {
    name: "Bold",
    startSymbol: "*",
    endSymbol: "*",
    matchPattern: "\\*(.*?)\\*",
    replacePattern: "<b>$1</b>",
  },
  ital: {
    name: "Ital",
    startSymbol: "_",
    endSymbol: "_",
    matchPattern: "_(.*?)_",
    replacePattern: "<i>$1</i>",
  },
  under: {
    name: "Under",
    startSymbol: "--",
    endSymbol: "--",
    matchPattern: "--(.*?)--",
    replacePattern: "<u>$1</u>",
  },
  strike: {
    name: "Strike",
    startSymbol: "~",
    endSymbol: "~",
    matchPattern: "~(.*?)~",
    replacePattern: "<s>$1</s>",
  },
  red: {
    name: "Red",
    startSymbol: "<red>",
    endSymbol: "</>",
    matchPattern: "<red>(.*?)</>",
    replacePattern: '<span class="comment-red">$1</span>',
  },
  blue: {
    name: "Blue",
    startSymbol: "<blue>",
    endSymbol: "</>",
    matchPattern: "<blue>(.*?)</>",
    replacePattern: '<span class="comment-blue">$1</span>',
  },
  green: {
    name: "Green",
    startSymbol: "<green>",
    endSymbol: "</>",
    matchPattern: "<green>(.*?)</>",
    replacePattern: '<span class="comment-green">$1</span>',
  },
};
