export interface GkImpoundmentCounts {
  [index: string]: any;
  active: number;
  released: number;
  wrongful: number;
  termended: number;
}

export const defaultGkImpoundmentCounts: GkImpoundmentCounts = {
  active: 0,
  released: 0,
  wrongful: 0,
  termended: 0,
};
