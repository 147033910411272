export enum RequestType {
  Impoundment = "Impoundment",
  Disposal = "Disposal",
  Payment = "Payment",
  Release = "Release",
  NewLocation = "NewLocation",
  NewTowingVehicle = "NewTowingVehicle",
  NewInsurancePolicy = "NewInsurancePolicy",
  NewInfo = "NewInfo"
}

export const RequestTypeLabel: {
  [key in RequestType]: string;
} = {
  [RequestType.Impoundment]: "Impoundment",
  [RequestType.Disposal]: "Disposal",
  [RequestType.Payment]: "Payment",
  [RequestType.Release]: "Release",
  [RequestType.NewLocation]: "New Compound",
  [RequestType.NewTowingVehicle]: "New Towing Vehicle",
  [RequestType.NewInsurancePolicy]: "New Insurance Policy",
  [RequestType.NewInfo]: "New Info"
};

export const impoundmentRequestTypes = [
  RequestType.Impoundment,
  RequestType.Disposal,
  RequestType.Payment,
  RequestType.Release,
];

export const garagekeeperRequestTypes = [
  RequestType.NewLocation,
  RequestType.NewTowingVehicle,
  RequestType.NewInsurancePolicy,
];
