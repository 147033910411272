import {
    SET_VEHICLEIMPOUNDMENT,
    CLEAR_VEHICLEIMPOUNDMENT,
  } from "./vehicleImpoundmentActions";
import { VehicleImpoundment, defaultVehicleImpoundment } from "../../Types/vehicleImpoundment";
  
  export interface VehicleImpoundmentState {
    vehicleImpoundment: VehicleImpoundment;
  }
  
  const initialState: VehicleImpoundmentState = {
    vehicleImpoundment: defaultVehicleImpoundment as VehicleImpoundment    
  };
  
  export const vehicleImpoundmentReducer: any = (
    state: VehicleImpoundmentState = initialState,
    action: any
  ): VehicleImpoundmentState => {
    let type = action.type;
  
    switch (type) {
        case SET_VEHICLEIMPOUNDMENT:
            return {
                ...state,
                vehicleImpoundment: action.value
            }

        case CLEAR_VEHICLEIMPOUNDMENT:
            return initialState
  
        default:
            return state;
    }
  };
  