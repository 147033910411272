import { Link } from "react-router-dom";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUser,
  faSignOutAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as Sgilogo } from "Resources/images/sgi-logo.svg";
import { AccountInfo } from "@azure/msal-browser";
import { useMediaQuery } from "react-responsive";
import { getMediaQuery, ScreenSize } from "Utils/mediaQueries";
import UserInfoDropdown from "./UserInfoDropdown";
import HamburgerMenu from "./HamburgerMenu";

function TopBar(props: any) {
  fontAwesomeLibrary.add(faBars, faUser, faSignOutAlt, faUserCircle);

  const { accounts } = useMsal();
  let account = (accounts[0] as AccountInfo) || {};

  const smScreen = useMediaQuery(getMediaQuery(ScreenSize.SM, true));
  return (
    <nav className="shadow bg-white">
      {/* Sidebar Toggle (Topbar) */}

      {/* Topbar Navbar */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "calc(100vw - " + props.sidebarWidth + ")",
          height: "4em",
          alignItems: "center",
          listStyle: "none",
          position: "fixed",
          zIndex: 1000,
          background: "white",
          padding: "1rem",
          boxShadow: "0px 0px 25px grey",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {smScreen && (
            <>
              <HamburgerMenu />
              <Link to="/">
                <div
                  className="mr-3 ml-3"
                  style={{ width: "24vw", maxWidth: "8em" }}
                >
                  <Sgilogo title="SGI" />
                </div>
              </Link>
            </>
          )}
        </div>
        {/* Nav Item - User Information */}
        <UserInfoDropdown account={account} handleLogout={props.handleShow} />
      </div>
    </nav>
  );
}

export default TopBar;
