import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
interface Props {
  text: string;
  required?: boolean;
  phoneNumber?: string;
  testId?: string;
}

function FormLabel({
  testId,
  text,
  required = false,
  phoneNumber = "",
}: Props) {
  fontAwesomeLibrary.add(faPhone);

  return (
    <Form.Label className="font-weight-bold" data-testid={testId}>
      {text}
      {required && <span style={{ color: "#e74a3b" }}> *</span>}
      {phoneNumber !== "" && (
        <a
          data-testid={testId ? testId + "-icon" : undefined}
          href={`tel:${phoneNumber}`}
          className="view-action ml-md-2"
        >
          <FontAwesomeIcon icon="phone" size="1x" color="#007bff" />
        </a>
      )}
    </Form.Label>
  );
}

export default FormLabel;
