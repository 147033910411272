import { Card, Col, Row } from "react-bootstrap";
import { ImpoundedVehicleListInfo } from "Types/impoundedVehicleListInfo";
import { ImpoundedVehicleStatusLabel } from "Types/impoundedVehicleStatus";
import { formatDateStripTime } from "Utils/format";
import FilterModal from "./Elements/FilterModal";
import ImpoundmentSearch from "./Elements/ImpoundmentSearch";
import ImpoundmentSortButton from "./Elements/ImpoundmentSortButton";

interface Props {
  history: any;
  impoundments: ImpoundedVehicleListInfo[];
  profileView?: boolean;
  garageKeeperId?: string;
  external?: boolean;
}

export default function ImpoundedVehicleCardList({
  history,
  impoundments,
  profileView,
  garageKeeperId,
  external,
}: Props) {
  return (
    <>
      <Row>
        <Col sm={5} md={6} className="mb-2">
          <ImpoundmentSearch
            garageKeeperId={garageKeeperId}
            external={external}
          />
        </Col>
        <Col sm={7} md={6} className="mb-2">
          <ImpoundmentSortButton external={external} />
          <div style={{ float: "right" }}>
            <FilterModal />
          </div>
        </Col>
      </Row>
      <Row data-testid="impounded-vehicle-card-list">
        {impoundments.map((i) => (
          <ImpoundedVehicleCard
            key={i.vehicleImpoundmentId}
            vehicle={i}
            onClick={() => {
              history.push({
                pathname: `/ImpoundedVehicles/${i.vehicleImpoundmentId}`,
                state: { vi: i },
              });
            }}
            profileView={profileView}
          />
        ))}
      </Row>
    </>
  );
}

interface CardItemProps {
  label?: string;
  text: string | number;
}
const CardItem = ({ label, text }: CardItemProps) => (
  <>
    <Card.Subtitle className="mb-2 text-muted">{label}</Card.Subtitle>
    <Card.Text>{text}</Card.Text>
  </>
);

interface VehicleCardProps {
  vehicle: ImpoundedVehicleListInfo;
  onClick: any;
  profileView?: boolean;
}

const ImpoundedVehicleCard = ({
  vehicle: v,
  onClick,
  profileView,
}: VehicleCardProps) => (
  <Col key={v.id} sm={12} md={6} lg={4} xl={3}>
    <Card style={{ height: "95%", cursor: "pointer" }} onClick={onClick}>
      <Card.Body>
        <Card.Title
          style={{
            borderBottom: "solid 1px gainsboro",
            paddingBottom: "5px",
          }}
        >
          {v.vin}
        </Card.Title>
        <CardItem
          label="Police File #"
          text={v.policeFileNumber ? v.policeFileNumber : "<Unknown>"}
        />
        {profileView ? (
          <CardItem label="Lot Location" text={v.locationAddress} />
        ) : (
          <CardItem label="Garage Keeper" text={v.garageKeeperName} />
        )}
        <CardItem label="Status" text={ImpoundedVehicleStatusLabel[v.status]} />
        <CardItem
          label="Term Start"
          text={formatDateStripTime(v.termStartDate?.toString()) ?? ""}
        />
        <CardItem
          label="Term End"
          text={formatDateStripTime(v.termEndDate?.toString()) ?? ""}
        />

        <CardItem label="Days Impounded" text={v.daysImpounded} />
      </Card.Body>
    </Card>
    <br />
  </Col>
);
