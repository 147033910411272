import { GarageKeeperStatus } from "./garageKeeperStatus";
import { Address } from "./address";
import { CommentCount } from "./comment";
import { CommPref } from "./contact";

export interface GarageKeeper {
  id: string;
  status: GarageKeeperStatus;
  customerNumber: number | null;
  name: string;
  primaryContactPerson: string;
  mainOfficePhoneNumber: string;
  mainOfficeFaxNumber?: string;
  mainOfficeEmailAddress?: string;
  mainOfficeCommunicationPreference: CommPref;
  lawEnforcementPhoneNumber: string;
  commentCount: CommentCount;
  attachmentCount: number;
  mailingAddress: Address;
  legalEntity?: string;
  securityOrganizationId?: string;
  canTowSemis: boolean;
}

export interface ExternalGarageKeeperDTO {
  id: string;
  primaryContactPerson: string;
  mailingAddress: Address;
  canTowSemis: boolean;
}

export const defaultGarageKeeper: GarageKeeper = {
  id: "00000000-0000-0000-0000-000000000000",
  status: GarageKeeperStatus.Active,
  customerNumber: null,
  name: "",
  primaryContactPerson: "",
  mainOfficePhoneNumber: "",
  mainOfficeCommunicationPreference: CommPref.Email,
  lawEnforcementPhoneNumber: "",
  commentCount: {
    internal: 0,
    external: 0,
  },
  attachmentCount: 0,
  mailingAddress: {
    street: "",
    city: "",
    postalCode: "",
    province: "SK",
    country: "CA",
  },
  canTowSemis: false,
};
