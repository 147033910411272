import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  defaultGarageKeeper,
  GarageKeeper,
  ExternalGarageKeeperDTO,
} from "Types/garageKeeper";
import { provinces } from "Utils/consts";
import CustomForm from "Components/FormElements/CustomForm";
import FormText from "Components/FormElements/FormText";
import FormSelect from "Components/FormElements/FormSelect";
import allActions from "Redux/allActions";
import { doToast, ToastType } from "Utils/toastUtils";
import { formatCustomerNumber } from "Utils/format";

interface Props {
  data: any;
  cancel?: any;
  submit: any;
}

function GarageKeeperFormExternal(props: Props) {
  const [garageKeeper, setGarageKeeper] = useState<GarageKeeper>(
    props.data
      ? {
          ...props.data,
          customerNumber: formatCustomerNumber(props.data.customerNumber),
        }
      : defaultGarageKeeper
  );
  const [saved, setSaved] = useState(false);

  const dispatch = useDispatch();

  const handleUpdate = (event: any) => {
    let splitName = event.target.name.split(".");

    if (splitName[0] === "mailingAddress") {
      let fieldToUpdate = splitName[1];
      let updatedMailingAddress = {
        ...garageKeeper.mailingAddress,
        [fieldToUpdate]: event.target.value,
      };

      setGarageKeeper({
        ...garageKeeper,
        mailingAddress: {
          ...updatedMailingAddress,
        },
      });
    } else {
      setGarageKeeper({
        ...garageKeeper,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      });
    }
  };

  const handleSubmit = async (event: any): Promise<boolean> => {
    event.preventDefault();

    let isValid = event.currentTarget.checkValidity();

    if (!isValid) return false;

    let gkToSubmit: ExternalGarageKeeperDTO = {
      id: garageKeeper.id,
      primaryContactPerson: garageKeeper.primaryContactPerson,
      mailingAddress: garageKeeper.mailingAddress,
      canTowSemis: garageKeeper.canTowSemis,
    };
    gkToSubmit.mailingAddress.postalCode = gkToSubmit.mailingAddress.postalCode
      .replace(/ /g, "")
      .toUpperCase();

    const requestUrl = `${window.REACT_APP_API_BASEURL}garagekeepers/ext/${gkToSubmit.id}`;

    try {
      const response = await props.submit(requestUrl, {
        method: "PUT",
        body: JSON.stringify(gkToSubmit),
      });

      if (response.ok) {
        dispatch(allActions.GKList.updateGarageKeeper(garageKeeper));
        dispatch(allActions.GKProfile.setGK(garageKeeper));
        setSaved(true);
        doToast("Garage Keeper saved successfully", ToastType.Success);
        return true;
      } else {
        doToast("An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const handleCancel = () => {
    if (!saved) {
      setGarageKeeper(props.data);
    }
    props.cancel?.();
  };

  return (
    <CustomForm
      edit={true}
      onSubmit={handleSubmit}
      cancel={handleCancel}
      openInEditMode={true}
    >
      <Form.Row>
        <Form.Label>
          <h4>Garage Keeper Details</h4>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="4" md="6" controlId="formCustomerNumber">
          <FormText
            disabled
            label="Customer Number"
            field="customerNumber"
            value={garageKeeper.customerNumber?.toString()}
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formName">
          <FormText
            disabled
            label="Business Name"
            field="name"
            value={garageKeeper.name}
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formLegalEntity">
          <FormText
            disabled
            label="Legal Entity"
            field="legalEntity"
            value={garageKeeper.legalEntity ?? ""}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="4" md="6" controlId="formStatus">
          <FormText
            disabled
            label="Status"
            field="status"
            value={garageKeeper.status}
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formPrimaryContactPerson">
          <FormText
            required
            label="Primary Contact Name"
            field="primaryContactPerson"
            value={garageKeeper.primaryContactPerson}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formCanTowSemis">
          <Form.Label className="edit-form-label">
            Tow Heavy Vehicles (over 4,600KG/ 10,000lbs)
          </Form.Label>
          <Form.Check
            name="canTowSemis"
            onChange={handleUpdate}
            checked={garageKeeper.canTowSemis ?? false}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Label>
          <h4>Mailing Address</h4>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="8" controlId="formMailingAddressStreet">
          <FormText
            required
            label="Street"
            field="mailingAddress.street"
            value={garageKeeper.mailingAddress.street}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formMailingAddressCity">
          <FormText
            required
            label="City"
            field="mailingAddress.city"
            value={garageKeeper.mailingAddress.city}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formMailingAddressProvince">
          <FormSelect
            required
            label="Province"
            field="mailingAddress.province"
            value={garageKeeper.mailingAddress.province}
            data={provinces}
            mapping={(key) => {
              return {
                value: key,
                text: key,
              };
            }}
            onChange={handleUpdate}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formMailingAddressPostalCode">
          <FormText
            required
            label="Postal Code"
            field="mailingAddress.postalCode"
            value={garageKeeper.mailingAddress.postalCode}
            pattern="^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$"
            onChange={handleUpdate}
            invalidMessage=" must match format 'A1A 1A1'"
          />
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}

export default GarageKeeperFormExternal;
