export function addDays(date: Date, days: number): Date {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addYears(date: Date, years: number): Date {
  let result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
}

export function stripTime(date: Date): Date {
  let result = new Date(date.toISOString().split("T")[0]);
  return result;
}

// Allows comparison of two "dates", whether they are actually dates or strings
export function isEqual(date1: Date | string, date2: Date | string): boolean {
  if (!(date1 instanceof Date)) {
    date1 = new Date(date1);
  }

  if (!(date2 instanceof Date)) {
    date2 = new Date(date2);
  }

  return date1.getTime() === date2.getTime();
}
