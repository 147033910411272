import { Col, Container, Row } from "react-bootstrap";
import { formatDateTime } from "Utils/format";
import { AttentionStatus, Comment } from "Types/comment";
import CommentDelete from "./CommentDelete";
import CommentFormatter from "./CommentFormatter";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import CommentActions from "Redux/comments/CommentsActions";
import CallBffApi from "Utils/CallBff";
import { doToast, ToastType } from "Utils/toastUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { UserType } from "Types/userInfo";
import { EntityType } from "Types/EntityType";
import { updateAttentionStatus } from "api/commentsApi";
import RequiredCommentsActions from 'Redux/comments/RequiredCommentsActions';
import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

interface Props {
  comment: Comment;
  user: string;
  onDelete: Function;
  onUpdate?: Function;
  entityType: EntityType;
  readonly?: boolean;
}

function CommentElement({
  comment,
  user,
  onDelete,
  onUpdate,
  entityType,
  readonly = false
}: Props) {
  library.add(faEye, faEyeSlash);
  const dispatch = useDispatch();
  const { deleteId } = useSelector((state: RootStateOrAny) => state.comments);
  const { userType } = useSelector((state: RootStateOrAny) => state.userInfo);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;

  const commentBox = {
    border: "1px solid grey",
    borderRadius: "10px",
    fontSize: "small",
    marginBottom: "5px",
    marginTop: "10px",
  } as React.CSSProperties;

  const header = {
    borderBottom: "1px solid grey",
    backgroundColor: "Gainsboro",
    borderRadius: "10px",
  } as React.CSSProperties;

  const subtext = {
    color: "grey",
    fontSize: "small",
  } as React.CSSProperties;

  const body = {
    margin: "10px 0px 10px 0px",
    whiteSpace: "pre-wrap",
  } as React.CSSProperties;

  const buttons = {
    display: "inline-block",
    cursor: "pointer",
    float: "right",
    margin: "2px",
  } as React.CSSProperties;

  const author = {
    fontWeight: comment.attentionStatus === AttentionStatus.Required
      || comment.attentionStatus === AttentionStatus.Resolved ? "bold" : "normal"
  } as React.CSSProperties;

  const toggleInternalMode = async () => {
    const url = `${window.REACT_APP_API_BASEURL}comments/${comment.id}/setInternal`;

    const response = await CallBffApi(url, {
      method: "PUT",
      body: JSON.stringify({
        Id: comment.id,
        IsInternal: !comment.isInternal,
      }),
    });

    if (response.ok) {
      onUpdate?.({
        ...comment,
        isInternal: !comment.isInternal,
        edited: true,
      });
    } else {
      doToast("An error occurred", ToastType.Error);
    }
  };

  const markCompleted = async () => {

    comment.attentionStatus = comment.createdBy === (account?.idTokenClaims as any)?.email ?
      AttentionStatus.Complete : AttentionStatus.Resolved;

    const success = await updateAttentionStatus(comment);
    if (success) {
      dispatch(RequiredCommentsActions.completeComment(comment));
      dispatch(CommentActions.update(comment));
      doToast('Comment completed.', ToastType.Success);
    } else {
      doToast('Failed to complete a comment.', ToastType.Error);
    }
  }

  const completeButtonVisible = (): boolean => {
    return ((comment.attentionStatus === AttentionStatus.Required
      || comment.attentionStatus === AttentionStatus.Resolved) && comment.createdBy === (account?.idTokenClaims as any)?.email)
      || ((comment.attentionStatus === AttentionStatus.Required) && comment.createdBy !== (account?.idTokenClaims as any)?.email);
  }

  return (
    <Container style={commentBox}>
      <Row style={header}>
        <Col md="auto" data-testid={'commentAuthorName'} style={author}>{comment.author}</Col>
        <Col style={subtext}>
          {formatDateTime(comment.createdOn)} {comment.edited ? "(edited)" : ""}
        </Col>
        <Col md="4">
          {
            !readonly &&
            <>
              {user === comment.createdBy && (
                <>
                  <div
                    data-testid={"comment-delete-button"}
                    style={buttons}
                    onClick={() => dispatch(CommentActions.setDeleteId(comment.id))}
                  >
                    <i className="fas fa-trash-alt delete-button" />
                  </div>
                  <div
                    data-testid={"edit-button"}
                    style={buttons}
                    onClick={() => dispatch(CommentActions.setEditId(comment.id))}
                  >
                    <i className="far fa-edit edit-button" />
                  </div>
                  {userType === UserType.Admin && (
                    <div
                      data-testid="mark-internal-button"
                      style={buttons}
                      onClick={() => toggleInternalMode()}
                    >
                      <FontAwesomeIcon
                        icon={comment.isInternal ? faEyeSlash : faEye}
                      />
                    </div>
                  )}
                </>
              )}
              {(completeButtonVisible()) && <div
                data-testid={"complete-button"}
                style={buttons}
                onClick={async () => await markCompleted()}>
                <i className="far fa-check complete-button" />
              </div>}
              <span
                style={{
                  ...subtext,
                  paddingRight: "0.5em",
                  float: "right",
                }}
              >
                {comment.isInternal && "(Internal-only)"}
              </span>
            </>
          }
        </Col>
      </Row>
      <Row style={body}>
        <Col>
          <CommentFormatter text={comment.commentText} />
        </Col>
      </Row>
      <Row style={body}>
        {deleteId === comment.id && (
          <CommentDelete
            id={comment.id}
            onCancel={() => dispatch(CommentActions.setDeleteId(""))}
            onDelete={onDelete}
            entityType={entityType}
          />
        )}
      </Row>
    </Container>
  );
}

export default CommentElement;
