import React from "react";
import { useState, createRef, useEffect } from "react";
import CommentFormatHelp from "./CommentFormatHelp";
import { FormattingTypes } from "../../Types/commentFormatting";
import CommentFormatter from "./CommentFormatter";

interface Props {
  text: string;
  onChange: any;
  disabled?: boolean;
}

const commentBox = {
  width: "100%",
  height: "75px",
} as React.CSSProperties;

function CommentEditor({ text, onChange, disabled = false }: Props) {
  const buttonStyle = {
    width: "4rem",
    height: "2rem",
    borderRadius: "5px",
    border: "1px solid grey",
  } as React.CSSProperties;
  const [cursorPos, setCursorPos] = useState<number | undefined>(undefined);

  const textAreaRef = createRef<HTMLTextAreaElement>();

  const handleStyle = (e: any) => {
    e.preventDefault?.();
    let format = FormattingTypes[e.target.name.toLowerCase()];

    let startSymbol = format!.startSymbol;
    let endSymbol = format!.endSymbol;

    let start = textAreaRef.current!.selectionStart;
    let end = textAreaRef.current!.selectionEnd;

    // insert tags into the propert positions
    text = text.slice(0, end) + endSymbol + text.slice(end);
    text = text.slice(0, start) + startSymbol + text.slice(start);

    // If no selection is made, remember the cursor position
    if (start === end) setCursorPos(start + startSymbol.length);
    else setCursorPos(end + startSymbol.length + endSymbol.length);

    // fire handleChange with a fake event so the parent registers the change
    onChange({ target: { value: text } });
  };

  const handleHotKeys = (e: any) => {
    if (e.ctrlKey) {
      let name = "";
      switch (e.keyCode) {
        case 66: //b
          e.preventDefault();
          name = "bold";
          break;
        case 73: // i
          e.preventDefault();
          name = "ital";
          break;
        case 85: // u
          e.preventDefault();
          name = "under";
          break;
        default:
          break;
      }

      if (name !== "") handleStyle({ target: { name } });
    }
  };

  const handleChange = (e: any) => {
    if (cursorPos !== undefined) setCursorPos(undefined);
    onChange(e);
  };

  useEffect(() => {
    if (cursorPos === undefined) return;

    // put focus back onto textArea
    textAreaRef.current!.focus();
    // then put cursor back to where it was
    textAreaRef.current!.selectionStart = textAreaRef.current!.selectionEnd =
      cursorPos;
  }, [text, textAreaRef, cursorPos]);

  return (
    <>
      {/* Styling Buttons */}
      <div
        data-testid="style-bar"
        style={{
          display: disabled ? "none" : "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          data-testid="style-buttons-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            padding: "0 0 5px 0",
            width: "90%",
          }}
        >
          {Object.keys(FormattingTypes).map((key) => {
            let type = FormattingTypes[key];
            let lowerName = type.name.toLowerCase();
            return (
              <button
                key={lowerName}
                onClick={handleStyle}
                name={lowerName}
                data-testid={lowerName + "-style-button"}
                style={buttonStyle}
              >
                <div style={{ pointerEvents: "none" }}>
                  <CommentFormatter
                    text={type.startSymbol + type.name + type.endSymbol}
                  />
                </div>
              </button>
            );
          })}
        </div>
        <CommentFormatHelp />
      </div>

      {/* Input box */}
      <textarea
        data-testid="comment-input"
        style={commentBox}
        placeholder="Enter comment text here..."
        value={text}
        disabled={disabled}
        onChange={handleChange}
        ref={textAreaRef}
        onKeyDown={handleHotKeys}
      ></textarea>
    </>
  );
}

export default CommentEditor;
