export enum ImpoundedVehicleStatus {
  Active = "Active",
  Released = "Released",
  ApplicationToDispose = "ApplicationToDispose",
  ApprovedForAuction = "ApprovedForAuction",
  ApprovedForTransfer = "ApprovedForTransfer",
  ApprovedUndefined = "ApprovedUndefined",
  SoldAuction = "SoldAuction",
  SoldPrivateSale = "SoldPrivateSale",
  SoldCrusherParts = "SoldCrusherParts",
}

export const ImpoundedVehicleStatusLabel: {
  [key in ImpoundedVehicleStatus]: string;
} = {
  [ImpoundedVehicleStatus.Active]: "Active",
  [ImpoundedVehicleStatus.Released]: "Released",
  [ImpoundedVehicleStatus.ApplicationToDispose]: "Application To Dispose",
  [ImpoundedVehicleStatus.ApprovedForAuction]: "Approved - For Auction",
  [ImpoundedVehicleStatus.ApprovedForTransfer]: "Approved - For Transfer",
  [ImpoundedVehicleStatus.ApprovedUndefined]: "Approved - Undefined",
  [ImpoundedVehicleStatus.SoldAuction]: "Sold - Auction",
  [ImpoundedVehicleStatus.SoldPrivateSale]: "Sold - Private Sale",
  [ImpoundedVehicleStatus.SoldCrusherParts]: "Sold - Crusher Parts",
};

export enum ImpoundmentAggregateStatus {
  All = "All",
  InCompound = "InCompound",
  ApprovedAll = "ApprovedAll",
  SoldAll = "SoldAll",
}

export const ImpoundmentAggregateStatusLabel: {
  [key in ImpoundmentAggregateStatus]: string;
} = {
  [ImpoundmentAggregateStatus.All]: "<All>",
  [ImpoundmentAggregateStatus.InCompound]: "In Compound",
  [ImpoundmentAggregateStatus.ApprovedAll]: "Approved - All",
  [ImpoundmentAggregateStatus.SoldAll]: "Sold - All",
};

export function IsApprovedStatus(
  status: ImpoundedVehicleStatus | string
): boolean {
  status = status.toString();

  return (
    status === ImpoundedVehicleStatus.ApprovedForAuction.toString() ||
    status === ImpoundedVehicleStatus.ApprovedForTransfer.toString() ||
    status === ImpoundedVehicleStatus.ApprovedUndefined.toString()
  );
}

export function IsSoldStatus(status: ImpoundedVehicleStatus | string): boolean {
  status = status.toString();

  return (
    status === ImpoundedVehicleStatus.SoldAuction.toString() ||
    status === ImpoundedVehicleStatus.SoldCrusherParts.toString() ||
    status === ImpoundedVehicleStatus.SoldPrivateSale.toString()
  );
}

export function IsApprovedOrSoldStatus(
  status: ImpoundedVehicleStatus | string
): boolean {
  status = status.toString();

  return IsApprovedStatus(status) || IsSoldStatus(status);
}

export function isTermEnded(
  asOfDate: Date,
  status: ImpoundedVehicleStatus | string,
  impoundmentEndDate?: Date | string
): boolean {
  if (
    IsSoldStatus(status) ||
    status === ImpoundedVehicleStatus.Released.toString()
  ) {
    // If the vehicle is sold or released, the impoundment is not term ended
    return false;
  }

  if (!impoundmentEndDate) {
    // If there is no date, the impoundment is not term ended
    return false;
  } else {
    // Handle "date" strings as well as dates
    if (!(impoundmentEndDate instanceof Date)) {
      impoundmentEndDate = new Date(impoundmentEndDate);
    }

    if (impoundmentEndDate < asOfDate) {
      // If the end date is in the past, the impoundment is term ended
      return true;
    } else {
      // If the end date is in the future, the impoundment is not term ended
      return false;
    }
  }
}
