import DetailLayout from "Layouts/DetailLayout";
import RequireAttenionCommentList from './RequireAttentionCommentsList'
import { useEffect, useState } from "react";
import { getRequireAttentionComments, updateAttentionStatus } from "api/commentsApi";
import { AttentionStatus, Comment } from "Types/comment";
import { useParams } from "react-router";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import actions from 'Redux/comments/RequiredCommentsActions';
import { doToast, ToastType } from "Utils/toastUtils";
import { useHistory } from "react-router-dom";
import { EntityType } from "Types/EntityType";
import { getRequest } from "api/requestApi";
import { getLocation } from "api/locationApi";
import { UserType } from "Types/userInfo";
import { getTowing } from "api/towingApi";
import { getInsurance } from "api/insuranceApi";
import { allCommentsFilter, myCommentsFilter, resolvedFilter } from "Utils/commentUtil";

interface IProps {
    garageKeeper: any | undefined;
}

const RequireAttentionCommentsListContainer = ({ garageKeeper }: IProps) => {
    const [commentsLoaded, setCommentsLoaded] = useState<boolean>(false);
    const { filter } = useParams<{ filter: string }>();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) as AccountInfo | null;
    const dispatch = useDispatch();
    const { list } = useSelector((state: RootStateOrAny) => state.RequireComments);
    let history = useHistory();
    const userType = useSelector((state: RootStateOrAny) => state.userInfo.userType);

    const handleCommentComplete = async (comment: Comment) => {

        comment.attentionStatus = comment.createdBy === (account?.idTokenClaims as any)?.email ?
            AttentionStatus.Complete : AttentionStatus.Resolved;

        const success = await updateAttentionStatus(comment);
        if (success) {
            dispatch(actions.completeComment(comment));
            doToast(`Comment ${comment.attentionStatus}.`, ToastType.Success);
        }
        else {
            doToast('Failed to complete a comment.', ToastType.Error);
        }
    }

    const handleCommentClick = async (comment: Comment) => {
        switch (comment.commentEntityType) {
            case EntityType.Request:
                const request = await getRequest(comment.commentEntityId, comment.commentEntityType);
                history.push({ pathname: `/RequestDetail`, state: { ...request } });
                break;
            case EntityType.VehicleImpoundment:
                history.push(
                    {
                        pathname: `/ImpoundedVehicles/${comment.commentEntityId}`,
                        state: { vi: { vehicleImpoundmentId: comment.commentEntityId } }
                    });
                break;
            case EntityType.GarageKeeper:
                if (userType === UserType.Admin) {
                    history.push({ pathname: `/GarageKeepers/${comment.commentEntityId}` });
                }
                else {
                    history.push({ pathname: '/Profile' });
                }
                break;
            case EntityType.Location:
                if (userType === UserType.Admin) {
                    const location = await getLocation(comment.commentEntityId);
                    history.push({ pathname: `/GarageKeepers/${location.garageKeeperId}/compounds` });
                }
                else {
                    history.push({ pathname: '/Compounds' });
                }
                break;
            case EntityType.TowingVehicle:
                if (userType == UserType.Admin) {
                    const towing = await getTowing(comment.commentEntityId);
                    history.push({ pathname: `/GarageKeepers/${towing.garageKeeperId}/towVehicles` });
                }
                else {
                    history.push({ pathname: '/TowingVehicles' });
                }
                break;
            case EntityType.InsurancePolicy:
                if (userType == UserType.Admin) {
                    const insurance = await getInsurance(comment.commentEntityId)
                    history.push({ pathname: `/GarageKeepers/${insurance.garageKeeperId}/insuranceinfo` });
                }
                else {
                    history.push({ pathname: '/InsurancePolicies' });
                }
                break;
        }
    }

    useEffect(() => {
        const loadComments = async () => {
            let response = await getRequireAttentionComments(garageKeeper?.id);
            const email = (account?.idTokenClaims as any)?.email;

            switch (filter) {
                case 'mycomments':
                    response = response.filter(x => myCommentsFilter(x, email));
                    break;
                case 'resolved':
                    response = response.filter(x => resolvedFilter(x, email));
                    break;
                default:
                    response = response.filter(x => allCommentsFilter(x, email));
            }

            dispatch(actions.setRequiredComments(response));
            setCommentsLoaded(true);
        }

        loadComments();
    }, []);

    return (
        <>
            <DetailLayout
                title="Require Attention Comments"
                header="Require Attention Comments"
                subheader="">
                {commentsLoaded &&
                    <RequireAttenionCommentList
                        comments={list}
                        onCompleteComment={handleCommentComplete}
                        onCommentClick={handleCommentClick}
                        user={(account?.idTokenClaims as any)?.email} />}
            </DetailLayout>
        </>
    )
}

export default RequireAttentionCommentsListContainer;