import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import CustomForm from "../../Components/FormElements/CustomForm";
import FormSelect from "../../Components/FormElements/FormSelect";
import FormText from "../../Components/FormElements/FormText";
import {
  defaultImpoundmentFeeRule,
  ImpoundmentFeeRule,
} from "../../Types/VehicleImpoundmentFee";
import {
  VehicleImpoundmentFeeLabel,
  VehicleImpoundmentFeeType,
} from "../../Types/VehicleImpoundmentFeeType";

interface Props {
  data?: ImpoundmentFeeRule;
  allFees?: ImpoundmentFeeRule[];
  submit: any;
  cancel?: any;
}

const HourlyRates = [
  VehicleImpoundmentFeeType.Winching,
  VehicleImpoundmentFeeType.Other,
];
const MileageRates = [
  VehicleImpoundmentFeeType.DollyUse,
  VehicleImpoundmentFeeType.FlatDeck,
  VehicleImpoundmentFeeType.TowingHeavy,
  VehicleImpoundmentFeeType.TowingLight,
  VehicleImpoundmentFeeType.Other,
  VehicleImpoundmentFeeType.AuctionLight,
  VehicleImpoundmentFeeType.AuctionHeavy,
];

function FeeRateForm(props: Props) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowString = tomorrow.toISOString().split("T")[0];

  const editMode = props.data !== undefined;

  const minimumEffectiveDate =
    props.data?.effectiveDate != null
      ? props.data?.effectiveDate
      : tomorrowString;

  const [fee, setFee] = useState<ImpoundmentFeeRule | undefined>(
    props.data == null ? defaultImpoundmentFeeRule : props.data
  );

  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (e: any) => {
    let { name, value } = e.target;

    // Empty string doesn't parse properly, it needs to be set to null
    if (name === "expiryDate" && value === "") {
      value = null;
    }

    if (name.match(/rate/i)) {
      let temp = value;
      let mask = temp.replace(/[^0-9\\.]/g, "");

      setFee((fee) => {
        return {
          ...fee!,
          [name]:
            mask === ""
              ? 0
              : mask.match(/\.\d+$/g) // 1.2
              ? parseFloat(mask) //   is a float
              : mask.match(/\.$/g) //
              ? mask // 1. - string
              : parseInt(mask), // 1  - int
        };
      });
    } else {
      setFee((fee) => {
        return {
          ...fee!,
          [name]: value,
        };
      });
    }
  };

  const handleSubmit = async (e: any): Promise<boolean> => {
    e.preventDefault();

    const newErrors: string[] = [];

    setErrors(newErrors);

    const form = e.currentTarget;

    if (!form.checkValidity()) return false;

    const feesToCheck = props.allFees?.filter((f) => {
      return f.feeType === fee?.feeType && f.id !== fee?.id;
    });

    const existingFee = props.allFees?.find((f) => {
      return f.id === fee?.id;
    });

    if (editMode) {
      if (existingFee?.expiryDate && existingFee?.expiryDate < tomorrowString) {
        newErrors.push("Cannot update an expired Fee Rate");
      }

      if (fee?.expiryDate && fee?.expiryDate < tomorrowString)
        newErrors.push("Cannot retroactively expire a Fee Rate");
    }

    feesToCheck?.forEach((f) => {
      const existingEffectiveDate = f.effectiveDate.split("T")[0];
      const existingExpiryDate = f.expiryDate
        ? f.expiryDate.split("T")[0]
        : null;

      if (
        existingEffectiveDate <= fee?.effectiveDate! &&
        ((existingExpiryDate && fee?.effectiveDate! <= existingExpiryDate) ||
          (existingExpiryDate === null && editMode))
      ) {
        newErrors.push(
          `Effective date falls between an Effective Date and Expiry Date for another '${f.feeTypeDescription}' Fee Rate version.`
        );
      }

      if (
        fee?.expiryDate &&
        existingEffectiveDate <= fee?.expiryDate &&
        ((fee?.expiryDate &&
          existingExpiryDate &&
          fee?.expiryDate <= existingExpiryDate) ||
          existingExpiryDate === null)
      ) {
        newErrors.push(
          `Expiry date falls between an Effective Date and Expiry Date for another '${f.feeTypeDescription}' Fee Rate version.`
        );
      }

      if (
        fee?.effectiveDate &&
        existingEffectiveDate >= fee?.effectiveDate &&
        ((existingExpiryDate &&
          fee?.expiryDate &&
          existingExpiryDate <= fee?.expiryDate) ||
          !fee?.expiryDate)
      ) {
        newErrors.push(
          `Fee Rate falls within the effective range for another '${f.feeTypeDescription}' Fee Rate version.`
        );
      }
    });

    if (newErrors.length > 0) {
      setErrors([...newErrors]);
      return false;
    } else {
      return await props.submit(fee);
    }
  };

  const moneyFormat = "^([0-9]+(\.[0-9]{1,2})?)$";
  const isMileageRate = !MileageRates.includes(fee?.feeType!);
  return (
    <CustomForm
      onSubmit={handleSubmit}
      cancel={props.cancel}
      edit
      openInEditMode
    >
      <Row>
        <Form.Group as={Col} md={4}>
          {editMode && fee?.isActive ? (
            <FormText
              label="Fee Type"
              field="feeType"
              testId="feeTypeText"
              disabled
              value={fee?.feeTypeDescription}
              onChange={handleChange}
            />
          ) : (
            <FormSelect
              label="Fee Type"
              field="feeType"
              testId="feeTypeSelect"
              onChange={handleChange}
              data={Object.keys(VehicleImpoundmentFeeType).filter(
                (f) => f !== VehicleImpoundmentFeeType.Other
              )}
              mapping={(k: VehicleImpoundmentFeeType) => {
                return { value: k, text: VehicleImpoundmentFeeLabel[k] };
              }}
              sorting={(
                a: VehicleImpoundmentFeeType,
                b: VehicleImpoundmentFeeType
              ) => {
                if (
                  VehicleImpoundmentFeeLabel[a] < VehicleImpoundmentFeeLabel[b]
                ) {
                  return -1;
                }
                if (
                  VehicleImpoundmentFeeLabel[a] > VehicleImpoundmentFeeLabel[b]
                ) {
                  return 1;
                }
                return 0;
              }}
              value={fee?.feeType}
            />
          )}
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <FormText
            required
            disabled={fee?.isActive}
            testId="effectiveDate"
            type="date"
            label="Effective Date"
            field="effectiveDate"
            value={fee?.effectiveDate.split("T")[0]}
            onChange={handleChange}
            min={minimumEffectiveDate}
            invalidMessage={" must be " + minimumEffectiveDate + " or later"}
          />
        </Form.Group>
        <Form.Group as={Col} md={4}>
          {editMode && (
            <FormText
              type="date"
              testId="expiryDate"
              label="Expiry Date"
              field="expiryDate"
              value={fee?.expiryDate?.split("T")[0]}
              onChange={handleChange}
            />
          )}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={4}>
          <FormText
            required
            disabled={fee?.isActive}
            testId="baseRate"
            label="Base Rate"
            field="baseRate"
            value={fee?.baseRate}
            onChange={handleChange}
            pattern={moneyFormat}
            min={0}
            invalidMessage={
              fee?.baseRate! < 0 ? " cannot be negative" : " is not valid"
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <FormText
            disabled={fee?.isActive || !HourlyRates.includes(fee?.feeType!)}
            required={HourlyRates.includes(fee?.feeType!)}
            testId="hourlyRate"
            label="Hourly Rate"
            field="hourlyRate"
            value={fee?.hourlyRate}
            onChange={handleChange}
            pattern={moneyFormat}
            min={0}
            invalidMessage={
              fee?.hourlyRate! < 0 ? " cannot be negative" : " is not valid"
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <FormText
            disabled={fee?.isActive || isMileageRate}
            required={!isMileageRate}
            testId="mileageRate"
            label="Mileage Rate (km)"
            field="mileageRate"
            value={fee?.mileageRate}
            onChange={handleChange}
            pattern={moneyFormat}
            min={0}
            invalidMessage={
              fee?.mileageRate! < 0 ? " cannot be negative" : " is not valid"
            }
          />
        </Form.Group>
      </Row>
      <Row>
        {editMode && (
          <Form.Group as={Col} md={12}>
            <FormText
              required
              testId="changeReason"
              label="Change Reason"
              field="changeReason"
              value={fee?.changeReason ?? ""}
              onChange={handleChange}
            />
          </Form.Group>
        )}
      </Row>
      {errors.length > 0 && (
        <>
          <div style={{ fontSize: "80%", color: "#dc3545" }}>
            {errors
              .filter((e, i, a) => a.indexOf(e) === i)
              .map((e) => {
                return <li key={e}>{e}</li>;
              })}
          </div>
          <br />
        </>
      )}
    </CustomForm>
  );
}

export default FeeRateForm;
