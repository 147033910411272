import { Contact, ContactType } from "Types/contact";
import { GarageKeeper } from "Types/garageKeeper";

export const SET_GK = "GKPROFILE/SET_GK";
export const UPDATE_MAIN_OFFICE_CONTACT =
  "GKPROFILE/UPDATE_MAIN_OFFICE_CONTACT";
export const UPDATE_LAW_ENFORCEMENT_CONTACT =
  "GKPROFILE/UPDATE_LAW_ENFORCEMENT_CONTACT";
export const NO_ACTION = "GKPROFILE/NO_ACTION";

const setGK = (garageKeeper: GarageKeeper) => {
  return {
    type: SET_GK,
    garageKeeper: garageKeeper,
  };
};

const updateContact = (contact: Contact) => {
  switch (contact.contactType) {
    case ContactType.MainOffice:
      return {
        type: UPDATE_MAIN_OFFICE_CONTACT,
        contact: contact,
      };

    case ContactType.LawEnforcement:
      return {
        type: UPDATE_LAW_ENFORCEMENT_CONTACT,
        contact: contact,
      };

    default:
      return {
        type: NO_ACTION,
      };
  }
};

const GKProfileActions = {
  setGK,
  updateContact,
};

export default GKProfileActions;
