import {
  LOAD_INSURANCEPOLICIES_SUCCESS,
  SAVE_INSURANCEPOLICY_SUCCESS,
  ERROR,
  SET_INSURANCEPOLICIES,
  ADD_INSURANCE_POLICY,
  UPDATE_INSURANCE_POLICY,
  LOAD_INSURANCEPOLICIES,
  DELETE_INSURANCEPOLICY_SUCCESS,
} from "./insurancePolicyActions";
import { RequestStatus } from "../requestStatus";
import { InsurancePolicy } from "../../Types/insurancePolicy";

export interface InsurancePolicyState {
  status: RequestStatus;
  insurancePolicies: InsurancePolicy[];
}

const initialState: InsurancePolicyState = {
  insurancePolicies: [] as InsurancePolicy[],
  status: RequestStatus.IDLE,
};

export const insurancePolicyReducer: any = (
  state: InsurancePolicyState = initialState,
  action: any
): InsurancePolicyState => {
  switch (action.type) {
    case SET_INSURANCEPOLICIES:
      return {
        ...state,
        insurancePolicies: action.value,
        status: RequestStatus.IDLE,
      };

    case LOAD_INSURANCEPOLICIES:
      return {
        ...state,
        status: RequestStatus.LOADING,
      };

    case LOAD_INSURANCEPOLICIES_SUCCESS:
      return {
        ...state,
        status: RequestStatus.IDLE,
        insurancePolicies: action.value,
      };

    case SAVE_INSURANCEPOLICY_SUCCESS:
      return {
        ...state,
        status: RequestStatus.IDLE,
        insurancePolicies: [action.value, ...state.insurancePolicies],
      };

    case ADD_INSURANCE_POLICY:
      return {
        ...state,
        insurancePolicies: [action.value, ...state.insurancePolicies],
      };

    case UPDATE_INSURANCE_POLICY:
      const index = state.insurancePolicies.findIndex(
        (p) => p.id === action.value.id
      );

      if (index === -1) return state;

      let newInsurancePolicies = [...state.insurancePolicies];
      newInsurancePolicies.splice(index, 1, action.value);

      return {
        ...state,
        insurancePolicies: newInsurancePolicies,
        status: RequestStatus.IDLE,
      };

    case DELETE_INSURANCEPOLICY_SUCCESS:
      return {
        ...state,
        insurancePolicies: state.insurancePolicies.filter(
          (ip) => ip.id !== action.insurancePolicyId
        ),
        status: RequestStatus.IDLE,
      };

    case ERROR:
      return {
        ...state,
        status: RequestStatus.ERROR,
      };

    default:
      return state;
  }
};
