import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "Redux/allActions";
import { RequestType, RequestTypeLabel } from "Types/requestType";
import { RequestStatus } from "Types/request";
import { Table } from "react-bootstrap";

interface DashboardGkFyiRequestCountProps {
  types: RequestType[];
  title?: string;
  history: any;
}

const DashboardGkFyiRequestCount = ({
  title,
  types,
  history,
}: DashboardGkFyiRequestCountProps) => {
  const { RecentlyCompleted } = useSelector(
    (state: RootStateOrAny) => state.dashboard.requestCounts
  );

  const dispatch = useDispatch();

  return (
    <Table style={{ textAlign: "center" }}>
      <tbody>
        <tr>
          <td className="border-top-0" colSpan={2}>
            <div className="font-weight-bold text-dark text-uppercase mb-1 text-left">
              {title}
            </div>
          </td>
        </tr>
        <tr>
          <td
            className="text-left"
            style={{
              borderTop: "none",
              paddingTop: 0,
              fontWeight: "bold",
            }}
          >
            Type
          </td>
          <td
            style={{
              borderTop: "none",
              paddingTop: 0,
              fontWeight: "bold",
            }}
          >
            {"Completed"}
          </td>
        </tr>
        {types.map((t) => (
          <tr key={t}>
            <td style={{ textAlign: "left" }}>
              {RequestTypeLabel[t as keyof typeof RequestType]}
            </td>
            <td>
              <div
                className="dashboardTableCountButton"
                data-testid={`"recentlyCompleted-"${t}`}
                onClick={() => {
                  dispatch(
                    allActions.RequestActions.filterStatus(
                      RequestStatus.Completed
                    )
                  );
                  dispatch(allActions.RequestActions.filterRecent(true));
                  dispatch(allActions.RequestActions.filterType(t));
                  history.push({
                    pathname: "/RequestList",
                  });
                }}
              >
                {RecentlyCompleted[t]}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DashboardGkFyiRequestCount;
