import { getAccessToken } from "./apiUtils";
import { assignIn } from "lodash";

export async function CallBffApi(
  input: RequestInfo,
  init?: RequestInit | undefined,
  multipartFormData: boolean = false
): Promise<Response> {
  const accessToken = await getAccessToken();

  if (!accessToken) return new Promise<Response>(() => {});

  if (!init) {
    init = {};
  }

  if (!init.headers) {
    init.headers = {};
  }

  assignIn(init.headers, {
    Authorization: `Bearer ${accessToken}`,
  });

  if (!multipartFormData) {
    assignIn(init.headers, {
      "Content-Type": "application/json",
    });
  }

  const response = await fetch(input, init);

  return response;
}

export default CallBffApi;
