import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";
import { getConfig } from "./authConfig";

export var msalInstance = new PublicClientApplication(getConfig());

export const initializeMsalInstance = () => {
  //to initialize on demand after config is loaded
  msalInstance = new PublicClientApplication(getConfig());

  console.log(
    "msal init. Config is typically not loaded first time this happens"
  );

  msalInstance.addEventCallback((event) => {
    const authResult = event.payload as AuthenticationResult;
    if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
      const account = authResult.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const accounts = msalInstance.getAllAccounts();
  if (accounts !== undefined) {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
  }
};
