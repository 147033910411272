import ReusableModal from "Components/Modal/ReusableModal";
import BulletinForm from "Forms/AdminForms/BulletinForm";
import { useCallback, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import CallBffApi from "Utils/CallBff";
import { Bulletin, BulletinType } from "Types/bulletin";
import BulletinElement from "./BulletinElement";
import { RootStateOrAny, useSelector } from "react-redux";
import { UserPermissions } from "Types/userInfo";

interface Props {
  submit: any;
  bulletinType: BulletinType;
}

export default function BulletinListAdmin({ submit, bulletinType }: Props) {
  const [loading, setLoading] = useState("Loading");
  const [showForm, setShowForm] = useState(false);
  const [bulletins, setBulletins] = useState<Bulletin[]>([]);
  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  const modifyBulletin = (bulletin: Bulletin) => {
    const index = bulletins.findIndex((b) => b.id === bulletin.id);

    if (index === -1) return;

    let newBulletins = [...bulletins];
    newBulletins.splice(index, 1, bulletin);
    setBulletins(newBulletins);
  };

  const loadBulletins = useCallback(async () => {
    const response = await submit(
      `${window.REACT_APP_API_BASEURL}bulletins?bulletinTypes=${bulletinType}`
    );

    if (response.ok) setBulletins(await response.json());

    setLoading("Loaded");
  }, [submit, bulletinType]);

  useEffect(() => {
    if (bulletins.length === 0 && loading !== "Loaded") loadBulletins();
  }, [bulletins, loading, loadBulletins]);

  return (
    <>
      {userPermissions.canCreateBulletins &&
        <ReusableModal
          buttonText={`Add ${bulletinType}`}
          show={showForm}
          open={() => setShowForm(true)}
          close={() => setShowForm(false)}
        >
          <BulletinForm
            onCancel={() => setShowForm(false)}
            onSubmit={CallBffApi}
            onSubmitSuccess={(b) => setBulletins([b, ...bulletins])}
            bulletinType={bulletinType}
          />
        </ReusableModal>
      }
      <br />
      <br />
      {bulletins.length > 0 ? (
        <>
          <Row>
            <Col xl={6} lg={9} md={12}>
              {bulletins
                .filter((b) => !b.archived)
                .map((b: any) => {
                  return (
                    <BulletinElement
                      key={b.id}
                      bulletin={b}
                      onSuccess={modifyBulletin}
                      onDelete={(deletedBulletinId: string) => {
                        setBulletins(
                          bulletins.filter((b) => b.id !== deletedBulletinId)
                        );
                      }}
                    />
                  );
                })}
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={6} lg={9} md={12}>
              {bulletins
                .filter((b) => b.archived)
                .map((b: any) => {
                  return (
                    <BulletinElement
                      key={b.id}
                      bulletin={b}
                      onSuccess={modifyBulletin}
                      onDelete={(deletedBulletinId: string) => {
                        setBulletins(
                          bulletins.filter((b) => b.id !== deletedBulletinId)
                        );
                      }}
                    />
                  );
                })}
            </Col>
          </Row>
        </>
      ) : (
        <Alert variant="dark">
          <i>No {bulletinType} found</i>
        </Alert>
      )}
    </>
  );
}
