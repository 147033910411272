import { Alert, Button, Row, Col, Form, Spinner } from "react-bootstrap";
import AttachmentInput from "Components/Attachments/AttachmentInput";
import AttachmentElement from "Components/Attachments/AttachmentElement";
import Loader from "Components/Loader/Loader";
import { Attachment } from "Types/attachment";
import { EntityType } from "Types/EntityType";
import { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import DetailLayout from "Layouts/DetailLayout";
import {
  defaultRequest,
  Request,
  RequestStatus,
  RequestStatusLabel,
} from "Types/request";
import CallBffApi from "Utils/CallBff";
import { useLocation } from "react-router";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import allActions from "Redux/allActions";
import CommentContainer from "Components/Comments/CommentContainer";
import { doToast, ToastType } from "Utils/toastUtils";
import { RequestType, RequestTypeLabel } from "Types/requestType";
import ReusableModal from "Components/Modal/ReusableModal";
import VehicleImpoundmentForm from "Forms/VehicleImpoundmentForm/VehicleImpoundmentForm";
import { VehicleImpoundment } from "Types/vehicleImpoundment";
import { UserPermissions, UserType } from "Types/userInfo";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import RequestViewRelatedButton from "./RequestViewRelatedButton";
import FormText from "Components/FormElements/FormText";
import guid from "Utils/guid";
import { getAttacmentTypes, createRequest } from "api/requestApi";
import { ImpoundedVehicle } from "Types/impoundedVehicle";
import RequestRelatedImpoundments from "./RequestRelatedImpoundments";
import { assignRequest } from "api/requestApi";

interface LocationState {
  id: string;
  garageKeeperId: string;
  entityId: string | null;
  type: RequestType;
  status: RequestStatus;
}

interface Props {
  history: any;
}

interface ConfirmationBox {
  showConfirmation: boolean;
  headerText: string;
  confirmButtonText: string;
  dialogText: string;
  newStatus: RequestStatus;
}

export enum RequestLoadingStatus {
  Initial,
  Loading,
  Loaded,
  Failed,
  Reload,
}

export default function RequestDetail({ history }: Props) {
  const [validated, setValidated] = useState(false);

  const location = useLocation<LocationState>();

  const [loadingStatus, setLoadingStatus] = useState<RequestLoadingStatus>(
    RequestLoadingStatus.Initial
  );

  const newRequest = location.state.id === undefined;
  const newRequestId = guid();
  const [request, setRequest] = useState<Request>({
    ...defaultRequest,
    id: location.state.id,
    garageKeeperId: location.state.garageKeeperId,
    entityId: location.state.entityId,
    type: location.state.type,
    status: location.state.status,
  });

  const { userPermissions }: { userPermissions: UserPermissions } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const readonly = !(userPermissions.canUpdateRequestsForAllGarageKeepers || userPermissions.isGarageKeeper);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;

  const dispatch = useDispatch();

  const requestUrl = `${(window as any).REACT_APP_API_BASEURL}requests`;

  const attachmentUrl = `${(window as any).REACT_APP_API_BASEURL
    }attachments/entityId=${request.id}?entityType=${EntityType.Request}`;

  const [idAttachmentInChange, setIdAttachmentInChange] = useState("");

  const [attachmentCount, setAttachmentCount] = useState(0);

  const [notEnoughAttachments, setNotEnoughAttachments] = useState(false);

  const [assignButtonDiabled, setAssignButtonDisabled] = useState(false);

  const confirmationBoxInitialState: ConfirmationBox = {
    showConfirmation: false,
    headerText: "",
    dialogText: "",
    confirmButtonText: "",
    newStatus: request.status,
  };

  const [confirmationBox, setconfirmationBox] = useState<ConfirmationBox>(
    confirmationBoxInitialState
  );

  const attachmentList = useSelector(
    (state: RootStateOrAny) => state.attachments.attachments
  );

  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );

  const updateCount = (increment: boolean, difference: number) => {
    if (increment) setAttachmentCount(attachmentCount + difference);
    else setAttachmentCount(attachmentCount - difference);
  };

  const fetchRequest = useCallback(async () => {
    const response = await CallBffApi(
      `${requestUrl}/${request.id}`,
      {
        method: "GET",
        body: null,
      }
    );

    if (response.ok) {
      var result = await response.json();
      setRequest(result);
      setLoadingStatus(RequestLoadingStatus.Loaded);
    } else {
      setLoadingStatus(RequestLoadingStatus.Failed);
    }
  }, [request, requestUrl]);

  useEffect(() => {
    if (
      loadingStatus === RequestLoadingStatus.Initial ||
      loadingStatus === RequestLoadingStatus.Reload
    ) {
      setLoadingStatus(RequestLoadingStatus.Loading);
      if (!newRequest)
        fetchRequest();
      else {
        const fetchAttahmentsTypes = async () => {
          const attachmentTypes = await getAttacmentTypes(location.state.type);
          setRequest(req => {
            return {
              ...req,
              attachmentNames: attachmentTypes ?? []
            }
          })
        }
        fetchAttahmentsTypes();
        setLoadingStatus(RequestLoadingStatus.Loaded);
      }
    }
  }, [loadingStatus, fetchRequest]);

  const vehicleImpoundment = useSelector(
    (state: RootStateOrAny) => state.vehicleImpoundment.vehicleImpoundment
  );

  useEffect(() => {
    if (
      request.entityId == null &&
      (vehicleImpoundment as VehicleImpoundment).garageKeeperId ===
      request.garageKeeperId
    ) {
      setLoadingStatus(RequestLoadingStatus.Reload);
    }
  }, [request.entityId, request.garageKeeperId, vehicleImpoundment]);

  useEffect(() => {
    if (notEnoughAttachments) {
      setNotEnoughAttachments(attachmentCount < request.numberOfAttachments);
    }
  }, [attachmentCount, notEnoughAttachments, request.numberOfAttachments]);

  const onSubmitHandler = async (event: any): Promise<boolean> => {
    event.preventDefault();

    setNotEnoughAttachments(attachmentCount < request.numberOfAttachments);

    if (attachmentCount < request.numberOfAttachments || !event.currentTarget.checkValidity()) {
      setValidated(true);
      return false;
    }

    return changeRequest(RequestStatus.AssignedToSgi, request.policeFileNumber, request.vinNumber);
  };

  const changeRequest = async (
    status: RequestStatus,
    policeFileNumber?: string,
    vinNumber?: string,
    silent: boolean = false
  ): Promise<boolean> => {
    try {
      let body: any = { status };

      if (policeFileNumber) {
        body = { ...body, policeFileNumber };
      }

      if (vinNumber) {
        body = { ...body, vinNumber };
      }

      const response = await CallBffApi(
        `${requestUrl}/${userType === UserType.GarageKeeper ? "external/" : ""
        }${request.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        if (!silent)
          doToast("Request updated successfully", ToastType.Success);

        fetchRequest();
        setconfirmationBox(confirmationBoxInitialState);

        return true;
      } else {

        if (!silent)
          doToast("An error occurred!", ToastType.Error);
      }
    } catch (e) {

      if (!silent)
        doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const assign = async () => {
    try {
      setAssignButtonDisabled(true);

      const assigneeInfo = {
        //checking against userId for both, just so they stay in sync
        assignedToUserName: request.assignedToUserId
          ? null
          : (account?.idTokenClaims as any)!.name,
        assignedToUserId: request.assignedToUserId
          ? null
          : (account?.idTokenClaims as any)!.email,
        changeAssignedTo: true,
      };

      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}requests/${request.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(assigneeInfo),
        }
      );

      if (response.ok) {
        setRequest({
          ...request,
          assignedToUserName: assigneeInfo.assignedToUserName,
          assignedToUserId: assigneeInfo.assignedToUserId,
        });
        doToast("Request assignee changed successfully", ToastType.Success);
        setAssignButtonDisabled(false);

        return true;
      } else {
        setAssignButtonDisabled(false);
        doToast("An error occurred!", ToastType.Error);
      }
    } catch (e) {
      setAssignButtonDisabled(false);
      doToast("An error occurred!", ToastType.Error);
    }
  };

  const handleUpdate = (event: any) => {
    setRequest({
      ...request,
      [event.target.name]: event.target.value,
    });
  };

  const makeNewRequest = async () => {
    const resultRequest = await createRequest(newRequestId, request);

    if (resultRequest !== undefined)
      setRequest(resultRequest);
  }

  useEffect(() => {
    if (request.id === undefined && attachmentCount > 0) {
      makeNewRequest();
    }
  }, [attachmentCount])

  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  const handleRequestPropertyChanged = async () => {
    if (request.id === undefined && (
      (request.policeFileNumber?.length ?? 0) || (request.vinNumber?.length ?? 0)) > 0)
      await makeNewRequest();
    else
      changeRequest(request.status, request.policeFileNumber, request.vinNumber, true);
  }

  const handleNewRequestCheck = async () => {
    if (request.id === undefined) {
      await makeNewRequest();
    }
  }

  const handleCompleteRequest = async () => {
    await changeRequest(RequestStatus.Completed);
  }

  const handleImpoundmentAssignment = async (impoundmentId: string) => {
    try {
      await assignRequest(request.id, impoundmentId);
      doToast("Request was assigned successfully.", ToastType.Success);
      setRequest({
        ...request,
        status: RequestStatus.Completed,
        entityId: impoundmentId
      });
    }
    catch {
      doToast("Request assign failed.", ToastType.Success);
    }
  }

  return (
    <DetailLayout
      title="Request"
      header={RequestTypeLabel[request.type] + " Request"}
      subheader=""
      notification={
        <div style={{ color: "black" }}>
          <CommentContainer
            id={request.id === undefined ? newRequestId : request.id}
            count={request.commentCount}
            size="lg"
            entityType={EntityType.Request}
            onOpen={handleNewRequestCheck}
            parentEntityExist={request.id !== undefined}
            orgId={userType === UserType.GarageKeeper ? undefined : location.state.garageKeeperId}
            readOnly={readonly}
          />
          {(request.id !== undefined && request.status === RequestStatus.New ||
            (userPermissions.canUpdateRequestsForAllGarageKeepers &&
              (request.status === RequestStatus.AssignedToGk ||
                request.status === RequestStatus.AssignedToSgi))) && (
              <span
                data-testid="cancel-request"
                title="Cancel"
                style={{ paddingLeft: "10px" }}
                onClick={() => {
                  setconfirmationBox({
                    showConfirmation: true,
                    confirmButtonText: "Cancel Request",
                    headerText: "Cancel Request?",
                    dialogText: "Are you sure you want to cancel this request?",
                    newStatus: RequestStatus.Cancelled,
                  });
                }}
              >
                <i className="fas fa-trash-alt delete-button fa-2x" />
              </span>
            )}
          {(request.id !== undefined && userPermissions.canUpdateRequestsForAllGarageKeepers
            && request.status !== RequestStatus.Cancelled && request.status !== RequestStatus.Completed
            && request.type === RequestType.NewInfo) &&
            <span
              data-testid="complete-request"
              title="Complete"
              style={{ paddingLeft: "10px" }}
              onClick={async () => await handleCompleteRequest()}>
              <i className="fas fa-check complete-button fa-2x" />
            </span>
          }
        </div>
      }
    >
      <ConfirmDelete
        show={confirmationBox.showConfirmation}
        onClickDelete={() => {
          changeRequest(confirmationBox.newStatus);
        }}
        onClickCancel={() => {
          setconfirmationBox(confirmationBoxInitialState);
        }}
        deleteButtonText={confirmationBox.confirmButtonText}
        headerText={confirmationBox.headerText}
        dialogText={confirmationBox.dialogText}
      />
      <div style={{ float: "right" }}>
        {userType === UserType.Admin &&
          request.status === RequestStatus.AssignedToSgi &&
          request.type === RequestType.Impoundment &&
          request.entityId == null && (
            <>
              <ReusableModal
                buttonText="Add New Impoundment"
                headerText="Add New Impoundment"
                open={() => {
                  displayForm();
                }}
                close={hideForm}
                show={showForm}
              >
                <VehicleImpoundmentForm
                  submit={CallBffApi}
                  cancel={hideForm}
                  requestId={request.id}
                  garageKeeperId={request.garageKeeperId}
                  policeFileNumber={request.policeFileNumber}
                  history={history}
                />
              </ReusableModal>
              {request.vinNumber != null &&
                <RequestRelatedImpoundments
                  gkId={request.garageKeeperId}
                  vin={request.vinNumber!}
                  assign={handleImpoundmentAssignment} />}
            </>
          )}
        {userPermissions?.canUpdateRequestsForAllGarageKeepers && (
          <div>
            {`Assigned To: `}
            {request.assignedToUserId ? (
              <span title={request.assignedToUserId}>
                {request.assignedToUserName}
              </span>
            ) : (
              "<none>"
            )}
            <br />
            <Button
              variant="primary"
              onClick={() => assign()}
              disabled={assignButtonDiabled}
            >
              {request.assignedToUserId ? "Unassign" : "Assign to me"}
            </Button>
          </div>
        )}
        {request.entityId != null && (
          <span style={{ float: "right" }}>
            <br />
            <RequestViewRelatedButton
              history={history}
              request={request}
              userType={userType}
            />
          </span>
        )}
      </div>
      <div data-testid="request-status">
        {`Status: ${RequestStatusLabel[request.status as keyof typeof RequestStatusLabel]
          }`}
      </div>
      {(request.status === RequestStatus.AssignedToGk
        && (userPermissions.canUpdateRequestsForAllGarageKeepers || userPermissions.isGarageKeeper)) &&
        (
          <Button
            data-testid="assign-to-sgi"
            onClick={() => {
              setconfirmationBox({
                showConfirmation: true,
                confirmButtonText: "Assign",
                headerText: "Assign to SGI?",
                dialogText:
                  "Are you sure you want to assign this request to SGI?",
                newStatus: RequestStatus.AssignedToSgi,
              });
            }}
          >
            {"Assign to SGI"}
          </Button>
        )}
      {request.status === RequestStatus.AssignedToSgi &&
        userType === UserType.Admin && (
          <Button
            data-testid="assign-to-gk"
            onClick={() => {
              setconfirmationBox({
                showConfirmation: true,
                confirmButtonText: "Assign",
                headerText: "Assign to Garage Keeper?",
                dialogText:
                  "Are you sure you want to assign this request to Garage Keeper?",
                newStatus: RequestStatus.AssignedToGk,
              });
            }}
          >
            {"Assign to Garage Keeper"}
          </Button>
        )}
      <br />
      <div>
        <div data-testid="attachment-count">
          Attachments: {attachmentCount} / {request.numberOfAttachments}
        </div>
        {notEnoughAttachments && (
          <div style={{ color: "red" }} data-testid="not-enough-attachments">
            You need to attach {request.numberOfAttachments} attachments!
          </div>
        )}
      </div>
      <br />

      {request.attachmentNames.length > 0 && <div>
        Must attach the following documents:
        <ul>
          {request.attachmentNames.map((x) => {
            return <li key={x}>{x}</li>;
          })}
        </ul>
      </div>}

      <Loader
        url={attachmentUrl}
        onLoadComplete={(result: Attachment[]) => {
          dispatch(allActions.AttachmentActions.setAttachments(result));
          setAttachmentCount(result.length);
        }}
        disabled={request.id === undefined}
      >
        <Row>
          <Col lg={6} sm={12}>
            <div style={{ padding: "10px" }}>
              {!readonly &&
                <AttachmentInput
                  entityId={request.id == undefined ? newRequestId : request.id}
                  entityType={EntityType.Request}
                  userName={account?.name!}
                  updateCount={updateCount}
                  showFileSelectButton={
                    request.status === RequestStatus.New ||
                    request.status === RequestStatus.AssignedToGk
                  }
                  requiredDocumentTypes={Object.values(request.attachmentNames)}
                  onPreSave={handleNewRequestCheck}
                />
              }
            </div>
            {attachmentList.length > 0 ? (
              <div data-testid="attachment-list">
                {attachmentList.map((file: Attachment) => {
                  return (
                    <AttachmentElement
                      attachment={file}
                      key={file.id}
                      userName={account?.name!}
                      idAttachmentInChange={idAttachmentInChange}
                      setIdAttachmentInChange={setIdAttachmentInChange}
                      updateCount={updateCount}
                      readonly={readonly}
                    />
                  );
                })}
              </div>
            ) : (
              <div style={{ padding: "10px" }}>
                <Alert variant="dark">
                  <i data-testid="no-attachments-found">No attachments found</i>
                </Alert>
              </div>
            )}
          </Col>
        </Row>
      </Loader>
      <Form
        data-testid="custom-form"
        onSubmit={onSubmitHandler}
        noValidate
        validated={validated}
      >
        {request.type === RequestType.Impoundment && (
          <>
            <Form.Row as={Col} lg="4" md="6">
              <FormText
                required={request.status === RequestStatus.New}
                testId={"request-police-file-number"}
                label="Police File Number"
                field="policeFileNumber"
                pattern=".{1,40}"
                value={request.policeFileNumber}
                onChange={handleUpdate}
                onBlur={handleRequestPropertyChanged}
                disabled={request.status !== RequestStatus.New || readonly}
                invalidMessage={!request.policeFileNumber ? " is required" : " must be 40 characters or less"}
              />
            </Form.Row>
            <br />
            <Form.Row as={Col} lg="4" md="6">
              <FormText
                required={request.status === RequestStatus.New}
                testId={"request-vin-number"}
                label="VIN Number"
                field="vinNumber"
                pattern="^[a-zA-Z0-9]{1,17}$"
                value={request.vinNumber}
                onChange={handleUpdate}
                onBlur={handleRequestPropertyChanged}
                disabled={request.status !== RequestStatus.New || readonly}
                invalidMessage={
                  !request.vinNumber || (request.vinNumber.length < 1 || request.vinNumber.length > 17) 
                    ? " must be between 1-17 characters" 
                    : " must only contains letters and numbers"
                }
              />
            </Form.Row>
            <br />
          </>
        )}
        {request.status === RequestStatus.New && !readonly && (
          <Button
            variant="primary"
            disabled={false}
            type="submit"
            id={"submit-request"}
            data-testid={"submit-request"}
            style={{ width: "6rem" }}
          >
            Submit
          </Button>
        )}
      </Form>
    </DetailLayout>
  );
}
