import { useEffect, useState } from "react";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  onChange?(): any;
}

function Checkbox({ checked = false, disabled = false, onChange }: Props) {
  const [isChecked, setChecked] = useState(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const toggleCheckbox = () => {
    if (!disabled) setChecked(!isChecked);
  };

  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        marginRight: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid",
        borderColor: disabled ? "grey" : "black",
        borderRadius: "4px",
        cursor: disabled ? "initial" : "pointer",
        backgroundColor: disabled ? "lightgrey" : "white",
        color: disabled ? "grey" : "initial",
        userSelect: "none",
      }}
      onClick={toggleCheckbox}
    >
      {isChecked && <span>✓</span>}
    </div>
  );
}

export default Checkbox;
