import { Table, Row, Container } from "react-bootstrap";

import ImpoundedVehicleListActions from "Redux/impoundedvehicles/ImpoundedVehicleListActions";
import { ImpoundedVehicleListInfo } from "Types/impoundedVehicleListInfo";
import { ImpoundedVehicleStatusLabel } from "Types/impoundedVehicleStatus";
import { formatDate } from "Utils/format";
import SortButton from "Components/SortButton/SortButton";
import FilterBar from "./Elements/FilterBar";

interface Props {
  history: any;
  impoundments: ImpoundedVehicleListInfo[];
  sortField: string;
  sortDirection: string;
  profileView?: boolean;
  garageKeeperId?: string;
  external?: boolean;
}

export default function ImpoundedVehicleTable({
  history,
  impoundments,
  sortField,
  sortDirection,
  profileView = false,
  garageKeeperId,
  external,
}: Props) {
  const tableFields = [
    { label: "VIN", property: "vin", type: "String" },
    { label: "Police File #", property: "policeFileNumber", type: "String" },
    { label: "Lot Location", property: "locationAddress", type: "String" },
    { label: "Term Start Date", property: "termStartDate", type: "Date" },
    { label: "Term End Date", property: "termEndDate", type: "Date" },
    { label: "Days Impounded", property: "daysImpounded", type: "String" },
    { label: "Garage Keeper", property: "garageKeeperName", type: "String" },
    { label: "Wrongful", property: "wrongful", type: "BooleanCheck" },
    { label: "Status", property: "status", type: "String" },
    {
      label: "Inv. Form Missing",
      property: "inventoryFormMissing",
      type: "BooleanX",
    },
  ].filter((x) => {
    // This filter effectively toggles between views
    if (profileView) return x.label !== "Garage Keeper";
    else return x.label !== "Lot Location";
  });

  return (
    <Container fluid>
      <FilterBar
        garageKeeperId={garageKeeperId}
        external={external}
        profileView={profileView}
      />
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              {tableFields.map((x, i) => {
                return (
                  <th key={i}>
                    <SortButton
                      property={x.property}
                      label={x.label}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      action={ImpoundedVehicleListActions.sortList}
                    />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody data-testid="impounded-vehicle-table">
            {impoundments.length > 0 &&
              impoundments.map((vi: ImpoundedVehicleListInfo) => {
                return (
                  <tr
                    key={vi.vehicleImpoundmentId}
                    onClick={() => {
                      history.push({
                        pathname: `/ImpoundedVehicles/${vi.vehicleImpoundmentId}`,
                        state: { vi },
                      });
                    }}
                  >
                    {tableFields.map((x) => {
                      if (x.type === "Date")
                        return (
                          <td key={`${vi.vehicleImpoundmentId}-${x.property}`}>
                            {formatDate(vi[x.property])}
                          </td>
                        );
                      else if (x.type === "BooleanX") {
                        return (
                          <td
                            key={`${vi.vehicleImpoundmentId}-${x.property}`}
                            style={{ textAlign: "center" }}
                          >
                            {vi[x.property] && (
                              <div>
                                <i className="fas fa-times"></i>
                              </div>
                            )}
                          </td>
                        );
                      } else if (x.type === "BooleanCheck") {
                        return (
                          <td
                            key={`${vi.vehicleImpoundmentId}-${x.property}`}
                            style={{ textAlign: "center" }}
                          >
                            {vi[x.property] && (
                              <div>
                                <i className="fas fa-check"></i>
                              </div>
                            )}
                          </td>
                        );
                      } else if (x.label === "Status")
                        return (
                          <td key={`${vi.vehicleImpoundmentId}-${x.property}`}>
                            {
                              ImpoundedVehicleStatusLabel[
                                vi[
                                  x.property
                                ] as keyof typeof ImpoundedVehicleStatusLabel
                              ]
                            }
                          </td>
                        );
                      else
                        return (
                          <td key={`${vi.vehicleImpoundmentId}-${x.property}`}>
                            {vi[x.property]}
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
