import { msalInstance } from "./initializeMsal";

export async function getAccessToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return;
  }
  const authResult = await msalInstance
    .acquireTokenSilent({
      scopes: [(window as any)["runConfig"]?.REACT_APP_B2C_BFF_API_SCOPES],
      account: account,
    })
    .catch((error) => {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect({
        scopes: [(window as any)["runConfig"]?.REACT_APP_B2C_BFF_API_SCOPES],
        account: account,
      });
    });

  return authResult?.accessToken;
}

export function buildQueryString(obj: any) {
  let qs = "";
  Object.keys(obj).forEach((key) => {
    // ignore if property is an object
    if (obj[key] !== Object(obj[key]))
      qs += `${key}=${encodeURIComponent(obj[key])}&`;
  });
  // trim final '&' from string
  return qs.slice(0, -1);
}
