import { Col, Form } from "react-bootstrap";
import { VehicleImpoundment } from "../../Types/vehicleImpoundment";
import Accordion from "../../Components/Accordion/Accordion";
import FormText from "../../Components/FormElements/FormText";
import { useState } from "react";

interface Props {
  handleUpdate(event: any): void;
  vehicleImpoundment: VehicleImpoundment;
  control: boolean;
}

export function VehicleDetailsForm({
  handleUpdate,
  vehicleImpoundment,
  control,
}: Props) {

  return (
    <>
      <Accordion title="Vehicle Details" defaultShow control={control}>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formVin">
            <FormText
              required
              label="VIN"
              testId={"vin"}
              field="impoundedVehicle.vin"
              pattern="^[a-zA-Z0-9]{1,17}$"
              invalidMessage={
                vehicleImpoundment.impoundedVehicle.vin.length < 1 ||
                  vehicleImpoundment.impoundedVehicle.vin.length > 17
                  ? "must be between 1-17 characters"
                  : "must only contain letters and numbers"
              }
              value={vehicleImpoundment.impoundedVehicle.vin}
              onChange={handleUpdate}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formLicensePlate">
            <FormText
              label="Licence Plate"
              testId={"licensePlate"}
              field="impoundedVehicle.licensePlate"
              pattern="^[a-zA-Z0-9 -]{1,10}$"
              invalidMessage={
                vehicleImpoundment.impoundedVehicle.licensePlate !==
                  undefined &&
                  vehicleImpoundment.impoundedVehicle.licensePlate.length > 10
                  ? "must be 10 characters or less"
                  : "must only only contains letters, numbers, spaces, hyphens"
              }
              value={vehicleImpoundment.impoundedVehicle.licensePlate}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="4" controlId="formYear">
            <FormText
              type="number"
              label="Year"
              testId={"year"}
              field="impoundedVehicle.year"
              value={vehicleImpoundment.impoundedVehicle.year}
              pattern="^[0-9]{4}$"
              onChange={handleUpdate}
              min="1886"
              max={new Date().getFullYear() + 1}
              invalidMessage={
                vehicleImpoundment.impoundedVehicle.year
                  ? "Year must be between 1886 and " +
                  (new Date().getFullYear() + 1)
                  : ""
              }
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formMake">
            <FormText
              required
              label="Make"
              testId={"make"}
              field="impoundedVehicle.make"
              pattern="^[a-zA-Z ]{1,120}"
              invalidMessage={
                vehicleImpoundment.impoundedVehicle.make.length > 120
                  ? " must be 120 characters or less"
                  : " must only contain letters and spaces"
              }
              value={vehicleImpoundment.impoundedVehicle.make}
              onChange={handleUpdate}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formModel">
            <FormText
              required
              label="Model"
              testId={"model"}
              field="impoundedVehicle.model"
              pattern=".{1,120}"
              invalidMessage=" must be 120 characters or less"
              value={vehicleImpoundment.impoundedVehicle.model}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="4" controlId="formRegisteredOwner">
            <FormText
              label="Registered Owner"
              testId={"registeredOwner"}
              field="impoundedVehicle.registeredOwner"
              pattern=".{1,300}"
              invalidMessage=" must be 300 characters or less"
              value={vehicleImpoundment.impoundedVehicle.registeredOwner}
              onChange={handleUpdate}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md="4"
            controlId="formRegisteredOwnerPhoneNumber"
          >
            <FormText
              label="R.O. Phone Number"
              testId={"registeredOwnerPhone"}
              field="impoundedVehicle.registeredOwnerPhoneNumber"
              pattern="^[(]?[0-9]{3}[)-]? ?[0-9]{3}[-]?[0-9]{4}$"
              invalidMessage=" must be a 10 digit number"
              value={
                vehicleImpoundment.impoundedVehicle.registeredOwnerPhoneNumber
              }
              onChange={handleUpdate}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formRegisteredOwnerCustNo">
            <FormText
              label="R.O. Customer Number"
              testId={"registeredOwnerCustNo"}
              field="impoundedVehicle.registeredOwnerCustNo"
              pattern="^[0-9]{1,15}"
              invalidMessage=" must be 15 digits or less"
              value={vehicleImpoundment.impoundedVehicle.registeredOwnerCustNo}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="5">
            <Form.Label className="edit-form-label">Driver the same as registered owner</Form.Label>
          </Form.Group>
          <Form.Group as={Col} md="1">
            <Form.Check
              name="isDriverTheSameAsRegisteredOwner"
              checked={vehicleImpoundment.impoundedVehicle.isDriverTheSameAsRegisteredOwner ?? false}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="4" controlId="formDriver">
            <FormText
              label="Driver"
              testId={"driver"}
              field="impoundedVehicle.driver"
              pattern=".{1,300}"
              invalidMessage=" must be 300 characters or less"
              value={vehicleImpoundment.impoundedVehicle.driver}
              onChange={handleUpdate}
              disabled={vehicleImpoundment.impoundedVehicle.isDriverTheSameAsRegisteredOwner}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formDriverPhoneNumber">
            <FormText
              label="Driver Phone Number"
              testId={"driverPhone"}
              field="impoundedVehicle.driverPhoneNumber"
              pattern="^[(]?[0-9]{3}[)-]? ?[0-9]{3}[-]?[0-9]{4}$"
              invalidMessage=" must be a 10 digit number"
              value={vehicleImpoundment.impoundedVehicle.driverPhoneNumber}
              onChange={handleUpdate}
              disabled={vehicleImpoundment.impoundedVehicle.isDriverTheSameAsRegisteredOwner}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formDriverCustNo">
            <FormText
              label="Driver Customer Number"
              testId={"DriverCustNo"}
              field="impoundedVehicle.driverCustNo"
              pattern="^[0-9]{1,15}"
              invalidMessage=" must be 15 digits or less"
              value={vehicleImpoundment.impoundedVehicle.driverCustNo}
              onChange={handleUpdate}
              disabled={vehicleImpoundment.impoundedVehicle.isDriverTheSameAsRegisteredOwner}
            />
          </Form.Group>
        </Form.Row>
      </Accordion>
    </>
  );
}

export default VehicleDetailsForm;
