import {
  InsurancePolicyTypes,
  InsuranceState,
} from "../Types/insurancePolicy";

export interface InsurancePolicyCounts {
  ExpiredCollisionUpset: number;
  ExpiringCollisionUpset: number;
  ExpiredComprehensive: number;
  ExpiringComprehensive: number;
}

export const defaultInsurancePolicyCounts: InsurancePolicyCounts = {
  ExpiredCollisionUpset: 0,
  ExpiringCollisionUpset: 0,
  ExpiredComprehensive: 0,
  ExpiringComprehensive: 0,
};

export enum InsurancePolicyStatus {
  ExpiredCollisionUpset = "ExpiredCollisionUpset",
  ExpiringCollisionUpset = "ExpiringCollisionUpset",
  ExpiredComprehensive = "ExpiredComprehensive",
  ExpiringComprehensive = "ExpiringComprehensive",
}

export const InsurancePolicyStatusLabel: {
  [key in InsurancePolicyStatus]: string;
} = {
  [InsurancePolicyStatus.ExpiredCollisionUpset]: "Expired Collision/Upset",
  [InsurancePolicyStatus.ExpiringCollisionUpset]: "Expiring Collision/Upset",
  [InsurancePolicyStatus.ExpiredComprehensive]: "Expired Comprehensive",
  [InsurancePolicyStatus.ExpiringComprehensive]: "Expiring Comprehensive",
};

export function getInsuranceStatusAndType(status: InsurancePolicyStatus) {
  let insuranceStatus: string = "",
    insuranceType: string = "";

  switch (status) {
    case InsurancePolicyStatus.ExpiredCollisionUpset:
      insuranceStatus = InsuranceState.Expired;
      insuranceType = InsurancePolicyTypes.CollisionUpset;
      break;
    case InsurancePolicyStatus.ExpiredComprehensive:
      insuranceStatus = InsuranceState.Expired;
      insuranceType = InsurancePolicyTypes.Comprehensive;
      break;
    case InsurancePolicyStatus.ExpiringCollisionUpset:
      insuranceStatus = InsuranceState.Expiring;
      insuranceType = InsurancePolicyTypes.CollisionUpset;
      break;
    case InsurancePolicyStatus.ExpiringComprehensive:
      insuranceStatus = InsuranceState.Expiring;
      insuranceType = InsurancePolicyTypes.Comprehensive;
      break;
  }

  return {
    insuranceStatus,
    insuranceType,
  };
}
