export interface RequestCountBreakdown {
  [index: string]: RequestCounts;
  All: RequestCounts;
  New: RequestCounts;
  AssignedToSgi: RequestCounts;
  AssignedToGk: RequestCounts;
  Cancelled: RequestCounts;
  Completed: RequestCounts;
  RecentlyCompleted: RequestCounts;
}

export interface RequestCounts {
  [index: string]: number;
  Impoundment: number;
  Disposal: number;
  Payment: number;
  Release: number;
  NewInsurancePolicy: number;
  NewLocation: number;
  NewTowingVehicle: number;
}

export const defaultRequestCounts: RequestCounts = {
  Impoundment: 0,
  Disposal: 0,
  Payment: 0,
  Release: 0,
  NewInsurancePolicy: 0,
  NewLocation: 0,
  NewTowingVehicle: 0,
};

export const defaultRequestCountBreakdown: RequestCountBreakdown = {
  All: defaultRequestCounts,
  New: defaultRequestCounts,
  AssignedToSgi: defaultRequestCounts,
  AssignedToGk: defaultRequestCounts,
  Cancelled: defaultRequestCounts,
  Completed: defaultRequestCounts,
  RecentlyCompleted: defaultRequestCounts,
};
