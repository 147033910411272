import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import DashboardGarageKeeper from "Components/Dashboard/DashboardGarageKeeper";
import GarageKeeperDetailExternal from "Components/GarageKeepers/GarageKeeperDetailExternal";
import ImpoundedVehicleListExternal from "Components/ImpoundedVehicles/ImpoundedVehicleListExternal";
import ImpoundedVehicleDetail from "Components/ImpoundedVehicles/ImpoundedVehicleDetail";
import RequestDetail from "Components/Requests/RequestDetail";
import RequestListExternal from "Components/Requests/RequestListExternal";
import RequestListByEntity from "Components/Requests/RequestListByEntity";
import TowingVehicleListExternal from "Components/TowingVehicles/TowingVehicleListExternal";
import { UserType } from "Types/userInfo";
import ContactInfoListExternal from "Components/Contact/ContactInfoListExternal";
import LocationListExternal from "Components/Location/LocationListExternal";
import InsurancePoliciesListExternal from "Components/InsurancePolicies/InsurancePoliciesListExternal";
import UserProfile from "Components/UserProfile/UserProfile";
import NotFound from "Components/NotFound/NotFound";
import BulletinAcknowledgementProvider from "./BulletinAcknowledgementProvider";
import ContactUs from "Components/ContactUs/ContactUs";
import BulletinListStandalone from "Components/Bulletins/BulletinListStandalone";
import { BulletinType } from "Types/bulletin";
import RequireAttentionCommentsListContainer from 'Components/Comments/RequireAttentionCommentsListContainer';

function GarageKeeperRoutes() {
  const userType = useSelector(
    (state: RootStateOrAny) => state.userInfo.userType
  );
  const history = useHistory();
  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );
  
  if (userType === UserType.GarageKeeper) {
    return (
      <BulletinAcknowledgementProvider>
        <Switch>
          <Route path="/" exact>
            <DashboardGarageKeeper history={history} />
          </Route>
          <Route path="/Profile" component={GarageKeeperDetailExternal} />
          <Route path="/RequestDetail" component={RequestDetail} />
          <Route path="/RequestList">
            <RequestListExternal
              garageKeeper={garageKeeper}
              history={history}
            />
          </Route>
          <Route path="/RequestHistory" component={RequestListByEntity} />
          <Route
            path="/ImpoundedVehicles/:id"
            component={ImpoundedVehicleDetail}
          />
          <Route path="/ImpoundedVehicles">
            <ImpoundedVehicleListExternal history={history} />
          </Route>
          <Route path="/TowingVehicles">
            <TowingVehicleListExternal garageKeeper={garageKeeper} />
          </Route>
          <Route path="/Contacts">
            <ContactInfoListExternal garageKeeper={garageKeeper} />
          </Route>
          <Route path="/ContactUs" component={ContactUs} />
          <Route path="/Compounds">
            <LocationListExternal
              garageKeeper={garageKeeper}
              history={history}
            />
          </Route>
          <Route path="/InsurancePolicies">
            <InsurancePoliciesListExternal garageKeeper={garageKeeper} />
          </Route>
          <Route path="/UserProfile" component={UserProfile} />
          <Route path="/Policies">
            <BulletinListStandalone
              bulletinType={BulletinType.Policy}
              title="Policies"
            />
          </Route>
          <Route path="/Manuals">
            <BulletinListStandalone
              bulletinType={BulletinType.Manual}
              title="Manuals"
            />
          </Route>
          <Route path="/RequireAttentionComments/:filter?">            
            <RequireAttentionCommentsListContainer garageKeeper={garageKeeper} />
          </Route>
          <Route component={NotFound} />          
        </Switch>
      </BulletinAcknowledgementProvider>
    );
  } else {
    return <></>;
  }
}

export default GarageKeeperRoutes;
