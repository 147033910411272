import { useState } from "react";
import { Alert, Card, Col, Dropdown, Row } from "react-bootstrap";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import { TowingVehicle } from "Types/towingVehicle";
import Loader from "Components/Loader/Loader";
import TowingVehicleForm from "Forms/TowingVehicleForm/TowingVehicleForm";
import allActions from "Redux/allActions";
import CommentContainer from "Components/Comments/CommentContainer";
import ReusableModal from "Components/Modal/ReusableModal";
import CallBffApi from "Utils/CallBff";
import AttachmentContainer from "Components/Attachments/AttachmentContainer";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import { UserPermissions, UserType } from "Types/userInfo";
import { TowingVehicleStatus } from "Types/towingVehicleStatus";
import { EntityType } from "Types/EntityType";

interface Props {
  garageKeeperId?: string;
}

export default function TowingVehicleList(props: Props) {
  const towingVehicleList = useSelector(
    (state: RootStateOrAny) => state.towingVehicles.towingVehicles
  );
  const { userType } = useSelector((state: RootStateOrAny) => state.userInfo);

  const match = useRouteMatch<{ id: string }>();

  const dispatch = useDispatch();

  const garageKeeperId = props.garageKeeperId ?? match.params.id;

  const requestUrl = `${window.REACT_APP_API_BASEURL}towingvehicles/garageKeeperId=${garageKeeperId}`;

  const [towingVehicleToEdit, setTowingVehicleToEdit] = useState(
    undefined as TowingVehicle | undefined
  );

  const location = useLocation<TowingVehicleStatus>();
  const status: TowingVehicleStatus | undefined = location.state;

  const [towingStatusFilter, setTowingStatusFilter] = useState(
    status ? status.toString() : "Active"
  );

  const filteredTowingVehicleList = towingVehicleList.filter(
    (towingVehicle: TowingVehicle) =>
      towingStatusFilter === "All" ||
      towingVehicle.status.toString() === towingStatusFilter
  );

  const [showDeactivate, setShowDeactivate] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const { userPermissions }: { userPermissions: UserPermissions } =
    useSelector((state: RootStateOrAny) => state.userInfo);

  return (
    <>
      {(userPermissions.canCreateTowingVehicle || userPermissions.canUpdateTowingVehicle) &&
        <ReusableModal
          buttonText="Add New Towing Vehicle"
          headerText={
            towingVehicleToEdit ? "Edit Towing Vehicle" : "Add New Towing Vehicle"
          }
          open={() => {
            setTowingVehicleToEdit(undefined);
            openModal();
          }}
          close={closeModal}
          show={showModal}
        >
          <TowingVehicleForm
            submit={CallBffApi}
            cancel={closeModal}
            data={towingVehicleToEdit}
          />
        </ReusableModal>
      }
      <Loader
        url={requestUrl}
        onLoadComplete={(result: TowingVehicle[]) =>
          dispatch(allActions.TowingVehicleActions.setTowingVehicles(result))
        }
      >
        <Dropdown className="mb-2 mt-2">
          <Dropdown.Toggle
            id="towingStatusDropDown"
            data-testid="towing-status-filter"
          >
            Status: {towingStatusFilter}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => setTowingStatusFilter("All")}
              data-testid="towing-status-All"
            >
              All
            </Dropdown.Item>
            {Object.keys(TowingVehicleStatus).map((status: string) => (
              <Dropdown.Item
                key={status}
                onClick={() => setTowingStatusFilter(status)}
                data-testid={"towing-status-" + status}
              >
                {status}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <br />
        <Row data-testid="TowingVehicleContainer">
          {filteredTowingVehicleList && filteredTowingVehicleList.length > 0 ? (
            filteredTowingVehicleList.map((towingVehicle: TowingVehicle) => {
              return (
                <Col sm={12} md={6} lg={4} xl={3} key={towingVehicle.id}>
                  <Card
                    style={{ height: "95%" }}
                    data-testid={`TowingVehicleCard-${towingVehicle.id}`}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          borderBottom: "solid 1px gainsboro",
                          paddingBottom: "5px",
                        }}
                      >
                        <Row>
                          <Col data-testid={`CardTitle-${towingVehicle.id}`}>
                            {towingVehicle.year} {towingVehicle.make}{" "}
                            {towingVehicle.model}
                          </Col>
                          {userPermissions.canUpdateTowingVehicle &&
                            <Col className="col-auto">
                              <span
                                title="Edit"
                                onClick={() => {
                                  setTowingVehicleToEdit(towingVehicle);
                                  openModal();
                                }}
                              >
                                <i className="far fa-edit edit-button"></i>
                              </span>
                            </Col>
                          }
                        </Row>
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        VIN
                      </Card.Subtitle>
                      <Card.Text data-testid={`VIN-${towingVehicle.id}`}>
                        {towingVehicle.vin}
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        License Plate
                      </Card.Subtitle>
                      <Card.Text
                        data-testid={`LicensePlate-${towingVehicle.id}`}
                      >
                        {towingVehicle.licensePlate}
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Registered Owner
                      </Card.Subtitle>
                      <Card.Text
                        data-testid={`RegisteredOwner-${towingVehicle.id}`}
                      >
                        {towingVehicle.registeredOwner}
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Status
                      </Card.Subtitle>
                      <Card.Text data-testid={`Status-${towingVehicle.id}`}>
                        {towingVehicle.status}
                      </Card.Text>
                      <Card.Subtitle className="mb-2 text-muted">
                        Attachments/Comments
                      </Card.Subtitle>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AttachmentContainer
                          entityId={towingVehicle.id}
                          entityType={EntityType.TowingVehicle}
                          count={towingVehicle.attachmentCount}
                          readOnly={!userPermissions.canUpdateTowingVehicle}
                        />
                        &nbsp;
                        <CommentContainer
                          id={towingVehicle.id}
                          count={towingVehicle.commentCount}
                          entityType={EntityType.TowingVehicle}
                          orgId={userType === UserType.Admin ? garageKeeperId : undefined}
                          readOnly={!userPermissions.canUpdateTowingVehicle}
                        />
                        {userType === UserType.GarageKeeper && (
                          <>
                            <span style={{ flexGrow: 1 }} />
                            <span
                              data-testid={
                                "DeactivateButton-" + towingVehicle.id
                              }
                              className="edit-button"
                              onClick={() => {
                                setTowingVehicleToEdit(towingVehicle);
                                setShowDeactivate(true);
                              }}
                            >
                              <i className="far fa-tombstone fa-lg" />
                            </span>
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                  <br />
                </Col>
              );
            })
          ) : towingVehicleList === undefined ||
            towingVehicleList.length === 0 ? (
            <Alert variant="dark">
              <i>No Towing Vehicles found</i>
            </Alert>
          ) : (
            filteredTowingVehicleList.length === 0 && (
              <Alert variant="dark">
                <i>No towing vehicles match current filter</i>
              </Alert>
            )
          )}
        </Row>
      </Loader>
      <ConfirmDelete
        show={showDeactivate}
        onClickDelete={() => {
          dispatch(
            allActions.TowingVehicleActions.deactivateTowingVehicle(
              towingVehicleToEdit?.id ?? ""
            )
          );
          setShowDeactivate(false);
        }}
        onClickCancel={() => {
          setShowDeactivate(false);
        }}
        deleteButtonText="Deactivate"
        headerText={`Mark vehicle Inactive?`}
        dialogText={`This will deactivate your ${towingVehicleToEdit?.year} ${towingVehicleToEdit?.make} ${towingVehicleToEdit?.model}. 
        You will have to contact SGI to reactivate it! Are you sure?`}
      />
    </>
  );
}
