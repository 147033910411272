import { CommentCount } from "./comment";
import { TowingVehicleStatus } from "./towingVehicleStatus";

export interface TowingVehicle {
  id: string;
  garageKeeperId: string;
  year: number | undefined;
  make: string;
  model: string;
  colour: string;
  licensePlate: string;
  registeredOwner: string;
  vin: string;
  status: TowingVehicleStatus;
  comment: string;
  commentCount: CommentCount;
  attachmentCount: number;
}

export const defaultTowingVehicle: TowingVehicle = {
  id: "00000000-0000-0000-0000-000000000000",
  garageKeeperId: "00000000-0000-0000-0000-000000000000",
  year: undefined,
  make: "",
  model: "",
  colour: "",
  licensePlate: "",
  registeredOwner: "",
  vin: "",
  status: TowingVehicleStatus.Active,
  comment: "",
  commentCount: {
    internal: 0,
    external: 0,
  },
  attachmentCount: 0,
};
