import { useCallback, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Bulletin, BulletinType } from "Types/bulletin";
import CallBffApi from "Utils/CallBff";
import BulletinElement from "./BulletinElement";

interface Props {
  submit?: any;
  bulletins?: Bulletin[];
  bulletinType?: BulletinType;
}

export default function BulletinListGarageKeeper({
  submit = CallBffApi,
  bulletinType,
  ...props
}: Props) {
  const [loading, setLoading] = useState("Loading");

  const [bulletins, setBulletins] = useState<Bulletin[]>(props.bulletins ?? []);

  const loadBulletins = useCallback(async () => {
    let url = `${window.REACT_APP_API_BASEURL}bulletins/external`;

    if (bulletinType) {
      url += `?bulletinTypes=${bulletinType}`;
    }

    const response = await submit(url);

    if (response.ok) setBulletins(await response.json());

    setLoading("Loaded");
  }, [submit, bulletinType]);

  useEffect(() => {
    if (bulletins.length === 0 && loading !== "Loaded") loadBulletins();
  }, [bulletins, loading, loadBulletins]);

  useEffect(() => {
    setBulletins(props.bulletins ?? []);
  }, [props.bulletins]);

  if (bulletins.length > 0)
    return (
      <Row>
        <Col md={12}>
          {bulletins
            .filter((b) => !b.archived)
            .map((b: any) => {
              return (
                <BulletinElement key={b.id} bulletin={b} submit={submit} onSuccess={() => {}} />
              );
            })}
        </Col>
      </Row>
    );
  else
    return (
      <Alert variant="dark">
        <i>No {bulletinType} found</i>
      </Alert>
    );
}
