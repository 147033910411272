import { Col, ListGroup, Row } from "react-bootstrap";

import DetailLayout from "Layouts/DetailLayout";
import { sgiContactInfo } from "Types/contactInfo";
import { formatPhoneNumber } from "Utils/format";
import PhoneEmail from "Utils/PhoneEmail";

export default function ContactUs() {
  return (
    <DetailLayout title="Contact Us" header="Contact Us" subheader="">
      <ListGroup>
        <ListGroup.Item>
          <Row>
            <Col md={2} xl={1} className={"font-weight-bold"}>
              Email
            </Col>
            <Col data-testid="sgi-email">
              <PhoneEmail value={sgiContactInfo.emailAddress} type="email" />
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col md={2} xl={1} className={"font-weight-bold"}>
              Phone
            </Col>
            <Col data-testid="sgi-phone-number">
              <PhoneEmail
                value={formatPhoneNumber(sgiContactInfo.phoneNumber)}
                type="phone"
              />
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col md={2} xl={1} className={"font-weight-bold"}>
              Fax
            </Col>
            <Col data-testid="sgi-fax-number">
              {formatPhoneNumber(sgiContactInfo.faxNumber)}
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </DetailLayout>
  );
}
