import { defaultGarageKeeper, GarageKeeper } from "Types/garageKeeper";
import {
  SET_GK,
  UPDATE_LAW_ENFORCEMENT_CONTACT,
  UPDATE_MAIN_OFFICE_CONTACT,
} from "./GKProfileActions";

export interface GKProfileState {
  garageKeeper: GarageKeeper;
}

export const initialState: GKProfileState = {
  garageKeeper: defaultGarageKeeper,
};

export default function GKReducer(
  state: GKProfileState = initialState,
  action: any
) {
  switch (action.type) {
    case SET_GK:
      return {
        ...state,
        garageKeeper: action.garageKeeper,
      };

    case UPDATE_MAIN_OFFICE_CONTACT:
      return {
        ...state,
        garageKeeper: {
          ...state.garageKeeper,
          mainOfficePhoneNumber: action.contact.phoneNumber,
          mainOfficeFaxNumber: action.contact.faxNumber,
          mainOfficeEmailAddress: action.contact.emailAddress,
          mainOfficeCommunicationPreference:
            action.contact.communicationPreference,
        },
      };

    case UPDATE_LAW_ENFORCEMENT_CONTACT:
      return {
        ...state,
        garageKeeper: {
          ...state.garageKeeper,
          lawEnforcementPhoneNumber: action.contact.phoneNumber,
        },
      };

    default:
      return state;
  }
}
