import { useState, useEffect } from "react";
import CustomForm from "Components/FormElements/CustomForm";
import { Col, Form } from "react-bootstrap";
import FormText from "Components/FormElements/FormText";
import { Bulletin, defaultBulletin } from "Types/bulletin";
import { doToast, ToastType } from "Utils/toastUtils";

interface Props {
  editBulletin: Bulletin;
  onCancel: Function;
  onSubmit: any;
  onSubmitSuccess(bulletin: Bulletin): any;
}

function BulletinEditForm({
  editBulletin,
  onCancel,
  onSubmit,
  onSubmitSuccess,
}: Props) {
  const [bulletin, setBulletin] = useState<Bulletin>(defaultBulletin);

  useEffect(() => {
    setBulletin(editBulletin);
  }, [editBulletin]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) return false;

    const requestUrl =
      (window as any).REACT_APP_API_BASEURL + "bulletins/" + editBulletin.id;

    let response = await onSubmit(requestUrl, {
      method: "PUT",
      body: JSON.stringify(bulletin),
    });

    if (response.ok) {
      doToast(`Saved ${bulletin.bulletinType} successfully`, ToastType.Success);
      onSubmitSuccess(bulletin);
      return true;
    }

    const errorResult = await response.json();
    doToast(errorResult, ToastType.Error);
    return false;
  };

  const handleCancel = () => {
    setBulletin(defaultBulletin);
    onCancel();
  };

  const handleChange = (e: any) => {
    setBulletin((bulletin) => {
      return {
        ...bulletin,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      };
    });
  };

  return (
    <CustomForm
      edit
      openInEditMode
      onSubmit={handleSubmit}
      cancel={handleCancel}
    >
      <Form.Row>
        <FormText
          required
          testId="bulletin-title"
          label="Title"
          placeholder=""
          field="title"
          value={bulletin.title}
          onChange={handleChange}
          pattern={"^.{5,40}$"}
          invalidMessage=" must be between 5-40 characters"
        />
      </Form.Row>
      <Form.Row>
        <FormText
          required
          as="textarea"
          testId="bulletin-description"
          label="Description"
          placeholder=""
          field="description"
          value={bulletin.description}
          onChange={handleChange}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="4" md="6" controlId="archived">
          <Form.Label className="edit-form-label">Archived</Form.Label>
          <Form.Check
            name="archived"
            onChange={handleChange}
            checked={bulletin.archived ?? false}
          />
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}

export default BulletinEditForm;
