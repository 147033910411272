import { Attachment } from "../../Types/attachment";
import {
  ADD_ATTACHMENT,
  SET_ATTACHMENTS,
  UPDATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "./AttachmentActions";
import { RequestStatus } from "../requestStatus";

export interface AttachmentListState {
  attachments: Attachment[];
  status: RequestStatus;
}

const initialState: AttachmentListState = {
  attachments: [] as Attachment[],
  status: RequestStatus.IDLE,
};

const sortByDescription = (attachments: Attachment[]) => {
  return attachments.sort((a, b) => {
    if (a.description && b.description) {
      if (a.description !== b.description)
        return a.description.localeCompare(b.description);
      else return a.createdOn < b.createdOn ? 1 : -1;
    }

    if (!a.description) {
      if (!b.description) return a.createdOn < b.createdOn ? 1 : -1;
      else return -1;
    } else return 1;
  });
};

export default function AttachmentReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_ATTACHMENTS: {
      let newAttachments = sortByDescription(action.attachments);
      return {
        ...state,
        attachments: newAttachments,
        status: RequestStatus.IDLE,
      };
    }
    case ADD_ATTACHMENT: {
      let newAttachments = [action.attachment, ...state.attachments];
      newAttachments = sortByDescription(newAttachments);
      return {
        ...state,
        attachments: newAttachments,
        status: RequestStatus.IDLE,
      };
    }
    case DELETE_ATTACHMENT: {
      return {
        ...state,
        attachments: state.attachments.filter(
          (a) => a.id !== action.attachmentId
        ),
        status: RequestStatus.IDLE,
      };
    }
    case UPDATE_ATTACHMENT: {
      let newAttachments = [...state.attachments];
      newAttachments[
        newAttachments.findIndex((a) => a.id === action.attachment.id)
      ] = action.attachment;
      newAttachments = sortByDescription(newAttachments);
      return {
        ...state,
        attachments: newAttachments,
        status: RequestStatus.IDLE,
      };
    }
    default:
      return state;
  }
}
