export const FILTER_STATUS = "EXPIRED_INSURANCE/FILTER_STATUS";
export const FILTER_STATUS_ALL = "EXPIRED_INSURANCE/FILTER_STATUS_ALL";

export const FILTER_TYPE = "EXPIRED_INSURANCE/FILTER_TYPE";
export const FILTER_TYPE_ALL = "EXPIRED_INSURANCE/FILTER_TYPE_ALL";

export const SORT = "EXPIRED_INSURANCE/SORT";

const filterStatus = (filter: string) => {
  return {
    type: FILTER_STATUS,
    filter: filter,
  };
};

const filterStatusAll = () => {
  return {
    type: FILTER_STATUS_ALL,
  };
};

const filterType = (filter: string) => {
  return {
    type: FILTER_TYPE,
    filter: filter,
  };
};

const filterTypeAll = () => {
  return {
    type: FILTER_TYPE_ALL,
  };
};

const sortList = (sortField: string, sortDirection: string) => {
  return {
    type: SORT,
    sortField: sortField,
    sortDirection: sortDirection,
  };
};

const ExpiredInsuranceActions = {
  filterStatus,
  filterStatusAll,
  filterType,
  filterTypeAll,
  sortList,
};

export default ExpiredInsuranceActions;
