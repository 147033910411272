import { Col, Form } from "react-bootstrap";
import Accordion from "../../Components/Accordion/Accordion";
import FormText from "../../Components/FormElements/FormText";
import FormSelect from "../../Components/FormElements/FormSelect";
import { IsExpired } from "../../Components/ImpoundedVehicles/ImpoundedVehicleDetail";
import {
  ImpoundmentType,
  ImpoundmentCategory,
  ImpoundmentCategoryLabel,
} from "../../Types/impoundmentType";
import { ImpoundedVehicleStatus } from "../../Types/impoundedVehicleStatus";
import {
  ReleaseDateReason,
  ReleaseDateReasonLabel,
  VehicleImpoundment,
} from "../../Types/vehicleImpoundment";

interface Props {
  handleUpdate(event: any): void;
  vehicleImpoundment: VehicleImpoundment;
  impoundmentTypes: ImpoundmentType[];
  control: boolean;
}

export function ImpoundmentDetailsForm({
  handleUpdate,
  vehicleImpoundment,
  impoundmentTypes,
  control,
}: Props) {
  return (
    <>
      <Accordion title="Impoundment Details" defaultShow control={control}>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formPoliceFileNumber">
            <FormText
              label="Police File Number"
              testId={"policeFileNumber"}
              field="policeFileNumber"
              pattern="^.{1,40}"
              invalidMessage={" must be less than 40 characters"}
              value={vehicleImpoundment.policeFileNumber}
              onChange={handleUpdate}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formLawEnforcementEntity">
            <FormText
              label="Law Enforcement Entity"
              testId={"lawEnforcementEntity"}
              field="lawEnforcementEntity"
              pattern="^.{1,40}"
              invalidMessage={" must be less than 40 characters"}
              value={vehicleImpoundment.lawEnforcementEntity}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formCategory">
            <FormSelect
              required
              label="Category"
              testId={"category"}
              field="category"
              value={vehicleImpoundment.category}
              onChange={handleUpdate}
              defaultOption={{
                value: "",
                text: "Select Category",
              }}
              data={Object.keys(ImpoundmentCategory).filter(
                (x) => x !== "Default"
              )}
              mapping={(s) => {
                return {
                  value: s,
                  text: ImpoundmentCategoryLabel[
                    s as keyof typeof ImpoundmentCategoryLabel
                  ],
                };
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="8" controlId="formType">
            <FormSelect
              required
              label="Type"
              testId={"type"}
              field="type"
              value={vehicleImpoundment.type}
              onChange={handleUpdate}
              defaultOption={{
                value: "",
                text: "Select Type",
              }}
              data={impoundmentTypes
                .filter((x) => x.category === vehicleImpoundment.category)
                .map((x) => x.type)
                .filter(
                  (value: any, index: number, self: any) =>
                    self.indexOf(value) === index
                )}
              mapping={(s) => {
                return {
                  value: s,
                  text: s,
                };
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formTermLength">
            <FormSelect
              required
              label="Term Length"
              testId={"termLength"}
              field="termLength"
              value={
                vehicleImpoundment.termLength === -1
                  ? ""
                  : vehicleImpoundment.termLength
              }
              onChange={handleUpdate}
              defaultOption={{
                value: "",
                text: "Select Term Length",
              }}
              data={impoundmentTypes
                .filter(
                  (x) =>
                    x.category === vehicleImpoundment.category &&
                    x.type === vehicleImpoundment.type
                )
                .map((x) => x.termLength)}
              mapping={(s) => {
                return {
                  value: s,
                  text: s,
                };
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formEffectiveDate">
            <FormText
              required
              type="date"
              label="Impoundment Term Start Date"
              testId={"impoundmentStart"}
              field="impoundmentStart"
              value={vehicleImpoundment.impoundmentStart}
              onChange={handleUpdate}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            controlId="formExpiryDate"
            className={`${
              IsExpired(
                vehicleImpoundment.impoundmentEnd === undefined
                  ? undefined
                  : new Date(vehicleImpoundment.impoundmentEnd)
              ) && "input-danger"
            }`}
          >
            <FormText
              type="date"
              label="Impoundment Term End Date"
              testId={"impoundmentEnd"}
              field="impoundmentEnd"
              disabled={true}
              value={vehicleImpoundment.impoundmentEnd}
              onChange={handleUpdate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="impoundmentFormReceivedDate">
            <FormText
              required
              type="date"
              label="Impound Form Date Received"
              testId={"impoundmentFormReceivedDate"}
              field="impoundmentFormReceivedDate"
              value={vehicleImpoundment.impoundmentFormReceivedDate}
              onChange={handleUpdate}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="releaseDate">
            <FormText
              required={
                vehicleImpoundment.status === ImpoundedVehicleStatus.Released
              }
              type="date"
              label="Date Released"
              testId={"releaseDate"}
              field="releaseDate"
              value={vehicleImpoundment.releaseDate}
              onChange={handleUpdate}
              max={new Date().toISOString().split("T")[0]}
            />
          </Form.Group>
        </Form.Row>
        {vehicleImpoundment.releaseDate !== undefined && (
          <Form.Row>
            <Form.Group
              as={Col}
              md="4"
              controlId="impoundmentFormReleaseDateReason"
            >
              <FormSelect
                required={vehicleImpoundment.releaseDate !== undefined}
                label="Release Date Reason"
                testId={"releaseDateReason"}
                field="releaseDateReason"
                value={vehicleImpoundment.releaseDateReason}
                onChange={handleUpdate}
                defaultOption={{
                  value: "",
                  text: "Select Release Date Reason",
                }}
                data={Object.keys(ReleaseDateReason).sort()}
                mapping={(s) => {
                  return {
                    value: s,
                    text: ReleaseDateReasonLabel[
                      s as keyof typeof ReleaseDateReasonLabel
                    ],
                  };
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md="8"
              controlId="impoundmentFormReleaseDescription"
            >
              <FormText
                required={
                  vehicleImpoundment.releaseDateReason ===
                    ReleaseDateReason.Other ||
                  vehicleImpoundment.releaseDateReason ===
                    ReleaseDateReason.WrongfulOther
                }
                label="Release Reason Description"
                testId={"releaseReasonDescription"}
                field="releaseDateReasonDescription"
                value={vehicleImpoundment.releaseDateReasonDescription}
                onChange={handleUpdate}
              />
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Group
            as={Col}
            md="4"
            controlId="formDaysImpounded"
            data-testid={"daysImpounded"}
          >
            <Form.Label className="edit-form-label">Days Impounded</Form.Label>
            <Form.Text className="formText">
              {vehicleImpoundment.daysImpounded}
            </Form.Text>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="formInventoryFormReceived">
            <Form.Label className="edit-form-label">
              Inventory Form Received
            </Form.Label>
            <Form.Check
              name="inventoryFormReceived"
              onChange={handleUpdate}
              checked={vehicleImpoundment.inventoryFormReceived ?? false}
              className={
                "" + !vehicleImpoundment.inventoryFormReceived &&
                "doc-not-received"
              }
            />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formReleaseFormReceived">
            <Form.Label className="edit-form-label">
              Release Form Received
            </Form.Label>
            <Form.Check
              name="releaseFormReceived"
              onChange={handleUpdate}
              checked={vehicleImpoundment.releaseFormReceived ?? false}
              className={
                "" + !vehicleImpoundment.releaseFormReceived &&
                "doc-not-received"
              }
            />
          </Form.Group>
        </Form.Row>
      </Accordion>
    </>
  );
}

export default ImpoundmentDetailsForm;
