import { Comment } from '../../Types/comment'

export const SET_DELETE_ID = "COMMENTS/SET_DELETE_ID";
export const SET_EDIT_ID = "COMMENTS/SET_EDIT_ID";
export const CLEAR_STATE = "COMMENTS/CLEAR";
export const SET_LIST = "COMMENTS/SET_LIST";
export const UPDATE = "COMMENTS/UPDATE";

const setList = (comments: Comment[]) => {
  return {
    type: SET_LIST,
    value: comments
  }
}

const setEditId = (id: string) => {
  return {
    type: SET_EDIT_ID,
    value: id,
  };
};

const setDeleteId = (id: string) => {
  return {
    type: SET_DELETE_ID,
    value: id,
  };
};

const clearCommentState = () => {
  return { type: CLEAR_STATE };
};

const update = (comment: Comment) => {
  return {
    type: UPDATE,
    value: comment
  }
}

const CommentActions = {
  setEditId,
  setDeleteId,
  clearCommentState,
  setList,
  update
};

export default CommentActions;
