import { useState } from "react";
import SortButton from "Components/SortButton/SortButton";
import { Button } from "react-bootstrap";
import { Row, Table } from "react-bootstrap";
import { RequestStatusLabel } from "Types/request";
import { formatDateTime } from "Utils/format";
import RequestActions from "Redux/requests/requestActions";
import { RequestListInfo } from "Types/requestListInfo";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { UserPermissions, UserType } from "Types/userInfo";
import { RequestTypeLabel } from "Types/requestType";
import CallBffApi from "Utils/CallBff";
import { doToast, ToastType } from "Utils/toastUtils";
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import ReusableModal from "Components/Modal/ReusableModal";

interface Props {
  sortField: string;
  sortDirection: string;
  requests: RequestListInfo[];
  history: any;
}

export default function RequestListTable({
  sortField,
  sortDirection,
  requests,
  history,
}: Props) {
  const {
    userPermissions,
    userType,
  }: { userPermissions: UserPermissions; userType: UserType } = useSelector(
    (state: RootStateOrAny) => state.userInfo
  );

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;

  const [assignButtonDiabled, setAssignButtonDisabled] = useState(false);
  const [assignConfirmationOpen, setAssignConfirmationOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<RequestListInfo>();

  const dispatch = useDispatch();

  const unassign = async (request: RequestListInfo) => {
    await updateRequest(request, "", "");
  }

  const assign = async (request: RequestListInfo) => {
    await updateRequest(
      request,
      (account?.idTokenClaims as any)!.name,
      (account?.idTokenClaims as any)!.email
    );
  }

  const updateRequest = async (request: RequestListInfo, name: string, id: string) => {
    try {
      setAssignButtonDisabled(true);

      const assigneeInfo = {
        assignedToUserName: name,
        assignedToUserId: id,
        changeAssignedTo: true,
      };
      const response = await CallBffApi(
        `${window.REACT_APP_API_BASEURL}requests/${request.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(assigneeInfo),
        }
      );

      if (response.ok) {
        dispatch(
          RequestActions.updateRequest({
            ...request,
            assignedToUserName: assigneeInfo.assignedToUserName,
            assignedToUserId: assigneeInfo.assignedToUserId,
          })
        );
        doToast("Request assignee changed successfully", ToastType.Success);
        setAssignButtonDisabled(false);
        return true;
      } else {
        setAssignButtonDisabled(false);
        doToast("An error occurred!", ToastType.Error);
      }
    } catch (e) {
      setAssignButtonDisabled(false);
      doToast("An error occurred!", ToastType.Error);
    }
  }

  const checkAssign = async (request: RequestListInfo) => {
    const response = await CallBffApi(
      `${window.REACT_APP_API_BASEURL}requests/${request.id}`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      const fullRequest = await response.json()

      if (fullRequest.assignedToUserId !== undefined &&
        fullRequest.assignedToUserId !== "" &&
        fullRequest.assignedToUserId !== null) {
        setCurrentRequest({
          ...request,
          assignedToUserId: fullRequest.assignedToUserId,
          assignedToUserName: fullRequest.assignedToUserName
        });
        setAssignConfirmationOpen(true);
      }
      else assign(request);
    }
  }

  return (
    <>
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              {userType === UserType.Admin &&
                history.location.pathname !== "/RequestHistory" && (
                  <th>
                    <SortButton
                      property={"garageKeeperName"}
                      label={"GarageKeeper Name"}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      action={RequestActions.sortRequests}
                    />
                  </th>
                )}
              <th>
                <SortButton
                  property={"policeFileNumber"}
                  label={"Police File #"}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  action={RequestActions.sortRequests}
                />
              </th>
              <th>
                <SortButton
                  property={"status"}
                  label={"Status"}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  action={RequestActions.sortRequests}
                />
              </th>
              <th>
                <SortButton
                  property={"type"}
                  label={"Type"}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  action={RequestActions.sortRequests}
                />
              </th>
              <th>
                <SortButton
                  property={"createdOn"}
                  label={"Created"}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  action={RequestActions.sortRequests}
                />
              </th>
              <th>
                <SortButton
                  property={"lastUpdatedOn"}
                  label={"Last Updated"}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  action={RequestActions.sortRequests}
                />
              </th>
              {userPermissions?.canUpdateRequestsForAllGarageKeepers && (
                <th>
                  <SortButton
                    property={"assignedToUserName"}
                    label={"Assigned To"}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    action={RequestActions.sortRequests}
                  />
                </th>
              )}
            </tr>
          </thead>
          <tbody data-testid="request-table">
            {requests.length > 0 &&
              requests.map((r: RequestListInfo) => {
                return (
                  <tr
                    key={r.id}
                    data-testid={`request-row-${r.id}`}
                    onClick={() => {
                      history.push({
                        pathname: `/RequestDetail`,
                        state: {
                          ...r,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {userType === UserType.Admin &&
                      history.location.pathname !== "/RequestHistory" && (
                        <td data-testid={`gkname-${r.id}`}>
                          {r.garageKeeperName}
                        </td>
                      )}
                    <td data-testid={`policeFileNumber-${r.policeFileNumber}`}>
                      {r.policeFileNumber}
                    </td>
                    <td data-testid={`status-${r.id}`}>
                      {RequestStatusLabel[r.status]}
                    </td>
                    <td data-testid={`type-${r.id}`}>
                      {RequestTypeLabel[r.type]}
                    </td>
                    <td data-testid={`created-${r.id}`}>
                      {formatDateTime(r.createdOn)}
                    </td>
                    <td data-testid={`updated-${r.id}`}>
                      {formatDateTime(r.lastUpdatedOn)}
                    </td>
                    {userPermissions?.canUpdateRequestsForAllGarageKeepers && (
                      <>
                        <td
                          data-testid={`assignedTo-${r.id}`}
                          title={r.assignedToUserId}
                        >
                          {r.assignedToUserName}
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            style={{ width: "100%" }}
                            data-testid={`button-assign-${r.id}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!!r.assignedToUserId)
                                unassign(r);
                              else checkAssign(r);
                            }}
                            disabled={assignButtonDiabled}
                          >
                            {r.assignedToUserId ? "Unassign" : "Assign to me"}
                          </Button>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
      {currentRequest !== undefined &&
        <ReusableModal
          headerText="Assign confirmation"
          open={() => setAssignConfirmationOpen(true)}
          show={assignConfirmationOpen}
          close={() => setAssignConfirmationOpen(false)}
          dataTestId={'assign-confirmation-modal'}
        >
          <h6>Are you sure you want to assign it to yourself? The request is already assigned to {currentRequest.assignedToUserName}. Please refresh the page for an update.</h6>
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => {
              assign(currentRequest!);
              setAssignConfirmationOpen(false);
            }}>Yes</Button>
            <Button onClick={() => setAssignConfirmationOpen(false)} style={{ marginLeft: '10px' }}>No</Button>
          </div>
        </ReusableModal>
      }
    </>
  );
}
