import StatelessSortButton from "Components/StatelessSortButton/StatelessSortButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Spinner, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Feedback } from "Types/feedback";
import CallBffApi from "Utils/CallBff";
import { formatDateStripTime } from "Utils/format";
import { doToast, ToastType } from "Utils/toastUtils";

function FeedbackList() {
  const [loading, setLoading] = useState("");
  const [sortField, setSortField] = useState("createdOn");
  const [sortDirection, setSortDirection] = useState("desc");
  const [feedback, setFeedback] = useState<Feedback[]>([]);

  const sortedFeedback = useMemo(() => {
    return feedback.sort((f1, f2) => {
      let val = 0;
      if (f1[sortField] > f2[sortField]) val = 1;
      if (f1[sortField] < f2[sortField]) val = -1;
      if (sortDirection === "desc") val *= -1;
      return val;
    });
  }, [feedback, sortDirection, sortField]);

  const loadFeedback = useCallback(async () => {
    const url = window.REACT_APP_API_BASEURL + "feedback";

    setLoading("Loading");

    const response = await CallBffApi(url);

    if (response.ok) {
      setLoading("Loaded");
      const result = await response.json();
      setFeedback(result);
    } else {
      setLoading("Error");
      doToast("Error loading feedback", ToastType.Error);
    }
  }, []);

  useEffect(() => {
    if (feedback.length === 0 && loading === "") {
      setLoading("Loading");
      loadFeedback();
    }
  }, [feedback.length, loadFeedback, loading]);

  const handleSort = (field: string) => {
    // toggle sort direction if same field is clicked
    if (field === sortField)
      setSortDirection((sort) => (sort === "asc" ? "desc" : "asc"));
    else setSortField(field);
  };

  return (
    <div>
      <CSVLink
        data-testid="csv-export-button"
        data={sortedFeedback.map((f) => {
          return {
            "Date Submitted": formatDateStripTime(f.createdOn.toString()),
            "Feedback Type": f.type,
            Content: f.feedbackText,
            Author: f.author,
            Email: f.createdBy,
          };
        })}
        filename={
          "Garage Keeper Feedback - " + new Date().toDateString() + ".csv"
        }
        className="btn btn-primary"
        style={{ float: "right" }}
      >
        Export to CSV
      </CSVLink>
      <h1>Feedback</h1>

      {loading === "Loading" ? (
        <Spinner animation="border">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : feedback.length > 0 ? (
        <Table striped bordered data-testid="feedback-table">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="createdOn"
                  text="Date Submitted"
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="type"
                  text="Type"
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="feedbackText"
                  text="Content"
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="author"
                  text="Name"
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
              </th>
              <th style={{ textAlign: "left" }}>
                <StatelessSortButton
                  field="createdBy"
                  text="Email"
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedFeedback.map((f) => (
              <tr key={f.id} data-testid={"feedback-" + f.id}>
                <td data-testid="col-createdOn">
                  {formatDateStripTime(f.createdOn.toString())}
                </td>
                <td data-testid="col-type">{f.type}</td>
                <td data-testid="col-feedbackText">{f.feedbackText}</td>
                <td data-testid="col-author">{f.author}</td>
                <td data-testid="col-createdBy">{f.createdBy}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="dark">
          {loading === "Error" ? "Error loading feedback" : "No feedback found"}
        </Alert>
      )}
    </div>
  );
}

export default FeedbackList;
