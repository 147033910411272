import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import CommentActions from "Redux/comments/CommentsActions";
import { UserType } from "Types/userInfo";
import { AttentionStatus, Comment, defaultComment } from "Types/comment";
import CallBffApi from "Utils/CallBff";
import CommentEditor from "./CommentEditor";
import { EntityType } from "Types/EntityType";
import { FormCheck } from "react-bootstrap";

interface Props {
  id: string;
  author: string;
  commentToEdit?: Comment;
  onSave: Function;
  onUpdate?: Function;
  disabled?: boolean;
  entityType: EntityType;
  orgId?: string;
}

function CommentInput({
  id,
  author,
  commentToEdit,
  onSave,
  onUpdate,
  disabled = false,
  entityType,
  orgId = undefined
}: Props) {

  const userType = useSelector((state: RootStateOrAny) => state.userInfo.userType);
  const isInternal = userType === UserType.Admin;

  const garageKeeper = useSelector((state: RootStateOrAny) => state.GKProfile.garageKeeper);
  const org = userType === UserType.GarageKeeper ? garageKeeper : {};

  const [comment, setComment] = useState<Comment>(
    commentToEdit !== undefined
      ? { ...commentToEdit, edited: true }
      : {
        ...defaultComment,
        commentEntityId: id,
        author: author,
        isInternal: isInternal,
        commentEntityType: entityType,
        garageKeeperId: orgId == undefined ? org.id : orgId
      }
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableInput, setDisableInput] = useState<boolean>(false);

  const method = commentToEdit === undefined ? "POST" : "PUT";

  const dispatch = useDispatch();

  useEffect(() => {
    setErrors([]);
    if (commentToEdit?.commentText.trim() !== comment.commentText.trim()) {
      if (comment.commentText.trim() !== "") {
        setDisableSave(false);
        return;
      }
    }
    setDisableSave(true);
  }, [comment.commentText, commentToEdit?.commentText]);

  const commentButtons = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "0.5rem",
    padding: "5px 0",
  } as React.CSSProperties;

  const button = {
    width: "4rem",
    cursor: "pointer",
  } as React.CSSProperties;

  const errorBox = {
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "3px",
    borderColor: "DarkRed",
    backgroundColor: "pink",
    paddingLeft: "10px",
    color: "DarkRed",
  } as React.CSSProperties;

  const validate = () => {
    let newErrors: string[] = [];
    if (comment.commentText === "")
      newErrors.push("Please enter a comment before submitting");

    if (newErrors.length > 0) {
      setErrors([...newErrors]);
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    let requestUrl = window.REACT_APP_API_BASEURL + "comments";

    if (method === "PUT") requestUrl += "/" + comment?.id;

    setDisableSave(true);
    setDisableInput(true);

    //Call Validation here
    if (!validate()) return false;

    const response = await CallBffApi(requestUrl, {
      method: method,
      body: JSON.stringify(comment),
    });

    if (response.ok) {
      if (method === "POST") {
        const result = await response.json();
        onSave(result);
        setComment({ ...comment, commentText: "" });
      } else {
        onUpdate?.(comment);
        dispatch(CommentActions.setEditId(""));
      }
    } else {
      setErrors([...errors, "Error saving Comment"]);
    }

    setDisableInput(false);
  };

  const handleAttentionRequiredChange = () => {
    setComment(comment => {
      return {
        ...comment,
        attentionStatus: comment.attentionStatus === AttentionStatus.Required
          ? AttentionStatus.None : AttentionStatus.Required
      }
    });
  }

  return (
    <div>
      <CommentEditor
        text={comment.commentText}
        disabled={disabled}
        onChange={(e: any) =>
          setComment({ ...comment, commentText: e.target.value })
        }
      />
      <div style={commentButtons}>
        <div>
          <FormCheck
            label="Attention Required"
            checked={comment.attentionStatus === AttentionStatus.Required}
            onChange={handleAttentionRequiredChange} />
        </div>
        <button
          style={button}
          type="button"
          title="Save"
          disabled={disableSave}
          className="btn btn-primary"
          onClick={() => submit()}
        >
          &#10004;
        </button>
        {method === "PUT" && (
          <button
            style={button}
            type="button"
            title="Cancel"
            disabled={disableInput}
            className="btn btn-secondary"
            onClick={() => dispatch(CommentActions.setEditId(""))}
          >
            &#10060;
          </button>
        )}
      </div>
      {errors.length > 0 && (
        <div style={errorBox}>
          {errors.map((x) => {
            return <li>{x}</li>;
          })}
        </div>
      )}
    </div>
  );
}

export default CommentInput;
