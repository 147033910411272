import React, {
    FunctionComponent
  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
  
  type Props = {
    control: boolean,
    controlToggle: Function
  };
  
  const AccordionExpandButton: FunctionComponent<Props> = (props) => {
    fontAwesomeLibrary.add(
      faPlus,
      faMinus,
    );

    const accordionItem = {
        boxShadow: "0px 0px 4px grey",
        borderRadius: "5px",
        marginLeft: "auto",
        marginRight: "40px",
        width: "40px",
    } as React.CSSProperties;

    const accordionHeader = {
      textAlign: "center",
      fontSize: "23px",
      cursor: "pointer",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      backgroundColor: (props.control ? "#e5e5e5" : "#fafafa"),
      color: "#888888"
    } as React.CSSProperties;

    const toggle = () => {
      props.controlToggle(!props.control)
    }

    return (
      <>
        <div style={accordionItem} title={props.control ? "Collapse All" : "Expand All"}>
            <div data-testid="expand-collapse-all" style={accordionHeader} onClick={() => toggle()}>
                {props.control ? 
                  <FontAwesomeIcon icon="minus" /> : 
                  <FontAwesomeIcon icon="plus" />}
            </div>
        </div>
      </>
    );
  };
  
  export default AccordionExpandButton;
  