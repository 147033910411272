import { emptyGuid } from "Utils/guid";

export enum FeedbackType {
  General = "General",
  Issue = "Issue",
  Improvement = "Improvement",
}

export interface Feedback {
  [index: string]: any;
  id: string;
  type: FeedbackType;
  author: string;
  feedbackText: string;
  createdBy: string;
  createdOn: string | Date;
}

export const defaultFeedback: Feedback = {
  id: emptyGuid,
  type: FeedbackType.General,
  author: "",
  createdBy: "",
  createdOn: new Date(),
  feedbackText: "",
};
