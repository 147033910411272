import { Button } from "react-bootstrap";

import { LocationStatus } from "Types/location";
import { Request } from "Types/request";
import { RequestType } from "Types/requestType";
import { TowingVehicleStatus } from "Types/towingVehicleStatus";
import { UserType } from "Types/userInfo";

interface Props {
  history: any;
  request: Request;
  userType: UserType;
}

export default function RequestViewRelatedButton({
  history,
  request,
  userType,
}: Props) {
  const isExternal = userType === UserType.GarageKeeper;

  switch (request.type) {
    case RequestType.Impoundment:
    case RequestType.Disposal:
    case RequestType.Release:
    case RequestType.Payment:
    case RequestType.NewInfo:
      return (
        <Button
          data-testid="request-view-impoundment-button"
          onClick={() => {
            history.push({
              pathname: `/ImpoundedVehicles/${request.entityId}`,
              state: {
                vi: {
                  vehicleImpoundmentId: request.entityId,
                  garageKeeperId: request.garageKeeperId,
                },
              },
            });
          }}
        >
          View Impoundment
        </Button>
      );

    case RequestType.NewInsurancePolicy:
      return (
        <Button
          data-testid="request-view-policies-button"
          onClick={() => {
            history.push({
              pathname: isExternal
                ? "/InsurancePolicies"
                : `/GarageKeepers/${request.garageKeeperId}/insuranceinfo`,
            });
          }}
        >
          View Insurance Policies
        </Button>
      );

    case RequestType.NewLocation:
      return (
        <Button
          data-testid="request-view-locations-button"
          onClick={() => {
            history.push({
              pathname: isExternal
                ? "/Compounds"
                : `/GarageKeepers/${request.garageKeeperId}/compounds`,
              state: LocationStatus.Pending,
            });
          }}
        >
          View Compounds
        </Button>
      );

    case RequestType.NewTowingVehicle:
      return (
        <Button
          data-testid="request-view-towvehicles-button"
          onClick={() => {
            history.push({
              pathname: isExternal
                ? "/TowingVehicles"
                : `/GarageKeepers/${request.garageKeeperId}/towVehicles`,
              state: TowingVehicleStatus.Pending,
            });
          }}
        >
          View Towing Fleet
        </Button>
      );
  }
}
