import { Col, Form, InputGroup } from "react-bootstrap";

import FormLabel from "Components/FormElements/FormLabel";
import FormText from "Components/FormElements/FormText";
import { Contact, CommPref, ContactType } from "Types/contact";
import { patterns } from "Utils/regex";

interface Props {
  contact: Contact;
  onChange(event: any): void;
}

export function ValidateMainOfficeContact(contact: Contact) {
  if (contact.contactType !== ContactType.MainOffice) {
    return true;
  } else {
    return isEmailValid(contact) && isFaxValid(contact);
  }
}

function isEmailValid(contact: Contact): boolean {
  return !(
    CommPref[contact.communicationPreference!] === CommPref.Email &&
    !contact.emailAddress
  );
}

function isFaxValid(contact: Contact): boolean {
  return !(
    CommPref[contact.communicationPreference!] === CommPref.Fax &&
    !contact.faxNumber
  );
}

export default function MainOfficeContactForm({ contact, onChange }: Props) {
  return (
    <div data-testid="main-office-contact-form">
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="contactDescription">
          <FormText
            testId="contact-description"
            required
            label="Contact Name"
            field="description"
            value={contact.description}
            onChange={onChange}
            disabled
          />
          <Form.Control.Feedback />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="contactPhoneNumber">
          <FormText
            required
            testId="contact-phone"
            label="Phone Number"
            field="phoneNumber"
            value={contact.phoneNumber}
            pattern={patterns.phoneNumber}
            onChange={onChange}
            invalidMessage=" is invalid"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="contactEmailAddress">
          <FormLabel
            text="Email Address"
            required={
              CommPref[contact.communicationPreference!] === CommPref.Email
            }
          />
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio
                data-testid="contact-email-select"
                name="communicationPreference"
                value={CommPref.Email}
                checked={
                  CommPref[contact.communicationPreference!] === CommPref.Email
                }
                onChange={onChange}
              />
            </InputGroup.Prepend>
            <FormText
              placeholder="Email Address"
              testId="contact-email"
              field="emailAddress"
              value={contact.emailAddress}
              pattern={patterns.email}
              onChange={onChange}
              isValid={isEmailValid(contact)}
              invalidMessage={
                isEmailValid(contact)
                  ? "Email Address is invalid"
                  : "Email Address is required when preferred"
              }
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="contactFaxNumber">
          <FormLabel
            text="Fax Number"
            required={
              CommPref[contact.communicationPreference!] === CommPref.Fax
            }
          />
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio
                data-testid="contact-fax-select"
                name="communicationPreference"
                checked={
                  CommPref[contact.communicationPreference!] === CommPref.Fax
                }
                value={CommPref.Fax}
                onChange={onChange}
              />
            </InputGroup.Prepend>
            <FormText
              placeholder="Fax Number"
              testId="contact-fax"
              field="faxNumber"
              pattern={patterns.phoneNumber}
              value={contact.faxNumber}
              onChange={onChange}
              isValid={isFaxValid(contact)}
              invalidMessage={
                isFaxValid(contact)
                  ? "Fax Number is invalid"
                  : "Fax Number is required when preferred"
              }
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
    </div>
  );
}
