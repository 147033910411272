import {
  defaultUserPermissions,
  UserPermissions,
  UserType,
} from "Types/userInfo";
import { SET_USER_TYPE, SET_USER_PERMISSIONS } from "./userInfoActions";

export interface UserInfoState {
  userType: UserType;
  userPermissions: UserPermissions;
}

export const initialState: UserInfoState = {
  userType: UserType.NoAccess,
  userPermissions: defaultUserPermissions,
};

const userInfoReducer = (
  state: UserInfoState = initialState,
  action: any
): UserInfoState => {
  switch (action.type) {
    case SET_USER_TYPE:
      return { ...state, userType: action.value };

    case SET_USER_PERMISSIONS:
      return { ...state, userPermissions: action.value };

    default:
      return state;
  }
};

export default userInfoReducer;
