import { useCallback, useEffect, useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { defaultGarageKeeper, GarageKeeper } from "Types/garageKeeper";
import { GarageKeeperStatus } from "Types/garageKeeperStatus";
import { provinces } from "Utils/consts";
import CustomForm from "Components/FormElements/CustomForm";
import FormText from "Components/FormElements/FormText";
import FormSelect from "Components/FormElements/FormSelect";
import allActions from "Redux/allActions";
import { doToast, ToastType } from "Utils/toastUtils";
import { patterns } from "Utils/regex";
import { formatCustomerNumber } from "Utils/format";
import FormLabel from "Components/FormElements/FormLabel";
import { CommPref } from "Types/contact";
import { Organization } from "Types/organization";
import CallBffApi from "Utils/CallBff";
import { getBadRequestErrorsAsList } from "Utils/apiErrorUtils";

interface Props {
  data?: any;
  cancel?: any;
  submit: any;
}

enum LoadingStatus {
  Initial,
  Loading,
  Loaded,
  Failed,
}

export default function GarageKeeperFormInternal(props: Props) {
  const [garageKeeper, setGarageKeeper] = useState<GarageKeeper>(
    props.data
      ? {
          ...props.data,
          customerNumber: formatCustomerNumber(props.data.customerNumber),
        }
      : defaultGarageKeeper
  );
  const [sameName, setSameName] = useState<boolean>(false);
  const [sameNumber, setSameNumber] = useState<boolean>(false);

  const [saved, setSaved] = useState(false);

  const [securityOrgs, setSecurityOrgs] = useState<Organization[]>([]);
  const [orgLoadingStatus, setOrgLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.Initial
  );

  const dispatch = useDispatch();

  const editMode = props.data ? true : false;

  const handleUpdate = (event: any) => {
    let splitName = event.target.name.split(".");

    if (splitName[0] === "mailingAddress") {
      let fieldToUpdate = splitName[1];
      let updatedMailingAddress = {
        ...garageKeeper.mailingAddress,
        [fieldToUpdate]: event.target.value,
      };

      setGarageKeeper({
        ...garageKeeper,
        mailingAddress: {
          ...updatedMailingAddress,
        },
      });
    } else {
      setGarageKeeper({
        ...garageKeeper,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      });
    }
  };

  const isMainOfficeEmailValid = !(
    CommPref[garageKeeper.mainOfficeCommunicationPreference] ===
      CommPref.Email && !garageKeeper.mainOfficeEmailAddress
  );

  const isMainOfficeFaxValid = !(
    CommPref[garageKeeper.mainOfficeCommunicationPreference] === CommPref.Fax &&
    !garageKeeper.mainOfficeFaxNumber
  );

  const handleSubmit = async (event: any): Promise<boolean> => {
    event.preventDefault();

    const isValid = event.currentTarget.checkValidity();

    const isContactInfoValid =
      editMode || (isMainOfficeEmailValid && isMainOfficeFaxValid);

    if (!isValid || !isContactInfoValid) {
      return false;
    }

    if (sameName) {
      garageKeeper.name = garageKeeper.legalEntity!;
    }

    if (sameNumber) {
      garageKeeper.lawEnforcementPhoneNumber =
        garageKeeper.mainOfficePhoneNumber;
    }

    if (garageKeeper.securityOrganizationId === "") {
      garageKeeper.securityOrganizationId = undefined;
    }

    let gkToSubmit: GarageKeeper = { ...garageKeeper };
    gkToSubmit.mainOfficePhoneNumber =
      gkToSubmit.mainOfficePhoneNumber?.replace(/\D/g, "");
    gkToSubmit.mainOfficeFaxNumber = gkToSubmit.mainOfficeFaxNumber?.replace(
      /\D/g,
      ""
    );
    gkToSubmit.lawEnforcementPhoneNumber =
      gkToSubmit.lawEnforcementPhoneNumber?.replace(/\D/g, "");
    gkToSubmit.mailingAddress.postalCode = gkToSubmit.mailingAddress.postalCode
      .replace(/ /g, "")
      .toUpperCase();

    const requestUrl = editMode
      ? `${window.REACT_APP_API_BASEURL}garagekeepers/${gkToSubmit.id}`
      : `${window.REACT_APP_API_BASEURL}garagekeepers`;

    try {
      const response = await props.submit(requestUrl, {
        method: editMode ? "PUT" : "POST",
        body: JSON.stringify(gkToSubmit),
      });

      if (response.ok) {
        if (!editMode) {
          const result = await response.json();
          dispatch(allActions.GKList.addGarageKeeper(result));
          setGarageKeeper(defaultGarageKeeper);
        } else {
          dispatch(allActions.GKList.updateGarageKeeper(gkToSubmit));
          dispatch(allActions.GKProfile.setGK(gkToSubmit));
        }
        setSaved(true);
        doToast("Garage Keeper saved successfully", ToastType.Success);
        return true;
      } else {
        const formattedApiErrors = await getBadRequestErrorsAsList(response);

        doToast(formattedApiErrors ?? "An error occurred!", ToastType.Error);
      }
    } catch {
      doToast("An error occurred!", ToastType.Error);
    }
    return false;
  };

  const handleCancel = () => {
    if (editMode && !saved) {
      setGarageKeeper(props.data);
    }
    props.cancel?.();
  };

  const securityOrgUrl = `${
    (window as any).REACT_APP_API_BASEURL
  }auth/orgs/external`;

  const loadSecurityOrgs = useCallback(async () => {
    const response = await CallBffApi(securityOrgUrl);

    if (response.ok) {
      var result: Organization[] = await response.json();

      result.unshift({ organizationId: "", name: "<None>" });

      setSecurityOrgs(result);
      setOrgLoadingStatus(LoadingStatus.Loaded);
    } else {
      setOrgLoadingStatus(LoadingStatus.Failed);
    }
  }, [securityOrgUrl]);

  useEffect(() => {
    if (orgLoadingStatus === LoadingStatus.Initial) {
      setOrgLoadingStatus(LoadingStatus.Loading);
      loadSecurityOrgs();
    }
  }, [orgLoadingStatus, loadSecurityOrgs]);

  return (
    <CustomForm
      edit={editMode}
      onSubmit={handleSubmit}
      cancel={handleCancel}
      openInEditMode={true}
    >
      <Form.Row>
        <Form.Label>
          <h4>Garage Keeper Details</h4>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="4" md="6" controlId="formCustomerNumber">
          <FormText
            required
            label="Customer Number"
            field="customerNumber"
            value={garageKeeper.customerNumber?.toString()}
            pattern="^[0-9]{8}$"
            onChange={handleUpdate}
            invalidMessage=" must contain exactly 8 digits"
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formLegalEntity">
          <FormText
            required
            label="Legal Entity"
            field="legalEntity"
            value={garageKeeper.legalEntity}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formName">
          <FormText
            required
            label="Business Name"
            field="name"
            value={sameName ? garageKeeper.legalEntity : garageKeeper.name}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
            disabled={sameName}
          />
          {!editMode && (
            <div style={{ display: "flex" }}>
              <Form.Check
                data-testid="sameNameCheckBox"
                onChange={(e) => setSameName(e.target.checked)}
                color="black"
                formNoValidate={true}
              />
              <Form.Label>Same as Legal Entity?</Form.Label>
            </div>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="4" md="6" controlId="formStatus">
          <FormSelect
            required
            label="Status"
            field="status"
            value={garageKeeper.status}
            data={Object.keys(GarageKeeperStatus)}
            mapping={(key) => {
              return {
                value: key,
                text: GarageKeeperStatus[
                  key as keyof typeof GarageKeeperStatus
                ],
              };
            }}
            onChange={handleUpdate}
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formPrimaryContactPerson">
          <FormText
            required
            label="Primary Contact Name"
            field="primaryContactPerson"
            value={garageKeeper.primaryContactPerson}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" controlId="formCanTowSemis">
          <Form.Label className="edit-form-label">
            Tow Heavy Vehicles (over 4,600KG/ 10,000lbs)
          </Form.Label>
          <Form.Check
            name="canTowSemis"
            onChange={handleUpdate}
            checked={garageKeeper.canTowSemis ?? false}
          />
        </Form.Group>
      </Form.Row>
      {!editMode && (
        <>
          <Form.Row>
            <Form.Group
              as={Col}
              lg="4"
              md="6"
              controlId="formMainOfficePhoneNumber"
            >
              <FormText
                required
                label="Main Office Phone"
                field="mainOfficePhoneNumber"
                value={garageKeeper.mainOfficePhoneNumber}
                pattern={patterns.phoneNumber}
                onChange={handleUpdate}
                invalidMessage=" must contain 10 digits"
              />
            </Form.Group>
            <Form.Group as={Col} lg="4" md="6" controlId="formMainOfficeEmail">
              <FormLabel
                text="Main Office Email"
                required={
                  CommPref[garageKeeper.mainOfficeCommunicationPreference] ===
                  CommPref.Email
                }
              />
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Radio
                    data-testid="mainOfficeEmailSelect"
                    name="mainOfficeCommunicationPreference"
                    value={CommPref.Email}
                    checked={
                      CommPref[
                        garageKeeper.mainOfficeCommunicationPreference
                      ] === CommPref.Email
                    }
                    onChange={handleUpdate}
                  />
                </InputGroup.Prepend>
                <FormText
                  placeholder="Main Office Email"
                  testId="mainOfficeEmail"
                  field="mainOfficeEmailAddress"
                  value={garageKeeper.mainOfficeEmailAddress}
                  pattern={patterns.email}
                  onChange={handleUpdate}
                  isValid={isMainOfficeEmailValid}
                  invalidMessage={
                    isMainOfficeEmailValid
                      ? "Main Office Email is invalid"
                      : "Preferred contact method is required"
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              as={Col}
              lg="4"
              md="6"
              controlId="formMainOfficeFaxNumber"
            >
              <FormLabel
                text="Main Office Fax"
                required={
                  CommPref[garageKeeper.mainOfficeCommunicationPreference] ===
                  CommPref.Fax
                }
              />
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Radio
                    data-testid="mainOfficeFaxSelect"
                    name="mainOfficeCommunicationPreference"
                    value={CommPref.Fax}
                    checked={
                      CommPref[
                        garageKeeper.mainOfficeCommunicationPreference
                      ] === CommPref.Fax
                    }
                    onChange={handleUpdate}
                  />
                </InputGroup.Prepend>
                <FormText
                  placeholder="Main Office Fax"
                  testId="mainOfficeFax"
                  field="mainOfficeFaxNumber"
                  value={garageKeeper.mainOfficeFaxNumber}
                  pattern={patterns.phoneNumber}
                  onChange={handleUpdate}
                  isValid={isMainOfficeFaxValid}
                  invalidMessage={
                    isMainOfficeFaxValid
                      ? "Main Office Fax must contain 10 digits"
                      : "Preferred contact method is required"
                  }
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              lg="4"
              md="6"
              controlId="formLawEnforcementNumber"
            >
              <FormText
                required
                label="Law Enforcement Phone"
                field="lawEnforcementPhoneNumber"
                value={
                  sameNumber
                    ? garageKeeper.mainOfficePhoneNumber
                    : garageKeeper.lawEnforcementPhoneNumber
                }
                onChange={handleUpdate}
                pattern={patterns.phoneNumber}
                invalidMessage=" must contain 10 digits"
                disabled={sameNumber}
              />
              <div style={{ display: "flex" }}>
                <Form.Check
                  data-testid="sameNumberCheckBox"
                  onChange={(e) => setSameNumber(e.target.checked)}
                  color="black"
                  formNoValidate={true}
                />
                <Form.Label>Same as Main Office</Form.Label>
              </div>
            </Form.Group>
          </Form.Row>
        </>
      )}
      <Form.Row>
        <Form.Label>
          <h4>Mailing Address</h4>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="8" controlId="formMailingAddressStreet">
          <FormText
            required
            label="Street"
            field="mailingAddress.street"
            value={garageKeeper.mailingAddress.street}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formMailingAddressCity">
          <FormText
            required
            label="City"
            field="mailingAddress.city"
            value={garageKeeper.mailingAddress.city}
            onChange={handleUpdate}
            pattern="^.{1,100}$"
            invalidMessage=" must be 100 characters or less"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formMailingAddressProvince">
          <FormSelect
            required
            label="Province"
            field="mailingAddress.province"
            value={garageKeeper.mailingAddress.province}
            data={provinces}
            mapping={(key) => {
              return {
                value: key,
                text: key,
              };
            }}
            onChange={handleUpdate}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formMailingAddressPostalCode">
          <FormText
            required
            label="Postal Code"
            field="mailingAddress.postalCode"
            value={garageKeeper.mailingAddress.postalCode}
            pattern="^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$"
            onChange={handleUpdate}
            invalidMessage=" must match format 'A1A 1A1'"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Label>
          <h4>Security</h4>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="8" controlId="formSecurityOrganization">
          <FormSelect
            label="Organization"
            field="securityOrganizationId"
            value={garageKeeper.securityOrganizationId ?? ""}
            onChange={handleUpdate}
            data={securityOrgs}
            mapping={(o: Organization) => {
              return {
                value: o.organizationId,
                text: o.name,
              };
            }}
            sorting={(a: Organization, b: Organization) => {
              if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
              if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
              return 0;
            }}
          />
        </Form.Group>
      </Form.Row>
    </CustomForm>
  );
}
