import React from "react";
import { Row } from "react-bootstrap";
import {
  TermEndedFilter,
  WrongfulFilter,
  InventoryFormFilter,
  LawEnforcementFormFilter,
  DateRangeFilter,
} from "./FilterButtons";
import ImpoundmentSearch from "./ImpoundmentSearch";
import StatusFilter from "./StatusFilter";
import LocationFilter from "./LocationFilter";

interface Props {
  garageKeeperId?: string;
  external?: boolean;
  profileView: boolean;
}

function FilterBar(props: Props) {
  return (
    <>
      <Row>
        <ImpoundmentSearch
          garageKeeperId={props.garageKeeperId}
          external={props.external}
        />

        <div style={{ flexGrow: 1 }} />
        <WrongfulFilter />
        <TermEndedFilter />
        <InventoryFormFilter />
        {!props.external && <LawEnforcementFormFilter />}
        <StatusFilter />
        {props.profileView && <LocationFilter />}
      </Row>
      {!props.external && (
        <Row style={{ paddingBottom: "5px" }}>
          <div style={{ flexGrow: 1 }} />
          <DateRangeFilter />
        </Row>
      )}
    </>
  );
}

export default FilterBar;
