import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { default as actions } from "Redux/impoundedvehicles/ImpoundedVehicleListActions";

interface Props {
  external?: boolean;
}

export default function ImpoundmentSortButton({ external }: Props) {
  const dispatch = useDispatch();
  const { sortDirection, sortField } = useSelector(
    (state: RootStateOrAny) => state.ImpoundedVehicleList
  );

  const toggleSortDirection = () => {
    if (sortDirection === "ascending")
      dispatch(actions.sortList(sortField, "descending"));
    else dispatch(actions.sortList(sortField, "ascending"));
  };

  const tableFields = [
    { label: "VIN", property: "vin" },
    { label: "Police File #", property: "policeFileNumber", type: "String" },
    { label: "Lot Location", property: "address" },
    { label: "Term Start Date", property: "effectiveDate termStartDate" },
    { label: "Term End Date", property: "expiryDate termEndDate" },
    { label: "Days Impounded", property: "daysImpounded" },
    { label: "Garage Keeper", property: "garageKeeperName" },
    { label: "Wrongful", property: "wrongful" },
    { label: "Status", property: "status" },
    { label: "Inventory Form", property: "inventoryFormReceived" },
  ];

  return (
    <ButtonGroup>
      <Button
        data-testid="impoundment-sort-button"
        onClick={toggleSortDirection}
      >
        {sortDirection === "ascending" ? "↑" : "↓"}
      </Button>
      <DropdownButton
        as={ButtonGroup}
        data-testid="impoundment-sort-dropdown"
        title={tableFields.find((f) => f.property.includes(sortField))!.label}
      >
        {tableFields.map((field) => (
          <Dropdown.Item
            key={field.property}
            eventKey={field.property}
            data-testid={"impoundment-sort-" + field.property}
            onSelect={() =>
              dispatch(actions.sortList(field.property, sortDirection))
            }
          >
            {field.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </ButtonGroup>
  );
}
