import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "Redux/allActions";
import {
  impoundmentRequestTypes,
  RequestType,
  RequestTypeLabel,
} from "Types/requestType";
import { RequestStatus } from "Types/request";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

interface RequestCountTableProps {
  types: RequestType[];
  title?: string;
  history: any;
  internal: Boolean;
}

const DashboardRequestCountTable = ({
  title,
  types,
  history,
  internal,
}: RequestCountTableProps) => {
  const { AssignedToSgi, AssignedToGk, New } = useSelector(
    (state: RootStateOrAny) => state.dashboard.requestCounts
  );

  const dispatch = useDispatch();

  return (
    <Table style={{ textAlign: "center" }}>
      <tbody>
        <tr>
          <td className="border-top-0">
            <div className="font-weight-bold text-dark text-uppercase mb-1 text-left">
              {title}
            </div>
          </td>
          <td
            className="border-top-0"
            colSpan={2}
            style={{ fontSize: "0.8em" }}
          >
            Assigned to
          </td>
        </tr>
        <tr>
          <td
            className="text-left"
            style={{
              borderTop: "none",
              paddingTop: 0,
              fontWeight: "bold",
            }}
          >
            Type
          </td>
          <td
            style={{
              borderTop: "none",
              paddingTop: 0,
              fontWeight: "bold",
            }}
            data-testid={"assignedTo-left"}
          >
            {internal ? "SGI" : "GK"}
          </td>
          <td
            style={{
              borderTop: "none",
              paddingTop: 0,
              fontWeight: "bold",
            }}
            data-testid={"assignedTo-right"}
          >
            {internal ? "GK" : "SGI"}
          </td>
        </tr>
        {types.map((t) => (
          <tr key={t}>
            <td style={{ textAlign: "left" }}>
              {RequestTypeLabel[t as keyof typeof RequestType]}
            </td>
            <td>
              <div
                className="dashboardTableCountButton"
                data-testid={`${internal ? "SGI-" : "GK-"}${t}`}
                onClick={() => {
                  dispatch(
                    allActions.RequestActions.filterStatus(
                      internal
                        ? RequestStatus.AssignedToSgi
                        : RequestStatus.AssignedToGk
                    )
                  );
                  dispatch(allActions.RequestActions.filterType(t));
                  history.push({
                    pathname: "/RequestList",
                  });
                }}
              >
                {internal ? AssignedToSgi[t] : AssignedToGk[t]}
              </div>
            </td>
            <td>
              <div
                className="dashboardTableCountButton"
                data-testid={`${internal ? "GK-" : "SGI-"}${t}`}
                onClick={() => {
                  dispatch(
                    allActions.RequestActions.filterStatus(
                      internal
                        ? RequestStatus.AssignedToGk
                        : RequestStatus.AssignedToSgi
                    )
                  );
                  dispatch(allActions.RequestActions.filterType(t));
                  history.push({
                    pathname: "/RequestList",
                  });
                }}
              >
                {internal ? AssignedToGk[t] : AssignedToSgi[t]}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
            {internal === false &&
              types === impoundmentRequestTypes &&
              Object.values(New).some((count) => count !== 0) && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      allActions.RequestActions.filterStatus(RequestStatus.New)
                    );
                    dispatch(allActions.RequestActions.filterType("ALL"));
                    history.push({
                      pathname: "/RequestList",
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                  data-testid={"draft-detected"}
                >
                  <FontAwesomeIcon icon={faBell} size="1x" color="orange" />
                  {" You have "}
                  <a href="/RequestList">
                    {Object.keys(New).reduce((a, b) => {
                      return a + New[b];
                    }, 0)}
                  </a>
                  {" draft(s), click to continue."}
                </div>
              )}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default DashboardRequestCountTable;
