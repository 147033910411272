import { Comment } from "Types/comment";

export const SET_REQUIRE_COMMENTS = "REQUIRED_COMMENTS/LIST";
export const COMPLETE_REQUIRE_COMMENTS = "REQUIRED_COMMENTS/COMPLETE";

const setRequiredComments = (comments: Comment[]) => {
    return {
        type: SET_REQUIRE_COMMENTS,
        value: comments
    }
}

const completeComment = (comment: Comment) => {
    return {
        type: COMPLETE_REQUIRE_COMMENTS,
        value: comment
    }
}

const RequiredCommentsActions = {
    setRequiredComments,
    completeComment
}

export default RequiredCommentsActions;