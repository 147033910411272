import { useMemo, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Loader from "Components/Loader/Loader";
import { LawEnforcement } from "Types/lawEnforcement";
import LawEnforcementForm from "Forms/LawEnforcementForm/LawEnforcementForm";
import CallBffApi from "Utils/CallBff";

function LawEnforcementAttachmentList() {
  const [lawEnforcementFiles, setLawEnforcementFiles] = useState([] as LawEnforcement[]);
  const [sortField, setSortField] = useState("policeFileNumber");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedlawEnforcementFiles = useMemo(() => {
    return lawEnforcementFiles.sort((l1, l2) => {
      let val = 0;
      if (l1[sortField] > l2[sortField]) val = 1;
      if (l1[sortField] < l2[sortField]) val = -1;
      if (sortDirection === "desc") val *= -1;
      return val;
    });
  }, [lawEnforcementFiles, sortDirection, sortField]);

  const GetSortLabel = (field: string) => {
    switch(field){
      case "policeFileNumber":
        return "Police File #";
      case "createdBy":
        return "Author";
      case "createdOn":
        return "Date";
      default:
        return "Police File #"
    }
  }

  const handleSuccess = async () => {
    //Calling the get method instead of just updating the list because this shouldn't be called very often
    //And this will more accurately pick up whether a document was attached after updating the police file #
    const requestUrl = `${window.REACT_APP_API_BASEURL}lawEnforcement/list`;

    let response = await CallBffApi(requestUrl, {
      method: "GET"
    });

    if(response.ok){
      const result = await response.json();
      setLawEnforcementFiles(result)
    }
    return true;
  }

  const SortButton = () => (
    <div style={{paddingBottom: "5px"}}>
      <Button variant="outline-secondary"
        data-testid="sortDirectionButton"
        style={{marginRight: "-1px", borderRadius: ".25rem 0px 0px .25rem"}}
        onClick={() => setSortDirection((sort) => (sort === "asc" ? "desc" : "asc"))}
      >
        {sortDirection === "asc" ? "↑" : "↓"}
      </Button>
      <Dropdown style={{display: "inline-block"}}>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="sortDropdown"
          data-testid="sortButton"
          style={{borderRadius: "0px .25rem .25rem 0px"}}
        >
          {GetSortLabel(sortField)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => setSortField("policeFileNumber")}
            data-testid={"policeFileNumber-sort"}
          >
            Police File #
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setSortField("createdBy")}
            data-testid={"createdBy-sort"}
          >
            Author
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setSortField("createdOn")}
            data-testid={"createdOn-sort"}
          >
            Date
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const requestUrl = `${window.REACT_APP_API_BASEURL}lawEnforcement/list`;

  return (
    <>
      <Loader
          url={requestUrl}
          onLoadComplete={(result: LawEnforcement[]) =>
              setLawEnforcementFiles(result)
          }
      >
        {sortedlawEnforcementFiles.length > 2 && <SortButton />}
        <div
          data-testid="lawEnforcement-list"
          style={{paddingRight: "10px", maxHeight: "200px", overflowX: "hidden", overflowY: "auto"}}
        >
          {sortedlawEnforcementFiles.map((file: LawEnforcement) => {
            return (
              <LawEnforcementForm
                key={file.id}
                id={file.id}
                policeFileNumber={file.policeFileNumber}
                createdBy={file.createdBy}
                createdOn={file.createdOn}
                handleSuccess={handleSuccess} />
            );
          })}
        </div>
      </Loader>
    </>
  );
}

export default LawEnforcementAttachmentList;
