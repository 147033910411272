import React, {
    useState,
    useEffect,
    FunctionComponent
  } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";

  type Props = {
      title: any,
      defaultShow?: boolean,
      count?: number,
      control?: boolean
  };
  
  const Accordion: FunctionComponent<Props> = (props) => {
    const [hidden, setHidden] = useState<boolean>(!props.defaultShow ?? true);

    useEffect(() => {
        if(props.control !== undefined) setHidden(!props.control);
    }, [props.control]);

    fontAwesomeLibrary.add(
      faChevronRight,
      faChevronDown,
    );

    const accordionBody = {
        borderTop: "1px solid #aaaaaa",
        padding: "10px",
    } as React.CSSProperties;

    const accordionItem = {
        boxShadow: "0px 0px 4px grey",
        borderRadius: "5px",
        marginBottom: "2px",
    } as React.CSSProperties;

    const accordionHeader = {
        paddingLeft: "15px",
        paddingRight: "15px",
        fontSize: "25px",
        cursor: "pointer",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        borderBottomRightRadius: (hidden ? "5px" : "0px"),
        borderBottomLeftRadius: (hidden ? "5px" : "0px"),
        backgroundColor: (hidden ? "#fafafa" : "#e5e5e5"),
    } as React.CSSProperties;

    const indicator = {
        float: "right",
        color: "#888888"
    } as React.CSSProperties;

    return (
      <>
        <div style={accordionItem}>
            <div style={accordionHeader} onClick={() => setHidden(!hidden)}>
              <Row>
                <Col md={11}>
                  {props.title}
                  {(props.count ?? 0) > 0 && (<>&nbsp;<Badge pill variant="secondary">{props.count}</Badge></>)}
                </Col>
                <Col md={1}>
                  <span style={indicator}>{hidden ?
                      <FontAwesomeIcon icon="chevron-right" /> : 
                      <FontAwesomeIcon icon="chevron-down" />}
                  </span>
                </Col>
              </Row>
            </div>
            <div style={accordionBody} hidden={hidden}>
                {props.children}
            </div>
        </div>
      </>
    );
  };
  
  export default Accordion;
  