import { GarageKeeper } from "Types/garageKeeper";
import DetailLayout from "Layouts/DetailLayout";
import RequestListContainer from "./RequestListContainer";
import { Button } from "react-bootstrap";
import { RequestType } from "Types/requestType";
import { RequestStatus } from "Types/request";
import { UserPermissions, UserType } from "Types/userInfo";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  garageKeeper: GarageKeeper;
  history: any;
}

export default function RequestListExternal({ garageKeeper, history }: Props) {
  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  return (
    <DetailLayout
      title="Requests"
      header={garageKeeper.name}
      subheader="Requests"
    >
      {
        (userPermissions.canCreateRequestsForAllGarageKeepers || userPermissions.isGarageKeeper) &&
        <div className="mb-2">
          <Button
            data-testid="new-impoundment-request-button"
            className="font-weight-bold"
            onClick={() => {
              history.push({
                pathname: `/RequestDetail`,
                state: {
                  garageKeeperId: garageKeeper.id,
                  entityId: null,
                  type: RequestType.Impoundment,
                  status: RequestStatus.New,
                },
              });
            }}
          >
            New Impoundment Request
          </Button>{" "}
        </div>
      }

      <RequestListContainer
        garageKeeperId={garageKeeper.id}
        history={history}
        internal={false}
      />
    </DetailLayout>
  );
}
