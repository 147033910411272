import { useState } from "react";
import { Alert, CardGroup } from "react-bootstrap";
import {
  defaultInsurancePolicy,
  formatInsurancePolicy,
  InsurancePolicy,
  InsuranceState,
} from "Types/insurancePolicy";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import allActions from "Redux/allActions";
import { useRouteMatch } from "react-router-dom";
import Loader from "Components/Loader/Loader";
import ReusableModal from "Components/Modal/ReusableModal";
import InsurancePolicyForm from "Forms/InsurancePolicyForm/InsurancePolicyForm";
import CallBffApi from "Utils/CallBff";
import Accordion from "Components/Accordion/Accordion";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import InsuranceCard from "./InsuranceCard";
import { UserPermissions, UserType } from "Types/userInfo";

interface Props {
  garageKeeperId?: string;
  insurancePolicies?: InsurancePolicy[];
}

export default function InsurancePoliciesList(props: Props) {
  const insurancePolicies = useSelector(
    (state: RootStateOrAny) => state.insurancePolicies.insurancePolicies
  );

  const [showForm, setShowForm] = useState(false);
  const [policyToEdit, setPolicyToEdit] = useState(
    undefined as InsurancePolicy | undefined
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [insurancePolicyToDelete, setInsurancePolicyToDelete] = useState(
    defaultInsurancePolicy
  );

  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  const futurePolicies = insurancePolicies
    .filter((p: InsurancePolicy) => p.state === InsuranceState.Future)
    .sort((a: InsurancePolicy, b: InsurancePolicy) => {
      // Eff date only matters when same policy type
      if (a.insuranceType === b.insuranceType)
        return a.effectiveDate < b.effectiveDate ? 1 : -1;
      // Else sort by type
      return a.insuranceType > b.insuranceType ? 1 : -1;
    });

  const expiredPolicies = insurancePolicies
    .filter((p: InsurancePolicy) => p.state === InsuranceState.Expired)
    .sort((a: InsurancePolicy, b: InsurancePolicy) => {
      // Eff date only matters when same policy type
      if (a.insuranceType === b.insuranceType)
        return a.effectiveDate < b.effectiveDate ? 1 : -1;
      // Else sort by type
      return a.insuranceType > b.insuranceType ? 1 : -1;
    });

  const activePolicies = insurancePolicies
    .filter(
      (ip: InsurancePolicy) =>
        ip.state === InsuranceState.Active ||
        ip.state === InsuranceState.Expiring
    )
    .sort((a: InsurancePolicy, b: InsurancePolicy) => {
      // Eff date only matters when same policy type
      if (a.insuranceType === b.insuranceType)
        return a.effectiveDate > b.effectiveDate ? 1 : -1;
      // Else sort by type
      return a.insuranceType > b.insuranceType ? 1 : -1;
    });
  const match = useRouteMatch<{ id: string }>();

  const dispatch = useDispatch();

  const garageKeeperId = props.garageKeeperId ?? match.params.id;

  const requestUrl = `${window.REACT_APP_API_BASEURL}insurancepolicies/List?garageKeeperId=${garageKeeperId}`;

  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  const onEdit = (policy: InsurancePolicy) => {
    setPolicyToEdit(policy);
    displayForm();
  };

  const onDelete = (policy: InsurancePolicy) => {
    setInsurancePolicyToDelete(policy);
    setShowDeleteConfirm(true);
  };

  return (
    <>
      {userPermissions.canCreateInsurance &&
        <ReusableModal
          buttonText="Add Insurance Policy"
          headerText={
            policyToEdit ? "Edit Insurance Policy" : "Add Insurance Policy"
          }
          open={() => {
            setPolicyToEdit(undefined);
            displayForm();
          }}
          close={hideForm}
          show={showForm}
        >
          <InsurancePolicyForm
            submit={CallBffApi}
            cancel={hideForm}
            data={policyToEdit}
            isExternalUser={userPermissions.isGarageKeeper}
          />
        </ReusableModal>
      }
      <ConfirmDelete
        show={showDeleteConfirm}
        headerText={"Delete Insurance Policy"}
        dialogText={`Are you sure you wish to delete '${insurancePolicyToDelete.insurer} - ${insurancePolicyToDelete.insuranceType}'?`}
        requireDeleteReason={true}
        onClickDelete={(deleteReason: string) => {
          dispatch(
            allActions.InsurancePolicyActions.deleteInsurancePolicy(
              insurancePolicyToDelete.id,
              deleteReason
            )
          );
          setShowDeleteConfirm(false);
        }}
        onClickCancel={() => setShowDeleteConfirm(false)}
      />
      <Loader
        url={requestUrl}
        onLoadComplete={(result: InsurancePolicy[]) => {
          let formattedResult = [] as InsurancePolicy[];
          result.forEach((p) => formattedResult.push(formatInsurancePolicy(p)));
          dispatch(
            allActions.InsurancePolicyActions.setInsurancePolicies(
              formattedResult
            )
          );
        }}
        onBeforeLoad={() =>
          dispatch(
            allActions.InsurancePolicyActions.startLoadingInsurancePolicies()
          )
        }
      >
        <br />
        <br />
        <InsuranceAccordian
          title="Active"
          data={activePolicies}
          testId="active-policies"
          isExternalUser={userPermissions.isGarageKeeper}
          onEdit={onEdit}
          onDelete={onDelete}
          defaultShow
        />
        <InsuranceAccordian
          title="Future"
          data={futurePolicies}
          testId="future-policies"
          isExternalUser={userPermissions.isGarageKeeper}
          onEdit={onEdit}
          onDelete={onDelete}
        />
        <InsuranceAccordian
          title="Historical"
          data={expiredPolicies}
          testId="historical-policies"
          isExternalUser={userPermissions.isGarageKeeper}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Loader>
    </>
  );
}

interface InsuranceAccordianProps {
  title: string;
  data: InsurancePolicy[];
  testId: string;
  onEdit: any;
  onDelete: any;
  isExternalUser: boolean;
  defaultShow?: boolean;
}

function InsuranceAccordian({
  title,
  data,
  testId,
  onEdit,
  onDelete,
  isExternalUser,
  defaultShow = false,
}: InsuranceAccordianProps) {
  return (
    <Accordion title={title} count={data.length} defaultShow={defaultShow}>
      {data.length > 0 ? (
        <CardGroup data-testid={testId}>
          {data.map((insurancePolicy: InsurancePolicy) => {
            return (
              <InsuranceCard
                key={insurancePolicy.id}
                insurancePolicy={insurancePolicy}
                onEdit={onEdit}
                onDelete={onDelete}
                isExternalUser={isExternalUser}
              />
            );
          })}
        </CardGroup>
      ) : (
        <Alert variant="dark">
          <i>No {title} Insurance Policies found</i>
        </Alert>
      )}
    </Accordion>
  );
}
