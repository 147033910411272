import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import AdminRoutes from "./AdminRoutes";
import GarageKeeperRoutes from "./GarageKeeperRoutes";
import LawEnforcementRoutes from "./LawEnforcementRoutes";
import NoAccessRoutes from "./NoAccessRoutes";
import GKProfileActions from "Redux/garagekeepers/GKProfileActions";
import { insurancePolicyActions } from "Redux/insurancePolicies/insurancePolicyActions";
import { UserInfoActions } from "Redux/userInfo/userInfoActions";
import { UserType, UserPermissions } from "Types/userInfo";
import CallBffApi from "Utils/CallBff";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import { checkWorkAsGkFromLocalStorage } from "Utils/localStorageUtils";

export default function GlobalRouter() {
  const [status, setStatus] = useState<string>("Initial");

  const dispatch = useDispatch();

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo | null;
  const userEmail = (account?.idTokenClaims as any)?.email;

  const loadPermissions = useCallback(async () => {    
    const permissionUrl = `${window.REACT_APP_API_BASEURL}permissions`;

    const response = await CallBffApi(permissionUrl);
    if (response.ok) {
      const permissions: UserPermissions = await response.json();

      dispatch(UserInfoActions.setUserPermissions(permissions));
      if (permissions.isAdmin) {
        dispatch(UserInfoActions.setUserType(UserType.Admin));
      }
      else if (permissions.isDriverRecordsEmployee) {
        dispatch(UserInfoActions.setUserType(UserType.DriverExamEmployee));
      }
      else if (permissions.isSGIEmployee) {
        dispatch(UserInfoActions.setUserType(UserType.SGIEmployee));
      }
      else if (permissions.isGarageKeeper) {
        if (permissions.gkPermissions) {
          dispatch(UserInfoActions.setUserType(UserType.GarageKeeper));
          if (permissions.gkPermissions.length === 1)
            SetNewGk(dispatch, permissions.gkPermissions[0]);
          else if (
            checkWorkAsGkFromLocalStorage(userEmail, permissions.gkPermissions)
          ) {
            SetNewGk(
              dispatch,
              checkWorkAsGkFromLocalStorage(
                userEmail,
                permissions.gkPermissions
              )
            );
          } else {
            SetNewGk(dispatch, permissions.gkPermissions[0]);
            localStorage.setItem(
              "workAsGk",
              JSON.stringify({
                userEmail: userEmail,
                workAsGk: permissions.gkPermissions[0],
              })
            );
          }
        } else {
          dispatch(UserInfoActions.setUserType(UserType.NoAccess));
        }
      } else if (permissions.isLawEnforcement) {
        dispatch(UserInfoActions.setUserType(UserType.LawEnforcement));
      } else {
        dispatch(UserInfoActions.setUserType(UserType.NoAccess));
      }
    }
  }, [dispatch, userEmail]);

  useEffect(() => {
    if (status === "Initial") {
      setStatus("Loading");
      loadPermissions().then(() => {
        setStatus("Loaded");
      });
    }
  }, [loadPermissions, status]);

  return (
    <>
      {status === "Loading" && (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {status === "Loaded" && (
        <>
          <AdminRoutes />
          <GarageKeeperRoutes />
          <LawEnforcementRoutes />
          <NoAccessRoutes />
        </>
      )}
    </>
  );
}

export async function SetNewGk(dispatch: any, gk: any) {
  dispatch(GKProfileActions.setGK(gk));
  dispatch(insurancePolicyActions.startLoadingInsurancePolicies());
  dispatch(insurancePolicyActions.loadInsurancePolicies(gk.id));

  // Refresh permissions in case user has different permissions for different GKs
  if (gk.securityOrganizationId) {
    const permissionUrl = `${window.REACT_APP_API_BASEURL}permissions?organizationId=${gk.securityOrganizationId}`;
    const response = await CallBffApi(permissionUrl);
    if (response.ok) {
      const permissions: UserPermissions = await response.json();

      dispatch(UserInfoActions.setUserPermissions(permissions));
    }
  }
}
