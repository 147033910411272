export interface ContactInfo {
  emailAddress: string;
  phoneNumber: string;
  faxNumber: string;
}

export const sgiContactInfo: ContactInfo = {
  emailAddress: "vehicleimpoundment@sgi.sk.ca",
  phoneNumber: "3067756553",
  faxNumber: "3067758811",
};
