import Loader from "Components/Loader/Loader";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import allActions from "Redux/allActions";
import { Alert, Button, Table } from 'react-bootstrap';
import { PagedResponse } from "Types/pagination";
import { ImpoundedVehicleListInfo } from "Types/impoundedVehicleListInfo";
import { formatDate } from "Utils/format";
import { useHistory } from "react-router";

interface Props {
    vin: string;
    gkId: string;
    assign: (id: string) => void;
}

const RequestRelatedImpoundments = ({ vin, gkId, assign }: Props) => {
    const relatedImpoundmentsUrl = `${(window as any).REACT_APP_API_BASEURL
        }vehicleImpoundments/list?SearchVin=${vin}&SearchGarageKeeperId=${gkId}&FilterStatus=Active`;

    const dispatch = useDispatch();
    const requests = useSelector((state: RootStateOrAny) => state.requests);
    const history = useHistory();

    const handleImpoundmentClick = (id: string) => {
        history.push({
            pathname: `/ImpoundedVehicles/${id}`,
            state: {
                vi: {
                    vehicleImpoundmentId: id
                },
            },
        });
    }

    return (
        <div style={{ marginTop: "10px" }}>
            <Loader
                url={relatedImpoundmentsUrl}
                onLoadComplete={(response: PagedResponse) => {
                    dispatch(allActions.RequestActions.setRelatedImpoundments(response.data));
                }}>

                <h3>Vin related impoundments</h3>

                {requests.relatedImpoundments.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>VIN</th>
                                <th>Start Term</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody data-testid="related-impoundments-table">
                            {requests.relatedImpoundments.map((impoundment: ImpoundedVehicleListInfo) => {
                                return (
                                    <tr key={`related-impoundment-${impoundment.vehicleImpoundmentId}`} 
                                        onClick={() => handleImpoundmentClick(impoundment.vehicleImpoundmentId)}
                                        data-testid={`related-impoundment-${impoundment.vehicleImpoundmentId}-test`}>
                                        <td>{impoundment.vin}</td>
                                        <td>{formatDate(impoundment.termStartDate, false)}</td>
                                        <td>
                                            <Button variant="primary" 
                                                    onClick={() => assign(impoundment.vehicleImpoundmentId)}
                                                    data-testid={`assign-impoundment-${impoundment.vehicleImpoundmentId}-test`}>Assign</Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <div style={{ padding: "10px" }}>
                        <Alert variant="dark">
                            <i data-testid="no-related-impoundments-found">No impoundments found</i>
                        </Alert>
                    </div>
                )}
            </Loader>
        </div>
    );
}

export default RequestRelatedImpoundments;