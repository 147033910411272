import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Col, ListGroup, Row } from "react-bootstrap";

import GKProfile from "Redux/garagekeepers/GKProfileActions";
import {
  formatCustomerNumber,
  formatPhoneNumber,
  formatPostalCode,
} from "Utils/format";
import AttachmentContainer from "Components/Attachments/AttachmentContainer";
import CommentContainer from "Components/Comments/CommentContainer";
import { GarageKeeper } from "Types/garageKeeper";
import DetailLayout from "Layouts/DetailLayout";
import { useEffect, useCallback, useState } from "react";
import { doToast, ToastType } from "Utils/toastUtils";
import ReusableModal from "Components/Modal/ReusableModal";
import GarageKeeperFormExternal from "Forms/GarageKeeperForm/GarageKeeperFormExternal";
import CallBffApi from "Utils/CallBff";
import { EntityType } from "Types/EntityType";
import PhoneEmail from "Utils/PhoneEmail";
import { CommPref } from "Types/contact";
import { UserPermissions } from "Types/userInfo";

export default function GarageKeeperDetailExternal() {
  const dispatch = useDispatch();

  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const [showForm, setShowForm] = useState(false);
  const hideForm = () => setShowForm(false);
  const displayForm = () => setShowForm(true);

  const requestUrl = `${(window as any).REACT_APP_API_BASEURL}garagekeepers/${garageKeeper.id
    }`;

  const mailingAddress = `${garageKeeper?.mailingAddress?.street} ${garageKeeper?.mailingAddress?.city
    }, ${garageKeeper?.mailingAddress?.province} ${formatPostalCode(
      garageKeeper?.mailingAddress?.postalCode
    )}`;

  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  const loadGK = useCallback(async () => {
    try {
      const result = await CallBffApi(requestUrl);
      if (!result.ok) throw new Error("error");
      let gk: GarageKeeper = await result.json();
      dispatch(GKProfile.setGK(gk));
    } catch {
      doToast("Failed to load Garage Keeper data", ToastType.Error);
    }
  }, [dispatch, requestUrl]);

  useEffect(() => {
    loadGK();
  }, [loadGK]);

  interface RowProps {
    title: string;
    value: string | number;
    testId: string;
    type?: string;
  }

  const ProfileRow = ({ title, value, testId, type }: RowProps) => (
    <ListGroup.Item>
      <Row>
        <Col md={3} className={"font-weight-bold"}>
          {title}
        </Col>
        <Col data-testid={testId}>
          {type ? <PhoneEmail value={value} type={type} /> : value}
        </Col>
      </Row>
    </ListGroup.Item>
  );

  return (
    <DetailLayout
      title="Profile"
      header={garageKeeper.name}
      subheader="Profile"
    >
      {
        userPermissions.canUpdateGarageKeeper &&
        <ReusableModal
          buttonText="Edit Garage Keeper"
          headerText="Edit Garage Keeper"
          open={() => {
            displayForm();
          }}
          close={hideForm}
          show={showForm}
        >
          <GarageKeeperFormExternal
            submit={CallBffApi}
            cancel={hideForm}
            data={garageKeeper}
          />
        </ReusableModal>
      }

      <ListGroup>
        <ProfileRow
          title="Customer Number"
          value={formatCustomerNumber(garageKeeper.customerNumber)}
          testId="customer-number"
        />
        <ProfileRow
          title="Legal Entity"
          value={garageKeeper.legalEntity}
          testId="legal-entity"
        />
        <ProfileRow
          title="Business Name"
          value={garageKeeper.name}
          testId="business-name"
        />
        <ProfileRow
          title="Primary Contact Name"
          value={garageKeeper.primaryContactPerson}
          testId="primary-contact"
        />
        <ProfileRow
          title="Tow Heavy Vehicles (over 4,600KG/ 10,000lbs)"
          value={garageKeeper.canTowSemis ? "Yes" : "No"}
          testId="can-tow-semis"
        />
        <ProfileRow
          title="Main Office Phone"
          value={formatPhoneNumber(garageKeeper.mainOfficePhoneNumber)}
          type="phone"
          testId="main-office-phone"
        />
        <ProfileRow
          title="Law Enforcement Phone"
          value={formatPhoneNumber(garageKeeper.lawEnforcementPhoneNumber)}
          type="phone"
          testId="law-enforcement-phone"
        />
        {garageKeeper.mainOfficeCommunicationPreference === CommPref.Email && (
          <ProfileRow
            title="Main Office Email"
            value={garageKeeper.mainOfficeEmailAddress}
            testId="main-office-email"
            type="email"
          />
        )}
        {garageKeeper.mainOfficeCommunicationPreference === CommPref.Fax && (
          <ProfileRow
            title="Main Office Fax"
            value={formatPhoneNumber(garageKeeper.mainOfficeFaxNumber)}
            testId="main-office-fax"
          />
        )}
        <ProfileRow
          title="Status"
          value={garageKeeper.status}
          testId="status"
        />
        <ProfileRow
          title="Mailing Address"
          value={mailingAddress}
          testId="mailing-address"
        />
      </ListGroup>
      <br />
      <AttachmentContainer
        entityId={garageKeeper.id}
        entityType={EntityType.GarageKeeper}
        count={garageKeeper.attachmentCount}
        readOnly={!userPermissions.canUpdateGarageKeeper}
      />
      &nbsp;
      <CommentContainer
        id={garageKeeper.id}
        count={garageKeeper.commentCount ?? { internal: 0, external: 0 }}
        entityType={EntityType.GarageKeeper}
        readOnly={!userPermissions.canUpdateGarageKeeper}
      />
    </DetailLayout>
  );
}
