import DetailLayout from "Layouts/DetailLayout";
import { Button } from "react-bootstrap";
import { RootStateOrAny, useSelector } from "react-redux";
import { RequestStatus } from "Types/request";
import { RequestType } from "Types/requestType";
import ImpoundedVehicleListContainer from "./ImpoundedVehicleListContainer";
import { UserPermissions } from "Types/userInfo";

interface Props {
  history: any;
}

function ImpoundedVehicleListExternal({ history }: Props) {
  const garageKeeper = useSelector(
    (state: RootStateOrAny) => state.GKProfile.garageKeeper
  );

  const { userPermissions }: { userPermissions: UserPermissions }
    = useSelector((state: RootStateOrAny) => state.userInfo);

  return (
    <DetailLayout
      title="Impounded Vehicle List"
      header={garageKeeper.name}
      subheader="Impounded Vehicle List"
    >
      {
        userPermissions.canCreateImpoundment &&
        <Button
          data-testid="new-impoundment-request-button"
          className="mb-2 font-weight-bold"
          onClick={() => {
            history.push({
              pathname: `/RequestDetail`,
              state: {
                garageKeeperId: garageKeeper.id,
                entityId: null,
                type: RequestType.Impoundment,
                status: RequestStatus.New,
              },
            });
          }}
        >
          New Impoundment Request
        </Button>
      }
      <ImpoundedVehicleListContainer
        garageKeeperId={garageKeeper.id}
        profileView
        external
      />
    </DetailLayout>
  );
}

export default ImpoundedVehicleListExternal;
